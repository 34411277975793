import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Spacer } from '@missionlane/compass-ui';

interface ScheduledPaymentsLinkProps {
  amountOfPayments: number;
  onClick: () => void;
}

const ScheduledPaymentsLink = ({
  amountOfPayments,
  onClick,
}: ScheduledPaymentsLinkProps) => (
  <a className="flex link pointer blue hover-indigo" onClick={onClick}>
    <Icon name="calendar" color="blue" />
    <Spacer size="xs" />
    {`${amountOfPayments} payment${amountOfPayments > 1 ? 's' : ''} scheduled`}
  </a>
);
ScheduledPaymentsLink.defaultProps = {
  onShowScheduledPaymentsModal: undefined,
};

ScheduledPaymentsLink.propTypes = {
  amountOfPayments: PropTypes.number.isRequired,
  onShowScheduledPaymentsModal: PropTypes.func,
};

export default ScheduledPaymentsLink;
