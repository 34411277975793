export const convertCurrencyInputToInt = (v: string): number => {
  const stripped = v.replace(/,/g, '');
  return parseInt(stripped);
};

export const removeMask = (v: string): string => v.replace(/,/g, '');

const DIGITS_REGEX = /^[0-9]*$/;

export const currencyRules = {
  isOnlyComma: (v: string) => v.length === 1 && v === ',',
  isEmpty: (v: string) => v.length === 0,
  maxLengthReached: (v: string) => v.length > 6,
  hasOnlyDigits: (v: string) => DIGITS_REGEX.test(v),
};

export const isCurrencyValue = (v: string) => {
  if (currencyRules.isOnlyComma(v)) {
    return false;
  }

  const unmaskedValue = removeMask(v);

  if (currencyRules.isEmpty(unmaskedValue)) {
    return true;
  }

  if (currencyRules.maxLengthReached(unmaskedValue)) {
    return false;
  }

  return currencyRules.hasOnlyDigits(unmaskedValue);
};

export const formatCurrency = (v: string) => {
  const currency = removeMask(v);

  if (v.length === 0) {
    return v;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  return formatter.format(Number(currency));
};
