import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { CustomerAccountsQuery } from '@core/graphql/globalTypes';
import { MLError } from '@core/services';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CUSTOMER_ACCOUNTS_QUERY = gql`
  query CustomerAccounts {
    customer {
      id
      accounts {
        id
      }
    }
  }
`;

const AccountContext = createContext<{
  accountId: string;
  /**
   * @deprecated will be deprecated in switch to Multicard experience,
   * still in use for Singlecard experience
   */
  accountLoading?: boolean;
  /**
   * @deprecated will be deprecated in switch to Multicard experience,
   * still in use for Singlecard experience
   */
  accountError?: ApolloError;
}>({ accountId: '', accountLoading: true });

interface AccountProviderProps {
  children: React.ReactNode;
  /**
   * @deprecated will be deprecated in switch to Multicard experience,
   * still in use for Singlecard experience
   */
  initializedAccountId?: string;
}
const MultiCardAccountProvider = ({ children }: AccountProviderProps) => {
  const { accountId } = useParams<{ accountId?: string }>();

  if (!accountId) {
    // TODO
    MLError.report({ name: 'No accountId was provided for an AccountRoute!' });
    throw 'No accountId provided!';
  }

  const value = { accountId };

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

const SingleCardAccountProvider = ({
  children,
  initializedAccountId = '',
}: AccountProviderProps) => {
  const [accountId, setAccountId] = useState<string>(initializedAccountId);

  const [
    getCustomerAccounts,
    { loading: accountLoading, error: accountError },
  ] = useLazyQuery<CustomerAccountsQuery>(CUSTOMER_ACCOUNTS_QUERY, {
    onCompleted: handleCustomerAccountsResult,
  });

  /**
   * If we have to re-fetch the customer accounts, set the accountId to the id of the first
   * account in the list and notify MLError if there was more than one account in the list
   */
  function handleCustomerAccountsResult(data: CustomerAccountsQuery) {
    const accounts = data.customer?.accounts;

    if (!accounts) return;

    if (accounts.length > 1) notifyOfMultipleAccounts();

    setAccountId(accounts[0]?.id);
  }

  useEffect(() => {
    if (!accountId) {
      getCustomerAccounts();
    }
  }, [accountId]);

  const value = {
    accountId,
    accountLoading,
    accountError,
  };

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export const AccountProvider: FC<AccountProviderProps> = ({
  children,
  ...rest
}) => {
  const { showMulticardExperience } = useFlags();
  if (showMulticardExperience) {
    return (
      <MultiCardAccountProvider {...rest}>{children}</MultiCardAccountProvider>
    );
  }
  return (
    <SingleCardAccountProvider {...rest}>{children}</SingleCardAccountProvider>
  );
};

export const useAccount = () => useContext(AccountContext);

/**
 * @deprecated will be deprecated in switch to Multicard experience,
 * still in use for Singlecard experience
 */
export function notifyOfMultipleAccounts() {
  MLError.report({
    name: `Customer has more than one account, we're assuming the first is correct.`,
  });
}
