import { useMutation } from '@apollo/client';
import { UPDATE_INCOME } from '../api';
import { Customer } from '@core/graphql/globalTypes';

export const useUpdateIncomeMutation = () =>
  useMutation<Customer>(UPDATE_INCOME, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          customer(customer: Customer) {
            return {
              ...customer,
              financialInfo: data?.financialInfo,
            };
          },
        },
      });
    },
  });
