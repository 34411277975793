import cx from 'classnames';
interface AutopayListItemProps {
  label: string;
  children: React.ReactNode;
  bottom?: boolean;
}

const AutopayListItem = ({
  label,
  children,
  bottom = false,
}: AutopayListItemProps) => (
  <>
    <div className="ink fw7" key={1}>
      {label}
    </div>
    <div className={cx('light-ink', { mb4: !bottom })} key={2}>
      {children}
    </div>
  </>
);

export default AutopayListItem;
