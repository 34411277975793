import toTitleCase from '@core/utils/toTitleCase';
import { Tile, TileDetail } from './TransactionTile';

interface Props {
  category: string;
}

export const TransactionCategory = ({ category }: Props) => (
  <Tile>
    <TileDetail title="Category" value={toTitleCase(category)} />
  </Tile>
);
