import { B, P3, Spacer, H5 } from '@missionlane/compass-ui';

import { ReplacementCardLink } from './ReplacementCardLink';

export const UnlockCardCopy = () => (
  <>
    <H5 color="ink">
      <B>
        All new transactions will be blocked. This includes cash advances and
        recurring card transactions like subscriptions, and utility or other
        bill payments.
      </B>
    </H5>
    <Spacer size="m" />
    <P3>
      You can still:
      <ul>
        <li>Make payments to your account</li>
        <li>Receive refunds</li>
        <li>Incur fees</li>
      </ul>
    </P3>
    <ReplacementCardLink />
  </>
);
