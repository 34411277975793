import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import getClipUiState from '@clip/ClipProgress/hooks/types/getClipUiState';
import {
  ClipOfferStatusCodes,
  ClipOfferUiStates,
  ClipOfferTrackerItems,
} from '@clip/ClipProgress/hooks/types/ClipOffer';

import { clipOfferQuery } from '../network';
import { ClipOfferQuery } from '@core/graphql/globalTypes';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';

export const formatTrackerItemsDates = (
  trackerItems: (ClipOfferTrackerItems | null)[] | null,
) =>
  trackerItems?.map((trackItem) => ({
    ...trackItem,
    formattedMonth: dayjs(trackItem?.date).format('MMM'),
    statementNumber: Number(trackItem?.statementNumber),
  }));

const useClipOffer = () => {
  const { accountId, customerId } = useCustomerAndAccountIdentifiers();
  const { clipTrackerRelaunch, clipTrackerRelaunchControl } = useFlags();

  const skipQuery =
    (!clipTrackerRelaunch && !clipTrackerRelaunchControl) ||
    !accountId ||
    !customerId;

  const { data, loading, error } = useQuery<ClipOfferQuery>(clipOfferQuery, {
    variables: { accountId, customerId },
    skip: skipQuery,
  });

  if (
    error ||
    loading ||
    data == null ||
    data.clipOffer == null ||
    data.customer == null ||
    data.account == null
  ) {
    return {
      uiState: ClipOfferUiStates.CONTROL,
    };
  }

  const uiState = getClipUiState({
    eligibility: data.clipOffer.eligible,
    streakGroupFlag: clipTrackerRelaunchControl,
    testGroupFlag: clipTrackerRelaunch,
  });

  if (uiState === ClipOfferUiStates.CONTROL) {
    return {
      uiState: ClipOfferUiStates.CONTROL,
    };
  }

  return {
    // ui state
    uiState,
    userFirstName: data.customer.contactInfo.firstName,
    // clip
    clipStatus: data.clipOffer.status,
    currentStatementNumber: Number(data.clipOffer.currentStatementNumber),
    trackerItems: formatTrackerItemsDates(data.clipOffer.trackerItems),
    lineIncreaseAmount: data.clipOffer.lineIncreaseAmount,
    evaluationDate: data.clipOffer.evaluationDate,
    // new flags
    isCurrentPaymentPending:
      data.clipOffer.currentPaymentPending ||
      data.clipOffer.status === ClipOfferStatusCodes.PENDING_PAYMENT,
    currentPaymentStreak: data.clipOffer.currentPaymentStreak,
    neverActive: Boolean(data.clipOffer.neverActive),
    currentCreditLimit:
      (data.account.pricingDetails.creditLimit &&
        Math.round(data.account.pricingDetails.creditLimit / 100)) ||
      0,
    // TODO: https://missionlane.atlassian.net/browse/LM-7249 temporary until we get the real data
    isInGracePeriod: false,
  };
};

export default useClipOffer;

export type UseClipOfferReturn = ReturnType<typeof useClipOffer>;
