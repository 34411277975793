import { B, H4, P2, Icon, H5 } from '@missionlane/compass-ui';
import Kard from '@core/components/General/Kard/Kard';
import './OfferDetailsKard.css';
import { centsToDollars } from '@core/utils/centsToDollars';
import {
  SettlementOfferState,
  OfferAggregate,
  SettlementOfferSubtype,
} from '@core/graphql/globalTypes';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { formatDate } from '@core/utils/formatters';

interface OfferDetailsKardProps {
  offer: OfferAggregate;
  offerEndDate: string | null;
}

const OfferDetailsKard = ({ offer, offerEndDate }: OfferDetailsKardProps) => {
  if (!offer.data || !offerEndDate) {
    return null;
  }

  const {
    originalOutstandingBalance,
    originalSettlementBalance,
    remainingBalance,
    subType,
  } = offer.data;

  const formattedExpirationDate = formatDate(offerEndDate, 'MMMM D, YYYY');
  const formattedSettlementAmount = centsToDollars(originalSettlementBalance);
  const forgivenBalanceAmount =
    originalSettlementBalance &&
    originalOutstandingBalance &&
    centsToDollars(originalOutstandingBalance - originalSettlementBalance);
  const paidAmount =
    offer.data?.state === SettlementOfferState.InProgress &&
    remainingBalance !== undefined &&
    centsToDollars(originalSettlementBalance - remainingBalance);

  return (
    <Kard
      footer={
        <div className="offer-footer pv3 ph4 bg-haze-lightest flex items-center">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P2"
          >
            <span className="mr1">
              <Icon name="calendar" size="s" />
            </span>
            Plan due date
          </ResponsiveTypography>
          <div className="flex flex-auto justify-end" data-chromatic="ignore">
            <H5>{formattedExpirationDate}</H5>
          </div>
        </div>
      }
    >
      <H4>
        <B>Your Settlement Offer</B>
      </H4>
      <div className="flex justify-between w-100 mt3 items-end">
        {formattedSettlementAmount && (
          <div className="flex flex-column justify-center mr2">
            <ResponsiveTypography
              type="HEADER"
              mobileLevel="H1"
              desktopLevel="H2"
              testID="SettlementAmount"
            >
              {formattedSettlementAmount}
            </ResponsiveTypography>
            <span>
              <P2>Settlement Balance</P2>
            </span>
          </div>
        )}
        <div className="flex flex-column">
          {paidAmount && (
            <div className="flex flex-column items-end justify-end mb2">
              <H4 color="ink" testID="SettlementPaidAmount">
                {paidAmount}
              </H4>
              <span className="tr">
                <P2>Paid</P2>
              </span>
            </div>
          )}
          {subType === SettlementOfferSubtype.Sif && (
            <div className="flex flex-column items-end justify-end">
              <H4 color="inkLight" testID="SettlementForgivenBalanceAmount">
                {forgivenBalanceAmount}
              </H4>
              <span className="tr">
                <P2>Forgiven Balance</P2>
              </span>
            </div>
          )}
        </div>
      </div>
    </Kard>
  );
};

export default OfferDetailsKard;
