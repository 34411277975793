import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, Colors } from '@missionlane/compass-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { getPT } from '@core/utils/timezones';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const dateStringFormat = 'YYYYMMDD';

const getWeeks = (
  current,
  minDateParam,
  maxDateParam,
  titles,
  invalidDates,
) => {
  const minDate = getPT(minDateParam);
  const maxDate = getPT(maxDateParam);

  let date = current.startOf('month').startOf('week');
  const end = current.endOf('month').endOf('week');
  const month = current.month();

  const weeks = [];
  let week = [];

  while (dayjs(date).isSameOrBefore(end, 'day')) {
    const invalidDate = invalidDates.includes(date.format('YYYY-MM-DD'));
    const dateString = date.format(dateStringFormat);
    week.push({
      display: date.format('D'),
      isCurrentMonth: date.month() === month,
      isSelectable:
        (!minDate || dayjs(date).isSameOrAfter(minDate, 'day')) &&
        (!maxDate || dayjs(date).isSameOrBefore(maxDate, 'day')) &&
        !invalidDate,
      timestamp: date.unix(),
      title: titles && titles[dateString] ? titles[dateString] : null,
    });
    date = date.add(1, 'day');

    if (date.day() === 0) {
      weeks.push(week);
      week = [];
    }
  }

  return weeks;
};

const Day = ({
  display,
  isCurrentMonth,
  isSelected,
  isSelectable,
  onClick,
  timestamp,
  title,
}) => {
  const className = cx(
    'h2 w2 dib br-100 pt1',
    { 'ink-30': (!isCurrentMonth || !isSelectable) && !isSelected },
    { pointer: isSelectable },
    { white: isSelected },
  );

  return (
    <td className="relative pt2">
      {title && !isSelected && (
        <div className="ink-50 absolute left-0 f8 w-100 center top-0 nt1">
          {title}
        </div>
      )}

      <a
        onClick={() => {
          if (isSelectable) {
            onClick(timestamp);
          }
        }}
        className={className}
        style={isSelected ? { backgroundColor: Colors.blue } : null}
      >
        {display}
      </a>
    </td>
  );
};

/** @deprecated Please use [Datepicker]{@link @core/components/General/Datepicker} instead */
class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    // Ensure that the current date is never one of the invalid dates
    let currentDate = dayjs(props.startDate);
    while (props.invalidDates.includes(currentDate.format('YYYY-MM-DD'))) {
      currentDate = currentDate.add(1, 'day');
    }
    const current = getPT(currentDate.format('YYYYMMDD'));
    this.state = {
      current,
      weeks: getWeeks(
        current,
        props.min,
        props.max,
        props.titles,
        props.invalidDates,
      ),
    };

    this.handleDateClicked = this.handleDateClicked.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
  }

  canGoNext() {
    if (!this.props.max) {
      return true;
    }
    return this.state.current.isBefore(getPT(this.props.max), 'month');
  }

  canGoPrevious() {
    if (!this.props.min) {
      return true;
    }
    return this.state.current.isAfter(getPT(this.props.min), 'month');
  }

  handleDateClicked(timestamp) {
    this.props.onChange(timestamp);
  }

  handleNext() {
    this.setState((prevState) => {
      const current = prevState.current.add(1, 'month');
      return {
        current,
        weeks: getWeeks(
          current,
          this.props.min,
          this.props.max,
          this.props.titles,
          this.props.invalidDates,
        ),
      };
    });
  }

  handlePrevious() {
    this.setState((prevState) => {
      const current = prevState.current.subtract(1, 'month');
      return {
        current,
        weeks: getWeeks(
          current,
          this.props.min,
          this.props.max,
          this.props.titles,
          this.props.invalidDates,
        ),
      };
    });
  }

  render() {
    return (
      <table className="bg-white w-100 pa2 pa3-l tc ink table">
        <tbody>
          <tr className="ink-30">
            <td className="pa2">Sun</td>
            <td className="pa2">Mon</td>
            <td className="pa2">Tue</td>
            <td className="pa2">Wed</td>
            <td className="pa2">Thu</td>
            <td className="pa2">Fri</td>
            <td className="pa2">Sat</td>
          </tr>
          <tr>
            <td className="pv3">
              {this.canGoPrevious() && (
                <a onClick={this.handlePrevious} className="pointer">
                  <FontAwesomeIcon icon={faArrowLeft} color={Colors.blue} />
                </a>
              )}
            </td>
            <td className="pv3" colSpan="5">
              {this.state.current.format('MMMM YYYY')}
            </td>
            <td className="pv3">
              {this.canGoNext() && (
                <a onClick={this.handleNext} className="pointer">
                  <Icon name="arrowRight" color="blue" />
                </a>
              )}
            </td>
          </tr>

          {this.state.weeks.map((week) => (
            <tr className="f6" key={week[0].timestamp}>
              {week.map((day) => (
                <Day
                  key={day.timestamp}
                  onClick={this.handleDateClicked}
                  isSelected={day.timestamp === this.props.selected}
                  {...day}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

DatePicker.defaultProps = {
  max: null,
  min: null,
  selected: null,
  startDate: null,
  titles: null,
  invalidDates: [],
};

DatePicker.propTypes = {
  max: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number,
  startDate: PropTypes.string.isRequired,
  titles: PropTypes.object,
  invalidDates: PropTypes.array.isRequired,
};

export default DatePicker;
