import { P3, B, Colors, Spacing } from '@missionlane/compass-ui';

export const Tile = ({ children }: { children: React.ReactNode }) => (
  <div style={styles.tile}>{children}</div>
);

export const TileDetail = ({
  title,
  value,
}: {
  title: string;
  value?: string;
}) => (
  <P3>
    <B color="ink">{title}:</B> {value}
  </P3>
);

const styles = {
  tile: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.inkLightest,
    padding: Spacing.m,
    borderRadius: Spacing.s,
  },
};
