import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import GenericFallbackKard from '@core/components/GenericFallbacks/GenericFallbackKard';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import PaymentKardBody from './PaymentKardBody';
import PaymentKardFooter from './PaymentKardFooter';
import PaymentKardBodySettled from './PaymentKardBodySettled';
import PaymentKardBodyRecoveries from './PaymentKardBodyRecoveries';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import PaymentKardSubHeader from './PaymentKardSubHeader';
import {
  PaymentKardQueryQuery,
  PaymentKardQueryQueryVariables,
} from '@core/graphql/globalTypes';
import PaymentKardBodyClosed from './PaymentKardBodyClosed';
import { ErrorBoundary } from '@core/services/Error/Error';

export enum BucketStatus {
  BUCKET_1 = 'BUCKET_1',
  BUCKET_2_TO_5 = 'BUCKET_2_TO_5',
  BUCKET_6 = 'BUCKET_6',
  EMPTY = '',
}

export const PAYMENT_KARD_QUERY = gql`
  query PaymentKardQuery($accountId: String!) {
    account(accountId: $accountId) {
      id
      upcomingPayments {
        id
        amount
        date
        state
      }
      autopay {
        id
        amount
        nextPaymentDate
      }
    }
  }
`;

interface PaymentKardBodyProps {
  isPaymentsOverview?: boolean;
  hasPastDueBalancePayment?: boolean;
}

interface PaymentKardProps {
  isPaymentsOverview?: boolean;
}

const PaymentKardContent = ({ isPaymentsOverview }: PaymentKardBodyProps) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const {
    loading: usePaymentStatusLoading,
    isBankrupt,
    isChargedOff,
    isDebitEligible,
    isLate,
    isPastDue,
    isPaymentPlanEligible,
    isSettled,
    isRecoveries,
    minimumDue,
    pastDueBalance,
    paymentPlanURL,
    printDueDate,
    canMakePayment,
    nextStatementCloseDate,
  } = usePaymentStatus();
  const { data, loading } = useAccountIdQuery<
    PaymentKardQueryQuery,
    PaymentKardQueryQueryVariables
  >(PAYMENT_KARD_QUERY);
  const { autopay } = data?.account || {};

  const showAutopayLink =
    isPaymentPlanEligible ||
    isBankrupt ||
    isChargedOff ||
    autopay === undefined ||
    (isPastDue && !autopay.length);

  if (loading || usePaymentStatusLoading) return <LoadingSpinner />;
  if (isSettled) return <PaymentKardBodySettled />;
  if (isRecoveries) {
    return (
      <PaymentKardBodyRecoveries
        paymentPlanUrl={paymentPlanURL}
        trackingProperties={customerAndAccountIds}
      />
    );
  }
  if (isBankrupt) return <PaymentKardBodyClosed />;

  // Default Kard Body
  return (
    <>
      <PaymentKardSubHeader
        isPastDue={isPastDue}
        minimumDue={minimumDue}
        nextStatementCloseDate={nextStatementCloseDate}
        pastDueBalance={pastDueBalance}
        printDueDate={printDueDate}
        isLate={isLate}
      />
      <PaymentKardBody isPaymentsOverview={isPaymentsOverview} />
      <PaymentKardFooter
        trackingProperties={customerAndAccountIds}
        disableAutopayLink={showAutopayLink}
        autopayOn={!!autopay?.length}
        isDebitEligible={!!isDebitEligible}
        isPastDue={isPastDue}
        isPaymentsOverview={isPaymentsOverview}
        canMakePayment={canMakePayment}
      />
    </>
  );
};

const PaymentKard = ({ isPaymentsOverview }: PaymentKardProps) => {
  const { isSettled, isRecoveries, isBankrupt } = usePaymentStatus();
  const isDefaultKard = !isSettled && !isRecoveries && !isBankrupt;
  const paymentKardHeader: KardHeader | undefined =
    isDefaultKard ?
      { textPrimary: 'Current bill', level: 'H4', className: 'mb3' }
    : undefined;

  return (
    <ErrorBoundary
      fallback={() => <GenericFallbackKard header={paymentKardHeader} />}
    >
      <Kard header={paymentKardHeader} testID="payment-kard">
        <PaymentKardContent isPaymentsOverview={isPaymentsOverview} />
      </Kard>
    </ErrorBoundary>
  );
};

export default PaymentKard;
