import PageWrapper from '@core/components/Page/PageWrapper';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import Breadcrumbs from '@core/components/General/Breadcrumbs/Breadcrumbs';
import { Breadcrumb } from '@core/components/General/Breadcrumbs/Breadcrumb';
import {
  OfferAggregate,
  ServicingOffersTransaction,
} from '@core/graphql/globalTypes';
import { useEffect, useState } from 'react';
import OfferQuestions from './OfferQuestions';
import OfferOverview from './OfferOverview';
import OfferFundingDetails from './OfferFundingDetails';
import PaymentSchedule from './PaymentSchedule';
import OfferConfirmation from './OfferConfirmation';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import CadenceAndDate from './CadenceAndDate';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { useLocation, useNavigate } from 'react-router-dom';
import ExitSetupModal from './ExitSetupModal';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

import './PaymentPlanFlow.css';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

// To create a payment plan, the customer must provide information in a sequence of steps. The sequence goes as follows:
enum PaymentPlanStep {
  OFFER_QUESTIONS = 'OFFER_QUESTIONS', // 1. Customer answers some questions about their financials such as how much and how often they want to pay
  OFFER_OVERVIEW = 'OFFER_OVERVIEW', // 2. We display an offer that was generated based on the answers provided before
  CADENCE_AND_DATE = 'CADENCE_AND_DATE', // 3. Customer selects the payment plan cadence and initial payment date
  PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE', // 4. We display the payment schedule
  FUNDING_DETAILS = 'FUNDING_DETAILS', // 5. We ask for bank account info
  CONFIRMATION = 'CONFIRMATION', // 6. We display disclosures and a confirmation screen
}

export interface PaymentPlanStepProps {
  previousStep?: () => void;
  nextStep?: () => void;
  offer?: OfferAggregate | null;
  flowId?: string;
}

// Advance customer further in the flow depending on the offer state
const getPlanStep = (pendingOffer: OfferAggregate): PaymentPlanStep => {
  const hasPaymentPlan = pendingOffer.data?.paymentPlan;
  const hasFundingAccount = pendingOffer.data?.fundingAccountId;

  if (hasFundingAccount && hasPaymentPlan) {
    return PaymentPlanStep.CONFIRMATION;
  }

  if (hasPaymentPlan) {
    return PaymentPlanStep.PAYMENT_SCHEDULE;
  }

  return PaymentPlanStep.OFFER_OVERVIEW;
};

const CreatePaymentPlanFlow = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activeOffer, fundingAccounts, flowId, refetch, loading, error } =
    useSettlementOffers();
  const { isMobile } = useUserDevice();
  const fundingAccountId = activeOffer?.data?.fundingAccountId;
  const fundingAccountLast4 = fundingAccounts?.find(
    (fundingAccount) => fundingAccount.id === fundingAccountId,
  )?.numberLast4;
  const [paymentPlanStep, setPaymentPlanStep] = useState<PaymentPlanStep>();

  useEffect(() => {
    if (!paymentPlanStep && activeOffer !== undefined) {
      if (activeOffer) {
        if (location.state?.addBankAccountSuccess === true) {
          nextStep(PaymentPlanStep.FUNDING_DETAILS);
        } else {
          const step: PaymentPlanStep = getPlanStep(activeOffer);
          nextStep(step);
        }
        return;
      }
      setPaymentPlanStep(PaymentPlanStep.OFFER_QUESTIONS);
    }
  }, [activeOffer, location.state]);

  const nextStep = async (nextStep: PaymentPlanStep) => {
    await refetch();
    setPaymentPlanStep(nextStep);
  };

  const navigate = useNavigate();
  const pageTitle = useAccountPageTitle('Visa');

  const goToDashboard = () => {
    navigate('../../summary');
  };

  const breadCrumbs: Breadcrumb[] = [
    {
      text: 'Home',
      onClickOverride: () => setIsModalOpen(true),
    },
    { text: 'Set up a Plan' },
  ];

  let Step;

  switch (paymentPlanStep) {
    case PaymentPlanStep.OFFER_QUESTIONS:
      //TODO use canGoBack to determine if the user can go back to previous question once it's implemented in apollo
      Step = (
        <OfferQuestions
          nextStep={() => nextStep(PaymentPlanStep.OFFER_OVERVIEW)}
          flowId={flowId}
        />
      );
      break;
    case PaymentPlanStep.OFFER_OVERVIEW:
      Step = (
        <OfferOverview
          offer={activeOffer}
          nextStep={() => nextStep(PaymentPlanStep.CADENCE_AND_DATE)}
        />
      );
      break;
    case PaymentPlanStep.CADENCE_AND_DATE:
      Step = (
        <CadenceAndDate
          nextStep={() => nextStep(PaymentPlanStep.PAYMENT_SCHEDULE)}
          previousStep={() => nextStep(PaymentPlanStep.OFFER_OVERVIEW)}
          offer={activeOffer}
          fundingAccountLast4={fundingAccountLast4}
        />
      );
      break;
    case PaymentPlanStep.PAYMENT_SCHEDULE:
      Step = (
        <PaymentSchedule
          paymentList={
            activeOffer?.data?.paymentPlan
              ?.transactions as ServicingOffersTransaction[]
          }
          nextStep={() => nextStep(PaymentPlanStep.FUNDING_DETAILS)}
          previousStep={() => nextStep(PaymentPlanStep.CADENCE_AND_DATE)}
        />
      );
      break;
    case PaymentPlanStep.FUNDING_DETAILS:
      Step = (
        <OfferFundingDetails
          offer={activeOffer}
          nextStep={() => nextStep(PaymentPlanStep.CONFIRMATION)}
          initialSelectedFundingAccountId={
            location.state?.newDefaultFundingAccountId
          }
        />
      );
      break;
    case PaymentPlanStep.CONFIRMATION:
      Step = (
        <OfferConfirmation
          offer={activeOffer}
          nextStep={goToDashboard}
          fundingAccountLast4={fundingAccountLast4}
        />
      );
      break;
  }

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper pageTitle={pageTitle} greyBackground={!isMobile}>
      {error ?
        <GenericFallbackFull className="bg-haze-lightest" />
      : <>
          <Breadcrumbs items={breadCrumbs} />
          {Step}
          <ExitSetupModal
            isOpen={isModalOpen}
            onExitSetup={goToDashboard}
            onClose={() => setIsModalOpen(false)}
          />
        </>
      }
    </PageWrapper>
  );
};

export default CreatePaymentPlanFlow;
