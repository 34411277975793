export const COPY = {
  TITLE_CLIP: 'You’re on track for an increase!',
  TITLE_CASHBACK: 'You’re on track for an upgrade!',
} as const;

export const TEST_ID = {
  SPEND_BONUS__PENDING: 'SPEND_BONUS__PENDING',
  SPEND_BONUS__PENDING__MESSAGE__CLIP: 'SPEND_BONUS__PENDING__MESSAGE__CLIP',
  SPEND_BONUS__PENDING__MESSAGE__CASHBACK:
    'SPEND_BONUS__PENDING__MESSAGE__CASHBACK',
} as const;
