import { useMemo } from 'react';
import dayjs from 'dayjs';
import { ScheduledPayment } from '@core/utils/convertAutopayToScheduledPayment';
import PaymentsOverviewItems from './PaymentsOverviewItems';
import { gql } from '@apollo/client';
import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';
import {
  ActivityState,
  GetPaymentsOverview,
  GetPaymentsOverviewQuery,
  GetPaymentsOverviewQueryVariables,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { Button, Icon, Notification } from '@missionlane/compass-ui';
import PageWrapper from '@core/components/Page/PageWrapper';
import { formatCardLast4 } from '@core/utils/formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import PaymentKard from '@payments/components/PaymentKard/PaymentKard';
import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import TrackingLink from '@core/components/General/TrackingLink';
import { ScheduledPaymentListItemProps } from '@payments/components/ScheduledPayments/ScheduledPaymentListItem';
import { centsToDollars } from '@core/utils/centsToDollars';
import ScheduledPaymentsList from '@payments/components/ScheduledPayments/ScheduledPaymentsList';
import NoScheduledPayments from '../ScheduledPayments/NoScheduledPayments';
import { getOrdinalFromDayOfMonth } from '@core/utils/getOrdinalDate';
import { getAmountDisplay } from '../Autopay/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { canCancelPayment } from '@payments/utils/canCancelPayment';

export const PAYMENTS_OVERVIEW_QUERY = gql`
  query GetPaymentsOverview(
    $accountId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    account(accountId: $accountId) {
      id
      autopay {
        ...AutopayFields
      }
      upcomingPayments {
        ...PaymentFields
      }
      payments(fromDate: $fromDate, toDate: $toDate) {
        ...PaymentFields
      }
      cardDetails {
        last4
      }
    }
  }
  ${PAYMENT_FIELDS}
  ${AUTOPAY_FIELDS}
`;

interface PaymentsHistoryProps {
  onClick: () => void;
  payments?: GetPaymentsOverview.Payments[];
  upcomingPayments?: ScheduledPayment[];
}

const PaymentsHistory = ({
  onClick,
  payments,
  upcomingPayments,
}: PaymentsHistoryProps) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const paymentHistoryKardHeader: KardHeader = {
    textPrimary: 'Completed',
    level: 'H4',
    className: 'mb2',
  };

  if (payments === undefined || upcomingPayments === undefined) {
    return (
      <>
        <Kard header={paymentHistoryKardHeader} testID="payment-history-kard">
          <Notification level="warning">
            Sorry, we're having trouble showing your payment history. Please
            check back soon.
          </Notification>
        </Kard>
      </>
    );
  }
  const historicalPayments =
    payments
      .filter(
        (payment) =>
          payment.state === ActivityState.Pending ||
          payment.state === ActivityState.Posted
      )
      .slice(0, 5) || [];

  return (
    <Kard header={paymentHistoryKardHeader} testID="payment-history-kard">
      <PaymentsOverviewItems payments={historicalPayments} />
      {historicalPayments.length > 0 && (
        <div className="mt4">
          <TrackingLink
            onClick={onClick}
            trackingName="More payment history"
            trackingProperties={customerAndAccountIds}
          >
            See more
          </TrackingLink>
        </div>
      )}
    </Kard>
  );
};

const PaymentsOverview = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { showMulticardExperience } = useFlags();
  const navigate = useNavigate();
  const { isSettled, isRecoveries, isPastDue, canEnrollInAutopay, hasAutopay } =
    usePaymentStatus();
  const { data, loading } = useAccountIdQuery<
    GetPaymentsOverviewQuery,
    GetPaymentsOverviewQueryVariables
  >(PAYMENTS_OVERVIEW_QUERY, {
    variables: {
      // fetch 3 months of payments
      fromDate: dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const scheduledPaymentsKardHeader: KardHeader = {
    textPrimary: 'Scheduled',
    level: 'H4',
    className: 'mb2',
  };

  const { account } = data || {};
  const {
    payments: accountPayments,
    upcomingPayments = [],
    autopay = [],
    cardDetails,
  } = account || {};

  const mappedUpcomingPayments = useMemo(
    () =>
      upcomingPayments.reduce<ScheduledPaymentListItemProps[]>(
        (payments, payment) => {
          if (payment.state === ActivityState.Scheduled) {
            return [
              ...payments,
              {
                iconName: 'calendar',
                label: 'One-Time',
                secondaryLabel: centsToDollars(payment.amount),
                description: dayjs(payment.date).format('MMM DD, YYYY'),
                linkComponent: canCancelPayment(payment) ? (
                  <TrackingLink
                    to={`scheduled-payments/cancel-payment/${payment.id}`}
                    trackingName="Cancel Scheduled Payment"
                    trackingProperties={customerAndAccountIds}
                  >
                    Cancel
                  </TrackingLink>
                ) : null,
              },
            ];
          }
          return payments;
        },
        []
      ),
    [upcomingPayments]
  );

  const mappedAutopay: ScheduledPaymentListItemProps[] = useMemo(
    () =>
      autopay.map((autopayItem) => {
        return {
          iconName: 'autopay',
          label: 'Autopay',
          secondaryLabel: getAmountDisplay(
            autopayItem.paymentType,
            autopayItem.amount
          ),
          description: `${dayjs(autopayItem.nextPaymentDate).format(
            'MMM DD, YYYY'
          )} (every ${getOrdinalFromDayOfMonth(autopayItem.dayOfMonth)})`,
          linkComponent: (
            <TrackingLink
              to="autopay/manage"
              trackingName="Edit Autopay from Scheduled Payments"
              trackingProperties={customerAndAccountIds}
            >
              <Icon name="edit" color="blue" />
            </TrackingLink>
          ),
        };
      }),
    [autopay]
  );

  const scheduledPaymentsData = [...mappedUpcomingPayments, ...mappedAutopay];

  if (loading) return <LoadingSpinnerPage />;

  const pageTitle = showMulticardExperience
    ? {
        primaryText: 'Payments',
        secondaryText: formatCardLast4(cardDetails?.last4),
      }
    : undefined;

  const ScheduledPaymentsListKard = () => (
    <Kard header={scheduledPaymentsKardHeader} testID="scheduled-payments-kard">
      {scheduledPaymentsData.length ? (
        <>
          <ScheduledPaymentsList scheduledPayments={scheduledPaymentsData} />
          {!hasAutopay && canEnrollInAutopay && (
            <div className="flex justify-center mt5">
              <Button
                variant="outlined"
                text="Set up Autopay"
                onPress={() => navigate('autopay')}
              />
            </div>
          )}
        </>
      ) : (
        <NoScheduledPayments
          isRecoveries={isRecoveries}
          isSettled={!!isSettled}
          isPastDue={isPastDue}
        />
      )}
    </Kard>
  );

  return (
    <PageWrapper
      pageTitle={pageTitle}
      trackingProperties={{
        featureName: 'Payments',
        pageName: 'Payments Overview',
      }}
    >
      <div id="payments-overview" className="sans-serif lh-copy pb6">
        <AccountBreadcrumbs items={[{ text: 'Payments' }]} />
        <div className="flex ph0-l">
          <div className="w-50-l pr4-l">
            <div className="mb4">
              <PaymentKard isPaymentsOverview />
            </div>
            <div className="db dn-l mb4">
              <ScheduledPaymentsListKard />
            </div>
            <PaymentsHistory
              payments={accountPayments}
              upcomingPayments={upcomingPayments}
              onClick={() => navigate('../payments/payments-completed')}
            />
          </div>
          <div className="dn db-l w-50">
            <ScheduledPaymentsListKard />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PaymentsOverview;
