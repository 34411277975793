import { B, Notification, P3 } from '@missionlane/compass-ui';
import { centsToDollars } from '@core/utils/centsToDollars';
import {
  ActivityState,
  BalanceInfoQuery,
  BalanceInfoQueryVariables,
} from '@core/graphql/globalTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BucketsBody from './BucketsBody';
import { useNavigate } from 'react-router-dom';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { BALANCE_INFO_QUERY } from '../MakePayment/gql/queries';
import ScheduledPaymentsLink from '@payments/components/PaymentKard/ScheduledPaymentsLink';
import { getUTC } from '@core/utils/timezones';
import { useEffect } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';
dayjs.extend(isSameOrBefore);

interface PaymentKardBodyProps {
  isPaymentsOverview?: boolean;
  currentBalance?: number | undefined;
}

interface PastDueBodyProps {
  message: string;
}

export const LATE_TEXT = `Your payment is late, but there’s still time to avoid becoming past due if you can pay now.`;
export const LATE_COVERED_TEXT = (dateCovered: string) =>
  `Your minimum due is currently late, but your scheduled payment on ${dayjs(
    dateCovered,
  ).format('MMM D')}, will cover it.`;
export const LATE_NOT_COVERED_TEXT = `Your scheduled payment doesn’t meet your minimum due, but there’s still time to make another payment and avoid becoming past due.`;
export const LATE_NOT_COVERED_TIMING_TEXT = `Your scheduled payment date is past your due date, so you’ll still need to make your minimum payment to avoid additional fees or finance charges.`;

const PaymentKardBody = ({ isPaymentsOverview }: PaymentKardBodyProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const {
    loading: paymentStatusLoading,
    isBucket1,
    isBucket2to5,
    printDueDate,
    upcomingPayments,
    autopay,
    minimumDue,
    pastDueBalance,
    isPastDue,
    isBucket6,
    isLate,
    printCycleDate,
    nextStatementCloseDate,
    hasPastDueBalancePayment,
  } = usePaymentStatus();
  const { showPastDueExperience, showRemainingStatementBalance } = useFlags();

  const { data } = useAccountIdQuery<
    BalanceInfoQuery,
    BalanceInfoQueryVariables
  >(BALANCE_INFO_QUERY);

  const { remainingStatementBalance } = data?.account?.balanceInfo || {};
  const noMinDueWithStatementBalance = !!(
    showRemainingStatementBalance &&
    remainingStatementBalance &&
    remainingStatementBalance > 0 &&
    minimumDue === 0
  );

  const numScheduledpayments =
    (upcomingPayments?.filter(
      (payment) => payment.state === ActivityState.Scheduled,
    ).length || 0) + (autopay?.length || 0);

  const CurrentButLate = ({ message }: PastDueBodyProps) => (
    <div className="mt3 mb4">
      <Notification level="warning">{message}</Notification>
    </div>
  );

  type ReducedPayment = {
    amount: number;
    date: string | null;
  };

  const getLateText = () => {
    if (
      !printCycleDate ||
      (upcomingPayments?.length === 0 && autopay?.length === 0)
    ) {
      return LATE_TEXT;
    }

    const cycleDateObj = dayjs(printCycleDate);
    const paymentsBeforeCycle: ReducedPayment[] = [];
    upcomingPayments
      ?.filter((payment) =>
        dayjs(payment.date).isSameOrBefore(cycleDateObj, 'day'),
      )
      .forEach((payment) => {
        paymentsBeforeCycle.push({
          date: payment.date,
          amount: payment.amount,
        });
      });

    autopay
      ?.filter((payment) =>
        dayjs(payment.nextPaymentDate).isSameOrBefore(cycleDateObj),
      )
      .forEach((payment) => {
        paymentsBeforeCycle.push({
          date: payment.nextPaymentDate,
          amount: payment.amount,
        });
      });

    let dateCovered = '';
    paymentsBeforeCycle.reduce(
      (accumulator: number, currentValue: ReducedPayment) => {
        const paymentSum = accumulator + Math.abs(currentValue.amount);
        if (minimumDue && paymentSum >= minimumDue) {
          dateCovered = currentValue.date || '';
        }
        return paymentSum;
      },
      0,
    );
    const totalUpcomingLength =
      (autopay?.length ?? 0) + (upcomingPayments?.length ?? 0);

    if (dateCovered) {
      return LATE_COVERED_TEXT(dateCovered);
    } else if (paymentsBeforeCycle.length === 0 && totalUpcomingLength > 0) {
      return LATE_NOT_COVERED_TIMING_TEXT;
    } else {
      return LATE_NOT_COVERED_TEXT;
    }
  };

  useEffect(() => {
    if (!paymentStatusLoading) {
      if (isLate) {
        trackEvent({
          eventName: 'Viewed late payment',
          name: 'Viewed late payment',
          feature: 'Current Bill Details',
        });
      } else if (isBucket1 || isBucket2to5) {
        trackEvent({
          eventName: 'Viewed B1-B5',
          name: 'Viewed B1-B5',
          feature: 'Current Bill Details',
        });
      } else if (isBucket6) {
        trackEvent({
          eventName: 'Viewed B6',
          name: 'Viewed B6',
          feature: 'Current Bill Details',
        });
      }
    }
  }, [paymentStatusLoading, isLate, isBucket1, isBucket2to5, isBucket6]);

  const getBucketText = () => {
    if (nextStatementCloseDate && printDueDate && isBucket6) {
      return `You have until ${getUTC(printDueDate).format(
        'MM/DD',
      )} to pay your past due amount. After that, your account will be charged off and permanently closed.`;
    }

    return 'To bring your account current, pay your past due amount as soon as you can. Any payment you make will count towards your total minimum due.';
  };

  const BuildCardBody = () => {
    if (isLate) {
      return <CurrentButLate message={getLateText()} />;
    }
    if (isPastDue) {
      return (
        <BucketsBody
          printDueDate={printDueDate}
          message={getBucketText()}
          minimumDue={minimumDue}
          pastDueBalance={pastDueBalance}
          hasPastDueBalancePayment={hasPastDueBalancePayment}
        />
      );
    }
    return null;
  };

  const RemainingStatementBody = () => {
    let dueDate;
    if (printDueDate) {
      dueDate = getUTC(printDueDate);
    }
    return dueDate ?
        <div className="mv3">
          <P3>
            Your remaining balance is{' '}
            {centsToDollars(remainingStatementBalance || 0)}.{' '}
            <B color="green">
              Pay by {dueDate.format('MMM DD')} to reduce interest charges if
              applicable.
            </B>
          </P3>
        </div>
      : null;
  };
  return (
    <div>
      {!isPastDue && (
        <>
          {noMinDueWithStatementBalance && <RemainingStatementBody />}
          {!isPaymentsOverview &&
            !!upcomingPayments &&
            numScheduledpayments > 0 && (
              <div className="pb3">
                <ScheduledPaymentsLink
                  onClick={() => navigate('../payments/scheduled-payments')}
                  amountOfPayments={numScheduledpayments}
                />
              </div>
            )}
        </>
      )}
      <div>{showPastDueExperience && <BuildCardBody />}</div>
    </div>
  );
};

export default PaymentKardBody;
