import { getET } from '@core/utils/timezones';

const paymentAlreadyScheduled = (
  paymentDate: string,
  upcomingPayments: any,
) => {
  return upcomingPayments.some((payment: any) =>
    getET(paymentDate).isSame(getET(payment.date), 'day'),
  );
};

export default paymentAlreadyScheduled;
