import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
  query GetCustomerInfo {
    customer {
      id
      financialInfo {
        shouldGetCLIPTout
        annualIncome
        monthlyLiving
        eligibleForUpdate
        offers {
          amount
          name
        }
      }
    }
  }
`;
