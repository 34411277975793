import { ReactNode } from 'react';
import { ColorNames, P1, P3, Spacing } from '@missionlane/compass-ui';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface ResponsiveMenuItemTextProps {
  children: ReactNode;
  color?: ColorNames;
}
export const ResponsiveMenuItemText = ({
  children,
  color = 'blue',
}: ResponsiveMenuItemTextProps) => {
  const { isMobile } = useUserDevice();
  const Text = isMobile ? P1 : P3;
  return (
    <Text style={{ marginVertical: Spacing.s }} color={color}>
      {children}
    </Text>
  );
};
