import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import { P3, Link } from '@missionlane/compass-ui';

type GoodStandingTip = {
  testID: string;
  label: string;
  content: React.ReactNode;
  iconName: IconNames | 'envelope-open-dollar' | 'lock-open';
};

export const OUR_PHONE_NUMBER_URL = 'tel:+1-888-570-0773';
export const header =
  "Here's how to keep your account in good standing and stay eligible for offers:";

export const payOnTimeGoodStandingTip: GoodStandingTip = {
  label: 'Pay on time',
  content: 'Making on-time payments is one of the most important factors.',
  iconName: 'alarmClock',
  testID: 'payOnTimeGoodStandingTip',
};

export const stayBelowYourLimit: GoodStandingTip = {
  label: 'Stay below your limit',
  content: 'Remaining under your credit limit shows responsible card use.',
  iconName: 'arrowDown',
  testID: 'stayBelowYourLimit',
};

export const resolveAnyCardIssues: GoodStandingTip = {
  label: 'Resolve any card issues',
  iconName: 'phoneVolume',
  content: (
    <P3>
      If your account is currently restricted,{' '}
      <Link
        onPress={(e) => {
          e.stopPropagation();
          window.location.href = OUR_PHONE_NUMBER_URL;
        }}
        href={OUR_PHONE_NUMBER_URL}
      >
        give us a call
      </Link>{' '}
      as soon as you can to resolve the restriction.
    </P3>
  ),
  testID: 'resolveAnyCardIssues',
};

export const maintainAbilityToPay: GoodStandingTip = {
  label: 'Maintain your ability to pay',
  content:
    'Try to keep your monthly debts at a manageable level, relative to your monthly income.',
  iconName: 'envelope-open-dollar',
  testID: 'maintainAbilityToPay',
};

export const keepAccountOpen: GoodStandingTip = {
  label: 'Keep your account open',
  content:
    'Closing your account will deactivate your credit card and make you ineligible.',
  iconName: 'lock-open',
  testID: 'keepAccountOpen',
};

export const useYourCardRegularly: GoodStandingTip = {
  label: 'Use your card regularly',
  content:
    'Making regular purchases on your card shows responsible use, as long as you pay on time and stay below your credit limit.',
  iconName: 'creditCard',
  testID: 'useYourCardRegularly',
};

export const tips: GoodStandingTip[] = [
  payOnTimeGoodStandingTip,
  stayBelowYourLimit,
  resolveAnyCardIssues,
  maintainAbilityToPay,
  keepAccountOpen,
  useYourCardRegularly,
];
