export const COPY = {
  TITLE: 'Your account isn’t eligible for an offer right now.',
} as const;

export const TEST_ID = {
  SPEND_BONUS__MISSED_PAYMENT: 'SPEND_BONUS__MISSED_PAYMENT',
  SPEND_BONUS__MISSED_PAYMENT__MESSAGE__CLIP:
    'SPEND_BONUS__MISSED_PAYMENT__MESSAGE__CLIP',
  SPEND_BONUS__MISSED_PAYMENT__MESSAGE__CASHBACK:
    'SPEND_BONUS__MISSED_PAYMENT__MESSAGE__CASHBACK',
} as const;
