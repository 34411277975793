import { Link } from '@missionlane/compass-ui';
import { useState } from 'react';
import SecurityDepositModal from './SecurityDepositModal/SecurityDepositModal';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { TrackService } from '@core/services';
import { GET_SECURITY_DEPOSIT_QUERY } from '@core/queries';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { DescriptionList } from '@core/components/General/DescriptionList/DescriptionList';
import { centsToDollars } from '@core/utils/centsToDollars';
import { GetSecurityDepositQuery } from '@core/graphql/globalTypes';

const SecurityDepositDotItem = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data, loading, error } = useAccountIdQuery<GetSecurityDepositQuery>(
    GET_SECURITY_DEPOSIT_QUERY,
  );
  const securityDepositAmount = data?.account?.securityDeposit?.currentAmount;

  if (loading || error || !securityDepositAmount) return null;

  function openModal() {
    TrackService.click(
      'Account details: Secured Card Deposit Info',
      customerAndAccountIds,
    );
    setIsModalOpen(true);
  }
  function closeModal() {
    TrackService.click(
      'Account details: Close Deposit Explanation',
      customerAndAccountIds,
    );
    setIsModalOpen(false);
  }

  return (
    <>
      <DescriptionList>
        <DescriptionList.Item
          label="Security Deposit"
          value={centsToDollars(securityDepositAmount)}
          subTitle={<Link onPress={openModal}>What is this for?</Link>}
        />
      </DescriptionList>
      <SecurityDepositModal
        setModalClosed={closeModal}
        isModalOpen={isModalOpen}
      />
    </>
  );
};

export default SecurityDepositDotItem;
