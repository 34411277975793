import { ACCOUNT_QUERY } from '@core/components/ChargedOff/gql/queries';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import BalanceKard from '@core/components/ChargedOff/Kards/BalanceKard';
import BuildPaymentPlanKard from '@core/components/ChargedOff/Kards/BuildPaymentPlanKard';
import DashboardFooter from '@core/components/ChargedOff/Dashboards/DashboardFooter';
import { AccountQuery, ActivityState } from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { Button, P4 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import PreOfferUpcomingPaymentsKard from '../Kards/PreOfferUpcomingPaymentsKard';
import { OfferType } from '../utils/OfferType';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import isNil from '@core/utils/isNil';
import { OfferIneligibilityReason } from '../utils/types';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

const PreOfferDashboard = () => {
  const { data, loading } = useAccountIdQuery<AccountQuery>(ACCOUNT_QUERY);
  const navigate = useNavigate();
  const { isDebitEligible } = usePaymentStatus();

  useEffect(() => {
    TrackService.trackPage('Charged Off Non-Offer Dashboard', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  if (loading) return <LoadingSpinner />;

  const currentBalance = data?.account?.balanceInfo?.currentBalance;

  const hasScheduledPayment =
    !!data?.account?.upcomingPayments?.find(
      (upcomingPayment) => upcomingPayment.state === ActivityState.Scheduled,
    ) || !!data?.account?.autopay.length;

  const hasPendingPayment = !!data?.account?.upcomingPayments?.find(
    (upcomingPayment) => upcomingPayment.state === ActivityState.Pending,
  );

  const allowedIneligibilityReasons: string[] = [
    OfferIneligibilityReason.HAS_SCHEDULED_PAYMENT,
    OfferIneligibilityReason.HAS_PENDING_PAYMENT,
  ];

  const ineligibleForOffer = Boolean(
    data?.offerEligibility
      ?.filter((offer) => offer.offerType === OfferType.Settlement)
      .every(
        (settlementOffer) =>
          !settlementOffer.eligible &&
          !settlementOffer.ineligibleReasons.every((reason) =>
            allowedIneligibilityReasons.includes(reason),
          ),
      ),
  );

  const getKardBody = () =>
    hasScheduledPayment || hasPendingPayment ?
      <PreOfferUpcomingPaymentsKard scheduledPayments={hasScheduledPayment} />
    : <BuildPaymentPlanKard />;

  return (
    <>
      <div className="flex flex-column flex-row-ns items-start-ns">
        {!isNil(currentBalance) && (
          <div className="mb2 mr4-ns w-50-ns">
            <BalanceKard
              testID="PreOfferDashboardBalanceKard"
              currentBalance={currentBalance}
            />
            {ineligibleForOffer && currentBalance > 0 && (
              <div className="flex-ns mt5">
                <Button
                  onPress={() => {
                    !!isDebitEligible ?
                      navigate('../payments/pay')
                    : navigate('../payments/pay/make-ach-payment');
                  }}
                  text="Make a payment"
                />
              </div>
            )}
          </div>
        )}
        {!ineligibleForOffer && <div className="w-50-ns">{getKardBody()}</div>}
      </div>
      <DashboardFooter
        CallToAction={
          <div style={{ marginLeft: '-8px', marginBottom: '24px' }}>
            <Button
              size="small"
              text="Access Account Details"
              variant="text"
              onPress={() => navigate('../details')}
              icon={{ name: 'forward' }}
            />
          </div>
        }
        Disclaimer={
          <P4>
            This is an attempt to collect a debt and any information obtained
            will be used for that purpose.
          </P4>
        }
      />
    </>
  );
};

export default PreOfferDashboard;
