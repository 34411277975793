import { ContentCard } from '@core/components/General/ContentCard';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import CardholderInformation, {
  CardholderInformationProps,
} from './CardholderInformation';
import FinancialInformation, {
  FinancialInformationProps,
} from './FinancialInformation';
import UpdateInformationLink from './UpdateInformationLink';

interface MyProfilePageContentProps
  extends FinancialInformationProps,
    CardholderInformationProps {}

const MyProfilePageContent = ({
  financialInfo,
  address,
  email,
  phoneNumber,
}: MyProfilePageContentProps) => {
  const { customerId } = useCustomerAndAccountIdentifiers();

  useEffect(() => {
    if (customerId) {
      TrackService.page('My profile', {
        customerId,
      });
    }
  }, [customerId]);

  return (
    <ContentCard>
      <CardholderInformation
        email={email}
        phoneNumber={phoneNumber}
        address={address}
      />
      <UpdateInformationLink />
      <FinancialInformation financialInfo={financialInfo} />
    </ContentCard>
  );
};

export default MyProfilePageContent;
