import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
export const timeAndDateFormat = 'MMM D, YYYY h:mm A';
export const dateFormat = 'MMMM D, YYYY';

/**
 *
 * @param date
 * @param isPending
 * @param isPayment
 * @param isTxCredit default to false because we only want to use this parameter in the TransactionList and not the Transaction/PaymentDetailsScreen
 * @returns date formatted in MMM D, YYYY h:mm A for pending activity and MMMM D, YYYY for activity. Uses UTC mode for transactions only.
 */
export const formatTxDate = (
  date: string | Date | number,
  isPending: boolean,
  isPayment: boolean,
  isTxCredit = false,
) => {
  if (isTxCredit) return 'Refund';

  return isPayment ?
      dayjs(date).format(isPending ? timeAndDateFormat : dateFormat)
    : dayjs.utc(date).format(isPending ? timeAndDateFormat : dateFormat);
};
