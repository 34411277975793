import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import getOktaRedirectUri from '@core/components/App/getOktaRedirectUri/getOktaRedirectUri';
import { useNavigate } from 'react-router-dom';

interface OktaSecurityProviderProps {
  children: React.ReactNode;
}

const OKTA_REDIRECT_URI = getOktaRedirectUri();

const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER_URL,
  redirectUri: OKTA_REDIRECT_URI,
  ignoreLifetime: true,
  tokenManager: {
    autoRenew: false,
  },
});

export const OktaSecurityProvider = ({
  children,
}: OktaSecurityProviderProps) => {
  const navigate = useNavigate();

  /** Okta calls redirectToLogin when the user is not authenticated */
  function redirectToLogin() {
    // okta-react is not clearing the tokens if the user is not authenticated
    navigate('/signin', { replace: true });
  }
  async function restoreOriginalUri(_oktaAuth: OktaAuth, originalUri: string) {
    // keep the original URI set in okta for use in RiskAuth flow
    _oktaAuth.setOriginalUri(originalUri);
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={redirectToLogin}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  );
};
