import { FC, ReactNode } from 'react';
import { H2, H3 } from '@missionlane/compass-ui';
import cx from 'classnames';

/**
 * This component mimics the basic styles of the Kard component (padding & box-shadow)
 * However when viewed in mobile screen, all of the styles drop off
 * Additionally it provides a header attribute that will switch between H3/H2.
 * H3 for wide screens and H2 for Mobile
 */
export const ResponsiveKard: FC<{
  children: ReactNode;
  header?: string;
  className?: string;
  headerClassName?: string;
}> = ({ children, header, className, headerClassName }) => {
  return (
    <div className={cx('pa5-ns shadow-smallest-ns', className)}>
      {header && (
        <div className={headerClassName ? headerClassName : 'mb5'}>
          <div className="db-ns dn">
            <H3>{header}</H3>
          </div>
          <div className="dn-ns db">
            <H2>{header}</H2>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
