import { Icon, Link, P3, Spacer } from '@missionlane/compass-ui';
import { faLeaf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';

/**
 * Kard Header Actions have been made complicated due to the dynamic linking of Paperless
 * Statements (multiple actions now needed) and the pause on Compass UI updates (Font
 * Awesome Icon one-off implementation here).
 *
 * @TODO remove ternary and <FontAwesomeIcon /> when leaf icon can be added to Compass UI.
 */

export type KardHeaderAction = {
  label: string | React.ReactNode;
  onClick: () => void;
  iconName?: IconNames | 'leaf'; // @TODO remove after update to compass UI (see line 166 below)
};

interface KardHeaderActionsProps {
  headerAction?: KardHeaderAction;
  headerActionSecondary?: KardHeaderAction;
}

export const KardHeaderActions = ({
  headerAction,
  headerActionSecondary,
}: KardHeaderActionsProps) => {
  // return nothing if no action, or only secondary action
  if (!headerAction) return null;

  return (
    <div className="flex justify-between items-center">
      {headerAction && (
        <Link
          style={{
            textDecorationLine: 'none',
          }}
          onPress={headerAction.onClick}
        >
          <P3 color="blue">
            <div className="inline-flex items-center h-100">
              {headerAction.label}
              {headerAction.iconName && (
                <>
                  <Spacer size="xs" />
                  {headerAction.iconName === 'leaf' ?
                    <FontAwesomeIcon icon={faLeaf} size="sm" />
                  : <Icon name={headerAction.iconName} color="blue" />}
                </>
              )}
            </div>
          </P3>
        </Link>
      )}
      {headerActionSecondary && (
        <>
          <Spacer size="xs" />
          <span className="ink-light">|</span>
          <Spacer size="xs" />
          <Link
            style={{
              textDecorationLine: 'none',
            }}
            onPress={headerActionSecondary.onClick}
          >
            <P3 color="blue">
              <div className="inline-flex items-center h-100">
                {headerActionSecondary.label}
                {headerActionSecondary.iconName && (
                  <>
                    <Spacer size="xs" />
                    {headerActionSecondary.iconName === 'leaf' ?
                      <FontAwesomeIcon icon={faLeaf} size="sm" />
                    : <Icon
                        name={headerActionSecondary.iconName}
                        color="blue"
                      />
                    }
                  </>
                )}
              </div>
            </P3>
          </Link>
        </>
      )}
    </div>
  );
};
