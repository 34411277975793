import dayjs from 'dayjs';
import { CardTermsPurchaseApr } from '../../../../graphql/globalTypes';
import { DescriptionList } from '../../../General/DescriptionList/DescriptionList';
import { Unavailable } from '../../Unavailable';

export const FUTURE_PURCHASE_APR_LABEL = 'Purchase APR starting';
export const FUTURE_APR_ERROR_LABEL = `${FUTURE_PURCHASE_APR_LABEL} --`;

export const FuturePurchaseAprRow = ({
  introductoryPurchaseApr,
  purchaseApr,
}: {
  introductoryPurchaseApr: CardTermsPurchaseApr.IntroductoryPurchaseApr;
  purchaseApr?: string | React.ReactElement;
}) => {
  // If we get an error result for introductoryPurchaseApr or futurePurchaseAprData, display
  // the future apr as unavailable
  if (
    introductoryPurchaseApr?.__typename === 'ErrorResult' ||
    introductoryPurchaseApr.futurePurchaseAprData?.__typename === 'ErrorResult'
  ) {
    return (
      <DescriptionList.DetailItem
        label={FUTURE_APR_ERROR_LABEL}
        value={<Unavailable />}
      />
    );
  }

  const isIntroductoryPurchaseApr =
    introductoryPurchaseApr?.__typename === 'IntroductoryPurchaseApr';
  // default values if promotions.introductoryPurchaseApr has an error
  let futurePurchaseApr;
  let futurePurchaseAprStartDate;

  // if promotions.introductoryPurchaseApr succeeds, replace the default values
  if (isIntroductoryPurchaseApr) {
    const { futurePurchaseAprData } = introductoryPurchaseApr;
    if (futurePurchaseAprData?.value) {
      futurePurchaseApr = futurePurchaseAprData.value;
    }
    if (futurePurchaseAprData?.startDate) {
      futurePurchaseAprStartDate = dayjs(
        futurePurchaseAprData.startDate,
      ).format('MMM D, YYYY');
    }
  }

  if (isIntroductoryPurchaseApr) {
    const { introAprLengthInMonths } = introductoryPurchaseApr;
    if (futurePurchaseAprStartDate) {
      return (
        <DescriptionList.DetailItem
          label={`${FUTURE_PURCHASE_APR_LABEL} ${futurePurchaseAprStartDate}`}
          value={
            futurePurchaseApr ?
              `${futurePurchaseApr} variable`
            : <Unavailable />
          }
        />
      );
    }

    /**
     * When an account is created with Intro APR, the intro APR is not applied until
     * TSYS runs their batch processing (8pm EST).
     *
     * Before that time, we know they have an intro APR but we don't know what that
     * intro APR percentage is or when it ends, so we display a different label to
     * signal to the user that they have an intro APR and that their regular purchase
     * APR will begin after the intro APR ends
     */
    if (introAprLengthInMonths) {
      return (
        <DescriptionList.DetailItem
          label="Purchase APR after intro APR"
          value={getPurchaseAprText(purchaseApr)}
        />
      );
    }
  }
  /**
   * For whatever reason, we didn't get the necessary info from TSYS to display
   * the formatted future purchase APR label. Display the error scenario instead.
   */
  return (
    <DescriptionList.DetailItem
      label={FUTURE_APR_ERROR_LABEL}
      value={getPurchaseAprText(purchaseApr)}
    />
  );
};

const getPurchaseAprText = (purchaseApr?: string | React.ReactElement) =>
  `${
    typeof purchaseApr === 'string' ? `${purchaseApr} variable` : purchaseApr
  }`;
