import { ExternalBrandIconNames } from '../ExternalBrandIcon/ExternalBrandIcon';

interface BaseFooterLink {
  id: string;
  href: string;
}

export interface NavLink extends BaseFooterLink {
  label: string;
}

const navLinks: NavLink[] = [
  {
    id: 'nav-link-1',
    href: `${process.env.REACT_APP_MONO_URL}/contact-us`,
    label: 'Contact Us',
  },
  {
    id: 'nav-link-2',
    href: `${process.env.REACT_APP_MONO_URL}/legal`,
    label: 'Legal',
  },
  {
    id: 'nav-link-3',
    href: `${process.env.REACT_APP_MONO_URL}/privacy`,
    label: 'Privacy',
  },
];

export interface SocialLink extends BaseFooterLink {
  label: ExternalBrandIconNames;
}

const socialLinks: SocialLink[] = [
  {
    id: 'social-link-1',
    href: 'https://facebook.com/MissionLaneCredit',
    label: 'Facebook',
  },
  {
    id: 'social-link-2',
    href: 'https://instagram.com/missionlane',
    label: 'Instagram',
  },
  {
    id: 'social-link-3',
    href: 'https://twitter.com/mission_lane',
    label: 'Twitter',
  },
  {
    id: 'social-link-4',
    href: 'https://linkedin.com/company/missionlane',
    label: 'LinkedIn',
  },
  {
    id: 'social-link-5',
    href: 'https://www.youtube.com/channel/UC3j3p-MX32IHjY4IbudcQnA',
    label: 'YouTube',
  },
];

export { navLinks, socialLinks };
