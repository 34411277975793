import { P3 } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { DescriptionList } from '../../../General/DescriptionList/DescriptionList';
import {
  INTRO_PURCHASE_APR_LABEL,
  PurchaseAprsSubtitleProps,
} from './PurchaseAprsSubtitle';

const IntroPurchaseAprRow = ({
  introductoryPurchaseApr,
  currentPurchaseApr,
}: PurchaseAprsSubtitleProps) => {
  if (introductoryPurchaseApr.__typename === 'IntroductoryPurchaseApr') {
    const { expirationDate, introAprLengthInMonths } = introductoryPurchaseApr;
    if (expirationDate) {
      return (
        <DescriptionList.DetailItem
          label={`${INTRO_PURCHASE_APR_LABEL} ${dayjs(expirationDate).format(
            'MMM D, YYYY',
          )}`}
          value={currentPurchaseApr}
        />
      );
    }

    /**
     * When an account is created with Intro APR, the intro APR is not applied until
     * TSYS runs their batch processing (8pm EST).
     *
     * Before that time, we know they have an intro APR but we don't know what that
     * intro APR percentage is or when it ends, so we display "Pending" as the value
     * until the expirationDate is available.
     */
    if (introAprLengthInMonths) {
      return (
        <DescriptionList.DetailItem
          label={`Intro Purchase APR for ${introAprLengthInMonths} months`}
          value={<P3>Pending</P3>}
        />
      );
    }
  }

  /**
   * For whatever reason, we didn't get the necessary info from TSYS to display
   * the intro APR label. Display the error scenario instead.
   */
  return (
    <DescriptionList.DetailItem
      label={`${INTRO_PURCHASE_APR_LABEL} --`}
      value={currentPurchaseApr}
    />
  );
};

export default IntroPurchaseAprRow;
