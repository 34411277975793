import dayjs from 'dayjs';
import { P3 } from '@missionlane/compass-ui';
import { AccountStandingInfoSection } from '../AccountStandingInfoSection';
import { CFUStatus } from '@clip/CashFlowUnderwritingExperience/network/types';

interface CFUContentProps {
  offerExpirationDate: string | null | undefined;
  nextEvaluationDate: string | null | undefined;
}

const getInitialContent = (data: CFUContentProps) => {
  const offerExpirationDate = data?.offerExpirationDate;

  if (offerExpirationDate == null) {
    return null;
  }

  const formattedOfferExpirationDate =
    dayjs(offerExpirationDate).format('MMMM D, YYYY,');

  return (
    <div className="mb4">
      <P3>
        Congrats on your recent line increase! To celebrate your success,{' '}
        <span className="ink b">
          we'll evaluate you for a bonus increase now if you link your bank
          account with Plaid.
        </span>
        <div className="mt4">
          {`Connect with Plaid by ${formattedOfferExpirationDate} and we'll email you an answer, likely within a day.`}
        </div>
      </P3>
    </div>
  );
};

const getInProgressContent = () => {
  return (
    <P3>
      Thanks for linking your bank account with Plaid! We're evaluating your
      account for an extra increase now.{' '}
      <span className="ink b">
        We'll send you an email when we have an answer, most likely within a
        day.
      </span>
    </P3>
  );
};

const getApprovedContent = (data: CFUContentProps) => {
  if (!data?.nextEvaluationDate) return null;
  const formatWithYear = dayjs().year() < dayjs(data.nextEvaluationDate).year();
  const formattedNextEvaluationDate = dayjs(data.nextEvaluationDate).format(
    formatWithYear ? 'MMMM of YYYY' : 'MMMM',
  );
  return (
    <P3>
      Congrats, you've earned an extra increase! You'll see your updated credit
      line reflected in your Account Details.
      <div className="mt4">
        Keep up the good work!
        <span className="ink b">
          {` Your next evaluation for a line increase is in ${formattedNextEvaluationDate}.`}
        </span>
      </div>
    </P3>
  );
};

const getDeclinedContent = (data: CFUContentProps) => {
  if (!data?.nextEvaluationDate) return null;
  const formatWithYear = dayjs().year() < dayjs(data.nextEvaluationDate).year();
  const formattedNextEvaluationDate = dayjs(data.nextEvaluationDate).format(
    formatWithYear ? 'MMMM of YYYY' : 'MMMM',
  );
  return (
    <>
      <P3>
        Your account wasn't in good standing during our evaluation, so we can't
        give you a bonus increase.
        <div className="mt4">
          But don't worry, your previous increase still applies and
          <span className="ink b">
            {` you'll have another evaluation for an increase in ${formattedNextEvaluationDate}.`}
          </span>
        </div>
      </P3>
      <AccountStandingInfoSection />
    </>
  );
};

const CFU_CONTENT: Record<
  CFUStatus,
  (data: CFUContentProps) => JSX.Element | null
> = {
  INITIAL: getInitialContent,
  IN_PROGRESS: getInProgressContent,
  DECLINED: getDeclinedContent,
  APPROVED: getApprovedContent,
};

export const getCfuContentRenderer = (status: CFUStatus) => CFU_CONTENT[status];
