import { gql, useQuery } from '@apollo/client';
import PageWrapper from '@core/components/Page/PageWrapper';
import { HomePageQuery } from '@core/graphql/globalTypes';
import CreditCardsSection from './CreditCardsSection';
import { getCustomerGreeting } from './helpers';

const HOME_PAGE_QUERY = gql`
  query HomePage {
    customer {
      contactInfo {
        firstName
      }
    }
  }
`;

const HomePage = () => {
  const { data, loading } = useQuery<HomePageQuery>(HOME_PAGE_QUERY);

  const pageTitle =
    loading ? undefined : (
      getCustomerGreeting(data?.customer?.contactInfo?.firstName)
    );

  return (
    <PageWrapper
      pageTitle={pageTitle}
      greyBackground
      trackingProperties={{ pageName: 'Account Selection' }}
    >
      <CreditCardsSection />
    </PageWrapper>
  );
};

export default HomePage;
