import { ActivityState } from '@core/graphql/globalTypes';
import { getET } from '@core/utils/timezones';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export function canCancelPayment({ state, date }) {
  const isScheduled = state === ActivityState.Scheduled;
  const isTooLate = getET().isSameOrAfter(getET(date));

  return isScheduled && !isTooLate;
}
