import { B, Icon, Link, P1 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import ResponsiveTypography from '../General/ResponsiveTypography';

interface Props {
  onGoBack?: () => void;
  showFAQ?: boolean;
}

const NavLinks = ({ onGoBack, showFAQ = true }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="w-100 flex justify-between">
      <div>
        {onGoBack && (
          <Link style={{ textDecorationLine: 'none' }} onPress={onGoBack}>
            <div className="flex items-center">
              <Icon name="back" color="blue" size={18} />
              <div className="ml1">
                <ResponsiveTypography
                  type="PARAGRAPH"
                  mobileLevel="P1"
                  desktopLevel="P2"
                  color="blue"
                >
                  <B>Back</B>
                </ResponsiveTypography>
              </div>
            </div>
          </Link>
        )}
      </div>
      {showFAQ && (
        <div>
          <P1>
            <Link onPress={() => navigate('../faq')}>FAQs</Link>
          </P1>
        </div>
      )}
    </div>
  );
};

export default NavLinks;
