import {
  AddUpdateAutopay,
  AddUpdateAutopayQuery,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react';
import { ADD_UPDATE_AUTOPAY_QUERY } from './queries';
import { PageWrapperProps } from '@core/components/Page/PageWrapper';
import { formatCardLast4 } from '@core/utils/formatters';
import { useTracking } from '@core/services/TrackService/useTracking';

export type AutopayFlowType = 'SET_UP' | 'MODIFY';

type AutopayContextData = {
  autopay?: Partial<AddUpdateAutopay.Autopay>;
  autopayStartDateRange?: AddUpdateAutopay.Account['autopayStartDateRange'];
  paymentInfo?: AddUpdateAutopay.Account['paymentInfo'];
  fundingAccounts?: AddUpdateAutopay.FundingAccounts[] | null;
  confirmationId?: string | null;
  destinationAccount?: string | null;
};

type AutopayContextType = {
  autopayContext?: AutopayContextData;
  autopayFlowType: AutopayFlowType;
  setAutopay: (autopay: Partial<AutopayContextData>) => void;
  setAutopayFlowType: (autopayFlowType: AutopayFlowType) => void;
  autopayLoading?: boolean;
  autopayPageTitle?: PageWrapperProps['pageTitle'];
};

export const AutopayContext = createContext<AutopayContextType>({
  autopayContext: {},
  autopayFlowType: 'SET_UP',
  setAutopay: () => {},
  setAutopayFlowType: () => {},
});

export const AutopayProvider = ({ children }: PropsWithChildren) => {
  const { trackError } = useTracking();
  const [autopayState, setAutopayState] =
    useState<Partial<AutopayContextData>>();
  const [autopayFlowType, setAutopayFlowType] =
    useState<AutopayFlowType>('SET_UP');
  const [cardLast4, setCardlast4] = useState<string | null | undefined>('');

  const setAutopay = (autopay: Partial<AutopayContextData>) =>
    setAutopayState({ ...autopayState, ...autopay });

  const handleAutopayQuery = (data: AddUpdateAutopayQuery) => {
    if (!autopayState?.autopay) {
      const result = data.account;
      let initialAutopay: Partial<AddUpdateAutopay.Autopay> = {};
      if (result?.autopay && result.autopay.length > 0) {
        initialAutopay = {
          id: result.autopay[0].id,
          paymentType: result.autopay[0].paymentType,
          amount: result.autopay[0].amount,
          dayOfMonth: result.autopay[0].dayOfMonth,
          fundingAccount: result.autopay[0].fundingAccount,
        };
      }
      setCardlast4(result?.cardDetails.last4);
      setAutopay({
        autopay: initialAutopay,
        autopayStartDateRange: result?.autopayStartDateRange,
        paymentInfo: result?.paymentInfo,
        fundingAccounts: data.fundingAccounts,
        destinationAccount: data.accountInformation?.destinationAccount,
      });
    }
  };

  const handleAutopayError = (error: Error) => {
    trackError({
      name: 'Failed to load autopay data',
      feature: 'Create and update autopay',
      error: {
        code: 'PAY0009',
        message: error.message,
        name: 'Failed to load autopay data',
      },
    });
  };

  const { loading } = useAccountIdQuery<AddUpdateAutopayQuery>(
    ADD_UPDATE_AUTOPAY_QUERY,
    { onCompleted: handleAutopayQuery, onError: handleAutopayError }
  );

  return (
    <AutopayContext.Provider
      value={{
        autopayContext: autopayState,
        setAutopay: setAutopay,
        setAutopayFlowType: setAutopayFlowType,
        autopayLoading: loading,
        autopayFlowType: autopayFlowType,
        autopayPageTitle: {
          primaryText:
            autopayFlowType === 'MODIFY' ? 'Manage Autopay' : 'Set up Autopay',
          secondaryText: formatCardLast4(cardLast4),
        },
      }}
    >
      {children}
    </AutopayContext.Provider>
  );
};

export const useAutopay = () => useContext(AutopayContext);
