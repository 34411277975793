import { v4 as uuidv4 } from 'uuid';
import {
  EvaluateResponse,
  EvaluateResponseError,
  EvaluateResponseSuccess,
  IRiskAuthService,
  OtpFactor,
  OtpFactorsResponse,
  OtpResponse,
  ThreatMetrixClient,
} from './types';
import { RiskAuthServiceError, handleError } from './errors';
import { MLError } from '@core/services';

const getThreatMetrixClient = (): ThreatMetrixClient | undefined => {
  try {
    const tmxClient = window['threatmetrix'];
    if (!tmxClient) {
      handleError('Threatmetrix client not found');
    }
    return tmxClient;
  } catch (e) {
    handleError('getThreatMetrixClient threw an exception.');
    return undefined;
  }
};

const TMX_BASE_SESSION_ID_KEY = 'tmx-base-session-id';
const TMX_SESSION_ID_KEY = 'tmx-session-id';

export const setTmxBaseSessionId = (): void => {
  if (localStorage.getItem(TMX_BASE_SESSION_ID_KEY)) {
    return;
  }
  const newTmxBaseSessionId = uuidv4();
  localStorage.setItem(TMX_BASE_SESSION_ID_KEY, newTmxBaseSessionId);
};

export const setTmxSessionId = (): void => {
  const tmxBaseSessionId = localStorage.getItem(TMX_BASE_SESSION_ID_KEY);
  if (!tmxBaseSessionId) {
    handleError(
      `${TMX_BASE_SESSION_ID_KEY} needs to be set to create a new ${TMX_BASE_SESSION_ID_KEY}`,
    );
    return;
  }
  const newTmxSessionId = `${tmxBaseSessionId}-${Date.now()}`;
  sessionStorage.setItem(TMX_SESSION_ID_KEY, newTmxSessionId);
};

/*
profile the user with a
[time stamped tmxSessionId in session storage] derived from
[tmxBaseSessionId from local storage] if it does not exist
 */
const profileUser = (): string | null => {
  try {
    if (location.pathname !== '/signin') {
      const currentTmxSessionId = sessionStorage.getItem(TMX_SESSION_ID_KEY);
      if (currentTmxSessionId) {
        return currentTmxSessionId;
      }
    }

    setTmxBaseSessionId();
    setTmxSessionId();
    const newTmxSessionId = sessionStorage.getItem(TMX_SESSION_ID_KEY);
    if (!newTmxSessionId) {
      handleError(`${TMX_SESSION_ID_KEY} does not exist`);
      return null;
    }

    const tmxClient = getThreatMetrixClient();
    tmxClient?.profile(
      process.env.REACT_APP_THREATMETRIX_PROFILE_DOMAIN,
      process.env.REACT_APP_THREATMETRIX_ORG_ID,
      newTmxSessionId,
    );

    return newTmxSessionId;
  } catch (e) {
    handleError('profileUser threw an exception.');
    return null;
  }
};

const isEvaluateResponseError = (
  res: EvaluateResponse,
): res is EvaluateResponseError => !!(res as EvaluateResponseError).error;

const evaluateSession = async (
  threatMetrixSessionId: string | null,
  intention?: string | null,
  sessionId?: string,
): Promise<EvaluateResponseSuccess> => {
  const response = await fetch(
    `${process.env.REACT_APP_RISK_AUTH_SERVICE_URL}/v1/auth/sessions/evaluate`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        threatMetrixSessionId,
        intention,
        sessionId,
      }),
    },
  );

  const responseJson = await response.json();

  // if the response status is not 200 (ie not "ok"), throw an error with
  // the errorMessage returned from risk-auth
  if (!response.ok && isEvaluateResponseError(responseJson)) {
    throw new RiskAuthServiceError(
      `${responseJson.error}: ${responseJson.message}`,
    );
  }

  return responseJson;
};

const otpRequest = async (
  sessionId: string,
  factor: OtpFactor,
  intention?: string | null,
): Promise<OtpResponse> => {
  try {
    await fetch(
      `${process.env.REACT_APP_RISK_AUTH_SERVICE_URL}/v1/auth/sessions/challenges`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          factorId: factor.id,
          intention,
        }),
      },
      // 200 means request was sent, should be a 201, but ¯\_(ツ)_/¯
    );
    return {
      success: true,
    };
  } catch (error: unknown) {
    // Server/Network error
    if (error instanceof Error)
      MLError.report({
        name: error.name,
        error,
      });

    return {
      success: false,
      error: 'Something went wrong, please try again',
    };
  }
};

const otpEnter = async (
  sessionId: string,
  factorId: string,
  passCode: string,
  intention?: string | null,
): Promise<OtpResponse> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_RISK_AUTH_SERVICE_URL}/v1/auth/sessions/challenges/verify`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          factorId,
          passCode,
          intention,
        }),
      },
    )
      // Parse 200 response
      .then((res) => res.json());

    if (response.verificationOutcome === 'SUCCEEDED') {
      return {
        success: true,
      };
    }
    return {
      success: false,
      error: 'Code is incorrect. Please try again.',
    };
  } catch (error) {
    // Server/Network error or parsing the JSON blew up
    if (error instanceof Error)
      MLError.report({
        name: error.name,
        error,
      });
    throw error;
  }
};

const otpFactors = async (): Promise<OtpFactorsResponse> => {
  try {
    const { factors } = await fetch(
      `${process.env.REACT_APP_RISK_AUTH_SERVICE_URL}/v1/auth/sessions/factors`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
      },
    ).then((res) => res.json());
    return {
      success: true,
      factors,
    };
  } catch (e) {
    const error = e as Error;
    return {
      success: false,
      error: error.message,
    };
  }
};

export const RiskAuthService: IRiskAuthService = {
  profileUser,
  evaluateSession,
  otpRequest,
  otpEnter,
  otpFactors,
};
