import React from 'react';
import { PaymentMethod } from './types';
import PaymentMethodOptions from './PaymentMethodOptions';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import { ResponsiveKard } from '@core/components/General/ResponsiveKard/ResponsiveKard';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';

export enum PaymentMethodName {
  ACH = 'ACH',
  DEBIT_CARD = 'DEBIT_CARD',
}

const paymentMethods: PaymentMethod[] = [
  {
    name: PaymentMethodName.ACH,
    url: 'make-ach-payment',
    icon: <i className="far fa-university" />,
    iconName: 'bank',
    blurb: 'Make one-time, recurring or scheduled payments.',
    title: 'Bank Account',
  },
  {
    name: PaymentMethodName.DEBIT_CARD,
    url: 'make-card-payment',
    icon: <i className="far fa-credit-card-front" />,
    iconName: 'creditCard',
    blurb: 'Use for one-time payments only.',
    title: 'Debit Card',
  },
];

const Pay: React.FC = () => {
  const pageTitle = useAccountPageTitle('Make a Payment');
  const breadcrumbs = [
    { text: 'Payments', link: '..' },
    { text: 'Payment method' },
  ];
  return (
    <PageWrapper
      pageTitle={pageTitle}
      trackingProperties={{
        featureName: 'Payments: Make Payment',
        pageName: 'Make a Payment: Select Payment Method',
      }}
    >
      <AccountBreadcrumbs items={breadcrumbs} />
      <div className="flex justify-center">
        <ResponsiveKard
          header="How'd you like to pay?"
          className="mw7 flex-auto"
        >
          <PaymentMethodOptions availableMethods={paymentMethods} />
        </ResponsiveKard>
      </div>
    </PageWrapper>
  );
};

export default Pay;
