import React from 'react';
import TrackingLink, {
  TrackingLinkProps,
} from '@core/components/General/TrackingLink';

interface Props {
  trackingProperties: TrackingLinkProps['trackingProperties'];
}

const PaymentsOverviewLink = ({ trackingProperties }: Props) => {
  return (
    <TrackingLink
      to="../payments"
      trackingName="Payments Overview"
      trackingProperties={trackingProperties}
    >
      Payments overview
    </TrackingLink>
  );
};

export default PaymentsOverviewLink;
