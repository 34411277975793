import { useEffect, useState } from 'react';
import { H4, Link, P4, B } from '@missionlane/compass-ui';
import FeatureContainer from '../FeatureContainer';
import {
  MultifactorAuthenticationState,
  OtpFactor,
} from '@core/services/RiskAuthService';
import VerifyRequestCode from '../VerifyRequestCode/VerifyRequestCode';
import { VerifyEnterCode } from '../VerifyEnterCode/VerifyEnterCode';
import { useNavigate, useLocation } from 'react-router-dom';
import { TrackService } from '@core/services';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ErrorBoundary } from '@core/services/Error/Error';

const FEATURES_WITH_MFA = ['/change-address'];

const MfaContainer = () => {
  const [mfaState, setMfaState] = useState<MultifactorAuthenticationState>();
  const [selectedOtpFactor, setSelectedOtpFactor] = useState<OtpFactor>();
  const location = useLocation();
  const navigate = useNavigate();
  const { showMulticardExperience } = useFlags();

  useEffect(() => {
    if (!FEATURES_WITH_MFA.includes(location.state?.from)) {
      navigate('/');
    }
  }, []);

  const renderStep = () => {
    switch (mfaState) {
      case 'MFA_CHALLENGE': {
        return (
          <VerifyEnterCode
            onSuccess={handleSuccess}
            selectedOtpFactor={selectedOtpFactor}
          />
        );
      }
      default:
        return (
          <VerifyRequestCode
            onChangeMfaState={handleChangeMfaState}
            onSetSelectedOtpFactor={handleSetSelectedOtpFactor}
          />
        );
    }
  };

  const handleChangeMfaState = (
    newMfaState: MultifactorAuthenticationState,
  ) => {
    setMfaState(newMfaState);
  };

  const handleSuccess = () => {
    const redirect = location.state.from;
    if (redirect) {
      const preservedPrevState = location.state;
      navigate(redirect, { state: preservedPrevState });
    }
  };

  const handleBackToDash = () => {
    TrackService.click('Back to Dashboard');
    navigate('/', { replace: true });
  };

  const handleSetSelectedOtpFactor = (newSelectedOtpFactor: OtpFactor) => {
    setSelectedOtpFactor(newSelectedOtpFactor);
  };

  return (
    <FeatureContainer>
      {!showMulticardExperience && (
        <P4>
          <Link onPress={handleBackToDash}>Back to dashboard</Link>
        </P4>
      )}
      <H4>
        <B>Verify</B>
      </H4>
      <div className="mt4"> {renderStep()}</div>
    </FeatureContainer>
  );
};

const MfaContainerWithAccountDetailsProvider = () => (
  <ErrorBoundary>
    <MfaContainer />
  </ErrorBoundary>
);

export default MfaContainerWithAccountDetailsProvider;
