import { H5, P3 } from '@missionlane/compass-ui';
import Modal from '@core/components/General/Modal/Modal';

export interface PlaidFAQModalProps {
  isOpen: boolean;
  setShowModal: (showPlaidModal: boolean) => void;
}

const PlaidFAQModal = ({ isOpen, setShowModal }: PlaidFAQModalProps) => {
  const onClose = () => setShowModal(false);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        onPress: onClose,
        text: 'Close',
      }}
      headerText="Frequently Asked Questions"
    >
      <div className="mb4">
        <H5>What is Plaid?</H5>
      </div>
      <P3>
        Plaid is a free and safe service that easily connects your bank to your
        Mission Lane account.
      </P3>
      <div className="mv4">
        <H5>Why should I link my account?</H5>
      </div>
      <P3>
        Linking your bank account with Plaid allows us to determine your ability
        to manage a higher credit limit based on your cash flow.
      </P3>
      <div className="mv4">
        <H5>How long does it take?</H5>
      </div>
      <P3>
        It only takes a couple of minutes to connect your bank account with
        Plaid.
      </P3>
      <div className="mv4">
        <H5>What will you do with my data?</H5>
      </div>
      <P3>
        We'll use your bank account data to determine whether you're eligible
        for a higher credit limit. We won't use it to lower your limit.
      </P3>
    </Modal>
  );
};

export default PlaidFAQModal;
