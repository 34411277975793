import cx from 'classnames';
import { Button, Colors, H2, H3 } from '@missionlane/compass-ui';
import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

import './Modal.css';
import {
  ContainedButtonProps,
  TextButtonProps,
} from '@missionlane/compass-ui/lib/typescript/components/Button/types';
import GenericFallbackModalContent from '@core/components/GenericFallbacks/GenericFallbackModalContent';
import { ErrorBoundary } from '@core/services/Error/Error';

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
  headerText?: string;
  primaryButton?: Pick<ContainedButtonProps, 'text' | 'onPress'> & {
    isCritical?: boolean;
    isDisabled?: boolean;
  };
  secondaryButton?: Pick<TextButtonProps, 'text' | 'onPress'>;
  children?: ReactNode;
  accessibilityContentLabel?: string;
  disableCloseOnOverlayClick?: boolean;
  maxWidth?: string;
}

// Prevent failures in test environment
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const createModalClassName = (element: string) => `compass-modal-${element}`;

const Modal = ({
  isOpen,
  headerText,
  onClose,
  onOpen,
  primaryButton,
  secondaryButton,
  children,
  accessibilityContentLabel = 'Modal',
  disableCloseOnOverlayClick,
  maxWidth = '800px',
}: ModalProps) => {
  const { isMobile } = useUserDevice();
  const HeaderText = isMobile ? H2 : H3;
  return (
    <ReactModal
      className={cx(
        createModalClassName('content'),
        'bg-white mh3 pa5 br2 shadow-3 overflow-auto',
      )}
      overlayClassName={cx(
        createModalClassName('overlay'),
        'flex justify-center items-center z-3',
      )}
      isOpen={isOpen}
      onAfterOpen={onOpen}
      contentLabel={accessibilityContentLabel}
      style={{
        // We use inline styles so we can use the compass color variable,
        // which we dont have access to in a css file
        overlay: { backgroundColor: `${Colors.grey}cc` },
        content: { maxWidth },
      }}
      onRequestClose={!disableCloseOnOverlayClick ? () => onClose() : undefined} // Ensures modal gets closed on overlay click or ECS key press
    >
      {headerText && (
        <div className="flex items-start justify-between mb3">
          <HeaderText>{headerText}</HeaderText>
          <span className="pl3 nt2">
            <Button
              accessibilityLabel={`${accessibilityContentLabel} close button`}
              variant="icon"
              icon="close"
              onPress={onClose}
            />
          </span>
        </div>
      )}
      <ErrorBoundary
        fallback={() => <GenericFallbackModalContent onClose={onClose} />}
      >
        <div className={cx(createModalClassName('body'), 'mb4')}>
          {children}
        </div>
        <div className="flex flex-column flex-column-reverse flex-row-ns justify-end-ns">
          {secondaryButton && (
            <Button
              variant="text"
              text={secondaryButton.text}
              onPress={secondaryButton.onPress}
            />
          )}
          {primaryButton && (
            <span className={cx(secondaryButton && 'ml4-ns mb2 mb0-ns')}>
              <Button
                text={primaryButton.text}
                onPress={primaryButton.onPress}
                variant={primaryButton.isCritical ? 'critical' : 'contained'}
                disabled={primaryButton.isDisabled}
              />
            </span>
          )}
        </div>
      </ErrorBoundary>
    </ReactModal>
  );
};

export default Modal;
