import { H4, Spacer } from '@missionlane/compass-ui';
import { ReactNode } from 'react';

interface AccountDetailsPanelProps {
  title: string;
  children: ReactNode;
}

const AccountDetailsPanel = ({ title, children }: AccountDetailsPanelProps) => (
  <div>
    <H4>{title}</H4>
    <Spacer size="l" />
    <div>{children}</div>
    <Spacer size="s" />
  </div>
);

export default AccountDetailsPanel;
