import {
  ActivityState,
  MakePayment,
  UpcomingPayments as UpcomingPaymentsNamespace,
} from '@core/graphql/globalTypes';
import {
  BubbleIcon,
  P2,
  P3,
  P4,
  Button,
  Notification,
  LoadingIndicator,
  Link,
  Icon,
} from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { centsToDollars } from '@core/utils/centsToDollars';
import { getOrdinalFromDayOfMonth } from '@core/utils/getOrdinalDate';

import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import Kard from '../../../@core/components/General/Kard/Kard';
import { ApolloError } from '@apollo/client';
import dayjs from 'dayjs';
import './UpcomingPayments.css';
import { getAmountDisplay } from '../Autopay/utils/helpers';

type PaymentFormLayoutProps = {
  statuses?: MakePayment.Statuses | undefined;
  upcomingPayments?: UpcomingPaymentsNamespace.UpcomingPayments[] | undefined;
  autopay?: MakePayment.Autopay | undefined;
  error?: ApolloError;
  loading?: boolean;
};

const UpcomingPaymentsKard = ({ children }: { children: React.ReactNode }) => (
  <Kard
    header={{
      textPrimary: 'Scheduled',
      level: 'H4',
    }}
  >
    <div className="flex flex-column items-center upcoming-payments-gap-16">
      {children}
    </div>
  </Kard>
);

const getScheduledPayments = (
  payments?: UpcomingPaymentsNamespace.UpcomingPayments[],
) => {
  if (!payments) {
    return [];
  }

  const nextPayments = [...payments];

  return nextPayments
    .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
    .filter((p) => p.state === ActivityState.Scheduled);
};

const canCancel = (payment: UpcomingPaymentsNamespace.UpcomingPayments) =>
  payment.state !== ActivityState.Pending &&
  dayjs().isBefore(dayjs(payment.date).subtract(1, 'day').endOf('day'));

const MARGIN_0 = {
  margin: 0,
};

export const UPCOMING_PAYMENTS_LOADING_TEST_ID =
  'UPCOMING_PAYMENTS_LOADING_TEST_ID';

export const EDIT_AUTOPAY_TEST_ID = 'EDIT_AUTOPAY_TEST_ID';

export const UpcomingPayments = ({
  upcomingPayments,
  autopay,
  error,
  loading,
}: PaymentFormLayoutProps) => {
  const { canEnrollInAutopay, hasAutopay } = usePaymentStatus();
  const navigate = useNavigate();

  if (error) {
    return (
      <UpcomingPaymentsKard>
        <Notification level="warning">
          Sorry, we're having trouble showing your future payments. Please check
          back soon.
        </Notification>
      </UpcomingPaymentsKard>
    );
  }

  if (loading) {
    return (
      <UpcomingPaymentsKard>
        <div
          className="mb4 mt1"
          data-testid={UPCOMING_PAYMENTS_LOADING_TEST_ID}
        >
          <LoadingIndicator size="large" />
        </div>
      </UpcomingPaymentsKard>
    );
  }

  const scheduledPayments = getScheduledPayments(upcomingPayments);
  const canSignUpForAutopay = !hasAutopay && !autopay && canEnrollInAutopay;

  if (!autopay && scheduledPayments.length <= 0) {
    return (
      <UpcomingPaymentsKard>
        <BubbleIcon
          name="calendar"
          iconColor="grey"
          bubbleColor="greyLightest"
        />
        <P2 color="ink" style={MARGIN_0}>
          Nothing Yet
        </P2>
        <P3 style={{ ...MARGIN_0, textAlign: 'center' }}>
          You haven't scheduled a payment. When you do, you can find it here!
        </P3>
        {canSignUpForAutopay && (
          <Button
            variant="outlined"
            text="Create Autopay"
            onPress={() => navigate('../../autopay')}
          />
        )}
      </UpcomingPaymentsKard>
    );
  }

  return (
    <UpcomingPaymentsKard>
      {canSignUpForAutopay && (
        <div className="w-100">
          <Button
            variant="outlined"
            text="Create Autopay"
            onPress={() => navigate('../../autopay')}
          />
        </div>
      )}
      <div className="flex flex-column w-100">
        {autopay && (
          <div className="flex flex-row w-100 justify-between items-center pv3 upcoming-payments-scheduled-payments-border-bottom">
            <div className="flex upcoming-payments-gap-16">
              <BubbleIcon
                name="autopay"
                iconColor="green"
                bubbleColor="greenWashed"
                size="large"
              />
              <div className="flex flex-column upcoming-payments-gap-4">
                <P4 color="ink" style={MARGIN_0}>
                  Autopay
                </P4>
                <P4 color="greyLight" style={MARGIN_0}>
                  {`${dayjs(autopay.nextPaymentDate).format(
                    'MMMM DD, YYYY',
                  )} ( every ${getOrdinalFromDayOfMonth(autopay.dayOfMonth)} )`}
                </P4>
              </div>
            </div>
            <div className="flex flex-column upcoming-payments-gap-4">
              <P4 color="ink" style={MARGIN_0}>
                {getAmountDisplay(autopay.paymentType, autopay.amount)}
              </P4>
              <div className="upcoming-payments-margin-left-auto">
                <Link
                  onPress={() => navigate('../../autopay/manage')}
                  style={{
                    textDecorationLine: 'none',
                    fontWeight: 'bold',
                  }}
                  testID={EDIT_AUTOPAY_TEST_ID}
                >
                  <Icon name="edit" color="blue" />
                </Link>
              </div>
            </div>
          </div>
        )}
        {scheduledPayments.map((sp) => (
          <div
            key={sp.id}
            className="flex flex-row w-100 justify-between items-center pv3 upcoming-payments-scheduled-payments-border-bottom"
          >
            <div className="flex upcoming-payments-gap-16">
              <BubbleIcon
                name="calendar"
                iconColor="green"
                bubbleColor="greenWashed"
                size="large"
              />
              <div className="flex flex-column upcoming-payments-gap-4">
                <P4 color="ink" style={MARGIN_0}>
                  One-Time
                </P4>
                <P4 color="greyLight" style={MARGIN_0}>
                  {dayjs(sp.date).format('MMM D, YYYY')}
                </P4>
              </div>
            </div>
            {canCancel(sp) ?
              <div className="flex flex-column upcoming-payments-gap-4">
                <P4 color="ink" style={MARGIN_0}>
                  {centsToDollars(sp.amount)}
                </P4>
                <Link
                  style={{
                    textDecorationLine: 'none',
                    fontWeight: 'bold',
                  }}
                  onPress={() =>
                    navigate(`../../scheduled-payments/cancel-payment/${sp.id}`)
                  }
                >
                  Cancel
                </Link>
              </div>
            : <div className="flex flex-column self-start">
                <P4 color="ink" style={MARGIN_0}>
                  {centsToDollars(sp.amount)}
                </P4>
              </div>
            }
          </div>
        ))}
      </div>
      {!!scheduledPayments.length && (
        <P3>
          Payments can be canceled until 11:59 p.m. ET one day before payment
          date.
        </P3>
      )}
    </UpcomingPaymentsKard>
  );
};
