import { ReactNode } from 'react';
import Kard, { KardProps } from '../Kard/Kard';

interface KardWrapperBaseProps {
  children: ReactNode;
  className?: string;
}

interface WithKard extends KardWrapperBaseProps {
  withKard: true;
  kardProps?: Omit<KardProps, 'className' | 'children'>;
}

interface WithoutKard extends KardWrapperBaseProps {
  withKard: false;
  kardProps?: never;
}

type KardWrapperProps = WithKard | WithoutKard;

/**
 * Wraps children in a Kard component when `props.withKard` is true.
 */
const KardWrapper = ({
  withKard,
  kardProps,
  ...otherProps
}: KardWrapperProps) =>
  withKard ?
    <Kard {...{ ...otherProps, ...kardProps }} />
  : <div {...otherProps} />;

export default KardWrapper;
