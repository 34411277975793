import { useRef } from 'react';
import classNames from 'classnames';
import Lottie, {
  LottieRefCurrentProps,
  LottieComponentProps,
} from 'lottie-react';

import './SuccessAnimation.css';

export const DEFAULT_ANIMATION_DURATION = 2000;

interface SuccessAnimationProps
  extends Omit<LottieComponentProps, 'animationData'> {
  /** @description duration in ms */
  duration?: number;
}

const calcLottieAnimationSpeed = (
  animationData: typeof successAnimation,
  duration: number,
) => {
  const animationFrames = animationData.op;
  const animationFps = animationData.fr;
  const fpsRatio = animationFps / 1000;

  return animationFrames / (duration * fpsRatio);
};

export const SuccessAnimation = ({
  className,
  duration = DEFAULT_ANIMATION_DURATION,
  ...lottieComponentProps
}: SuccessAnimationProps) => {
  const animationRef = useRef<LottieRefCurrentProps>(null);

  const handleDOMLoaded = () => {
    const speed = calcLottieAnimationSpeed(successAnimation, duration);
    animationRef.current?.setSpeed(speed);
    animationRef.current?.play();
  };

  return (
    <Lottie
      className={classNames('success-animation', className)}
      alt="Successfully Updated Income"
      animationData={successAnimation}
      onDOMLoaded={handleDOMLoaded}
      lottieRef={animationRef}
      autoplay={false}
      loop={false}
      {...lottieComponentProps}
    />
  );
};

export default SuccessAnimation;

const successAnimation = {
  nm: 'check',
  ddd: 0,
  h: 64,
  w: 64,
  meta: { g: '@lottiefiles/toolkit-js 0.25.8' },
  layers: [
    {
      ty: 4,
      nm: 'check',
      sr: 1,
      st: 0,
      op: 90,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [33.333, 33.333, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [32, 32, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          nm: 'check',
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.25, -6],
                    [-2.75, 6],
                    [-9.25, -0.5],
                  ],
                },
              },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              nm: 'Trim Paths 1',
              e: { a: 0, k: 100 },
              o: { a: 0, k: 0 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.466, y: 0 },
                    i: { x: 0.461, y: 1 },
                    s: [100],
                    t: 44,
                  },
                  { s: [0], t: 90 },
                ],
              },
              m: 1,
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100 },
              w: { a: 0, k: 3 },
              c: { a: 0, k: [0, 0.4549, 0.349] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [350, 350] },
              sk: { a: 0, k: 0 },
              p: { a: 0, k: [0, 0] },
              r: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
            },
          ],
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'circle',
      sr: 1,
      st: 0,
      op: 90,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [33.333, 33.333, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [32, 32, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          nm: 'circle',
          it: [
            {
              ty: 'el',
              bm: 0,
              hd: false,
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0] },
              s: { a: 0, k: [53, 53] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              nm: 'Trim Paths 1',
              e: {
                a: 1,
                k: [
                  { o: { x: 0.66, y: 0 }, i: { x: 0.34, y: 1 }, s: [0], t: 0 },
                  { s: [100], t: 77 },
                ],
              },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.66, y: 0 },
                    i: { x: 0.34, y: 1 },
                    s: [-360],
                    t: 0,
                  },
                  { s: [0], t: 77 },
                ],
              },
              s: { a: 0, k: 0 },
              m: 1,
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100 },
              w: { a: 0, k: 3 },
              c: { a: 0, k: [0, 0.4549, 0.349] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [300, 300] },
              sk: { a: 0, k: 0 },
              p: { a: 0, k: [0, 0] },
              r: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
            },
          ],
        },
      ],
      ind: 2,
    },
  ],
  v: '5.7.5',
  fr: 60,
  op: 90,
  ip: 0,
  assets: [],
};
