import React, { useEffect, useState } from 'react';

function useClickOutside(
  ref: React.MutableRefObject<HTMLElement | null> | null,
  onClickOutside: () => void,
) {
  const [disableClickOutside, setDisableClickOutside] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = 'target' in event && (event.target as Node);

      if (ref && ref.current && target && !ref.current.contains(target)) {
        onClickOutside();
      }
    }

    if (disableClickOutside) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, disableClickOutside, onClickOutside]);

  return {
    disableClickOutside: () => {
      setDisableClickOutside(true);
    },
    enableClickOutside: () => {
      setDisableClickOutside(false);
    },
  };
}

export default useClickOutside;
