import cx from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { TrackService } from '@core/services';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { createMenuClassName } from './Menu';
import { MenuItemIcon } from './MenuItemIcon';
import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import { ResponsiveMenuItemText } from './ResponsiveMenuItemText';

interface MenuItemProps {
  trackingName: string;
  onSelect: () => void;
  children: React.ReactNode;
  icon?: IconNames;
}
export const MenuItem = ({
  trackingName,
  onSelect,
  children,
  icon,
}: MenuItemProps) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  return (
    <DropdownMenu.Item
      className={cx(
        createMenuClassName('content', 'item'),
        'flex items-center ph4 pointer',
      )}
      onSelect={() => {
        TrackService.click(trackingName, customerAndAccountIds);
        onSelect();
      }}
    >
      {icon && <MenuItemIcon name={icon} />}
      <ResponsiveMenuItemText>{children}</ResponsiveMenuItemText>
    </DropdownMenu.Item>
  );
};
