export function validatePasswordComplexity(password: string) {
  const hasEightChars = password.length >= 8;
  const hasOneLetter = /[a-zA-Z]/.test(password);
  const hasOneNumOrSpecialChar = /^.*(?=.*[\d\W]).*$/.test(password);

  return {
    hasEightChars: hasEightChars,
    hasOneLetter,
    hasOneNumOrSpecialChar,
    isValid: hasEightChars && hasOneLetter && hasOneNumOrSpecialChar,
  };
}

interface PasswordValidatorItemProps {
  label: string;
  valid: boolean;
}

export const PasswordValidatorItem = ({
  label,
  valid,
}: PasswordValidatorItemProps) => {
  const checkIconColor = valid ? 'green' : 'ink-30';
  return (
    <li
      style={{
        listStyleType: 'none',
        paddingBottom: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span>{label}</span>
      <i role="img" className={`far fa-check ${checkIconColor}`} />
    </li>
  );
};

interface Props {
  password: string;
}

const PasswordValidator = ({ password }: Props) => {
  const { hasEightChars, hasOneLetter, hasOneNumOrSpecialChar } =
    validatePasswordComplexity(password);

  return (
    <ul className="pl0 pb3">
      <PasswordValidatorItem
        label="At least 8 characters:"
        valid={hasEightChars}
      />
      <PasswordValidatorItem
        label="At least one letter:"
        valid={hasOneLetter}
      />
      <PasswordValidatorItem
        label="At least one number or special character:"
        valid={hasOneNumOrSpecialChar}
      />
    </ul>
  );
};

export default PasswordValidator;
