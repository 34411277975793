import PhoneLink from '@core/components/General/PhoneLink';

interface ApiErrorProps {
  error: string;
  linkText: string;
  url?: string;
  phone: string;
}

const ApiErrors = ({ error, linkText, phone, url }: ApiErrorProps) => {
  if (error.includes(linkText) && !error.includes(phone)) {
    error = error.replace(linkText, '');
    return (
      <>
        {error}
        {
          <a href={url} target="_blank" rel="noreferrer">
            {linkText}
          </a>
        }
      </>
    );
  } else if (error.includes(phone)) {
    const errorText = error.split(phone);
    return (
      <>
        {errorText[0]}
        <PhoneLink phoneNumber={phone} />
        {errorText[1]}
      </>
    );
  } else {
    return <>{error}</>;
  }
};

export default ApiErrors;
