import { GestureResponderEvent } from 'react-native';
import { TrackService } from '@core/services';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import OfferKard from './OfferKard';

export const REACTIVE_MULTICARD_OFFER_EVENT = 'REACTIVE_MULTICARD_OFFER';

interface ReactiveMultcardOfferProps {
  onOfferDismiss: () => void;
}

const ReactiveMulticardOffer = ({
  onOfferDismiss,
}: ReactiveMultcardOfferProps) => {
  useEffect(() => {
    TrackService.trackEvent('Offer Viewed', {
      feature: REACTIVE_MULTICARD_OFFER_EVENT,
    });
  }, []);

  const hideOffer = () => {
    localStorage.setItem(REACTIVE_MULTICARD_OFFER_EVENT, dayjs().toString());
    onOfferDismiss();
  };

  const onDismiss = () => {
    TrackService.trackClick(`Dismiss ${REACTIVE_MULTICARD_OFFER_EVENT} tile`, {
      feature: REACTIVE_MULTICARD_OFFER_EVENT,
    });
    hideOffer();
  };

  const onClickCta = (e: GestureResponderEvent) => {
    e.preventDefault();
    TrackService.trackClick('See If You Qualify', {
      feature: REACTIVE_MULTICARD_OFFER_EVENT,
    });
    window
      .open(
        'https://apply.missionlane.com/prequalification?invite-code=MLPQRC7890',
        '_blank',
      )
      ?.focus();
    hideOffer();
  };

  return (
    <OfferKard
      title="Apply for a second card"
      description="See what you qualify for with no hit to your credit score."
      onClickClose={onDismiss}
      onClickCta={onClickCta}
    />
  );
};

export default ReactiveMulticardOffer;
