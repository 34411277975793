import { P1, B } from '@missionlane/compass-ui';

import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { gql } from '@apollo/client';
import {
  GetLast4AndCardNameQuery,
  GetLast4AndCardNameQueryVariables,
} from '@core/graphql/globalTypes';

export const getLast4AndCardName = gql`
  query GetLast4AndCardName($accountId: String!) {
    account(accountId: $accountId) {
      id
      cardDetails {
        productDisplayName {
          productName
        }
        last4
      }
    }
  }
`;

const ActivateCardNameAndLast4Header = () => {
  const { data, loading } = useAccountIdQuery<
    GetLast4AndCardNameQuery,
    GetLast4AndCardNameQueryVariables
  >(getLast4AndCardName);

  if (loading) return null;

  const { last4, productDisplayName } = data?.account?.cardDetails || {};
  const { productName } = productDisplayName || {};
  const cardName = productName || 'Visa';

  return (
    <>
      <P1 color="ink">Activate your </P1>
      <P1 color="green">
        <B>
          {cardName} {last4 && `(...${last4})`}
        </B>
      </P1>
    </>
  );
};

export default ActivateCardNameAndLast4Header;
