import { Link, Notification } from '@missionlane/compass-ui';

interface AlertMessageWithLinkProps {
  linkText: string;
  message: string;
  url: string;
}
const AlertMessageWithLink = ({
  linkText,
  message,
  url,
}: AlertMessageWithLinkProps) => {
  return (
    <Notification variant="inline" level="warning">
      {message}&nbsp;
      <Link onPress={() => window.open(url)}>{linkText}</Link>
    </Notification>
  );
};

export default AlertMessageWithLink;
