import { useEffect, useState } from 'react';
import { TRY_AUTOPAY_TILE_EVENT } from './OpportunityTiles/AutopayTile';
import dayjs from 'dayjs';
import { TrackService } from '@core/services';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  OpportunityTileCarouselQueryVariables,
  OpportunityTileCarouselQuery,
} from '@core/graphql/globalTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MLFlags } from 'flags';
import { PROACTIVE_MULTICARD_TILE_EVENT } from '@clip/Multicard/ProactiveMulticardTile';
import { REACTIVE_MULTICARD_OFFER_EVENT } from '@clip/Multicard/ReactiveMulticardOffer';
import { ADD_PAYMENT_METHOD_TILE_EVENT } from './OpportunityTiles/AddPaymentMethodTile';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { OnDismiss } from '@core/components/Carousel/Carousel';
import { ONBOARDING_TILE_EVENT } from '@core/components/Onboarding/OnboardingOpportunityTile';
import { CREDIT_PROTECTION_TILE_EVENT } from '@core/components/CreditProtection/CreditProtectionTile';

export const OPPORTUNITY_TILE_CAROUSEL_QUERY = gql`
  query OpportunityTileCarousel($accountId: String!) {
    fundingAccounts {
      id
    }
    proactiveOffers {
      eligible
    }
    reactiveMulticardEligibility(accountId: $accountId) {
      eligible
    }
    account(accountId: $accountId) {
      creditProtectionInfo {
        isEligibleForMarketing
      }
    }
  }
`;

export type OpportunityTileType =
  | typeof TRY_AUTOPAY_TILE_EVENT
  | typeof PROACTIVE_MULTICARD_TILE_EVENT
  | typeof REACTIVE_MULTICARD_OFFER_EVENT
  | typeof ADD_PAYMENT_METHOD_TILE_EVENT
  | typeof CREDIT_PROTECTION_TILE_EVENT
  | typeof ONBOARDING_TILE_EVENT;

export interface OpportunityTile {
  loading: boolean;
  tiles: OpportunityTileType[];
  dismissTile: OnDismiss;
}

export const useOpporunityTileManager = (): OpportunityTile => {
  const {
    addFundingAccountOpportunityTile,
    showOpportunityTilesV2,
    proactiveMulticard,
    clipMulticardOffer,
    creditProtectionMarketing,
    enableMyProgressOnboarding,
  } = useFlags<MLFlags>();

  const {
    loading: paymentStatusLoading,
    isBankrupt,
    canEnrollInAutopay,
    hasAutopay,
  } = usePaymentStatus();

  const [tiles, setTiles] = useState<OpportunityTileType[]>([]);

  const { data, loading } = useAccountIdQuery<
    OpportunityTileCarouselQuery,
    OpportunityTileCarouselQueryVariables
  >(OPPORTUNITY_TILE_CAROUSEL_QUERY);

  //Local Storage conditions
  const storedAutopayDismissed = localStorage.getItem(TRY_AUTOPAY_TILE_EVENT);
  const proactiveMulticardDismissed = localStorage.getItem(
    PROACTIVE_MULTICARD_TILE_EVENT,
  );
  const reactiveMulticardDismissed = localStorage.getItem(
    REACTIVE_MULTICARD_OFFER_EVENT,
  );
  const addPaymentMethodDismissed = localStorage.getItem(
    ADD_PAYMENT_METHOD_TILE_EVENT,
  );
  const creditProtectionDismissed = localStorage.getItem(
    CREDIT_PROTECTION_TILE_EVENT,
  );

  //Account conditions
  const proactiveMulticardEligible = data?.proactiveOffers?.eligible;
  const reactiveMulticardEligible =
    data?.reactiveMulticardEligibility?.eligible;
  const hasNoPaymentMethod = !data?.fundingAccounts?.length;
  const autopayEligible = canEnrollInAutopay && !hasAutopay && !isBankrupt;
  const isEligibleForCreditProtectionMarketing =
    data?.account?.creditProtectionInfo?.isEligibleForMarketing;

  //Display conditions
  const showProactiveMultiCardTile =
    proactiveMulticard &&
    proactiveMulticardEligible &&
    !proactiveMulticardDismissed;

  const showReactiveMulticardTile =
    clipMulticardOffer &&
    reactiveMulticardEligible &&
    !reactiveMulticardDismissed;

  const showAddPaymentMethodTile =
    showOpportunityTilesV2 &&
    addFundingAccountOpportunityTile &&
    hasNoPaymentMethod &&
    !addPaymentMethodDismissed &&
    !isBankrupt;

  const showAutopayTile =
    showOpportunityTilesV2 && autopayEligible && !storedAutopayDismissed;

  const showCreditProtectionTile =
    showOpportunityTilesV2 &&
    creditProtectionMarketing !== 'DISABLED' &&
    isEligibleForCreditProtectionMarketing &&
    !creditProtectionDismissed;
  const showOnboardingProgressTile =
    enableMyProgressOnboarding && showOpportunityTilesV2;

  const dismissTile = (
    tileID: OpportunityTileType,
    skipLocalStorageUpdate?: boolean,
  ) => {
    //Skip tracking event for data determined removal
    if (!skipLocalStorageUpdate) {
      TrackService.trackClick(`Dismiss ${tileID} tile`, {
        feature: tileID,
      });
      localStorage.setItem(tileID, dayjs().toString());
    }

    setTiles((prev) => prev.filter((tile) => tile !== tileID));
  };

  useEffect(() => {
    if (!loading) {
      if (
        showOnboardingProgressTile &&
        !tiles.includes(ONBOARDING_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, ONBOARDING_TILE_EVENT]);
      }
      if (
        showCreditProtectionTile &&
        !tiles.includes(CREDIT_PROTECTION_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, CREDIT_PROTECTION_TILE_EVENT]);
      }
      if (
        showProactiveMultiCardTile &&
        !tiles.includes(PROACTIVE_MULTICARD_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, PROACTIVE_MULTICARD_TILE_EVENT]);
      }
      if (
        showReactiveMulticardTile &&
        !tiles.includes(REACTIVE_MULTICARD_OFFER_EVENT)
      ) {
        setTiles((prev) => [...prev, REACTIVE_MULTICARD_OFFER_EVENT]);
      }
      if (
        showAddPaymentMethodTile &&
        !tiles.includes(ADD_PAYMENT_METHOD_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, ADD_PAYMENT_METHOD_TILE_EVENT]);
      }
      if (showAutopayTile && !tiles.includes(TRY_AUTOPAY_TILE_EVENT)) {
        setTiles((prev) => [...prev, TRY_AUTOPAY_TILE_EVENT]);
      }
    }
  }, [loading]);

  return {
    loading: loading || paymentStatusLoading,
    tiles: tiles,
    dismissTile: dismissTile,
  };
};
