import currency from 'currency.js';
import dayjs from 'dayjs';

import { TrackService } from '@core/services';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import TrackingLink from '@core/components/General/TrackingLink';
import SuccessAnimation, {
  DEFAULT_ANIMATION_DURATION,
} from '@clip/IncomeUpdate/components/SuccessAnimation';
import { InteractableModalProps, useModalProvider } from '@core/contexts/modal';
import {
  SpendBonusTrackingNames,
  SPEND_BONUS_FEATURE_NAME,
} from '@clip/SpendBonus/lib/events';

import './OptOutModal.css';

const modalMaxWidth: InteractableModalProps['maxWidth'] = '576px';

const successAnimationModalProps: InteractableModalProps = {
  disableCloseOnOverlayClick: true,
  children: <SuccessAnimation />,
  maxWidth: modalMaxWidth,
};

export const OptOutModal = () => {
  const { openModal, closeModal } = useModalProvider();
  const spendBonusResult = useSpendBonus();

  if (!spendBonusResult.isEnabled) return null;

  const {
    spendBonus: { dueDate, spendTarget, offerId },
    optOut,
  } = spendBonusResult;

  const formattedDueDate = dayjs(dueDate).format('MMMM D, YYYY');

  const formattedSpendTarget = currency(spendTarget, {
    precision: 0,
  }).format();

  const optOutMetrics = {
    rewardType: spendBonusResult.spendBonus.rewardType,
    rewardAmount: spendBonusResult.spendBonus.reward,
    feature_name: SPEND_BONUS_FEATURE_NAME,
  };

  const optOutConfirmationModalProps: InteractableModalProps = {
    headerText: 'Are you sure you don’t want this offer?',
    maxWidth: modalMaxWidth,
    primaryButton: {
      text: 'Yes, Dismiss This Offer',
      onPress: () => {
        optOut({ offerId });
        openModal(successAnimationModalProps);
        /**
         * In this case we want to close the modal after the animation is done.
         * We can't use the useTimeout hook here because the modal will be unmounted
         * before the timeout is complete. The usage of setTimeout here is intentional.
         */
        setTimeout(closeModal, DEFAULT_ANIMATION_DURATION);
        TrackService.click(
          `${SpendBonusTrackingNames.OptOutConfirmation}: Confirm`,
          optOutMetrics,
        );
      },
    },
    secondaryButton: {
      text: 'No, Keep This Offer',
      onPress: () => {
        closeModal();
        TrackService.click(
          `${SpendBonusTrackingNames.OptOutConfirmation}: Cancel`,
          optOutMetrics,
        );
      },
    },
    children: (
      <>
        <p>
          A higher credit limit can help progress your financial journey in many
          ways, including:
        </p>
        <ul className="spend-bonus__opt-out-modal-list pv0 ph3">
          <li>More flexibility for larger purchases or unplanned expenses</li>
          <li>More opportunities to build credit with responsible use</li>
        </ul>
        <p>
          Your account will be regularly evaluated for credit limit increases in
          the future, whether or not you participate in this offer.
        </p>
        <p>
          If you're still not interested, you can dismiss this offer and you
          won't see it again.
        </p>
      </>
    ),
  };

  const offerDetailsModalProps: InteractableModalProps = {
    headerText: 'Offer Details',
    maxWidth: modalMaxWidth,
    primaryButton: {
      text: 'Done',
      onPress: () => {
        closeModal();
        TrackService.click(
          `${SpendBonusTrackingNames.OptOutInfo}: Cancel`,
          optOutMetrics,
        );
      },
    },
    secondaryButton: {
      text: 'I Don’t Want This Offer',
      onPress: () => {
        openModal(optOutConfirmationModalProps);
        TrackService.click(
          `${SpendBonusTrackingNames.OptOutInfo}: Confirm`,
          optOutMetrics,
        );
      },
    },
    children: (
      <>
        <p>
          To qualify for this offer, you must make Purchases totaling{' '}
          {formattedSpendTarget} or more before the end of {formattedDueDate},
          continue to make on-time payments, and keep your account in good
          standing. (See your Credit Card Agreement for more information about
          what qualifies as a Purchase.)
        </p>
        <p>
          Additional credit requirements may apply to remain eligible for this
          offer, including maintaining your ability to pay.
        </p>
        <p>
          It may take up to one week for your spend progress tracker to reflect
          new Purchases, depending on merchant processing times. Any Purchases
          that are refunded during the offer period will not count towards the
          total.
        </p>
        <p>
          If you qualify, please allow up to 1 week after the offer end date for
          the credit limit increase to apply to your Mission Lane Visa®
          account.
        </p>
      </>
    ),
  };

  const handleClickLink = () => {
    openModal(offerDetailsModalProps);
    TrackService.click(
      `${SpendBonusTrackingNames.OptOutInfo}: Click`,
      optOutMetrics,
    );
  };

  return (
    <TrackingLink trackingName="See Offer Details" onClick={handleClickLink}>
      See Offer Details
    </TrackingLink>
  );
};
