import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { B, P1, P3 } from '@missionlane/compass-ui';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

interface MenuLabelProps {
  label: string | React.ReactNode;
}

export const MenuLabel = ({ label }: MenuLabelProps) => {
  const { isMobile } = useUserDevice();
  const Text = isMobile ? P1 : P3;
  return (
    <DropdownMenu.Label className="header-menu__label">
      <Text color="ink">
        <B>{label}</B>
      </Text>
    </DropdownMenu.Label>
  );
};
