export enum AddAccountBannerStatuses {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

type AddAccountBannerStatus =
  | AddAccountBannerStatuses.SUCCESS
  | AddAccountBannerStatuses.ERROR;

interface AddAccountBannerProps {
  bannerStatus: AddAccountBannerStatus;
  closeBanner: () => void;
  setShowPlaidModal: (showPlaidModal: boolean) => void;
}

const AddAccountBanner = ({
  bannerStatus,
  closeBanner,
  setShowPlaidModal,
}: AddAccountBannerProps) => {
  function getBannerStatus(action: string) {
    switch (action) {
      case 'ERROR':
        return {
          iconStyles: 'fas fa-exclamation-triangle red',
          text: 'Sorry, Plaid had trouble linking your bank account. ',
          textStyles: 'red',
          wrapperStyles: 'bg-washed-red',
        };
      case 'SUCCESS': {
        return {
          iconStyles: 'fas fa-check-circle green',
          text: `All set! You've linked your bank account through Plaid.`,
          textStyles: 'green',
          wrapperStyles: 'bg-washed-green',
        };
      }
      default: {
        return;
      }
    }
  }

  function openPlaid() {
    closeBanner();
    setShowPlaidModal(true);
  }
  const bannerInfo = getBannerStatus(bannerStatus);

  return (
    <div
      className={`fixed center z-1 pv3 w-100 top-0 left-0 tc ${bannerInfo?.wrapperStyles}`}
    >
      <div className="center">
        <p className={`mv0 di`}>
          <i className={`mr2 ${bannerInfo?.iconStyles}`}></i>
          <button className={`bg-none b--none ${bannerInfo?.textStyles}`}>
            {bannerInfo?.text}
          </button>
          {bannerStatus === 'ERROR' && (
            <button
              className="bg-none b--none blue hover-indigo underline pointer"
              onClick={openPlaid}
            >
              Try again.
            </button>
          )}
        </p>
        <button
          className="bg-none b--none fr mr3 blue pointer"
          onClick={closeBanner}
        >
          <i data-testid="close-icon" className="fa fa-times"></i>
        </button>
      </div>
    </div>
  );
};

export default AddAccountBanner;
