import { gql } from '@apollo/client';
import { BreadcrumbsProps } from '@core/components/General/Breadcrumbs/Breadcrumbs';
import { HomeBreadcrumb } from '@core/components/General/Breadcrumbs/common';
import { Temp_MulticardBreadcrumbs } from '@core/components/General/Breadcrumbs/temp_MulticardBreadcrumbs';
import {
  GetAccountBreadcrumbsQuery,
  GetAccountBreadcrumbsQueryVariables,
} from '@core/graphql/globalTypes';
import { formatCardLast4 } from '@core/utils/formatters';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';

const GET_ACCOUNT_BREADCRUMBS_QUERY = gql`
  query GetAccountBreadcrumbs($accountId: String!) {
    customer {
      accounts {
        id
      }
    }
    accountDetails(accountId: $accountId) {
      id
      name
      cardLast4
    }
  }
`;

export const AccountBreadcrumbs = ({
  items = [],
}: Partial<BreadcrumbsProps>) => {
  const { data, loading } = useAccountIdQuery<
    GetAccountBreadcrumbsQuery,
    GetAccountBreadcrumbsQueryVariables
  >(GET_ACCOUNT_BREADCRUMBS_QUERY);
  const { accountDetails, customer } = data || {};
  const { name, cardLast4, id } = accountDetails || {};

  if (loading) return null;

  const formattedCardLast4 = formatCardLast4(cardLast4);
  const breadcrumbCardName = name || 'Visa';
  const breadcrumbCardLast4 =
    formattedCardLast4 ? ` ${formattedCardLast4}` : '';

  const breadcrumbs = [
    {
      text: `${breadcrumbCardName}${breadcrumbCardLast4}`,
      link: items.length ? `/account/${id}/summary` : '',
    },
    ...items,
  ];

  // if a customer has more than one account, display the
  // Home page breadcrumb
  if (customer && customer?.accounts.length > 1) {
    breadcrumbs.unshift(HomeBreadcrumb);
  }

  return (
    <Temp_MulticardBreadcrumbs
      items={breadcrumbs}
      testID="AccountBreadcrumbs"
    />
  );
};
