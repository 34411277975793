import {
  BubbleIcon,
  Button,
  H4,
  P2,
  Spacer,
  Link,
} from '@missionlane/compass-ui';
import { AddressQuery, IssuanceState } from '@core/graphql/globalTypes';
import { useQuery } from '@apollo/client';
import { ADDRESS_QUERY } from '@core/components/General/CustomerAddress';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useNavigate } from 'react-router-dom';
import { CONTACT_US_LINK } from '@core/utils/constants';
import { PropsWithChildren, useEffect } from 'react';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import Kard from '../General/Kard/Kard';

interface ActivationBannerKardProps {
  variant: IssuanceState | undefined;
}

const ActivationBannerKard = ({ variant }: ActivationBannerKardProps) => {
  switch (variant) {
    case IssuanceState.Delayed:
      return <ActivationBannerKardDelayed />;
    case IssuanceState.Returned:
      return <ActivationBannerKardFailed />;
    case IssuanceState.Mailed:
      return <ActivationBannerKardMailed />;
    case IssuanceState.PreMailed:
      return <ActivationBannerKardMailed isPreMailed />;
    default:
      return null;
  }
};

const ActivationBannerKardDelayed = () => {
  const { isMobile } = useUserDevice();
  const { trackClick } = useTracking();

  const onSendMessage = () => {
    trackClick({
      name: 'Send a message',
      feature: ACTIVATION_FEATURE_TRACKING_NAME,
    });
    window.location.href = CONTACT_US_LINK;
  };
  const messageFormatted =
    !isMobile ?
      <P2>
        If you have your card, activate it now. If it hasn’t arrived, send a
        message or call us.
      </P2>
    : <P2>
        If you have your card, activate it now. If it hasn’t arrived,{' '}
        <Link onPress={onSendMessage}>send a message</Link> or call us.
      </P2>;
  return (
    <BannerKardContainer
      iconColor="mustard"
      showActivateButton={true}
      showMessageButton={true}
      title="Did your card arrive?"
      trackingName="Delayed"
    >
      {messageFormatted}
    </BannerKardContainer>
  );
};

const ActivationBannerKardFailed = () => {
  const { isMobile } = useUserDevice();
  const { trackClick } = useTracking();

  const onSendMessage = () => {
    trackClick({
      name: 'Send a message',
      feature: ACTIVATION_FEATURE_TRACKING_NAME,
    });
    window.location.href = CONTACT_US_LINK;
  };
  const messageFormatted =
    !isMobile ?
      <P2>There was a delivery issue. Send a message or call us.</P2>
    : <P2>
        There was a delivery issue.{' '}
        <Link onPress={onSendMessage}>Send a message</Link> or call us.
      </P2>;

  return (
    <BannerKardContainer
      iconColor="red"
      showActivateButton={false}
      showMessageButton={true}
      title="We couldn’t deliver your card."
      trackingName="Failed"
    >
      {messageFormatted}
    </BannerKardContainer>
  );
};

interface ActivationHeaderKardMailedProps {
  isPreMailed?: boolean;
}

const ActivationBannerKardMailed = ({
  isPreMailed = false,
}: ActivationHeaderKardMailedProps) => {
  const { loading, error, data } = useQuery<AddressQuery>(ADDRESS_QUERY);
  const { street1, street2, city, state, postalCode } =
    data?.customer?.contactInfo?.address || {};

  if (loading) {
    return null;
  }
  const mailedTrackingName = isPreMailed ? 'PreMailed' : 'Mailed';
  const hasAddressError = typeof error !== 'undefined';
  const addressFormatted = ` ${street1}${street2 && ` ${street2}`}, ${city} ${state} ${postalCode}`;
  const messageFormatted = (
    <P2>
      We'll send it to{hasAddressError ? ` your address` : addressFormatted}
    </P2>
  );
  return (
    <BannerKardContainer
      iconColor="green"
      isPreMailed={isPreMailed}
      showActivateButton={true}
      showMessageButton={false}
      title="Your card is on the way!"
      trackingName={mailedTrackingName}
    >
      {messageFormatted}
    </BannerKardContainer>
  );
};

export default ActivationBannerKard;

const ACTIVATION_FEATURE_TRACKING_NAME = 'Activation Banner Kard';

interface BannerKardContainerProps extends PropsWithChildren {
  iconColor: 'green' | 'mustard' | 'red';
  isPreMailed?: boolean;
  showActivateButton: boolean;
  showMessageButton: boolean;
  title: string;
  trackingName: string;
}

const BannerKardContainer = ({
  children,
  iconColor,
  isPreMailed = false,
  showActivateButton,
  showMessageButton,
  title,
  trackingName,
}: BannerKardContainerProps) => {
  const navigate = useNavigate();
  const { isMobile } = useUserDevice();
  const { trackClick, trackEvent } = useTracking();

  const onSendMessage = () => {
    trackClick({
      name: 'Send a message',
      feature: ACTIVATION_FEATURE_TRACKING_NAME,
    });
    window.location.href = CONTACT_US_LINK;
  };

  const onNavigateToActivation = () => {
    trackClick({
      name: 'Activate Card',
      feature: ACTIVATION_FEATURE_TRACKING_NAME,
    });
    navigate('../activate');
  };

  useEffect(() => {
    trackEvent({
      eventName: `Viewed Activation CTA - ${trackingName}`,
      feature: ACTIVATION_FEATURE_TRACKING_NAME,
    });
  }, []);

  return (
    <Kard>
      <div className="flex items-start">
        <BubbleIcon
          name="truck"
          bubbleColor={`${iconColor}Washed`}
          iconColor={iconColor}
        />
        <Spacer size="m" />
        <div>
          <H4 color="ink">{title}</H4>
          <div className="mt3">{children}</div>
          <div className="flex mt4 justify-start">
            {showActivateButton && (
              <>
                <Button
                  disabled={isPreMailed}
                  text="Activate"
                  onPress={onNavigateToActivation}
                />
                <Spacer size="m" />
              </>
            )}
            {showMessageButton && !isMobile && (
              <Button
                text="Send a message"
                variant={showActivateButton ? 'outlined' : 'contained'}
                onPress={onSendMessage}
              />
            )}
          </div>
        </div>
      </div>
    </Kard>
  );
};
