import { gql } from '@apollo/client';

import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';

export const SCHEDULE_PAYMENT = gql`
  mutation SchedulePayment(
    $accountId: String!
    $source: Source!
    $fundingAccountId: Long!
    $paymentType: PaymentType!
    $accountType: AccountType!
    $amount: Int!
    $paymentDate: String!
  ) {
    createScheduledPayment(
      accountId: $accountId
      source: $source
      fundingAccountId: $fundingAccountId
      paymentType: $paymentType
      accountType: $accountType
      amount: $amount
      paymentDate: $paymentDate
    ) {
      id
      amount
      confirmationId
      nextPaymentDate
      paymentType
      fundingAccount {
        id
        bankName
        numberLast4
      }
    }
  }
`;

export const MAKE_IMMEDIATE_PAYMENT = gql`
  mutation MakeImmediatePayment(
    $accountId: String!
    $amount: Long!
    $fundingAccountId: Long!
    $idempotencyKey: String!
    $txMethod: TransactionMethod!
    $source: Source!
  ) {
    makePayment(
      accountId: $accountId
      amount: $amount
      fundingAccountId: $fundingAccountId
      idempotencyKey: $idempotencyKey
      txMethod: $txMethod
      source: $source
    ) {
      ...PaymentFields
    }
  }
  ${PAYMENT_FIELDS}
`;

/**
 * Mutation for submission of a payment using a debit card.
 *
 * This mutation is a String instead of a DocumentNode
 * because it is sent through a proxy to the orchestrator
 * for processing.
 *
 * If using within a useMutation hook, first wrap the
 * imported string in an apollo client gql call.
 */
export const MAKE_DEBIT_CARD_PAYMENT = `mutation MakeDebitCardPayment(
  $accountId: String!
  $amount: Long!
  $source: Source!
  $aliasedHolderName: String!
  $aliasedCardNumber: String!
  $aliasedExpiration: AliasedDebitCardExpiration!
  $aliasedPostalCode: String!
  $aliasedSecurityCode: String!
) {
  makeDebitCardPayment(
    accountId: $accountId
    amount: $amount
    source: $source
    card: {
      aliasedHolderName: $aliasedHolderName
      aliasedCardNumber: $aliasedCardNumber
      aliasedExpiration: $aliasedExpiration
      aliasedPostalCode: $aliasedPostalCode
      aliasedSecurityCode: $aliasedSecurityCode
    }
  ) {
    amount
    date
    id
    type
    state
    redirectURL
  }
}`;
