import React from 'react';
import TrackingLink, {
  TrackingLinkProps,
} from '@core/components/General/TrackingLink';

interface Props {
  trackingProperties: TrackingLinkProps['trackingProperties'];
  autopayOn?: boolean;
}

const PaymentKardAutopayLink = ({ trackingProperties, autopayOn }: Props) => {
  const autopayUrl =
    autopayOn ? '../payments/autopay/manage' : '../payments/autopay';

  const autopayLinkText = autopayOn ? 'Autopay: On' : 'Autopay: Off';
  return (
    <TrackingLink
      to={autopayUrl}
      trackingName="Account Summary: Set up Autopay link"
      trackingProperties={trackingProperties}
    >
      {autopayLinkText}
    </TrackingLink>
  );
};

export default PaymentKardAutopayLink;
