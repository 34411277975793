import React from 'react';
import { IssuanceState } from '@core/graphql/globalTypes';
import DefaultDashboard from './Dashboard/DefaultDashboard';
import {
  PreCardMailedDashboard,
  CardMailedDashboard,
  CardDelayedDashboard,
  DeliveryFailedDashboard,
} from './Dashboard/OnboardingDashboards';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import { Title } from '@core/components/TitleBar/TitleBar';
import { getCustomerGreeting } from '@core/components/HomePage/helpers';

export const getNewCardDashboardComponent = (
  issuanceState: IssuanceState | undefined,
): React.FC => {
  switch (issuanceState) {
    case IssuanceState.PreMailed:
      return PreCardMailedDashboard;
    case IssuanceState.Mailed:
      return CardMailedDashboard;
    case IssuanceState.Delayed:
      return CardDelayedDashboard;
    case IssuanceState.Returned:
      return DeliveryFailedDashboard;
    case IssuanceState.PreExpired:
    case IssuanceState.Activated:
    case IssuanceState.Canceled:
      return DefaultDashboard;
    default:
      return GenericFallbackFull;
  }
};

export const getReplacementCardDashboardComponent = (
  issuanceState: IssuanceState | undefined,
): React.FC => {
  if (issuanceState) {
    return DefaultDashboard;
  }

  return GenericFallbackFull;
};

export const getAccountSummaryPageTitle = (
  displayCustomerGreeting: boolean,
  customerFirstName: string | undefined,
  defaultPageTitle: Title,
): Title | undefined => {
  if (displayCustomerGreeting) {
    return getCustomerGreeting(customerFirstName);
  }
  return defaultPageTitle;
};
