import { gql } from '@apollo/client';
import { P3 } from '@missionlane/compass-ui';

export const CAG_MIXED_DISCLOSURE =
  'The Mission Lane Visa™ Credit Card is issued by Transportation Alliance Bank, Inc. dba TAB Bank, Member FDIC, or WebBank, pursuant to a license from Visa U.S.A. Inc. The Mission Money Visa™ Debit Card is issued by Sutton Bank, Member FDIC, also pursuant to a license from Visa U.S.A. Inc. Mission Lane LLC provides services for your Account, but is not a bank. The bank issuing your card will be identified on the back of your Visa Card and in your Cardholder Agreement, which governs your use of the Account.';

export const CAG_DISCLOSURE_FRAGMENT = gql`
  fragment CagDisclosure on Cag {
    bankDisclosure
  }
`;

interface Props {
  bankDisclosure?: string | null;
}
const CagDisclosureMessage = ({ bankDisclosure }: Props) => {
  return <P3>{bankDisclosure ?? CAG_MIXED_DISCLOSURE}</P3>;
};

export default CagDisclosureMessage;
