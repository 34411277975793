import { P3 } from '@missionlane/compass-ui';
import type { DimensionValue } from 'react-native';
import { formatTxDate } from '../formatTxDate';

interface Props {
  date: string;
  isPending: boolean;
  isPayment: boolean;
}

export const TransactionDate = ({ date, isPending, isPayment }: Props) => (
  <P3 style={styles.date}>{formatTxDate(date, isPending, isPayment)}</P3>
);
const styles = {
  date: {
    marginVertical: '0px' as DimensionValue,
  },
};
