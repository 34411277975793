import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import {
  H1,
  H2,
  H3,
  P2,
  P1,
  TypographyProps,
  P3,
  P4,
  H4,
  H5,
} from '@missionlane/compass-ui';

const HeaderLevelMap = {
  H1,
  H2,
  H3,
  H4,
  H5,
};

const ParagraphLevelMap = {
  P1,
  P2,
  P3,
  P4,
};

//The purpose of this component is to allow for the proper scaling of some
//compass typography components between desktop and mobile web. Some of the
//typography components do not match the design specs between mobile web and desktop
interface BaseResponsiveTypographyProps extends TypographyProps {
  children: React.ReactNode;
  className?: string;
}

interface ResponsiveHeaderProps extends BaseResponsiveTypographyProps {
  type: 'HEADER';
  mobileLevel: keyof typeof HeaderLevelMap;
  desktopLevel: keyof typeof HeaderLevelMap;
}

interface ResponsiveParagraphProps extends BaseResponsiveTypographyProps {
  type: 'PARAGRAPH';
  mobileLevel: keyof typeof ParagraphLevelMap;
  desktopLevel: keyof typeof ParagraphLevelMap;
}

type ResponsiveTypographyProps =
  | ResponsiveHeaderProps
  | ResponsiveParagraphProps;
const ResponsiveTypography = ({
  children,
  type,
  mobileLevel,
  desktopLevel,
  className,
  ...rest
}: ResponsiveTypographyProps) => {
  const { isMobile } = useUserDevice();
  const MobileComponent =
    type === 'HEADER' ?
      HeaderLevelMap[mobileLevel]
    : ParagraphLevelMap[mobileLevel];
  const DesktopComponent =
    type === 'HEADER' ?
      HeaderLevelMap[desktopLevel]
    : ParagraphLevelMap[desktopLevel];

  return (
    <div className={className}>
      {isMobile ?
        <MobileComponent {...rest}>{children}</MobileComponent>
      : <DesktopComponent {...rest}>{children}</DesktopComponent>}
    </div>
  );
};

export default ResponsiveTypography;
