import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useEffect } from 'react';
import { MAKE_PAYMENT_QUERY } from './gql/queries';

import './MakePayment.css';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import PaymentForm from './PaymentForm';
import {
  MakePaymentQuery,
  MakePaymentQueryVariables,
} from '@core/graphql/globalTypes';
import AddBankAccountBanner from '../BankAccount/AddBankAccount/AddBankAccountBanner';
import { MLError } from '@core/services';
import { MakeAchPaymentBreadcrumbs } from './MakeAchPaymentBreadcrumbs';
import { ErrorBoundary } from '@core/services/Error/Error';

const MakePayment = () => {
  const { showPaymentsOutage, showPastDueExperience } = useFlags();
  const { data, loading } = useAccountIdQuery<
    MakePaymentQuery,
    MakePaymentQueryVariables
  >(MAKE_PAYMENT_QUERY);

  const { account } = data || {};
  const {
    id,
    paymentInfo,
    cardDetails,
    autopay = [],
    statuses,
  } = account || {};
  const pageTitle = useAccountPageTitle('Make a Payment');

  useEffect(() => {
    if (autopay.length > 1) {
      MLError.report({ name: `Account ${id} has more than one autopay` });
    }
  }, [autopay.length]);

  if (loading) return <LoadingSpinnerPage />;

  return (
    <PageWrapper
      pageTitle={pageTitle}
      greyBackground={showPastDueExperience}
      banners={[AddBankAccountBanner]}
      trackingProperties={{
        pageName: 'Make a Payment: Pay with Bank Account',
        featureName: 'Payments: Make Payment',
      }}
    >
      <ErrorBoundary>
        <div className="sans-serif lh-copy">
          <MakeAchPaymentBreadcrumbs items={[{ text: 'Make a payment' }]} />
          <PaymentForm
            cardDetails={cardDetails}
            paymentInfo={paymentInfo}
            autopay={autopay[0]}
            loading={loading}
            showPaymentsOutage={showPaymentsOutage}
            statuses={statuses}
          />
        </div>
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default MakePayment;
