import { PaymentType } from '@core/graphql/globalTypes';
import { centsToDollars } from '@core/utils/centsToDollars';

export const getAmountDisplay = (paymentType: PaymentType, amount: number) => {
  switch (paymentType) {
    case PaymentType.MonthlyFixed:
      return centsToDollars(amount);
    case PaymentType.MonthlyStatementBalance:
      return 'Statement balance';
    case PaymentType.MonthlyMinimum:
      return 'Minimum payment';
    default:
      return undefined;
  }
};
