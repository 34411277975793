import EditLink from '../../../EditLink/EditLink';
import { gql, useQuery } from '@apollo/client';
import { GetAddressChangeEligibilityQuery } from '@core/graphql/globalTypes';

import { TrackService } from '@core/services';

export const GET_ADDRESS_CHANGE_ELIGIBILITY_QUERY = gql`
  query GetAddressChangeEligibility {
    customer {
      id
      contactInfo {
        addressChangeEligibility {
          isEligible
        }
      }
    }
  }
`;

const AddressChangeButton = () => {
  const { data, loading, error } = useQuery<GetAddressChangeEligibilityQuery>(
    GET_ADDRESS_CHANGE_ELIGIBILITY_QUERY,
  );

  if (loading) return <span data-testid="Loader" />;

  if (
    error ||
    !data?.customer?.contactInfo?.addressChangeEligibility?.isEligible
  ) {
    return null;
  }

  const handleOnClick = () => {
    TrackService.click('Address: Edit Address');
  };

  return (
    <EditLink
      testID="ChangeAddressLink"
      to={'/change-address'}
      onClick={handleOnClick}
    />
  );
};

export default AddressChangeButton;
