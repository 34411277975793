interface ProgressBarProps {
  progress: number;
}

export const getProgressFillWidth = (
  progress: ProgressBarProps['progress'],
) => {
  if (progress < 0) return '0%';
  if (progress > 1) return '100%';
  return `${progress * 100}%`;
};

const ProgressBar = ({ progress }: ProgressBarProps) => (
  <div className="w-100 br3 bg-washed-ink mv3" style={{ height: '0.75rem' }}>
    <span
      className="db h-inherit br3 bg-yellow"
      style={{ width: getProgressFillWidth(progress) }}
    />
  </div>
);

export default ProgressBar;
