import { PaymentMessage } from '../PaymentMessage';
import PaymentDisplay from '../PaymentDisplay';
import { PaymentMethodName } from '@payments/components/Pay/Pay';
import { Notification, Spacer, H3 } from '@missionlane/compass-ui';
import { PaymentConfirmationButtons } from '../PaymentConfirmationButtons';
import { useNavigate } from 'react-router-dom';
import { ResponsiveKard } from '@core/components/General/ResponsiveKard/ResponsiveKard';
import { useEffect } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';

interface PaymentReviewProps {
  amount: number;
  acctLast4?: string;
  handleSubmit: () => void;
  submissionError: string | null;
}

export const PaymentReview: React.FC<PaymentReviewProps> = ({
  amount,
  acctLast4,
  handleSubmit,
  submissionError,
}) => {
  const navigate = useNavigate();
  const { trackError } = useTracking();
  useEffect(() => {
    if (submissionError) {
      trackError({
        name: 'Debit Card Payment Failure',
        feature: 'Make Debit Card Payment',
        error: {
          code: 'PAY0003',
          message: submissionError,
          name: 'Debit Card Payment Failure',
        },
      });
    }
  }, []);

  return (
    <div className="flex justify-center">
      <ResponsiveKard className="w-75-l bg-white">
        <div className="ph3 ph0-l">
          <H3>Review Payment Details</H3>
          <PaymentDisplay
            amount={amount}
            isImmediate
            paymentMethod={PaymentMethodName.DEBIT_CARD}
            className="ml0 mv4"
          />
          {submissionError ? (
            <Notification variant="inline" level="error">
              <span>{submissionError}</span>
            </Notification>
          ) : (
            <PaymentMessage cardLast4={acctLast4 || ''} isImmediatePayment />
          )}
          <Spacer size="xm" direction="vertical" />
          <PaymentConfirmationButtons
            text={submissionError ? 'Edit Payment Details' : 'Submit Payment'}
            onSubmit={handleSubmit}
            onCancel={() => {
              navigate('..');
            }}
          />
        </div>
      </ResponsiveKard>
    </div>
  );
};
