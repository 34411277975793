import { useLocation } from 'react-router-dom';
import PaymentDetailPage from './PaymentDetailPage';
import TransactionDetailPage from './TransactionDetailPage';
import { Transactions } from '@core/graphql/globalTypes';

export const ActivityDetailPage = () => {
  const { state } = useLocation();

  const activity: Transactions.Activities = state.transaction;

  if (activity.__typename === 'Transaction') {
    return (
      <TransactionDetailPage
        transactionId={activity.transactionId}
        type={activity.type}
      />
    );
  } else {
    return <PaymentDetailPage transaction={activity} />;
  }
};
