import AuthenticatedBanners from './AuthenticatedBanners/AuthenticatedBanners';
import ReplacementCardBanner from './AuthenticatedBanners/ReplacementCardBanner';
import CustomerIOInlineMessage from './CustomerIOInlineMessage';
import ErrorBanner from './ErrorBanner/ErrorBanner';
import MaintenanceBanner from './MaintenanceBanner/MaintenanceBanner';

const BannerContainer = ({
  banners = [],
}: {
  banners?: React.ComponentType[];
}) => {
  return (
    <>
      <MaintenanceBanner />
      <AuthenticatedBanners />
      {banners.map((BannerComponent, index) => (
        <BannerComponent key={index} />
      ))}
      <ReplacementCardBanner />
      <ErrorBanner />
      <CustomerIOInlineMessage />
    </>
  );
};

export default BannerContainer;
