import { TrackService } from '@core/services';
import cx from 'classnames';
export interface PhoneLinkProps {
  label?: string | React.ReactNode;
  phoneNumber: string;
  className?: string;
  /** children will be displayed after the phone number */
  children?: React.ReactNode;
  hideNumber?: boolean;
  /** Segment tracking options */
  tracking?: {
    /** name is automatically prepended with "Call" */
    name: string;
    properties?: {
      [x: string]: any;
    };
  };
}

const PhoneLink = ({
  label,
  phoneNumber,
  className = '',
  tracking,
  children,
  hideNumber,
}: PhoneLinkProps) => {
  function handleOnClick() {
    if (tracking) {
      TrackService.click(tracking.name, tracking.properties);
    }
  }

  return (
    <a
      href={`tel:+1-${phoneNumber}`}
      onClick={handleOnClick}
      className={cx([
        className,
        'link sans-serif',
        {
          'flex items-center': !!children,
        },
      ])}
    >
      {label && <>{label} </>}
      <span className="blue hover-indigo">{hideNumber ? '' : phoneNumber}</span>
      {children}
    </a>
  );
};

export default PhoneLink;
