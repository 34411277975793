import { Notification, Spacer } from '@missionlane/compass-ui';

interface Props {
  error?: string;
}

export const LockCardError = ({ error }: Props) => {
  if (!error) return null;

  return (
    <>
      <Notification variant="inline" level="error">
        There was an issue updating your card's lock status. Please try again
        later.
      </Notification>
      <Spacer size="m" />
    </>
  );
};
