import PageWrapper from '@core/components/Page/PageWrapper';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { gql, useQuery } from '@apollo/client';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { ManageFundingAccounts as ManageFundingAccountNs } from '@core/graphql/globalTypes';
import { useAccount } from '@core/components/Auth/AccountContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PaymentFlowCard from '@payments/components/Payments/PaymentFlowCard';
import sortBy from 'lodash/sortBy';
import FundingAccountListItem from './FundingAccountListItem';
import AddAccountListItem from './AddAccountListItem';
import { useState } from 'react';
import './ManageFundingAccounts.css';
import AddPlaidAccount from '../AddPlaidAccount/AddPlaidAccount';
import { TrackService } from '@core/services';
import { useNavigate } from 'react-router-dom';

const manageFundingAccountsQuery = gql`
  query ManageFundingAccounts($accountId: String!) {
    accountDetails(accountId: $accountId) {
      id
      cardLast4
    }
    fundingAccounts {
      id
      numberLast4
      bankName
      isDefault
      balances {
        balance
      }
    }
  }
`;

export const MANAGE_FUNDING_ACCOUNT_FEATURE_NAME = 'Manage bank accounts';

const ManageFundingAccounts = () => {
  const { manageFundingAccounts } = useFlags();
  const { isMobile } = useUserDevice();
  const { accountId } = useAccount();
  const [banners, setBanners] = useState<React.ComponentType[]>([]);
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<
    ManageFundingAccountNs.Query,
    ManageFundingAccountNs.Variables
  >(manageFundingAccountsQuery, {
    variables: { accountId },
    skip: !manageFundingAccounts,
  });

  if (error) {
    throw error;
  }

  const pageTitle = () => {
    if (loading || !data?.accountDetails?.cardLast4) {
      return 'Manage Bank Accounts';
    }

    return `Manage Bank Accounts (...${data.accountDetails.cardLast4})`;
  };

  const fundingAccounts =
    !!data?.fundingAccounts?.length &&
    sortBy(data.fundingAccounts, (account) => !account.isDefault);

  return (
    <PageWrapper
      greyBackground={!isMobile}
      pageTitle={{ primaryText: pageTitle() }}
      banners={banners}
      trackingProperties={{
        pageName: 'Manage bank accounts',
        featureName: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
      }}
    >
      <AccountBreadcrumbs items={[{ text: 'Manage Bank Accounts' }]} />
      <PaymentFlowCard header="Bank Accounts">
        {loading && <LoadingSpinner />}
        {fundingAccounts && (
          <div className="flex flex-column mb2 gap-8">
            {fundingAccounts.map((fa) => (
              <FundingAccountListItem
                key={fa.id}
                fundingAccount={fa}
                canDeleteFundingAccount={fundingAccounts.length > 1}
                setBanners={setBanners}
              />
            ))}
          </div>
        )}
        {!loading && (
          <div className="flex flex-column gap-8">
            <div className="ba b--grey-lightest br3 pa3 pointer">
              <AddPlaidAccount
                onOpenModal={() => {
                  TrackService.trackClick('Select add account through plaid', {
                    feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
                  });
                }}
                onConnectAccount={() => {
                  TrackService.trackClick('Add plaid account', {
                    feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
                  });
                }}
              />
            </div>
            <AddAccountListItem
              label="Add a Bank Account"
              description="Connect account manually with a routing and account number"
              onClick={() => {
                TrackService.trackClick('Select add account manually', {
                  feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
                });
                navigate(`/account/${accountId}/add-bank-account`);
              }}
            />
          </div>
        )}
      </PaymentFlowCard>
    </PageWrapper>
  );
};

export default ManageFundingAccounts;
