import { useAccount } from '@core/components/Auth/AccountContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState, useEffect } from 'react';
import {
  AccountSummary,
  GetAccountSummaryQuery,
  GET_ACCOUNT_SUMMARY_QUERY,
  parseAccountSummaryQueryResult,
} from '../network';
import { useLazyQuery } from '@apollo/client';

export const useAccountSummary = () => {
  const { accountId } = useAccount();
  const { showClipExperience } = useFlags();

  const [data, setData] = useState<AccountSummary>();
  const [error, setError] = useState(false);

  const [getAccountSummary, accountSummary] =
    useLazyQuery<GetAccountSummaryQuery>(GET_ACCOUNT_SUMMARY_QUERY, {
      variables: { accountId },
    });

  useEffect(() => {
    if (!showClipExperience) {
      return;
    }

    getAccountSummary()
      .then((res) => {
        const parsed = parseAccountSummaryQueryResult(res.data?.account);
        setData(parsed);
        return res;
      })
      .catch(() => {
        // @TODO: set error from parser once error classes are defined https://missionlane.atlassian.net/browse/SLS-1575
        setError(true);
      });
  }, [
    showClipExperience,
    getAccountSummary,
    parseAccountSummaryQueryResult,
    setData,
  ]);

  return {
    data,
    loading: accountSummary.loading,
    error: accountSummary.error || error,
  };
};
