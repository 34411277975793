import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { B, Icon, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

interface BackLinkProps {
  onGoBack?: () => void;
  destination?: string;
}

const BackLink = ({ onGoBack, destination }: BackLinkProps) => {
  const navigate = useNavigate();
  return (
    <div className="w-100">
      <Link
        style={{ textDecorationLine: 'none' }}
        onPress={() => {
          if (destination) {
            navigate(destination);
          } else {
            navigate(-1);
          }

          onGoBack && onGoBack();
        }}
      >
        <div className="flex items-center">
          <Icon name="back" color="blue" size={18} />
          <div className="ml1">
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              color="blue"
            >
              <B>Back</B>
            </ResponsiveTypography>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BackLink;
