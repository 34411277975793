import { Icon, H2, P2 } from '@missionlane/compass-ui';
import { useQuery } from '@apollo/client';
import AddressDisplay from './AddressDisplay';
import { ResponsiveButton } from '@core/components/Button';
import './ChangeAddressSuccessPage.css';
import LoadingSpinner from '../../General/LoadingSpinner';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCustomerInfo } from '@core/queries/getCustomerInfoQuery';

const ChangeAddressSuccessPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showMulticardExperience } = useFlags();
  const isFromStatementsPref = state?.hasOwnProperty(
    'statementsAddressAccountId',
  );

  let returnTo = { path: '/', buttonText: 'Return to Dashboard' };
  if (isFromStatementsPref) {
    returnTo = {
      path: `/account/${state.statementsAddressAccountId}/statements/preferences/confirm`,
      buttonText: 'Return to Statement Preferences',
    };
  } else if (showMulticardExperience) {
    returnTo = { path: '/my-profile', buttonText: 'Return to My Profile' };
  }

  const { data, error, loading } = useQuery(getCustomerInfo, {
    fetchPolicy: 'network-only',
  });

  function returnToAction() {
    navigate(returnTo.path);
  }

  useEffect(() => {
    TrackService.page('Change Address Success');
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <GenericFallbackFull />;
  }

  const { firstName, lastName, address } = data.customer.contactInfo;

  return (
    <div className="container">
      <div className="mr6 mb4 icon-container">
        <Icon name="checkCircleLight" size={118} color="green" />
      </div>
      <div>
        <div className="mb4">
          <H2>Mailing Address Updated!</H2>
        </div>
        <div className="mb4">
          <P2>We'll send all future correspondence to:</P2>
        </div>
        <div className="mb4">
          <AddressDisplay name={`${firstName} ${lastName}`} address={address} />
        </div>
        <div className="mt5">
          <ResponsiveButton
            onPress={returnToAction}
            variant="contained"
            text={returnTo.buttonText}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeAddressSuccessPage;
