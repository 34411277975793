import { ActivityState, ActivityType } from '@core/graphql/globalTypes';
import { FormValues } from '@core/components/SecureForm';
/**
 * Ids used as keys for configuration of the VGS Collect form
 * upon initialization
 */
export enum FieldId {
  CardNumber = '#vgsc-card-number',
  Expiration = '#vgsc-expiration',
  HolderName = '#vgsc-holder-name',
  PostalCode = '#vgsc-postal-code',
  SecurityCode = '#vgsc-security-code',
}

/** Names used as keys for VGS form state */
export enum FieldName {
  HolderName = 'holderName',
  CardNumber = 'cardNumber',
  Expiration = 'expiration',
  PostalCode = 'postalCode',
  SecurityCode = 'securityCode',
}

export interface SubmitCardPaymentSuccess {
  state: ActivityState;
  id: string;
  date: string;
  amount: number;
  type: ActivityType.Payment;
  redirectURL?: string;
}

export interface SubmitCardPaymentFailure {
  status?: number;
  errors: Error[];
}

export type SubmitCardPaymentResponse =
  | SubmitCardPaymentSuccess
  | SubmitCardPaymentFailure;

export type SubmitCardPayment = (
  data: (formValues: FormValues) => void | Record<string, any>,
  onComplete: (reponse: SubmitCardPaymentResponse) => void,
) => void;

export interface IBankCardDetails {
  submitCardPayment: SubmitCardPayment;
}
