import { ActivityState, UpcomingPayments } from '@core/graphql/globalTypes';

export const getmaxPaymentAmount = (
  upcomingPayments: UpcomingPayments.Account['upcomingPayments'] | undefined,
  currentBalance: number,
) =>
  upcomingPayments
    ?.filter((payment) => payment.state == ActivityState.Pending)
    .reduce(
      (accumulator, payment) => accumulator - Math.abs(payment.amount),
      currentBalance,
    );
