const isMissionLaneUrl = (url: string): boolean => {
  // domain can be any characters, but must be mission lane subdomain
  const missionLaneUrlRegex = /^(.+[.])?missionlane([-]+(dev|staging))?[.]com$/;
  const urlObj = new URL(url);
  return missionLaneUrlRegex.test(urlObj.hostname);
};

// Constants for testing. These are just a few examples, not all-encompassing.
export const invalidMissionLaneUrls = [
  'https://dashboard.ml.com',
  'https://apply.missonlane-dev.com',
  'https://very.dangerous-site.com',
  'http://missionlane.fraudulent-subdomain.com',
  'https://missionlane.com.haxxors',
  'https://fakemissionlane.com',
];

export const validMissionLaneUrls = [
  'https://dashboard.missionlane-dev.com',
  'https://dashboard.missionlane-staging.com',
  'https://dashboard.missionlane.com',
  'https://apply.missionlane.com',
  'https://test123.missionlane-dev.com',
  'https://apply.missionlane-staging.com/flow/application?campaign=12345',
  'https://missionlane.com?campaign=12345',
  'http://dashboard.missionlane.com',
  'http://missionlane.com',
];

export default isMissionLaneUrl;
