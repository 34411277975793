import { useEffect, useState } from 'react';
import * as FullStory from '@fullstory/browser';
import cx from 'classnames';

import BannerContainer from '@core/components/Banners/BannerContainer';
import Footer from '@core/components/General/Footer/Footer';
import { Title } from '@core/components/TitleBar/TitleBar';
import { useTracking } from '@core/services/TrackService/useTracking';
import { ErrorBoundary } from '@core/services/Error/Error';

import { PageHeader } from './PageHeader';
import { TrackServiceTestProperties } from '@core/services/TrackService/TrackService';

export interface PageWrapperProps {
  children: React.ReactNode;
  pageTitle?: Title;
  trackingProperties?: {
    pageName: string;
    featureName?: string;
    testProperties?: TrackServiceTestProperties;
  };
  /**
   * Temporary solution for transition to Compass background color - once
   * all pages are using the grey background, remove this prop
   */
  greyBackground?: boolean;
  banners?: React.ComponentType[];
}

const PageWrapper = ({
  children,
  pageTitle,
  trackingProperties,
  greyBackground,
  banners,
}: PageWrapperProps) => {
  const { trackPage } = useTracking();
  const [showErrorTitle, setShowErrorTitle] = useState<boolean>(false);
  const errorPageTitle = { primaryText: "It's not you. It's us." };

  useEffect(() => {
    if (trackingProperties) {
      trackPage({
        name: trackingProperties.pageName,
        feature: trackingProperties.featureName,
      });
    }
  }, [trackingProperties?.pageName]);

  useEffect(() => {
    // https://developer.fullstory.com/browser/v1/set-page-properties/
    if (pageTitle) FullStory.setVars('page', { pageTitle });
  }, [pageTitle]);

  return (
    <div
      id="app"
      className={cx(
        'min-vh-100 flex flex-column',
        greyBackground ? 'bg-haze-lightest' : 'bg-white',
      )}
    >
      <PageHeader pageTitle={showErrorTitle ? errorPageTitle : pageTitle} />
      <BannerContainer banners={banners} />

      <div className="flex-auto ph5-l ph3 pt3 pt4-l pb4">
        <div className="w-100 mw8 center">
          <ErrorBoundary
            onError={() => {
              setShowErrorTitle(true);
            }}
          >
            {children}
          </ErrorBoundary>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PageWrapper;
