import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export const paymentsFlags: LDFlagSet = {
  enablePlaidBalance: false,
  enablePlaidFlow: false,
  'enable-plaid-onboarding': false,
  enablePlaidSpecificUsers: false,
  showPastDueExperience: false,
  showPaymentsOutage: false,
  showRemainingStatementBalance: false,
};
