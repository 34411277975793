import { gql, useQuery } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { NoAccountDashboard } from '@core/components/AccountSummaryPage/Dashboard/OnboardingDashboards';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import HomePage from '@core/components/HomePage/HomePage';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { AccountRoutes } from './AccountRoutes';
import PageWrapper from '../../Page/PageWrapper';
import { identifyLDUser } from '../helpers/identifyLDUser';
import { CustomerRoutes } from './CustomerRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AccountProvider } from '@core/components/Auth/AccountContext';
import AccountInformationProvider from '@core/components/AccountInformation/AccountInformationProvider';
import * as FullStory from '@fullstory/browser';
import { CustomerRoutesQuery } from '@core/graphql/globalTypes';
import { findPendingAccountFromApprovedCreditApplications } from '@core/components/HomePage/helpers';
import { CustomerProvider } from '../CustomerContext';
import { MLError } from '@core/services';

const CUSTOMER_ROUTES_QUERY = gql`
  query CustomerRoutes {
    customer {
      id
      contactInfo {
        email
        firstName
        lastName
      }
      accounts {
        id
        isEligibleForActivation
      }
      creditApplications {
        accountId
        outcome
      }
    }
  }
`;

/** CustomerRoutes is used only for the multicard experience */
export const AuthenticatedRoutes = () => {
  const ldClient = useLDClient();
  const { authState } = useOktaAuth();

  const { data, loading, error } = useQuery<CustomerRoutesQuery>(
    CUSTOMER_ROUTES_QUERY,
    {
      errorPolicy: 'all',
    },
  );
  const { customer } = data || {};
  const { accounts = [], creditApplications = [] } = customer || {};

  // This feels like a weird place to do these tracking identification updates
  // maybe we can move them to the login flow?
  useEffect(() => {
    if (customer) {
      MLError.setUser(customer);
      identifyLDUser(ldClient, customer);
      FullStory.identify(customer.id);
      FullStory.setUserVars({
        email: customer.contactInfo.email || 'Customer email is not defined',
      });
    }
  }, [customer?.id]);

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  const noAccountsFound =
    !error && authState?.isAuthenticated && !accounts.length;

  if (noAccountsFound) {
    return (
      <PageWrapper>
        <NoAccountDashboard />
      </PageWrapper>
    );
  }

  const hasOneAccount = accounts.length === 1;
  const hasPendingCard =
    findPendingAccountFromApprovedCreditApplications(creditApplications);

  const activationEligibleAccounts = data?.customer?.accounts.find(
    (account) => account.isEligibleForActivation,
  );

  const activationAccount = activationEligibleAccounts || accounts[0];

  const Element =
    hasOneAccount && !hasPendingCard ?
      <Navigate replace={true} to={`/account/${accounts[0].id}/summary`} />
    : <HomePage />;

  return (
    <CustomerProvider>
      <Routes>
        <Route index element={Element} />
        {/** This is a path made specifically for communications */}
        <Route path="/redirect" element={Element} />
        <Route
          path="/account/:accountId/*"
          element={
            <AccountProvider>
              <AccountInformationProvider>
                <AccountRoutes />
              </AccountInformationProvider>
            </AccountProvider>
          }
        />
        <Route
          index
          path={'/activate'}
          element={
            <Navigate to={`/account/${activationAccount.id}/activate`} />
          }
        />
        <Route path="/*" element={<CustomerRoutes />} />
      </Routes>
    </CustomerProvider>
  );
};
