import { P3 } from '@missionlane/compass-ui';

interface DescriptionListDetailItemProps {
  label: string;
  value: string | React.ReactNode;
}

/**
 * Label/value pairs (ie term/description) rendered with dt and dd elements.
 *
 * Should only be used within a DescriptionList.
 *
 * Use when there are multiple definitions for one
 * term (such as Cash Back Rewards in Account Details)
 */
export const DescriptionListDetailItem = ({
  label,
  value,
}: DescriptionListDetailItemProps) => (
  <div className="flex justify-between">
    <dt>
      <P3>{label}</P3>
    </dt>
    <dd className="tr">
      {typeof value === 'string' ?
        <P3 color="ink">{value}</P3>
      : value}
    </dd>
  </div>
);
