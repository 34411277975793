import LoadingSpinner from '@core/components/General/LoadingSpinner';
import AccountDetailsAccountSummary from './AccountDetailsAccountSummary/AccountDetailsAccountSummary';
import AccountDetailsCashAdvances from './AccountDetailsCashAdvances';
import AccountDetailsCardTerms from './AccountDetailsCardTerms';
import AccountDetailsCardholderInfo from './AccountDetailsCardholderInfo/AccountDetailsCardholderInfo';
import CagLink from './CagLink';
import CagDisclosureMessage from './CagDisclosureMessage';
import { H3, Spacer } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountDetailsPageQuery } from '@core/graphql/globalTypes';
import { ContentCard } from '@core/components/General/ContentCard';

interface Props {
  data?: AccountDetailsPageQuery;
  loading?: boolean;
}
const AccountDetailsBody = ({ data, loading }: Props) => {
  const { showMulticardExperience } = useFlags();

  const {
    annualFee,
    availableCredit,
    balanceInfo,
    creditLimit,
    cashAdvance,
    cashAdvanceFeeExempt,
    paymentInfo,
    purchaseAprData,
    promotions,
    rewards,
    statuses,
  } = data?.account || {};
  const { contactInfo } = data?.customer || {};
  const { canModifyPin, isChargedOff } = statuses || {};
  const { url: cagUrl, bankDisclosure } = data?.cagByAccountId || {};

  if (loading) return <LoadingSpinner />;

  return (
    <ContentCard>
      {!showMulticardExperience && (
        <>
          <div className="pb3 mb3">
            <H3>Account details</H3>
          </div>
          <AccountDetailsCardholderInfo contactInfo={contactInfo} />
        </>
      )}
      <AccountDetailsAccountSummary
        availableCredit={availableCredit}
        creditLimit={creditLimit || 0}
        balanceInfo={balanceInfo}
        paymentInfo={paymentInfo}
        isChargedOff={isChargedOff}
      />
      <SectionSeparator />
      <AccountDetailsCardTerms
        annualFee={annualFee}
        purchaseAprData={purchaseAprData}
        rewards={rewards}
        promotions={promotions}
      />
      <SectionSeparator />
      <AccountDetailsCashAdvances
        canModifyPin={canModifyPin !== false}
        cashAdvanceAvailableCreditLimit={
          balanceInfo?.cashAdvanceAvailableCreditLimit
        }
        cashAdvance={cashAdvance}
        cashAdvanceFeeExempt={cashAdvanceFeeExempt || false}
      />
      <SectionSeparator />
      <CagLink cagUrl={cagUrl} />
      <Spacer size="l" />
      <SectionSeparator />
      <CagDisclosureMessage bankDisclosure={bankDisclosure} />
    </ContentCard>
  );
};

export default AccountDetailsBody;

const SectionSeparator = () => <div className="bb b--ink-10 bw1 mb5" />;
