import { gql } from '@apollo/client';
import { DescriptionList } from '@core/components/General/DescriptionList/DescriptionList';
import { ERROR_FRAGMENT } from '@core/graphql/globalFragments';
import { CardTermsPurchaseApr } from '@core/graphql/globalTypes';
import { Unavailable } from '../../Unavailable';
import { PurchaseAprsSubtitle } from './PurchaseAprsSubtitle';
import { isErrorResult } from '@core/utils/isErrorResult';

const getPurchaseAprDisplayValue = (
  purchaseAprData: CardTermsPurchaseApr.PurchaseAprData | null | undefined,
) => {
  const purchaseApr =
    !isErrorResult(purchaseAprData) ? purchaseAprData : undefined;
  const { value, type } = purchaseApr || {};
  if (!value) return <Unavailable />;
  if (type) return `${value} ${type}`;
  return value;
};

export const CARD_TERMS_PURCHASE_APR = gql`
  fragment CardTermsPurchaseApr on Account {
    id
    purchaseAprData {
      ... on PurchaseAprData {
        __typename
        value
        type
      }
      ...ErrorFragment
    }
    promotions {
      ... on Promotions {
        introductoryPurchaseApr {
          ... on IntroductoryPurchaseApr {
            expirationDate
            futurePurchaseAprData {
              ... on FuturePurchaseApr {
                __typename
                startDate
                value
              }
              ...ErrorFragment
            }
            introAprLengthInMonths
          }
          ...ErrorFragment
        }
      }
      ...ErrorFragment
    }
  }
  ${ERROR_FRAGMENT}
`;

interface PurchaseAprsProps {
  purchaseAprData?: CardTermsPurchaseApr.PurchaseAprData | null;
  introductoryPurchaseApr?: CardTermsPurchaseApr.IntroductoryPurchaseApr | null;
}

export const PurchaseAprs = ({
  purchaseAprData,
  introductoryPurchaseApr,
}: PurchaseAprsProps) => {
  if (introductoryPurchaseApr) {
    return (
      <DescriptionList.Item
        label="Purchase APR"
        value=""
        subTitle={
          <PurchaseAprsSubtitle
            currentPurchaseApr={getPurchaseAprDisplayValue(purchaseAprData)}
            introductoryPurchaseApr={introductoryPurchaseApr}
          />
        }
      />
    );
  }

  return (
    <DescriptionList.Item
      label="Purchase APR"
      value={getPurchaseAprDisplayValue(purchaseAprData)}
    />
  );
};

export function formatApr(apr: number) {
  return `${apr}%`;
}
