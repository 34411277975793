import { H2, H4, P3 } from '@missionlane/compass-ui';

export const PaymentKardBodySettled = () => {
  return (
    <div className="flex flex-column">
      <div className="dn-ns">
        <H2>Your account is settled.</H2>
      </div>
      <div className="dn db-ns">
        <H4>Your account is settled.</H4>
      </div>
      <P3>
        You’ve paid off your full account balance, so we’ve settled and closed
        your account. Please give us a call if you have any questions.
      </P3>
    </div>
  );
};

export default PaymentKardBodySettled;
