import { P3, B, Colors, Spacing, P4 } from '@missionlane/compass-ui';
import { CSSProperties } from 'react';
import Switch from 'react-switch';

interface LockCardTileProps {
  last4?: string | null;
  isLocked: boolean;
  setIsLocked: (value: boolean) => void;
}

export const LockCardTile = ({
  last4,
  isLocked,
  setIsLocked,
}: LockCardTileProps) => (
  <div style={tileStyles}>
    <P3 color="ink">
      <B>Lock card</B>{' '}
      {last4 && (
        <>
          (<P4 color="green">...{last4}</P4>)
        </>
      )}
    </P3>
    <Switch
      checked={isLocked}
      onChange={setIsLocked}
      offColor={Colors.inkLight}
      onColor={Colors.blue}
      checkedIcon={false}
      uncheckedIcon={false}
    />
  </div>
);

const tileStyles: CSSProperties = {
  display: 'flex',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: Colors.inkLightest,
  padding: `${Spacing.s}px ${Spacing.m}px`,
  borderRadius: `${Spacing.s}px`,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};
