import { TrackService } from '@core/services';

import { Colors, Link } from '@missionlane/compass-ui';
import bankAndCoins from '@core/assets/addPaymentMethod.png';
import {
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
} from './LayoutComponents';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';

export const ADD_PAYMENT_METHOD_TILE_EVENT =
  'Opportunity Tile: Add Payment Method';

const AddPaymentMethodTile = ({ onDismiss }: CarouselTileProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.trackEvent('Offer Viewed', {
      feature: ADD_PAYMENT_METHOD_TILE_EVENT,
    });
  }, []);

  return (
    <OpportunityTileContainer backgroundColor="tealWashed">
      <div
        style={{
          position: 'absolute',
          width: `${BACKGROUND_SAFE_WIDTH}px`,
          height: '200px',
          background: Colors.pineLight,
          right: 0,
          top: 0,
        }}
      />
      <img
        src={bankAndCoins}
        alt="bank and coins"
        style={{
          position: 'absolute',
          width: `${IMAGE_WIDTH}px`,
          right: IMAGE_RIGHT,
          bottom: IMAGE_BOTTOM,
        }}
      />
      <OpportunityTileCTA
        trackingName={ADD_PAYMENT_METHOD_TILE_EVENT}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title="Add a payment method"
        text="Your first step for stress free payments!"
        onButtonClick={() =>
          navigate('../manage-bank-accounts', { preventScrollReset: false })
        }
        buttonLabel="Get started"
      />
      <Link
        onPress={() => onDismiss(ADD_PAYMENT_METHOD_TILE_EVENT)}
        style={{
          zIndex: 2,
        }}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default AddPaymentMethodTile;

const IMAGE_WIDTH = 90;
const IMAGE_RIGHT = 30;
const IMAGE_BOTTOM = 12;
const BACKGROUND_SAFE_WIDTH = IMAGE_WIDTH / 2 + IMAGE_RIGHT;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - IMAGE_RIGHT;
