import { useEffect } from 'react';
import LoadingSpinner from '@core/components/General/LoadingSpinner';

export const THREE_DS_CONFIRMATION_MESSAGE = '3DS-authentication-complete';

const ThreeDSConfirmation = () => {
  useEffect(() => {
    window.top?.postMessage(THREE_DS_CONFIRMATION_MESSAGE, '*');
  }, []);

  return <LoadingSpinner />;
};

export default ThreeDSConfirmation;