import dayjs, { ConfigType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

enum Timezones {
  ET = 'America/New_York',
  PT = 'America/Los_Angeles',
}

export const getET = (dateStr?: string) =>
  dateStr ? dayjs.tz(dateStr, Timezones.ET) : dayjs().tz(Timezones.ET);

export const getPT = (dateStr?: string) =>
  dateStr ? dayjs.tz(dateStr, Timezones.PT) : dayjs().tz(Timezones.PT);

export const getUTC = (date?: ConfigType) => dayjs.utc(date);
