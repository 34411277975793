import { useTracking } from '@core/services/TrackService/useTracking';
import { LOST_OR_STOLEN_FAQ_URL } from '@core/utils/constants';
import { B, Link, P3 } from '@missionlane/compass-ui';

export const ReplacementCardLink = () => {
  const { trackClick } = useTracking();

  return (
    <P3>
      Activity on your account will resume if you unlock your card or{' '}
      <Link
        onPress={() => {
          trackClick({ name: "contact us for a replacement", feature: "Lock Card" })
          window.open(LOST_OR_STOLEN_FAQ_URL)
        }}
        style={styles.link}
      >
        <B>contact us for a replacement.</B>
      </Link>
    </P3>
  )
};

const styles = {
  link: {
    textDecorationLine: 'none' as const,
  },
};
