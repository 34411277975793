import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import PageWrapper from '@core/components/Page/PageWrapper';
import { Button, P3 } from '@missionlane/compass-ui';
import FaqKard from '../Kards/FaqKard';
import { useLocation } from 'react-router-dom';
import { ChargedOffBannerVariant as FaqPageVariant } from '@core/components/Banners/AuthenticatedBanners/ChargedOffBanner';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

const FaqPage = () => {
  const location = useLocation();

  useEffect(() => {
    TrackService.trackPage('Charged Off FAQ', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  return (
    <PageWrapper pageTitle={useAccountPageTitle('Visa')} greyBackground>
      <P3>
        <Button
          text="Back"
          variant="text"
          icon={{ name: 'back', position: 'leading' }}
          onPress={() => window.history.back()}
        />
      </P3>
      <div className="mt3 flex justify-center">
        <FaqKard
          variant={location.state?.faqVariant || FaqPageVariant.DEFAULT}
        />
      </div>
    </PageWrapper>
  );
};

export default FaqPage;
