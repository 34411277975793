export const lockCardAnimation = {
  nm: 'Lock',
  ddd: 0,
  h: 72,
  w: 72,
  meta: { g: 'LottieFiles AE 0.1.21' },
  layers: [
    {
      ty: 4,
      nm: 'accent',
      sr: 1,
      st: 152,
      op: 339,
      ip: 152,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [38.875, 37.375, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 3',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [20.461, -12.855],
                    [26.233, -17.514],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 23.267, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 2',
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [18.184, -12.219],
                    [25.184, -19.719],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 10.336, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 1',
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [17.317, -10.973],
                    [21.891, -16.552],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0.748, 0.314], ix: 2 },
              r: { a: 0, k: -8.266, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 4,
          e: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 262 },
              { s: [100], t: 278 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 269 },
              { s: [100], t: 285 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'accent',
      sr: 1,
      st: 0,
      op: 187,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [38.875, 37.375, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 3',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [20.461, -12.855],
                    [26.233, -17.514],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 23.267, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 2',
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [18.184, -12.219],
                    [25.184, -19.719],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 10.336, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 1',
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [17.317, -10.973],
                    [21.891, -16.552],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0.748, 0.314], ix: 2 },
              r: { a: 0, k: -8.266, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 4,
          e: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 110 },
              { s: [100], t: 126 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 117 },
              { s: [100], t: 133 },
            ],
            ix: 1,
          },
          m: 1,
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: 'handle',
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [-13.5, 11.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [22.5, 34.75, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 108,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-33],
              t: 120,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.667, y: 1 },
              s: [-33],
              t: 259,
            },
            { s: [0], t: 271 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 40, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [3.5, 0],
                    [0, -2.761],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, -2.5],
                    [-3.5, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.5, 3.75],
                    [4.5, 1.25],
                    [0, -3.75],
                    [-4.5, 1.25],
                    [-4.5, 3.75],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 3 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [300, 300], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 3,
    },
    {
      ty: 4,
      nm: 'lock',
      sr: 1,
      st: 0,
      op: 300,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [-23.75, 0.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [12.25, 49.257, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 108,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [5],
              t: 120,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.667, y: 1 },
              s: [5],
              t: 259,
            },
            { s: [0], t: 271 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Fill 1',
          ix: 1,
          cix: 2,
          np: 4,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.459, 0],
                    [0, 0.458],
                    [0, 0],
                    [0.469, 0],
                    [0, -0.449],
                    [0, 0],
                  ],
                  o: [
                    [0.469, 0],
                    [0, 0],
                    [0, -0.449],
                    [-0.459, 0],
                    [0, 0],
                    [0, 0.458],
                  ],
                  v: [
                    [-0.005, 1.857],
                    [0.837, 1.033],
                    [0.837, -1.043],
                    [-0.005, -1.857],
                    [-0.837, -1.043],
                    [-0.837, 1.033],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              ix: 2,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-2.219, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.17],
                    [0, 0],
                    [2.219, 0],
                    [0, 0],
                    [0, 2.17],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [2.219, 0],
                    [0, 0],
                    [0, 2.17],
                    [0, 0],
                    [-2.219, 0],
                    [0, 0],
                    [0, -2.17],
                  ],
                  v: [
                    [-3.982, -6.252],
                    [3.982, -6.252],
                    [3.982, -6.252],
                    [8, -2.323],
                    [8, 2.323],
                    [3.982, 6.252],
                    [-3.982, 6.252],
                    [-8, 2.323],
                    [-8, -2.323],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'mm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Merge',
              nm: 'Merge Paths 1',
              mm: 5,
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0, 0.4549, 0.349], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [300, 300], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 4,
    },
  ],
  v: '5.5.7',
  fr: 60,
  op: 300,
  ip: 0,
  assets: [],
};
