import { P3 } from '@missionlane/compass-ui';

export interface CheckboxProps {
  onChange: (e: boolean) => void;
  id: string;
  name: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  label?: string;
}

const Checkbox = ({
  onChange,
  disabled,
  name,
  id,
  defaultChecked,
  label,
}: CheckboxProps) => {
  return (
    <span className="flex items-center">
      <input
        style={{ appearance: 'auto' }}
        id={id}
        name={name}
        type="checkbox"
        className="w1 h1 mr2"
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
      {label && (
        <label htmlFor={name}>
          <P3>{label}</P3>
        </label>
      )}
    </span>
  );
};

export default Checkbox;
