import PageWrapper from '@core/components/Page/PageWrapper';
import errorSvg from '@core/assets/crossroads.svg';
import { H3, P1, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <PageWrapper
      greyBackground
      trackingProperties={{
        pageName: 'PageNotFound',
        featureName: `Path: ${window.location.pathname}`,
      }}
    >
      <div className="bg-white ph6-l pa4 mv6-l mv4">
        <div className="flex-l">
          <img src={errorSvg} alt="error" className="db ma4 mr6-l" />
          <div className="w-45-l ma4">
            <div className="mv4">
              <H3>
                <span className="f1 f2-l">Oops, wrong way</span>
              </H3>
            </div>
            <div className="mv4">
              <P1>
                The page you’re looking for <br />
                got lost.
              </P1>
            </div>
            <div className="mv5">
              <P1>
                Let’s take you <Link onPress={() => navigate('/')}>home</Link>.
              </P1>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PageNotFound;
