import cx from 'classnames';
import { ReactNode } from 'react';

import './List.css';

interface ListProps {
  children: ReactNode[];
  className?: string;
}

const List = ({ children, className }: ListProps) => (
  <ul className={cx('generic-list list pa0 ma0', className)}>
    {children.map((child, index) => (
      <li
        className="generic-list-item pv3 bb b--ink-10 bw1"
        key={`Generic-List-Item-${index}`}
      >
        {child}
      </li>
    ))}
  </ul>
);

export default List;
