import { Icon } from '@missionlane/compass-ui';
import TrackingLink from '@core/components/General/TrackingLink';
import { AccountDetailsPage } from '@core/graphql/globalTypes';
import { useState } from 'react';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';

interface CagLinkProps {
  cagUrl: AccountDetailsPage.CagByAccountId['url'] | undefined;
}

const CagLink = ({ cagUrl }: CagLinkProps) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const [cagLinkError, setCagLinkError] = useState<Error>();

  if (cagLinkError) throw cagLinkError;

  const handleOnClick = async () => {
    if (!cagUrl) {
      setCagLinkError(new Error('Account details: No cag link to display'));
    } else {
      window.open(cagUrl);
    }
  };

  return (
    <TrackingLink
      onClick={handleOnClick}
      trackingName="Account details: Download Cardholder Agreement"
      trackingProperties={customerAndAccountIds}
    >
      <div className="inline-flex justify-between items-center w-100">
        Download cardholder agreement
        <span className="pt1">
          <Icon name="download" color="blue" />
        </span>
      </div>
    </TrackingLink>
  );
};

export default CagLink;
