import { useEffect, useState } from 'react';
import { centsToDollars } from '@core/utils/centsToDollars';

interface Props {
  targetValue: number;
  duration?: number;
}

/**
 * Calculate the multiplier for the current value in an animation
 * based on the current progress of the animation
 *
 * @param p progress in animation (current frame / total frames)
 */
const easeInOut = (p: number) =>
  p < 0.5 ? 8 * Math.pow(p, 4) : 1 - Math.pow(-2 * p + 2, 4) / 2;

/**
 * Calculate the value for a single frame between start and end
 * values of an animation
 *
 * @param m multiplier derived from current animation progress
 * @param startValue animation start value
 * @param endValue animation end value
 */
const valueAtTime = (m: number, startValue: number, endValue: number) =>
  Math.round(m * (endValue - startValue) + startValue);

/**
 * Currency display that animates its value in real-time upon
 * state changes
 */
export const AnimatedCurrency = ({ targetValue, duration = 2000 }: Props) => {
  const [currValue, setCurrValue] = useState<number>(targetValue);

  const frameDuration = 1000 / 50; // 50fps or 20spf
  const totalFrames = Math.round(duration / frameDuration);

  useEffect(() => {
    if (currValue != targetValue) {
      const startValue = currValue.valueOf();
      let frame = 0;
      const counter = setInterval(() => {
        frame++;
        setCurrValue(
          valueAtTime(easeInOut(frame / totalFrames), startValue, targetValue),
        );
        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    }
  }, [targetValue]);

  return <>{centsToDollars(currValue)}</>;
};
