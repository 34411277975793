import cx from 'classnames';
import { ReactNode } from 'react';
import { H1, H2, H3, H4, H5, P3, Icon } from '@missionlane/compass-ui';
import { KardHeaderActions, KardHeaderAction } from './KardHeaderActions';

export const KARD_DISMISS_BUTTON = 'KardDismissButton';

const HeaderLevelMap = {
  P3,
  H1,
  H2,
  H3,
  H4,
  H5,
} as const;

export type KardHeader = {
  textPrimary: string;
  textSecondary?: string;
  action?: KardHeaderAction;
  actionSecondary?: KardHeaderAction;
  className?: string;
  level?: keyof typeof HeaderLevelMap;
  isLightTheme?: boolean;
};

export interface BaseKardProps {
  children: ReactNode;
  className?: string;
  onDismiss?: () => void;
  /** Full width section at top of Kard, not contained by padding of the kard body */
  headline?: ReactNode;
  header?: KardHeader;
  footer?: ReactNode;
  testID?: string;
  hasDynamicCardBackground?: boolean;
  cardArtUrl?: string | null;
}

export type ClickableKardVariants = 'default' | 'navigationArrow';

interface ClickableKardProps extends BaseKardProps {
  onClick?: () => void;
  disabled?: boolean;
  variant?: ClickableKardVariants;
}

interface UnclickableKardProps extends BaseKardProps {
  onClick?: never;
  disabled?: never;
  variant?: never;
}

export type KardProps = ClickableKardProps | UnclickableKardProps;

/**
 * A configurable ui card component.
 *
 * Includes a navigationArrow variant, which is not the default.
 *
 * Intended to be used temporarily until a compass-ui component has been created.
 * Misspelling is intentional to differentiate it from an actual credit card product.
 */
const Kard = ({
  children,
  className,
  onDismiss,
  headline,
  header,
  footer,
  testID,
  onClick,
  disabled,
  variant = 'default',
  hasDynamicCardBackground,
}: KardProps) => {
  const Header = header && {
    ...header,
    Component: HeaderLevelMap[header.level || 'H3'], // Header level is H3 by default
  };
  const {
    action: headerAction,
    actionSecondary: headerActionSecondary,
    className: headerClassName,
    isLightTheme = true,
  } = Header || {};

  return (
    <div
      className={cx(
        `bg-white br2 flex`,
        onClick && !disabled && 'pointer',
        onDismiss && 'relative',
        !disabled && 'shadow-2', // We have some non-clickable cards that still want a box shadow
        className,
      )}
      data-testid={testID}
      onClick={!disabled ? onClick : undefined}
      aria-disabled={disabled}
    >
      {onDismiss && (
        <div
          className="absolute top-1 right-1 pointer"
          onClick={onDismiss}
          data-testid={KARD_DISMISS_BUTTON}
        >
          <Icon name="close" />
        </div>
      )}
      <div className="w-100">
        {headline && <article className="w-100">{headline}</article>}
        {/* Kard Body */}
        <div className="pa5-ns pa4">
          {Header && (
            <div
              className={cx(
                headerClassName || 'mb4', // default bottom margin
                headerAction && 'flex justify-between',
              )}
            >
              <Header.Component>
                {/**
                 * Our typography components are not designed to be nested
                 * (display: flex), so we have to use a span to wrap each of the
                 * text elements. The extra span is not ideal, but it's harmless
                 */}

                {Header.textSecondary ?
                  <span
                    className={
                      hasDynamicCardBackground ?
                        isLightTheme ?
                          'ink'
                        : 'white'
                      : undefined
                    }
                  >
                    {Header.textPrimary}
                    <span className={isLightTheme ? 'green' : 'white'}>
                      &nbsp;{Header.textSecondary}
                    </span>
                  </span>
                : Header.textPrimary}
              </Header.Component>
              <KardHeaderActions
                headerAction={headerAction}
                headerActionSecondary={headerActionSecondary}
              />
            </div>
          )}

          {children}
        </div>
        {/* Optional Kard Footer */}
        {footer}
      </div>
      {/* Optional Navigation Arrow */}
      {variant === 'navigationArrow' && (
        <div
          className={`w2 br2 br--right flex items-center justify-center bg-pine ${
            disabled ? 'o-50' : ''
          }`}
        >
          <Icon name="forward" color="white" />
        </div>
      )}
    </div>
  );
};

export default Kard;
