import { TextField } from '@missionlane/compass-ui';
import { useRef, useState } from 'react';
import { TextInput } from 'react-native';

interface CurrencyTextFieldProps {
  amount: number;
  onChangeAmount: (amount: number) => void;
  clearable?: boolean;
  maxLength?: number;
}
const CurrencyTextField = ({
  amount,
  onChangeAmount: onAmountChange,
  clearable,
  maxLength,
}: CurrencyTextFieldProps) => {
  const [displayAmount, setDisplayAmount] = useState<string>(
    (amount / 100).toFixed(2),
  );
  const textFieldRef = useRef<TextInput>(null);

  const handleAmountChange = (value: string) => {
    const valueAsNumber = Number(value.replace('.', ''));
    if (!isNaN(valueAsNumber) && valueAsNumber >= 0) {
      setDisplayAmount((valueAsNumber / 100).toFixed(2).toString());
      onAmountChange(valueAsNumber);
    }
  };

  return (
    <TextField
      name="Amount"
      label="Amount"
      icon="dollarSign"
      value={displayAmount}
      maxLength={maxLength}
      placeholder="0.00"
      onChangeText={handleAmountChange}
      trailingIcon={
        clearable ?
          {
            name: 'circleXMark',
            color: 'blue',
            disabled: false,
            button: {
              onPress: () => {
                handleAmountChange('0.00');
                textFieldRef.current?.focus();
              },
            },
          }
        : undefined
      }
    />
  );
};

export default CurrencyTextField;
