import { gql } from '@apollo/client';

export const PAYMENT_PLAN_QUERY = gql`
  query PaymentPlan($accountId: String!) {
    account(accountId: $accountId) {
      id
      paymentPlanUrl
    }
  }
`;

export type { PaymentPlanQuery as PaymentPlanQueryType } from '@core/graphql/globalTypes';
