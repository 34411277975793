import { Notification, Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import PaymentFlowCard from '../../Payments/PaymentFlowCard';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useAutopay } from '../AutopayContext';

const CancelAutoPaySuccess = () => {
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const onBackToSummary = () => navigate(summaryPath);
  const { autopayPageTitle } = useAutopay();

  return (
    <PageWrapper
      pageTitle={autopayPageTitle}
      greyBackground
      trackingProperties={{
        pageName: 'Cancel Autopay: Success',
        featureName: 'Payments: Autopay',
      }}
    >
      <PaymentFlowCard header="Autopay Canceled">
        <div className="mb5">
          <Notification level="success">
            You've canceled your autopay schedule. We've sent you a confirmation
            email.
          </Notification>
        </div>
        <div className="w-30-l">
          <Button
            variant="contained"
            text="Done"
            size="small"
            onPress={onBackToSummary}
          />
        </div>
      </PaymentFlowCard>
    </PageWrapper>
  );
};

export default CancelAutoPaySuccess;
