import { P3 } from '@missionlane/compass-ui';
import Modal from '@core/components/General/Modal/Modal';
import { TrackService } from '@core/services';

interface PaperStatementsModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

const PaperStatementsModal = ({
  isOpen,
  onConfirm,
  onClose,
  onCancel,
}: PaperStatementsModalProps) => {
  if (isOpen) {
    TrackService.modal('Statement Preference Modal: Verify paper preference');
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        TrackService.click('Statement Preference Modal: Close');
        onClose();
      }}
      headerText="Are you sure you want paper statements?"
      secondaryButton={{
        text: 'No, I Want Paperless',
        onPress: () => {
          TrackService.click('Statement Preference Modal: Stay paperless');
          onCancel();
        },
      }}
      primaryButton={{
        text: 'Yes, I Want Paper',
        onPress: () => {
          TrackService.click('Statement Preference Modal: Want paper');
          onConfirm();
        },
      }}
    >
      <P3>
        With paperless, not only will you save paper, you could also reduce
        clutter and paperwork.
      </P3>
    </Modal>
  );
};

export default PaperStatementsModal;
