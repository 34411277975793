import ActivationForm from './ActivationForm';
import ActivationSuccess from './ActivationSuccess';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import GetReady from './GetReady';
import { ProtectedRoute } from '../App/Routes/ProtectedRoute';

const ActivationFeature = () => {
  const pageTitle = useAccountPageTitle('Activate card');
  const location = useLocation();

  return (
    <PageWrapper
      greyBackground
      pageTitle={pageTitle}
      trackingProperties={{ pageName: 'Activate card' }}
    >
      <AccountBreadcrumbs items={[{ text: 'Activate card' }]} />
      <div className="mb5">
        <Routes>
          <Route path="/" element={<ActivationForm />} />
          <Route
            path="success"
            element={
              <ProtectedRoute
                blocked={!location.state?.prevPath?.includes('activate')}
                redirectTo=".."
              >
                <ActivationSuccess />
              </ProtectedRoute>
            }
          />
          <Route path="get-ready" element={<GetReady />} />
        </Routes>
      </div>
    </PageWrapper>
  );
};

export default ActivationFeature;
