import { RadioGroupProps } from '@missionlane/compass-ui';
import { OTPFactor } from '../types';
import { HookFormRadioGroup } from '@core/components/Form';

interface SendCodeOptionsProps {
  fieldName: string;
  onChange: (option: OTPFactor) => void;
  disabled?: boolean;
  selected?: OTPFactor;
}

const SendCodeOptions = ({
  fieldName,
  onChange,
  disabled,
  selected,
}: SendCodeOptionsProps) => {
  const options: RadioGroupProps<OTPFactor>['options'] = [
    {
      label: 'Text my phone',
      value: 'sms',
    },
    {
      label: 'Call my phone',
      value: 'call',
    },
  ];

  return (
    <HookFormRadioGroup
      variant="contained"
      name={fieldName}
      label="How should we send your code?"
      value={selected}
      options={options}
      onChange={(value) => onChange(value as OTPFactor)}
      disabled={disabled}
    />
  );
};

export default SendCodeOptions;
