import { B, Icon, Link, P3, P4, Spacer } from '@missionlane/compass-ui';
import { faLeaf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrackService } from '@core/services';

interface EditPaperlessPreferenceProps {
  isPaperless?: boolean;
  onPress: () => void;
}

const EditPaperlessPreference = ({
  isPaperless,
  onPress,
}: EditPaperlessPreferenceProps) => (
  <div className="mt3">
    <div className="flex justify-between items-center">
      {isPaperless ?
        <PaperlessStatementText />
      : <MailedStatementText />}
      <Link
        onPress={() => {
          TrackService.click('Statement Preference (step 1): Edit');
          onPress();
        }}
      >
        <Icon name="edit" color="blue" />
      </Link>
    </div>
    {!isPaperless && <GoPaperlessMessage onPress={onPress} />}
  </div>
);

export default EditPaperlessPreference;

const PaperlessStatementText = () => (
  <div>
    <P3 color="greyLight">
      <B>You’re enrolled in </B>
    </P3>
    <P3 color="green">
      <B>paperless </B>
      <FontAwesomeIcon icon={faLeaf} size="sm" color="green" />
    </P3>
  </div>
);

const MailedStatementText = () => (
  <div>
    <P3 color="greyLight">
      <B>You’re receiving </B>
    </P3>
    <P3 color="ink">
      <B>statements in the mail.</B>
    </P3>
  </div>
);

interface GoPaperlessMessageProps__TEMP {
  onPress: () => void;
}
const GoPaperlessMessage = ({ onPress }: GoPaperlessMessageProps__TEMP) => (
  <div className="bg-haze-lightest mt4 pt1 ph3 pb3 br2">
    <div className="flex justify-between items-center">
      <P3
        color="green"
        style={{
          fontWeight: '600',
        }}
      >
        Go paperless
        <Spacer size="xs" />
        <FontAwesomeIcon icon={faLeaf} size="1x" />
      </P3>
      <Link
        onPress={() => {
          TrackService.click(
            'Statement Preference (step 1): Edit - why go paperless message',
          );
          onPress();
        }}
      >
        <Icon name="forward" color="green" />
      </Link>
    </div>
    <div>
      <P4 color="greyLight">
        You can save about 50 pages a year with paperless billing. We’ll email
        you when your statement is ready, so you won’t miss a thing.
      </P4>
    </div>
  </div>
);
