import imgSrcPath from '@core/assets/underConstruction.svg';

interface DefaultErrorProps {
  message?: string;
}

export const DefaultError = ({ message }: DefaultErrorProps) => {
  return (
    <div>
      <div className="tc">
        <img src={imgSrcPath} />
        <p className="tl">
          {message ?
            message
          : "Sorry, we're having some tech trouble but our team is looking into it. Check back in a bit."
          }
        </p>
      </div>
    </div>
  );
};
