import { DynamicAccountSummaryKardProps } from './DynamicAccountSummaryKard';

const KardBackground = ({
  cardArtUrl,
}: Pick<DynamicAccountSummaryKardProps, 'cardArtUrl'>) => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
      }}
    >
      <img
        style={{ height: 'auto', width: '100%' }}
        src={cardArtUrl?.replace('.svg', 'blank.svg')}
        alt="Mission Lane credit card"
      />
    </div>
  );
};

export default KardBackground;
