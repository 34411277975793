import dayjs from 'dayjs';
import Datepicker, { DatepickerProps } from './Datepicker';
import { useEffect, useState } from 'react';
import { TextField } from '@missionlane/compass-ui';

interface Props {
  datePickerProps: Omit<DatepickerProps, 'isOpen' | 'onClose'>;
  inputProps?: {
    format?: string;
    formatRegex?: RegExp;
    label?: string;
    testID?: string;
    onError?: (errors: string[]) => void;
  };
}

const DatepickerWithInput = ({ datePickerProps, inputProps }: Props) => {
  const inputFormat = inputProps?.format || 'MM/DD/YYYY';
  // Enforce MM/DD/YYYY by default
  const inputRegex =
    inputProps?.formatRegex ||
    /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [dateInput, setDateInput] = useState(
    datePickerProps.selectedDate ?
      dayjs(datePickerProps.selectedDate).format(inputFormat)
    : '',
  );
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [showInputError, setShowInputError] = useState(false);
  const selectedDate =
    !showInputError ? datePickerProps.selectedDate : undefined;

  useEffect(() => {
    if (showInputError && inputErrors.length) {
      inputProps?.onError?.(inputErrors);
    }
  }, [showInputError, inputErrors]);

  const onInputChange = (input: string) => {
    const errors = [];

    const dayjsInput = dayjs(input);

    if (!input.length || !dayjsInput.isValid() || !inputRegex.test(input)) {
      errors.push('Please enter a valid date in MM/DD/YYYY format');
    }

    const { minDate, maxDate } = datePickerProps;

    if (minDate && dayjsInput.isBefore(dayjs(minDate).startOf('day'))) {
      errors.push(
        `Please enter a date on or after ${dayjs(minDate).format(inputFormat)}`,
      );
    }
    if (maxDate && dayjsInput.isAfter(dayjs(maxDate).startOf('day'))) {
      errors.push(
        `Please enter a date on or before ${dayjs(maxDate).format('MM/DD/YYYY')}`,
      );
    }

    setInputErrors(errors);

    if (!errors.length) {
      datePickerProps.onChange(dayjsInput.startOf('day').toDate());
    }
    setDateInput(input);
  };

  return (
    <>
      <TextField
        name="Datepicker input"
        placeholder={inputFormat}
        value={dateInput}
        onChangeText={onInputChange}
        onBlur={() => setShowInputError(!!inputErrors.length)}
        label={inputProps?.label}
        trailingIcon={{
          name: 'calendar',
          color: 'blue',
          button: { onPress: () => setIsDatepickerOpen(true) },
          disabled: false,
        }}
        testID={inputProps?.testID}
        error={showInputError ? inputErrors[0] : undefined}
      />
      <Datepicker
        {...datePickerProps}
        selectedDate={selectedDate}
        isOpen={isDatepickerOpen}
        onClose={() => {
          setIsDatepickerOpen(false);
        }}
        onChange={(selectedDate) => {
          setInputErrors([]);
          setShowInputError(false);
          datePickerProps.onChange(selectedDate);
          setDateInput(dayjs(selectedDate).format(inputFormat));
        }}
      />
    </>
  );
};

export default DatepickerWithInput;
