import ForgotPasswordContainer from './ForgotPasswordContainer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { Button } from '@missionlane/compass-ui';

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.page('Reset Password Success');
  }, []);

  return (
    <ForgotPasswordContainer
      signInButtonHeader={false}
      header={'Password Reset Success!'}
    >
      <div>Your password has been successfully reset.</div>
      <div className="mt5 flex-ns">
        <Button
          text="Back to Sign in"
          onPress={() => {
            navigate('/signin', { replace: true });
          }}
        />
      </div>
    </ForgotPasswordContainer>
  );
};

export default ResetPasswordSuccess;
