import { Notification, Spacer } from '@missionlane/compass-ui';

import { Tile, TileDetail } from './TransactionTile';
import { MerchantDetails } from './MerchantDetails';
import { TransactionDate } from './TransactionDate';
import { TransactionAmount } from './TransactionAmount';
import { PaymentAccount } from './PaymentAccount';
import { TransactionStatus } from './TransactionStatus';
import {
  ActivityState,
  TransactionMethod,
  Transactions,
} from '@core/graphql/globalTypes';
import { ContentCard } from '@core/components/General/ContentCard';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import {
  TestSeed,
  useTestProperties,
} from '@core/utils/hooks/useTestProperties';

interface Props {
  transaction: Transactions.Activities;
}

const PaymentDetailPage = ({ transaction }: Props) => {
  const pageTitle = useAccountPageTitle('Transactions');
  const testProperties = useTestProperties(TestSeed.SEED_1);

  if (transaction.__typename !== 'Payment') {
    return (
      <Notification level="error">
        There was a problem loading your transaction. Please try again later.
      </Notification>
    );
  }

  const { fundingAccount, amount, date, state, reference, transactionMethod } =
    transaction;

  const isDebitCardPayment = transactionMethod === TransactionMethod.Debit;

  return (
    <PageWrapper
      greyBackground
      pageTitle={pageTitle}
      trackingProperties={{
        pageName: 'Transaction detail',
        featureName: 'Transactions',
        testProperties,
      }}
    >
      <AccountBreadcrumbs
        items={[{ text: 'Transactions', link: '..' }, { text: 'Payment' }]}
      />
      <ContentCard>
        <div className="flex flex-column items-center mb3">
          <TransactionAmount amount={Math.abs(amount)} positive />
          <MerchantDetails merchant={{ name: 'Payment' }} />
          <TransactionDate
            date={date}
            isPending={transaction.state === ActivityState.Pending}
            isPayment
          />
        </div>
        <TransactionStatus status={state} />
        {fundingAccount && (
          <>
            <Spacer size="s" />
            <PaymentAccount fundingAccount={fundingAccount} />
          </>
        )}
        {isDebitCardPayment && (
          <>
            <Spacer size="s" />
            <Tile>
              <TileDetail title="Payment Type" value="Debit Card" />
            </Tile>
          </>
        )}
        {reference && (
          <>
            <Spacer size="s" />
            <Tile>
              <TileDetail title="Confirmation Number" value={reference} />
            </Tile>
          </>
        )}
      </ContentCard>
    </PageWrapper>
  );
};

export default PaymentDetailPage;
