import { gql } from '@apollo/client';

export const getSpendBonusQuery = gql`
  query getSpendBonus($accountId: String!) {
    spendBonus(accountId: $accountId) {
      offerId
      eligible
      rewardType
      spendTarget
      spendProgress
      dueDate
      dqDate
      status
      reward
    }
  }
`;
