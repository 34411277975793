import isMissionLaneUrl from '@core/utils/isMissionLaneUrl';

export function getSearchParamsFromUri(
  uri: string | null | undefined,
): URLSearchParams {
  const search = uri?.split('?')[1];
  return new URLSearchParams(search);
}

export const getOriginalUriRedirect = (
  originalUri: string | undefined,
  redirectTo?: string | null,
): { url: string; isExternal: boolean } => {
  // if there's a redirectTo param in the originalUri, redirect to that URL on successful authentication
  // instead of the originalUri - used for external ML applications such as apply.missionlane.com
  const decodedRedirectTo = redirectTo && decodeURIComponent(redirectTo);
  // if the redirectTo param is a valid ML uri, redirect to it after successful authentication
  if (decodedRedirectTo && isMissionLaneUrl(decodedRedirectTo)) {
    return { url: decodedRedirectTo, isExternal: true };
  } else {
    // delete redirectTo and loginIntention params
    if (!originalUri) {
      throw new Error('getOriginalUriRedirect: originalUri is undefined');
    }
    const redirectUrl = new URL(originalUri, window.location.origin);
    redirectUrl.searchParams.delete('redirectTo');
    redirectUrl.searchParams.delete('loginIntention');

    return {
      url: redirectUrl.pathname + redirectUrl.search,
      isExternal: false,
    };
  }
};
