import { useMutation, useQuery } from '@apollo/client';
import { B, Button, H4, Link, Notification, P4 } from '@missionlane/compass-ui';
import {
  FundingAccountsQuery,
  UpdateOfferMutation,
  MutationUpdateOfferArgs,
  ServicingOfferChannel,
} from '@core/graphql/globalTypes';
import { useEffect, useRef, useState } from 'react';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import { PaymentPlanStepProps } from './CreatePaymentPlanFlow';
import { UPDATE_OFFER } from '../gql/mutations';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import AccountPicker from '@payments/components/BankAccount/AccountPicker/AccountPicker';
import { BankAccountFlow } from '@payments/components/BankAccount/AddBankAccount/AddAccount';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import KardWrapper from '@core/components/General/Wrappers/KardWrapper';
import cx from 'classnames';

import './PaymentPlanFlow.css';
import { useNavigate } from 'react-router-dom';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { createXhtmlDocFromHtmlString } from '@core/utils/createXhtmlDocFromHtml';
import { renderToStaticMarkup } from 'react-dom/server';
import { getDisclosures } from './Disclosures';
import { getOfferEndDate } from '../utils/getOfferEndDate';
import { TrackService } from '@core/services';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

interface OfferFundingDetailsProps extends PaymentPlanStepProps {
  isEditing?: boolean;
  initialSelectedFundingAccountId?: number;
  onChange?: (newValue: number | undefined) => void;
}

const OfferFundingDetails = ({
  nextStep,
  offer,
  isEditing = false,
  initialSelectedFundingAccountId,
  onChange,
}: OfferFundingDetailsProps) => {
  const { cardDetails, loading: settlementOffersLoading } =
    useSettlementOffers();
  const navigate = useNavigate();
  const { isMobile } = useUserDevice();
  const {
    data,
    loading: loadingAccounts,
    error: accountsError,
  } = useQuery<FundingAccountsQuery>(ACH_FUNDING_ACCOUNTS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    TrackService.trackPage('Change Funding - View Accounts', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  const fundingAccounts = data?.fundingAccounts;
  const [showError, setShowError] = useState(false);
  const [selectedFundingAccountId, setSelectedFundingAccountId] = useState(
    initialSelectedFundingAccountId,
  );
  const selectedFundingAccountLast4 = fundingAccounts?.find(
    (fundingAcct) => fundingAcct.id === selectedFundingAccountId,
  )?.numberLast4;
  // Show a new efta when the user is in the edit flow and has selected a new funding account
  const showNewEfta =
    isEditing &&
    offer?.data.fundingAccountId &&
    offer.data.fundingAccountId !== selectedFundingAccountId;
  const eftaDisplayFailure =
    showNewEfta && (!cardDetails || !cardDetails.name || !cardDetails.last4);
  const eftaRef = useRef<HTMLDivElement>(null);

  const [updateOffer, { loading, error }] = useMutation<
    UpdateOfferMutation,
    MutationUpdateOfferArgs
  >(UPDATE_OFFER, {
    onCompleted: () => {
      nextStep?.();
    },
  });

  if (loading || loadingAccounts || settlementOffersLoading) {
    return <LoadingSpinner />;
  }

  const { paymentPlan, originalSettlementBalance, fundingAccountId } =
    offer?.data || {};

  if (
    error ||
    accountsError ||
    eftaDisplayFailure ||
    showError ||
    !originalSettlementBalance ||
    !paymentPlan
  ) {
    return <GenericFallbackFull className="bg-haze-lightest" />;
  }

  const disclosures = renderToStaticMarkup(
    <div>
      {getDisclosures(
        originalSettlementBalance,
        getOfferEndDate(paymentPlan),
        selectedFundingAccountLast4,
      ).map((disclosure) => disclosure.content)}
    </div>,
  );

  const handleFundingAccountConfirmed = () => {
    if (showNewEfta && !eftaRef.current?.innerHTML) {
      setShowError(true);
      return;
    }

    let authorizationText: string | null = null;

    if (isEditing && eftaRef.current?.innerHTML) {
      authorizationText = createXhtmlDocFromHtmlString(
        eftaRef.current.innerHTML,
      );
    } else {
      authorizationText = createXhtmlDocFromHtmlString(disclosures);
    }

    if (offer?.offerId && selectedFundingAccountId) {
      updateOffer({
        variables: {
          offerId: offer.offerId,
          channel: ServicingOfferChannel.Web,
          requestBody: {
            paymentPlanCreate: null,
            fundingAccountUpdate: {
              fundingAccountId: selectedFundingAccountId,
              authorizationText,
            },
            paymentPlanUpdate: null,
            acceptOffer: false,
            cancelOffer: false,
            declineOffer: false,
          },
        },
      });
    } else {
      setShowError(true);
    }
  };

  const navigateToReviewTransactions = () => navigate('./review-transactions');

  return (
    <div className="flex flex-column items-center mb6 h-100-ns justify-between">
      <KardWrapper withKard={!isMobile} className="payment-plan-kard-small">
        <div>
          {!isEditing && (
            <>
              <ResponsiveTypography
                type="HEADER"
                mobileLevel="H2"
                desktopLevel="H3"
              >
                Just a few steps left to set up your settlement plan.
              </ResponsiveTypography>
              <div className="mv3">
                <H4>How would you like to pay?</H4>
              </div>
            </>
          )}
          <AccountPicker
            onChange={(fundingAccount) => {
              setSelectedFundingAccountId(fundingAccount.id);
              onChange?.(fundingAccount.id);
            }}
            fundingAccounts={fundingAccounts || []}
            selectedId={selectedFundingAccountId}
            currentFlow={
              isEditing ?
                BankAccountFlow.EDIT_COLLECTIONS_OFFER
              : BankAccountFlow.COLLECTIONS_OFFER
            }
          />
          <div className="mv4">
            <Notification show level="info">
              <B>Don’t worry,</B> you’ll be able to edit your bank account{' '}
              <B>at any time</B>
              {!isEditing && ' after accepting your offer.'}
            </Notification>
          </div>
        </div>
        {showNewEfta && (
          <div
            className="flex flex-column pb3 bt bw--1px b--grey-lightest"
            ref={eftaRef}
          >
            <P4>
              By clicking ‘Continue’, you authorize Mission Lane to make
              recurring electronic debits from your bank account ending in{' '}
              {selectedFundingAccountLast4} towards your {cardDetails?.name}{' '}
              account ending in {cardDetails?.last4} for the dates and amounts
              outlined in{' '}
              <Link onPress={navigateToReviewTransactions}>
                your payment plan.
              </Link>
            </P4>
            <P4>
              I understand that I may edit or cancel a scheduled payment until
              11:59pm ET on the day before the payment date. You can edit or
              cancel payments by logging into your account online and clicking
              “Review and edit payments.”
            </P4>
          </div>
        )}
        <div
          className={cx({
            'flex justify-end': isEditing && !isMobile,
            'flex flex-column-reverse': isEditing && isMobile,
          })}
        >
          {isEditing && (
            <div className={cx({ 'flex pr5': !isMobile })}>
              <Button
                text="Cancel"
                variant="text"
                onPress={() => navigate('../../summary')}
              />
            </div>
          )}
          <div className={cx({ flex: !isMobile })}>
            <Button
              text="Continue"
              disabled={
                !selectedFundingAccountId ||
                selectedFundingAccountId === fundingAccountId
              }
              onPress={handleFundingAccountConfirmed}
            />
          </div>
        </div>
      </KardWrapper>
    </div>
  );
};

export default OfferFundingDetails;
