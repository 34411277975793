import { LoadingIndicator, P2 } from '@missionlane/compass-ui';
import Modal from './Modal/Modal';
import ResponsiveTypography from './ResponsiveTypography';
interface LoadingModalProps {
  isOpen: boolean;
  onClose: () => void;
  primaryText: string;
  secondaryText?: string;
}

const LoadingModal = ({
  isOpen,
  onClose,
  primaryText,
  secondaryText,
}: LoadingModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} disableCloseOnOverlayClick>
      <div className="w-100 flex items-center justify-center">
        <div className="w-75 flex flex-column items-center justify-center">
          <div className="ma5">
            <LoadingIndicator size="large" />
          </div>
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H2"
            desktopLevel="H3"
            style={{ textAlign: 'center' }}
          >
            {primaryText}
          </ResponsiveTypography>
          {secondaryText && (
            <P2 style={{ textAlign: 'center' }}>{secondaryText}</P2>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LoadingModal;
