import { Colors, Icon } from '@missionlane/compass-ui';
import { useSpringCarousel } from 'react-spring-carousel';
import { FC, useState } from 'react';
import { OpportunityTileType } from '../AccountSummaryPage/Dashboard/OpportunityTileCarousel/useOpportunityTileManager';

// event name defined by react-spring-carousel but not exported
const ON_SLIDE_START_CHANGE_EVENT = 'onSlideStartChange';

export type OnDismiss = (
  tileID: OpportunityTileType,
  skipLocalStorageUpdate?: boolean,
) => void;

export interface CarouselProps {
  data: CarouselTile[];
  onDismiss: OnDismiss;
}

export interface CarouselTileProps {
  onDismiss: OnDismiss;
}

export type CarouselTile = {
  id: string;
  component: FC<CarouselTileProps>;
};

const Carousel = ({ data, onDismiss }: CarouselProps) => {
  if (!data?.length) return null;

  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);

  const renderTile = (tile: CarouselTile) => {
    const Tile = tile.component;
    return (
      <div className="flex justify-center items-center w-100">
        <Tile onDismiss={onDismiss} />
      </div>
    );
  };

  const {
    carouselFragment,
    slideToItem,
    slideToNextItem,
    slideToPrevItem,
    thumbsFragment,
    useListenToCustomEvent,
  } = useSpringCarousel({
    enableThumbsWrapperScroll: false,
    gutter: 8,
    withLoop: true,
    withThumbs: data.length > 1 ? true : undefined,
    items: data.map((item, i) => {
      return {
        id: item.id,
        renderItem: renderTile(item),
        renderThumb: (
          <div
            style={{
              backgroundColor: `${activeThumbnailIndex == i ? Colors.blue : Colors.inkLightest}`,
              borderRadius: '50%',
              width: '7px',
              height: '7px',
              marginRight: '4px',
              marginLeft: '4px',
            }}
            onClick={() => slideToItem(item.id)}
          />
        ),
      };
    }),
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === ON_SLIDE_START_CHANGE_EVENT) {
      setActiveThumbnailIndex(event.nextItem.index);
    }
  });

  if (data.length > 1) {
    return (
      <>
        <div className="flex flex-row items-center justify-between w-100">
          <div className="db-ns dn pointer mr1" onClick={slideToPrevItem}>
            <Icon name="back" color="blue" />
          </div>
          <div className="overflow-hidden">{carouselFragment}</div>
          <div className="db-ns dn pointer ml1" onClick={slideToNextItem}>
            <Icon name="forward" color="blue" />
          </div>
        </div>
        <div className="flex items-center justify-center mt3 ">
          <div>{thumbsFragment}</div>
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-row items-center justify-between w-100">
      <div className="overflow-hidden">{carouselFragment}</div>
    </div>
  );
};

export default Carousel;
