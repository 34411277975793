import { B, Link, Notification } from '@missionlane/compass-ui';
import { FC } from 'react';

interface Props {
  setHasWarning: (hasWarning: boolean) => void;
  hasWarning: boolean;
  onRefresh: () => void;
}

export const AccountDetailsWarningNotification: FC<Props> = ({
  setHasWarning,
  hasWarning,
  onRefresh,
}) => {
  const onDismiss = () => setHasWarning(false);

  return (
    <Notification
      variant="banner"
      level="warning"
      onDismiss={onDismiss}
      show={hasWarning}
    >
      <B>We're having trouble loading some of your info right now. </B>
      <Link onPress={onRefresh}>Try refreshing.</Link>
    </Notification>
  );
};
