import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useRiskAuth } from '../../RiskAuthProvider';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useEffect, useState } from 'react';
import { MLError, TrackService } from '@core/services';
import { HookFormTextField } from '@core/components/Form';
import PhoneLink from '@core/components/General/PhoneLink';
import { cardSupportPhone } from '@core/utils/contact';
import { Notification, P3, Spacer } from '@missionlane/compass-ui';
import { ContactUsMessage } from '../../Login/ContactUsMessage';
import LoginActionButtons from '../../Login/LoginActionButtons';
import LoginContainer from '../../Login/LoginContainer';
import LoginInfoSection from '../../Login/LoginInfoSection';
import { LoginLink } from '../../Login/LoginLink';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

interface FormValues {
  otp: string;
}

const getValidationSchema = (otpLength: number) =>
  Yup.object().shape({
    otp: Yup.string()
      .nullable()
      .required('Please enter your security code.')
      .length(otpLength, `Code must be ${otpLength} digits.`),
  });

export const MfaEnterPage = () => {
  const [otpError, setOtpError] = useState(false);
  const { otpEnter, otpCancel, selectedFactor } = useRiskAuth();

  useEffect(() => {
    TrackService.page('OTP Enter');
  }, []);

  if (!selectedFactor) {
    MLError.report({ name: 'No factor chosen for Risk Auth OTP' });
    otpCancel();
    return <LoadingSpinnerPage />;
  }

  const phoneNumberLastFour = selectedFactor.phoneNumberLastFour;
  const codeLength = selectedFactor.factorType === 'call' ? 5 : 6;

  const form = useForm<FormValues>({
    resolver: yupResolver(getValidationSchema(codeLength)),
    mode: 'all',
    defaultValues: {
      otp: '',
    },
  });

  const {
    handleSubmit: hookFormSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isValid, touchedFields },
  } = form;

  const handleSubmit = hookFormSubmit(async ({ otp }) => {
    clearErrors();
    setOtpError(false);

    try {
      TrackService.track({
        event: 'Risk Auth OTP Enter Submit',
        properties: { factor: selectedFactor },
      });
      const response = await otpEnter(selectedFactor.id, otp);
      if (response.success) {
        TrackService.track({
          event: 'Risk Auth OTP Enter Success',
          properties: { selectedFactor },
        });
      } else {
        setError('otp', { message: response.error });
      }
    } catch (e) {
      TrackService.track({
        event: 'Risk Auth OTP Enter Failure',
        properties: { factor: selectedFactor, error: e },
      });
      setOtpError(true);
    }
  });

  return (
    <PageWrapper>
      <LoginContainer>
        <Info phone={phoneNumberLastFour} />

        <Spacer size="xm" />

        <FormProvider {...form}>
          <HookFormTextField
            autoFocus={true}
            label="Security Code"
            name="otp"
            placeholder={'X'.repeat(codeLength)}
            maxLength={codeLength}
            error={errors.otp?.message}
          />

          {otpError && <Error />}

          <Spacer size="xm" />

          <LoginActionButtons
            submitButton={{
              text: 'Log In',
              onPress: handleSubmit,
              loading: isSubmitting,
              disabled: !!touchedFields.otp && !isValid,
            }}
            onCancel={otpCancel}
          />

          <ResendCode />

          <ContactUsMessage />
        </FormProvider>
      </LoginContainer>
    </PageWrapper>
  );
};

const ResendCode = () => {
  const { otpResend } = useRiskAuth();
  const [error, seterror] = useState<string>();

  async function resendCode() {
    try {
      const result = await otpResend();
      if (!result.success) {
        seterror(result.error);
      }
    } catch (e) {
      seterror('An unknown error occured. Please try again later.');
    }
  }

  return (
    <>
      <LoginInfoSection
        headerText="Didn't receive your code?"
        message={<LoginLink onClick={resendCode}>Send a new one.</LoginLink>}
      />

      {error && (
        <div className="mt4 mb3">
          <Notification level="error">{error}</Notification>
        </div>
      )}
    </>
  );
};

interface InfoProps {
  phone: string | null;
}
const Info = ({ phone }: InfoProps) => {
  return (
    <P3>
      We sent a security code to your phone number{' '}
      {phone ?
        <>
          ending in <strong>{phone}</strong>.
        </>
      : <>on file.</>}{' '}
      Once you receive it, please enter it below.
    </P3>
  );
};

const Error = () => (
  <div className="mt4">
    <Notification level="error">
      Sorry there's an issue on our end. Resend your code or give us a call at{' '}
      <PhoneLink phoneNumber={cardSupportPhone} />.
    </Notification>
  </div>
);
