import { B, P3, Spacer } from '@missionlane/compass-ui';
import { ReactElement } from 'react';

interface DescriptionListItemProps {
  label: string;
  subTitle?: string | ReactElement;
  value?: string | ReactElement;
}

/**
 * Label/value pairs (ie term/description) rendered with dt and dd elements.
 *
 * Should only be used within a DescriptionList.
 *
 * "subTitle" (optional) will be rendered as a second dd element
 * associated with the "label" dt element
 */
export const DescriptionListItem = ({
  label,
  subTitle,
  value,
}: DescriptionListItemProps) => (
  <>
    <div className="flex justify-between">
      <dt>
        <P3 color="ink">
          <B>{label}</B>
        </P3>
      </dt>
      <dd className="tr">{value && <P3 color="ink">{value}</P3>}</dd>
    </div>
    {subTitle && (
      <dd className="ml0">
        <Spacer size="s" />
        {typeof subTitle === 'string' ?
          <P3>{subTitle}</P3>
        : subTitle}
      </dd>
    )}
    <Spacer size="xm" />
  </>
);
