import Modal from '@core/components/General/Modal/Modal';
import { P3, P1 } from '@missionlane/compass-ui';

export const COPY = {
  paragraph1: `Once you've updated your information, we'll email you with our answer. It may take up to one statement cycle.`,
  paragraph2: `This is a limited time offer valid during your current statement cycle. Based on the information we have for you today, it is likely you will receive the quoted “up to” credit limit increase when you update your Total Annual Income and Monthly Rent or Mortgage Payment.`,
  paragraph3: `This offer is based on your creditworthiness and there are some factors that may result in a higher or lower increase than the offer amount or ineligibility for the offer, including changes in your ability to pay, your account status, and/or your credit. (We will not conduct a hard pull of your credit for this offer). If you are eligible for a credit limit increase, the lowest credit limit increase you may receive is $100.`,
};

export const TEST_ID = 'PieOfferModal';

const OfferDetailsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      headerText="Offer Details"
      onClose={onClose}
      maxWidth="640px"
      isOpen={isOpen}
      primaryButton={{
        onPress: onClose,
        text: 'Close',
      }}
    >
      <div data-testid={TEST_ID}>
        {/* LARGE SCREENS */}
        <div className="mb3 dn db-m db-l">
          <P3>{COPY.paragraph1}</P3>
        </div>
        <div className="mb3 dn db-m db-l">
          <P3>{COPY.paragraph2}</P3>
        </div>
        <div className="dn db-m db-l">
          <P3>{COPY.paragraph3}</P3>
        </div>
        {/* SMALL SCREENS */}
        <div className="mb3 dn-ns">
          <P1>{COPY.paragraph1}</P1>
        </div>
        <div className="mb3 dn-ns">
          <P1>{COPY.paragraph2}</P1>
        </div>
        <div className="dn-ns">
          <P1>{COPY.paragraph3}</P1>
        </div>
      </div>
    </Modal>
  );
};

export default OfferDetailsModal;
