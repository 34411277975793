import { P3 } from '@missionlane/compass-ui';

export interface CheckboxProps {
  id: string;
  name: string;
  disabled?: boolean;
  isChecked: boolean;
  onChange: () => void;
  label?: string;
}

const ControlledCheckbox = ({
  disabled,
  name,
  id,
  isChecked,
  onChange,
  label,
}: CheckboxProps) => {
  return (
    <span className="flex items-center">
      <input
        style={{ appearance: 'auto' }}
        id={id}
        name={name}
        type="checkbox"
        className="w1 h1 mr2"
        onChange={onChange}
        disabled={disabled}
        checked={isChecked}
      />
      {label && (
        <label htmlFor={name}>
          <P3>{label}</P3>
        </label>
      )}
    </span>
  );
};

export default ControlledCheckbox;
