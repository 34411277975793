interface Props {
  CallToAction?: React.ReactNode;
  Disclaimer?: React.ReactNode;
}

const DashboardFooter = ({ CallToAction, Disclaimer }: Props) => {
  return (
    <div className="flex flex-column mt3 mt5-l items-start-ns">
      {CallToAction}
      <div
        className="w-100"
        style={{
          borderBottom: '1px solid var(--ink-lightest)',
        }}
      />
      {Disclaimer}
    </div>
  );
};

export default DashboardFooter;
