import AccordianItem, { AccordianItemProps } from './AccordianItem';

interface AccordianProps {
  items: AccordianItemProps[];
}

const Accordian = ({ items }: AccordianProps) => {
  return (
    <div>
      {items.map((item, index) => (
        <AccordianItem key={index} {...item} />
      ))}
    </div>
  );
};

export default Accordian;
