import { Link } from 'react-router-dom';
import { H1 } from '@missionlane/compass-ui';
import Menu from '../Menu/Menu';
import TitleBar, { Title } from '../TitleBar/TitleBar';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import missionLaneLogo from '@core/assets/missionLaneLogo.svg';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { TrackService } from '@core/services';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';

interface PageHeaderProps {
  pageTitle?: Title;
}
const priceText = 'Quicker sign in';
const storeText = 'alerts - & more';

export const PageHeader = ({ pageTitle }: PageHeaderProps) => {
  const { isAuthenticated } = useRiskAuth();
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  return (
    <>
      <SmartBanner
        title="Try the app"
        onClose={() => {
          TrackService.click('Smart banner closed', customerAndAccountIds);
        }}
        onInstall={() => {
          TrackService.click('Smart banner CTA', customerAndAccountIds);
        }}
        price={{
          ios: priceText,
          kindle: priceText,
          android: priceText,
          windows: priceText,
        }}
        storeText={{
          ios: storeText,
          kindle: storeText,
          android: storeText,
          windows: storeText,
        }}
      />
      <header className="ph5 ph3-l pv3 bg-pine">
        <div className="flex justify-between mw8 center relative">
          <div className="flex flex-auto flex-column">
            <HeaderLogo
              linkTo="/"
              onClick={() => {
                TrackService.click(
                  'Header: Mission Lane logo',
                  customerAndAccountIds,
                );
              }}
            />
            {pageTitle && <TitleBar title={pageTitle} />}
          </div>
          {isAuthenticated && <Menu />}
        </div>
      </header>
    </>
  );
};

interface HeaderLogoProps {
  linkTo: string;
  onClick?: () => void;
}

const HeaderLogo = ({ linkTo, onClick }: HeaderLogoProps) => (
  <H1>
    <Link className="db" to={linkTo} onClick={onClick}>
      <img
        src={missionLaneLogo}
        alt="Mission Lane Logo"
        style={{ width: 100 }}
      />
    </Link>
  </H1>
);
