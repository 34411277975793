import { paymentsFlags } from '@payments/paymentsFeatureFlags';
import { clipFlags } from '@clip/shared/flags';

export const flags = {
  acctInfoRefresh: false,
  disableActivationCacheBusting: false,
  'credit-protection': false,
  'credit-protection-marketing': 'DISABLED',
  'credit-protection-sso': false,
  enableRiskService: false,
  'enable-customer-id-notifications': false,
  loadTestRiskService: false,
  mlFaulttestHeader: 'off',
  'paperless-statement-enabled': false,
  'prompt-for-income-update': false,
  showActivation: false,
  'show-dashboard-autopay-tile': false,
  showChangeAddress: false,
  showChangePhone: false,
  showCreditScore: false,
  showHcrExperience: false,
  maintenanceBanner: undefined,
  showMfa: false,
  showModifyPin: false,
  showMulticardExperience: true,
  'show-opportunity-tile-carousel': false,
  showSecuredCard: false,
  showUpdateIncome: false,
  'test-flag': false,
  'show-esb-info-banner': false,
  'show-esb-reminder-banner': false,
  'show-spend-bonus': false,
  'show-transaction-detail': false,
  'show-manage-card': false,
  ...paymentsFlags,
  ...clipFlags,
  'clip-multicard-offer': false,
  'enable-dynamic-card-art': false,
  'proactive-multicard': false,
  'contextual-payment-support': false,
  'enable-account-summary-card-art': false,
  'manage-funding-accounts': false,
  'show-new-onboarding-experience': false,
  'show-opportunity-tiles-v2': false,
  'add-funding-account-opportunity-tile': false,
  'allow-activation-funding-account-flow': false,
  'enable-my-progress-onboarding': false,
};

type CamelCase<S extends string> =
  S extends `${infer P1}-${infer P2}${infer P3}` ?
    `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : S;

type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends {} ?
    KeysToCamelCase<T[K]>
  : T[K];
};

export type MLFlags = KeysToCamelCase<typeof flags>;
