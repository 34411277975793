import cx from 'classnames';
import { TrackService } from '@core/services';

type Tracking = {
  name: string;
  properties?: {
    [x: string]: any;
  };
};

interface Props {
  children: React.ReactNode;
  to: string;
  className?: string;
  tracking?: Tracking;
}

const ExternalLink = ({ to, children, className, tracking }: Props) => {
  function handleOnClick() {
    if (tracking) {
      TrackService.click(tracking.name, tracking.properties);
    }
  }
  return (
    <a
      href={to}
      onClick={handleOnClick}
      className={cx('blue', className)}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default ExternalLink;
