// true constants
export const SIGNIN_PATH = '#signin';

const DASH_LOCAL_DEV_URL = 'https://local.missionlane-dev.com';
const DASH_LOCAL_STAGING_URL = 'https://local.missionlane-staging.com';

const DASH_DEV_URL = 'https://dashboard.missionlane-dev.com';
const DASH_STAGING_URL = 'https://dashboard.missionlane-staging.com';

const DASH_ALPHA_URL = 'https://dashboard-alpha.missionlane.com';
const DASH_PROD_URL = 'https://dashboard.missionlane.com';

export const NO_PAYMENT_AMOUNT = 'Please select a payment amount.';
export const NO_PAYMENT_DATE = 'Please select a payment date.';
export const NO_PAYMENT_BANK = 'Please select a bank account.';

export const CFU_FEATURE_NAME = 'CLIP CFU';

// environment checks

// https://local.missionlane-dev.com
// https://local.missionlane-staging.com
const isLocal =
  window.location.hostname &&
  window.location.hostname.includes('local.missionlane');

// https://local.missionlane-dev.com
// https://dashboard.missionlane-dev.com
const isDev =
  window.location.hostname &&
  window.location.hostname.includes('.missionlane-dev.com');

// https://local.missionlane-staging.com
// https://dashboard.missionlane-staging.com
export const isStaging =
  window.location.hostname &&
  window.location.hostname.includes('.missionlane-staging.com');

// https://dashboard-alpha.missionlane.com
const isAlpha =
  window.location.hostname &&
  window.location.hostname.includes('dashboard-alpha.missionlane.com');

// TODO: migrate this logic into router.js and .env files.
export const DASHBOARD_URL =
  (isLocal && isDev && DASH_LOCAL_DEV_URL) ||
  (isLocal && isStaging && DASH_LOCAL_STAGING_URL) ||
  (isDev && DASH_DEV_URL) ||
  (isStaging && DASH_STAGING_URL) ||
  (isAlpha && DASH_ALPHA_URL) ||
  DASH_PROD_URL;

/**
 * this is to point them to OUR BE Url and then will redirect them to Zendesk
 */
export const CONTACT_US_LINK = `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/requests/new?ticket_form_id=328167`;
export const LOST_OR_STOLEN_FAQ_URL = `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/articles/1500004232661-Lost-or-Stolen-Card`;
