import { B, P4 } from '@missionlane/compass-ui';
import './CreditProtectionFAQ.css';

export const CREDIT_PROTECTION_FAQ_2_TITLE =
  'What events are covered, and how much is the benefit?';

const CreditProtectionFAQ_2 = () => {
  return (
    <div className="mt4 mb2 w-100">
      <table className="faq-table">
        <thead>
          <tr>
            <th className="faq-table-cell-left">
              <P4>
                <B>Event Type</B>
              </P4>
            </th>
            <th className="faq-table-cell-right">
              <P4>
                <B>Eligible Benefit</B>
              </P4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="faq-table-cell-left">
              <P4>
                Job loss/unemployment (Includes contract, part-time, and
                self-employed)
              </P4>
            </td>
            <td className="faq-table-cell-right">
              <P4>
                Up to 3 consecutive monthly minimum payments.
                <br />
                <br />
                For unemployment that lasts longer than 3 months, your statement
                balance when the unemployment occurred will be canceled.
              </P4>
            </td>
          </tr>
          <tr>
            <td className="faq-table-cell-left">
              <P4>Disability</P4>
            </td>
            <td className="faq-table-cell-right">
              <P4>
                Up to 3 consecutive monthly minimum payments.
                <br />
                <br />
                For disability that lasts longer than 3 months, your statement
                balance when the disability occurred will be canceled.
              </P4>
            </td>
          </tr>
          <tr>
            <td className="faq-table-cell-left">
              <P4>Loss of Life</P4>
            </td>
            <td className="faq-table-cell-right">
              <P4>Balance cancellation.</P4>
            </td>
          </tr>
          <tr>
            <td className="faq-table-cell-left">
              <P4>Hospitalization</P4>
            </td>
            <td className="faq-table-cell-right">
              <P4>
                1 monthly minimum payment for hospitalization of 1 night.
                <br />
                <br />
                For hospitalization that lasts longer than 5 consecutive nights,
                your statement balance when the hospitalization occurred is
                eligible for cancellation.
              </P4>
            </td>
          </tr>
          <tr>
            <td className="faq-table-cell-left">
              <P4>Auto Repair</P4>
            </td>
            <td className="faq-table-cell-right">
              <P4>
                1 month minimum payment if expense is greater than $200. Only
                eligible once per year.
              </P4>
            </td>
          </tr>
          <tr>
            <td className="faq-table-cell-left">
              <P4>Medical Bills</P4>
            </td>
            <td className="faq-table-cell-right">
              <P4>
                1 month minimum payment if expense is greater than $100. Only
                eligible once per year.
              </P4>
            </td>
          </tr>
          <tr>
            <td className="faq-table-cell-bottom-left">
              <P4>Natural Disaster</P4>
            </td>
            <td className="faq-table-cell-bottom-right">
              <P4>
                1 month minimum payment if you are in a ZIP code declared by
                FEMA as impacted by a natural disaster.
                <br />
                <br />2 additional consecutive months of minimum payment if you
                lose use of your primary residence due to a FEMA declared
                natural disaster.
              </P4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CreditProtectionFAQ_2;
