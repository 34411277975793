import { GetAccountSummaryQuery, AccountSummary } from '../types';

// @TODO: set error from parser once error classes are defined https://missionlane.atlassian.net/browse/SLS-1575
export const parseAccountSummaryQueryResult = (
  data?: GetAccountSummaryQuery['account'] | AccountSummary['account'],
): AccountSummary => {
  if (!data) {
    throw new Error('ClipExperienceError - GetAccountSummaryQuery');
  }

  return {
    account: {
      id: data.id,
      pricingDetails: {
        creditLimit: data.pricingDetails.creditLimit,
      },
    },
  };
};
