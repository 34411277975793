import { Button, H4, P2 } from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfettiCone from '@core/assets/confettiCone.svg';

import {
  GetLast4AndCardNameQuery,
  GetLast4AndCardNameQueryVariables,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { getLast4AndCardName } from './ActivateCardNameAndLast4Header';
import { formatCardLast4 } from '@core/utils/formatters';
import { ContentCard } from '../General/ContentCard';
import { useEffect } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import LoadingSpinner from '../General/LoadingSpinner';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ActivationSuccess = () => {
  const { allowActivationFundingAccountFlow } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const accountSummaryPath = useAccountSummaryPath();
  const { trackClick, trackEvent } = useTracking();
  const { data, loading } = useAccountIdQuery<
    GetLast4AndCardNameQuery,
    GetLast4AndCardNameQueryVariables
  >(getLast4AndCardName);

  const { last4, productDisplayName } = data?.account?.cardDetails || {};
  const { productName } = productDisplayName || {};
  const formattedCardLast4 = formatCardLast4(last4);
  const cardName = productName || 'Visa';
  const trailingCardLast4 = formattedCardLast4 ? ` ${formattedCardLast4}` : '';
  const formattedCardNameString = `${cardName}${trailingCardLast4}`;

  const onGoHomeClick = () => {
    trackClick({
      name: 'Go home',
      feature: 'Activation Success',
    });
    navigate(accountSummaryPath, { replace: true });
  };

  const onNextClick = () => {
    trackClick({
      name: 'Next',
      feature: 'Activation Success',
    });
    navigate('../get-ready', {
      state: {
        prevPath: location.pathname,
      },
    });
  };

  useEffect(() => {
    trackEvent({
      eventName: `Viewed Activation Success`,
    });
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  const canViewNewExperience =
    location.state?.isNewCardActivation && allowActivationFundingAccountFlow;

  return (
    <ContentCard className="pv7 flex flex-column items-center justify-center">
      <img src={ConfettiCone} alt="confetti cone" className="mb4" />
      <H4>Success!</H4>
      <P2
        style={{ textAlign: 'center' }}
      >{`Your new ${formattedCardNameString}\nis ready to use.`}</P2>
      {canViewNewExperience ?
        <Button text="Next" onPress={onNextClick} />
        : <Button text="Go home" onPress={onGoHomeClick} />}
    </ContentCard>
  );
};

export default ActivationSuccess;
