import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { B, Button, H4, P3, Spacer, Icon } from '@missionlane/compass-ui';
import { AccountBreadcrumbs } from '../../Account/AccountBreadcrumbs';
import { useAccountPageTitle } from '../../Account/useAccountPageTitle';
import PageWrapper from '../../Page/PageWrapper';
import { ContentCard } from '../../General/ContentCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { faLeaf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrackService } from '@core/services';
import useStatementPreference from '@core/utils/hooks/useStatementPreference';
import { ErrorBoundary } from '@core/services/Error/Error';

export const StatementsPreferencesSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasUserSubmittedChange =
    typeof location?.state?.isUpdatedToPaperless === 'boolean';
  // redirect User to Preferences page unless successfully submitted statement preference change
  if (!hasUserSubmittedChange) {
    navigate('../');
  }
  // use response from updateStatementPreferences mutation to determine success messaging, use hook for card details
  const isPaperlessStatement = Boolean(location.state.isUpdatedToPaperless);
  const { loading, error, cardNameWithLast4 } = useStatementPreference();

  if (loading) return <LoadingSpinner />;

  if (error) throw error;

  const handlePress = () => {
    TrackService.click('Statement Preference Success: Back to Account Summary');
    navigate('../../../summary', { replace: true });
  };

  return (
    <StatementsPreferencesSuccessPageWrapper
      isPaperlessStatement={isPaperlessStatement}
    >
      <div className="flex items-start">
        <div className="icon-container">
          <Icon name="checkCircleLight" size={75} color="green" />
        </div>
        <Spacer size="m" />
        <div className="mt1">
          {isPaperlessStatement ?
            <PaperlessSuccessContent cardLabel={cardNameWithLast4} />
          : <MailedSuccessContent cardLabel={cardNameWithLast4} />}

          <div className="flex mt5 justify-end">
            <Button text="Back to Account Summary" onPress={handlePress} />
          </div>
        </div>
      </div>
    </StatementsPreferencesSuccessPageWrapper>
  );
};

export default StatementsPreferencesSuccessPage;

interface StatementsPreferencesSuccessPageWrapperProps {
  children: React.ReactNode;
  isPaperlessStatement: boolean;
}
const StatementsPreferencesSuccessPageWrapper = ({
  children,
  isPaperlessStatement,
}: StatementsPreferencesSuccessPageWrapperProps) => {
  const pageTitle = useAccountPageTitle('Statements');

  return (
    <PageWrapper
      greyBackground
      pageTitle={pageTitle}
      trackingProperties={{
        pageName: `Statement Preferences Update Success: Account changed to ${
          isPaperlessStatement ? 'paperless' : 'mailed'
        }`,
        featureName: 'Statements Preferences',
      }}
    >
      <AccountBreadcrumbs
        items={[
          { text: 'Statements', link: '../' },
          { text: 'Statements Preferences' },
        ]}
      />
      <ErrorBoundary>
        <ContentCard>{children}</ContentCard>
      </ErrorBoundary>
    </PageWrapper>
  );
};

interface SuccessContentProps {
  cardLabel: string;
}

const PaperlessSuccessContent = ({ cardLabel }: SuccessContentProps) => (
  <>
    <H4 color="ink">Congrats, you’ve gone paperless!</H4>
    <div className="mt3">
      <P3>
        You may receive one more paper statement, depending on when your billing
        cycle ends.
      </P3>
    </div>
    <div className="bg-haze-lightest mt4 pt3 ph4 pb2 br2">
      <div>
        <P3 color="ink">
          <B>Enrolled in paperless </B>
        </P3>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faLeaf} size="sm" color="green" />
        <Spacer size="s" />
        <P3>{cardLabel}</P3>
      </div>
    </div>
  </>
);

const MailedSuccessContent = ({ cardLabel }: SuccessContentProps) => (
  <>
    <H4 color="ink">You're all set!</H4>
    <div className="mt3">
      <P3>
        You’re enrolled in paper statements. It may take up to one billing cycle
        to start receiving your statements in the mail.
      </P3>
    </div>
    <div className="bg-haze-lightest mt4 pt3 ph4 pb2 br2">
      <div>
        <P3 color="ink">
          <B>Enrolled in paper mail</B>
        </P3>
      </div>
      <div className="flex items-center">
        <Icon name="message" size="s" />
        <Spacer size="s" />
        <P3>{cardLabel}</P3>
      </div>
    </div>
  </>
);
