import { Controller, useFormContext } from 'react-hook-form';

import { TextField } from '@missionlane/compass-ui';
import { TextFieldProps } from '@missionlane/compass-ui/lib/typescript/components/Input/TextField/TextField';

interface HookFormTextFieldProps extends TextFieldProps {
  name: string;
}

const HookFormTextField = ({
  name,
  label,
  error,
  placeholder,
  onChangeText,
  ...textFieldProps
}: HookFormTextFieldProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            {...textFieldProps}
            placeholder={placeholder}
            onChangeText={onChangeText}
            label={label}
            error={error}
            autoCapitalize="none"
            returnKeyType="next"
          />
        );
      }}
      name={name}
    />
  );
};

export default HookFormTextField;
