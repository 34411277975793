import LoadingSpinner from '@core/components/General/LoadingSpinner';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import PreOfferDashboard from './PreOfferDashboard';
import ExistingOfferDashboard from './ExistingOfferDashboard/ExistingOfferDashboard';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { ExistingThirdPartyPlanDashboard } from './ExistingThirdPartyPlanDashboard';
import FulfilledPIFDashboard from './FulfilledPIFDashboard';
import {
  SettlementOfferState,
  SettlementOfferSubtype,
} from '@core/graphql/globalTypes';
import FulfilledSIFDashboard from './FulfilledSIFDashboard';
import { ErrorBoundary } from '@core/services/Error/Error';

const ChargedOffDashboard = () => {
  const { activeOffer, isInKatabat, isInDSCProgram, loading, error } =
    useSettlementOffers({
      fetchPolicy: 'cache-and-network',
    });

  if (loading) return <LoadingSpinner />;

  if (error) {
    throw new Error('ChargedOffDashboard: Failed to fetch offer data');
  }

  if (isInKatabat || isInDSCProgram) {
    return <ExistingThirdPartyPlanDashboard isInKatabat={isInKatabat} />;
  }

  if (activeOffer?.data.state === SettlementOfferState.Fulfilled) {
    if (activeOffer.data.subType === SettlementOfferSubtype.Pif) {
      return <FulfilledPIFDashboard />;
    }
    return <FulfilledSIFDashboard />;
  }

  if (
    activeOffer?.data.state === SettlementOfferState.InProgress ||
    activeOffer?.data.state === SettlementOfferState.PendingCloseOut
  ) {
    return <ExistingOfferDashboard offer={activeOffer} />;
  }

  return <PreOfferDashboard />;
};

const BoundChargedOffDashboard = () => (
  <ErrorBoundary
    fallback={() => <GenericFallbackFull className="bg-haze-lightest" />}
  >
    <ChargedOffDashboard />
  </ErrorBoundary>
);

export default BoundChargedOffDashboard;
