import { Routes as ReactRoutes, Route, Outlet } from 'react-router-dom';

import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { ForgotPasswordFlow, LoginFlow, Logout } from '@core/components/Auth';
import { ProtectedRoute } from './ProtectedRoute';
import { MfaEnterPage } from '../../Auth/MFA/MfaEnterPage';
import { MfaRequestPage } from '../../Auth/MFA/MfaRequestPage';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import LoginCallback from './LoginCallback/LoginCallback';
import { useOktaAuth } from '@okta/okta-react';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import RequireAuth from './RequireAuth';
import { Sentry } from '@core/services';
import ThreeDSConfirmation from '@payments/components/MakePayment/PayWithCard/ThreeDSConfirmation';

const Routes = () => {
  const { authState } = useOktaAuth();
  const { isAuthenticated } = useRiskAuth();

  if (authState?.isAuthenticated === null) {
    return <LoadingSpinnerPage />;
  }

  const RouterRoutes = window.isPlaywright ? ReactRoutes : Sentry.Routes;

  return (
    <RouterRoutes>
      <Route path="/signin" element={<LoginFlow />} />
      <Route path="/signin/callback" element={<LoginCallback />} />
      <Route
        path="/mfa/*"
        element={
          <ProtectedRoute blocked={!!isAuthenticated}>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route path="request" element={<MfaRequestPage />} />
        <Route path="enter" element={<MfaEnterPage />} />
      </Route>
      <Route path="/signout" element={<Logout />} />
      <Route
        path="/forgot-password"
        element={
          <ProtectedRoute blocked={!!isAuthenticated}>
            <ForgotPasswordFlow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/make-card-payment-confirmed"
        element={<ThreeDSConfirmation />}
      />
      <Route
        path="/*"
        element={
          <RequireAuth>
            <AuthenticatedRoutes />
          </RequireAuth>
        }
      />
    </RouterRoutes>
  );
};

export default Routes;
