export type PayWithCardHeader =
  | 'Make a payment'
  | 'Review & Submit'
  | 'Confirm Payment'
  | 'Payment Sent'
  | 'Payment Failed'
  | 'Submitting Payment';
export type PayWithCardPrevLinkText =
  | 'Edit your payment'
  | 'Back to dashboard'
  | 'Back'
  | undefined;

export enum CardPaymentState {
  INPUT_CARD_DETAILS,
  REVIEW_CARD_PAYMENT,
  SUBMIT_CARD_PAYMENT,
  CARD_PAYMENT_SUCCESS,
  CARD_PAYMENT_FAILED,
}

export type PayWithCardState = {
  state: CardPaymentState;
  header: PayWithCardHeader;
  prevLinkText: PayWithCardPrevLinkText;
  prevState: PayWithCardState | null;
};

export const INPUT_CARD_DETAILS: PayWithCardState = {
  state: CardPaymentState.INPUT_CARD_DETAILS,
  header: 'Make a payment',
  prevLinkText: 'Back to dashboard',
  prevState: null,
};
export const REVIEW_CARD_PAYMENT: PayWithCardState = {
  state: CardPaymentState.REVIEW_CARD_PAYMENT,
  header: 'Review & Submit',
  prevLinkText: 'Edit your payment',
  prevState: INPUT_CARD_DETAILS,
};
export const SUBMIT_CARD_PAYMENT: PayWithCardState = {
  state: CardPaymentState.SUBMIT_CARD_PAYMENT,
  header: 'Submitting Payment',
  prevLinkText: 'Back',
  prevState: REVIEW_CARD_PAYMENT,
};
export const CARD_PAYMENT_SUCCESS: PayWithCardState = {
  state: CardPaymentState.CARD_PAYMENT_SUCCESS,
  header: 'Payment Sent',
  prevLinkText: 'Back to dashboard',
  prevState: null,
};
export const CARD_PAYMENT_FAILED: PayWithCardState = {
  state: CardPaymentState.CARD_PAYMENT_FAILED,
  header: 'Payment Failed',
  prevLinkText: 'Edit your payment',
  prevState: INPUT_CARD_DETAILS,
};

export const toDashOnBack = (cardState: PayWithCardState) =>
  cardState.prevLinkText === 'Back to dashboard';
export const toPrevStateOnBack = (cardState: PayWithCardState) =>
  cardState.prevState !== null;
