import creditProtectionShield from '@core/assets/CreditProtectionShield.svg';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileCTA,
  OpportunityTileDiagonalBackground,
} from '../AccountSummaryPage/Dashboard/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import { useNavigate } from 'react-router-dom';
import { Link } from '@missionlane/compass-ui';
import { TrackService } from '@core/services';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MLFlags } from 'flags';

const IMAGE_WIDTH = 80;
const IMAGE_RIGHT = 6;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;
const IMAGE_BOTTOM = 6;
export const CREDIT_PROTECTION_TILE_EVENT =
  'Opportunity Tile: Credit Protection';

export const CREDIT_PROTECTION_TITLE_A =
  'Get Financial Protection from Unexpected Life Events';
export const CREDIT_PROTECTION_TITLE_B =
  'Get Coverage in Case of Future Unemployment or Other Events';

export const CREDIT_PROTECTION_BODY_A =
  'Enroll in Mission Lane Credit Protection to help ensure you’re covered.';
export const CREDIT_PROTECTION_BODY_B =
  'Enroll in Mission Lane Credit Protection to help prevent missed payments.';

const CreditProtectionTile = ({ onDismiss }: CarouselTileProps) => {
  const navigate = useNavigate();
  const { creditProtectionMarketing } = useFlags<MLFlags>();

  const title =
    (
      creditProtectionMarketing === 'TILE_A' ||
      creditProtectionMarketing === 'MODAL_TILE_A'
    ) ?
      CREDIT_PROTECTION_TITLE_A
      : CREDIT_PROTECTION_TITLE_B;

  const body =
    (
      creditProtectionMarketing === 'TILE_A' ||
      creditProtectionMarketing === 'MODAL_TILE_A'
    ) ?
      CREDIT_PROTECTION_BODY_A
      : CREDIT_PROTECTION_BODY_B;

  useEffect(() => {
    TrackService.trackEvent('Offer Viewed', {
      feature: CREDIT_PROTECTION_TILE_EVENT,
    });
  }, []);

  return (
    <OpportunityTileContainer backgroundColor="hazeLightest">
      <OpportunityTileDiagonalBackground
        background="hazeLightest"
        imgAlt="credit protection shield"
        imgSource={creditProtectionShield}
        imgWidth={IMAGE_WIDTH}
        imageRight={IMAGE_RIGHT}
        imageBottom={IMAGE_BOTTOM}
      />
      <OpportunityTileCTA
        trackingName={CREDIT_PROTECTION_TILE_EVENT}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title={title}
        text={body}
        onButtonClick={() => navigate('../credit-protection')}
        buttonLabel="Learn More"
      />
      <Link
        onPress={() => onDismiss(CREDIT_PROTECTION_TILE_EVENT)}
        style={{
          zIndex: 2,
        }}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default CreditProtectionTile;
