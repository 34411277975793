import { FormState } from 'react-hook-form';
import { HookFormTextField } from '../../../Form';
import ChangePhoneError from './ChangePhoneError';
import { ApolloError } from '@apollo/client';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { formatPhoneNumber } from './helper';
import { Button } from '@missionlane/compass-ui';
import { TrackService } from '@core/services';

export type ChangePhoneFormValues = {
  updatedPhone: string;
};

export interface ChangePhoneFormProps {
  onSubmit: () => void;
  formState: Pick<
    FormState<ChangePhoneFormValues>,
    'errors' | 'isSubmitting' | 'isValid' | 'isDirty'
  >;
  error: ApolloError | undefined;
  onCancel: () => void;
  setValue: UseFormSetValue<ChangePhoneFormValues>;
}

const ChangePhoneForm = ({
  onSubmit,
  setValue,
  error,
  formState,
  onCancel,
}: ChangePhoneFormProps) => {
  const { isSubmitting, isValid, isDirty } = formState;
  if (error) {
    TrackService.message('Change Phone Enter Phone: Rejection');
  }
  return (
    <form>
      <HookFormTextField
        testID="UpdatePhoneNumberField"
        name="updatedPhone"
        label="Enter new phone number"
        placeholder="(___) ___-____"
        error={formState.errors.updatedPhone?.message}
        onChangeText={(text) => {
          setValue('updatedPhone', formatPhoneNumber(text));
        }}
        keyboardType="phone-pad"
      />
      {error && <ChangePhoneError />}
      <div className="flex flex-column mt4 justify-start-ns items-center-ns flex-row-ns">
        <div className="mr3-ns">
          <Button
            text="Confirm"
            onPress={onSubmit}
            loading={isSubmitting}
            disabled={!isValid || !isDirty}
          />
        </div>
        <Button text="Cancel" variant="text" onPress={onCancel} />
      </div>
    </form>
  );
};

export default ChangePhoneForm;
