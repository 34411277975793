import { TrackService } from '@core/services';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import mlSecondCardOffer from '@core/assets/mlSecondCardOffer.svg';

import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
} from '@core/components/AccountSummaryPage/Dashboard/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import { Link } from '@missionlane/compass-ui';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';

export const REACTIVE_MULTICARD_TILE_EVENT = 'REACTIVE_MULTICARD_OFFER';

const ReactiveMulticardTile = ({ onDismiss }: CarouselTileProps) => {
  useEffect(() => {
    TrackService.trackEvent('Offer Viewed', {
      feature: REACTIVE_MULTICARD_TILE_EVENT,
    });
  }, []);

  const onClickCta = () => {
    TrackService.trackClick('See If You Qualify', {
      feature: REACTIVE_MULTICARD_TILE_EVENT,
    });
    window
      .open(
        'https://apply.missionlane.com/prequalification?invite-code=MLPQRC7890',
        '_blank',
      )
      ?.focus();
    localStorage.setItem(REACTIVE_MULTICARD_TILE_EVENT, dayjs().toString());
  };

  return (
    <OpportunityTileContainer backgroundColor="tealWashed">
      <OpportunityTileDiagonalBackground
        background="green"
        imgAlt="new ML credit card"
        imgSource={mlSecondCardOffer}
        imgWidth={IMAGE_WIDTH}
        imageRight={IMAGE_RIGHT}
        imageBottom={IMAGE_BOTTOM}
      />
      <OpportunityTileCTA
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title="Apply for a second card"
        text="See what you qualify for with no hit to your credit score."
        onButtonClick={onClickCta}
        buttonLabel="See if you qualify"
        trackingName={REACTIVE_MULTICARD_TILE_EVENT}
      />
      <Link
        onPress={() => onDismiss(REACTIVE_MULTICARD_TILE_EVENT)}
        style={{
          zIndex: 2,
        }}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default ReactiveMulticardTile;

const IMAGE_WIDTH = 87;
const IMAGE_RIGHT = 10;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH;
const IMAGE_BOTTOM = 10;
