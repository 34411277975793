import { CFUStatus } from '../network/types';

export const isEvaluationStatusValid = (
  evaluationStatus: CFUStatus | null,
): boolean =>
  !!evaluationStatus && Object.values(CFUStatus).includes(evaluationStatus);

export const isNextEvaluationDateValid = (
  evaluationStatus: CFUStatus | null,
  nextEvaluationDate: string | null,
): boolean =>
  (
    evaluationStatus === CFUStatus.APPROVED ||
    evaluationStatus === CFUStatus.DECLINED
  ) ?
    !!nextEvaluationDate
  : true;

export const isLineIncreaseAmountValid = (
  evaluationStatus: CFUStatus | null,
  lineIncreaseAmount: number | null,
) => (evaluationStatus === CFUStatus.APPROVED ? !!lineIncreaseAmount : true);

export const isOfferExpirationDateValid = (
  evaluationStatus: CFUStatus | null,
  offerExpirationDate: string | null,
) => (evaluationStatus === CFUStatus.INITIAL ? !!offerExpirationDate : true);
