import { useCallback, useEffect, useState } from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, ApolloError, useMutation } from '@apollo/client';
import { ADD_PLAID_FUNDING_ACCOUNT_MUTATION } from '@payments/components/BankAccount/queries';
import {
  GET_EXCHANGE_PUBLIC_TOKEN_QUERY,
  GET_PLAID_LINK_TOKEN,
} from '@payments/components/BankAccount/AddPlaidAccount/AddPlaidAccount';
import OAuthError from '@core/assets/plaidOAuthError.svg';
import PageWrapper from '@core/components/Page/PageWrapper';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import {
  AddPlaidFundingAccountMutation,
  AddPlaidFundingAccountMutationVariables,
  Channel,
  GetExchangePublicTokenQuery,
  GetExchangePublicTokenQueryVariables,
  GetPlaidLinkTokenQuery,
} from '@core/graphql/globalTypes';
import { MLError, TrackService } from '@core/services';
import {
  PlaidLinkClosedEvent,
  PlaidLinkCompletedEvent,
} from '@core/services/TrackService/models/events';
import { CFU_FEATURE_NAME } from '@core/utils/constants';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useCreateCFUAssetReportMutation } from '@clip/CashFlowUnderwritingExperience/hooks';
import { Button } from '@missionlane/compass-ui';

let externalFundingAccountId = '';

const PlaidOAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isClipCFUFlow = location.pathname.includes('plaid-login-clip-oauth');
  const accountId = sessionStorage.getItem('accountId');

  const redirectTo = sessionStorage
    .getItem(isClipCFUFlow ? 'redirectOAuthClipCfu' : 'redirectOAuth')
    ?.toString();
  const [plaidLinkTokenData, setPlaidLinkTokenData] =
    useState<GetPlaidLinkTokenQuery>();
  const [displayError, setDisplayError] = useState(false);

  const accessTokenId =
    plaidLinkTokenData?.plaidLinkToken.productRegistration.productAccess[0]
      .accessTokenId;

  const tokenStorageKey =
    isClipCFUFlow ? 'plaidLinkTokenClipCfu' : 'plaidLinkTokenData';

  function plaidOAuthError() {
    sessionStorage.removeItem(tokenStorageKey);
    sessionStorage.removeItem('accountId');
    setDisplayError(true);
  }

  useEffect(() => {
    const storedLinkTokenData = sessionStorage.getItem(
      isClipCFUFlow ? 'plaidLinkTokenClipCfu' : 'plaidLinkTokenData',
    );
    if (storedLinkTokenData) {
      setPlaidLinkTokenData(JSON.parse(storedLinkTokenData));
    } else {
      plaidOAuthError();
    }
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      externalFundingAccountId = metadata.accounts[0].id;
      if (accessTokenId?.length) {
        getAccessToken({
          variables: {
            publicToken: public_token,
            accessTokenId,
          },
        });
      } else {
        plaidOAuthError();
      }
    },
    [plaidLinkTokenData],
  );

  const onSuccessClipCFU = useCallback<PlaidLinkOnSuccess>(
    (public_token: string, metadata) => {
      if (!accountId) {
        plaidOAuthError();
      } else {
        TrackService.track(
          new PlaidLinkCompletedEvent({
            feature_name: CFU_FEATURE_NAME,
            institution: metadata.institution || undefined, // The institution the user selected.
          }),
        );
        createAssetReport({
          variables: {
            accountId,
            publicToken: public_token,
            accessTokenId: accessTokenId || '',
          },
        });
      }
    },
    [plaidLinkTokenData],
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      MLError.report({
        name: 'PlaidOnExit',
        message: JSON.stringify(metadata),
      });
      if (error) {
        plaidOAuthError();
      }
      if (!isClipCFUFlow) return;
      TrackService.track(
        new PlaidLinkClosedEvent({
          feature_name: CFU_FEATURE_NAME,
          error: error?.display_message || error?.error_message, // Either a human readable error message or the error message if unrelated to user action
          status: metadata.status || undefined, // The point at which the user exited the Link flow.
          institution: metadata.institution || undefined, // The institution the user selected.
        }),
      );
    },
    [],
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string) => {
      if (eventName === PlaidLinkStableEvent.EXIT) {
        plaidOAuthError();
      }
    },
    [],
  );

  const { open: openPlaid, ready } = usePlaidLink({
    token: plaidLinkTokenData?.plaidLinkToken.token || '',
    receivedRedirectUri: `${location.pathname}${location.search}`,
    onSuccess: isClipCFUFlow ? onSuccessClipCFU : onSuccess,
    onExit,
    onEvent,
  });

  useEffect(() => {
    if (ready) {
      openPlaid();
    }
  }, [ready]);

  const [getAccessToken] = useLazyQuery<
    GetExchangePublicTokenQuery,
    GetExchangePublicTokenQueryVariables
  >(GET_EXCHANGE_PUBLIC_TOKEN_QUERY, {
    onCompleted: async (accessTokenData) => {
      if (accessTokenData.exchangePublicToken) {
        try {
          await addFundingAccount({
            variables: {
              externalFundingAccountId,
              accessTokenId: accessTokenId || '',
              channel: Channel.Web,
            },
          });
        } catch (error: unknown) {
          plaidOAuthError();
          // we already notify MLError of ApolloErrors in apolloClient
          if (error instanceof Error && !(error instanceof ApolloError))
            MLError.report({
              name: error.name,
              error,
            });
        }
      } else {
        plaidOAuthError();
      }
    },
    onError: plaidOAuthError,
  });

  const [addFundingAccount] = useMutation<
    AddPlaidFundingAccountMutation,
    AddPlaidFundingAccountMutationVariables
  >(ADD_PLAID_FUNDING_ACCOUNT_MUTATION, {
    onCompleted: goBack,
    onError: plaidOAuthError,
    refetchQueries: [
      { query: ACH_FUNDING_ACCOUNTS_QUERY },
      { query: GET_PLAID_LINK_TOKEN },
    ],
  });

  const [createAssetReport] = useCreateCFUAssetReportMutation({
    onCompleted: () => {
      sessionStorage.removeItem('accountId');
      goBack();
    },
    onError: plaidOAuthError,
  });

  function goBack() {
    redirectTo ? navigate(redirectTo, { replace: true }) : navigate(-1);
  }

  return (
    <PageWrapper>
      {!displayError ?
        <LoadingSpinner />
      : <div className="ink pa4 sans-serif h-100 w-100 flex items-center justify-center">
          <div className="w-25-l">
            <img src={OAuthError} alt="" />
          </div>
          <div className="w-25-l">
            <h2 className="mb5 pt3 f2-l">Oops!</h2>
            <p>
              Sorry, Plaid had trouble linking your bank account. Let's take you
              back
            </p>
            <div className="flex">
              <Button text="Go Back" onPress={goBack} />
            </div>
          </div>
        </div>
      }
    </PageWrapper>
  );
};

export default PlaidOAuth;
