import { useQuery } from '@apollo/client';
import { B, Icon, P1, Spacing } from '@missionlane/compass-ui';
import { MENU_CUSTOMER_QUERY } from '@core/components/Menu/Menu';
import { MenuCustomerQuery } from '@core/graphql/globalTypes';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import dayjs from 'dayjs';
import DashboardFooter from '@core/components/ChargedOff/Dashboards/DashboardFooter';
import OfferDetailsKard from '@core/components/ChargedOff/Kards/OfferDetailsKard';
import { PaymentPlanStepProps } from './CreatePaymentPlanFlow';
import { centsToDollars } from '@core/utils/centsToDollars';
import EstimatedPaymentKard from '../Kards/EstimatedPaymentKard';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

const OfferOverview = ({ offer, nextStep }: PaymentPlanStepProps) => {
  const { data: customerData, loading } =
    useQuery<MenuCustomerQuery>(MENU_CUSTOMER_QUERY);

  useEffect(() => {
    TrackService.trackPage('Create Program - Offer Generated', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  if (loading || offer === undefined) {
    return <LoadingSpinner />;
  }

  if (
    offer === null ||
    !offer.data.durationMonths ||
    !offer.data.originalSettlementBalance
  ) {
    throw new Error('OfferOverview: Missing required offer data');
  }

  const firstName = customerData?.customer?.contactInfo?.firstName;
  const { durationMonths, originalSettlementBalance, atpUnderSIF } = offer.data;
  const offerEndDate = dayjs().add(durationMonths, 'months').toISOString();
  const { header, subHeader } = getHeaderAndSubHeader(
    originalSettlementBalance,
    offerEndDate,
    firstName,
    atpUnderSIF,
  );

  return (
    <>
      <div className="mb2">
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          {header}
        </ResponsiveTypography>
      </div>
      <div className="mb3">
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          {subHeader}
        </ResponsiveTypography>
      </div>
      <div className="flex flex-row-ns flex-column">
        <div className="mb3 w-50-ns mr2-ns">
          <OfferDetailsKard offer={offer} offerEndDate={offerEndDate} />
        </div>
        <div className="w-50-ns ml1-ns">
          <EstimatedPaymentKard
            settlementBalance={originalSettlementBalance}
            offerEndDate={offerEndDate}
            durationMonths={durationMonths}
            onSchedulePayments={() => nextStep?.()}
          />
        </div>
      </div>
      <DashboardFooter
        CallToAction={
          <div
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              alignItems: 'center',
              gap: Spacing.s,
              justifyContent: 'start',
            }}
          >
            <Icon name="phone" color="green" size="s" />
            <P1>Need more help?</P1>
          </div>
        }
        Disclaimer={
          <P1>
            If you have any questions or want to explore other plan options,{' '}
            <B style={{ color: 'var(--ink)' }}>
              please give us a call at 1-888-695-8536.
            </B>
          </P1>
        }
      />
    </>
  );
};

const getHeaderAndSubHeader = (
  originalSettlementBalance: number,
  offerEndDate: string,
  firstName: string | undefined,
  atpUnderSIF: boolean | null,
) => {
  const formattedSettlementAmount = centsToDollars(originalSettlementBalance);
  const formattedExpiration = dayjs(offerEndDate).format('MMMM D');

  // Every header starts with the same text
  let header = `Good news`;
  let subHeader: string;

  if (firstName) {
    header += `, ${firstName}`;
  }

  // Whether a firstName is defined or not, the first sentence should always end in an exclamation point
  header += '!';

  // This value is null when the offer is a PIF
  if (atpUnderSIF === null) {
    header += ' You qualify for an offer.';
    subHeader = `If you pay ${formattedSettlementAmount} by ${formattedExpiration}, we’ll consider your account paid in full.`;
  } else {
    // SIF offers always have this as the second sentence of their header
    header += ' You qualify for a settlement offer.';

    if (atpUnderSIF === true) {
      subHeader = `We can’t give you the exact plan you wanted, but here’s what we can offer. If you pay ${formattedSettlementAmount} by ${formattedExpiration}, we’ll forgive the rest of your balance.`;
    } else {
      subHeader = `If you pay ${formattedSettlementAmount} by ${formattedExpiration}, we’ll forgive the rest of your balance.`;
    }
  }

  return { header, subHeader };
};

export default OfferOverview;
