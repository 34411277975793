import { Tile, TileDetail } from './TransactionTile';

interface Props {
  fundingAccount: {
    bankName: string;
    numberLast4: string;
  };
}

export const PaymentAccount = ({ fundingAccount }: Props) => {
  const from = `${fundingAccount.bankName}..${fundingAccount.numberLast4}`;

  return (
    <Tile>
      <TileDetail title="From" value={from} />
    </Tile>
  );
};
