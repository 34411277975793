import { ApolloError, gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useAccount } from '@core/components/Auth/AccountContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ActivityState,
  GetPaymentStatus,
  GetPaymentStatusQuery,
  PastDueStatus,
} from '@core/graphql/globalTypes';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';

dayjs.extend(isSameOrBefore);

export const GET_PAYMENT_STATUS = gql`
  query GetPaymentStatus($accountId: String!) {
    account(accountId: $accountId) {
      id
      paymentPlanUrl
      paymentInfo {
        printDueDate
        minimumDue
        nextStatementCloseDate
      }
      upcomingPayments {
        ...PaymentFields
      }
      balanceInfo {
        currentBalance
        pastDueBalance
      }
      statuses {
        isChargedOff
        isDebitEligible
        pastDueStatus
        isChargedOff
        isDeceased
        hasAppFraud
        isPastDue
        isSettled
        isBankrupt
        isDelinquent
        isPaymentPlanEligible
        pastDueStatus
      }
      upcomingPayments {
        id
        amount
        date
        state
      }
      autopay {
        id
        amount
        nextPaymentDate
      }
    }
  }
  ${PAYMENT_FIELDS}
`;

export interface PaymentStatus {
  loading: boolean;
  error?: ApolloError;
  daysUntilDue?: number;
  dueToday: boolean;
  dueTomorrow: boolean;
  isBankrupt: boolean | undefined;
  /** Checks if the customer is in Bucket 1 */
  isBucket1: boolean;
  /** Checks if the customer is in Buckets 2-5 */
  isBucket2to5: boolean;
  /** Checks if the customer is in Bucket 6 */
  isBucket6: boolean;
  isChargedOff: boolean | undefined;
  isDebitEligible: boolean | undefined;
  isDelinquent: boolean | undefined;
  isLate: boolean;
  isOverDue: boolean;
  /** Checks if the customer is Past Due */
  isPastDue: boolean;
  isPaymentPlanEligible: boolean | undefined;
  /**checks if the customer is in recoveries */
  isRecoveries: boolean;
  isSettled: boolean | undefined;
  minimumDue: number | undefined;
  noDueDate: boolean;
  nextStatementCloseDate: string | undefined;
  /** Customers past due balance */
  pastDueBalance: number | undefined;
  paymentPlanURL: string;
  printDueDate: string | null | undefined;
  printCycleDate: string | null | undefined;
  paymentsCoverMinDue: boolean | undefined;
  /** checks if the customers account is settled/closed */
  settledAccount: boolean;
  showPastDueCredit: boolean | undefined;
  showSettledCurrentBalance: boolean | undefined;
  upcomingPayments: GetPaymentStatus.UpcomingPayments[] | undefined;
  autopay: GetPaymentStatus.Autopay[] | undefined;
  canMakePayment: boolean | undefined;
  hasPastDueBalancePayment: boolean;
  /** Account is neither past due nor charged off */
  canEnrollInAutopay: boolean;
  /** Account has autopay set up */
  hasAutopay: boolean;
}

export const usePaymentStatus = (): PaymentStatus => {
  const { accountId } = useAccount();
  const { showPastDueExperience } = useFlags();
  const { loading, error, data } = useQuery<GetPaymentStatusQuery>(
    GET_PAYMENT_STATUS,
    {
      variables: { accountId },
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    paymentInfo,
    statuses,
    balanceInfo,
    paymentPlanUrl,
    upcomingPayments,
    autopay,
  } = data?.account || {};

  /** PaymentInfo query values */
  const { printDueDate, nextStatementCloseDate, minimumDue } =
    paymentInfo || {};
  const noDueDate = !printDueDate;

  /** Statuses query values */
  const {
    isBankrupt,
    isChargedOff,
    isDebitEligible,
    isDelinquent,
    isPaymentPlanEligible,
    isSettled,
  } = statuses || {};
  const isPastDue = !!showPastDueExperience && !!statuses?.isPastDue;
  const showPastDueCredit = !!showPastDueExperience && statuses?.isPastDue;

  /** Dayjs values*/
  const dueDate = dayjs(printDueDate);
  const formattedCycleDate = dayjs(paymentInfo?.nextStatementCloseDate);
  const now = dayjs();
  const today = dayjs().startOf('day');

  const printCycleDate = formattedCycleDate.format('YYYY-MM-DD');

  /** PaymentDue values */
  const daysUntilDue = dueDate.diff(today, 'days');
  const dueToday = daysUntilDue === 0;
  const dueTomorrow = daysUntilDue === 1;
  const isOverDue = daysUntilDue < 0;

  const paymentPlanURL = paymentPlanUrl || '';
  const pastDueBalance = balanceInfo?.pastDueBalance || 0;

  /** Settled account */
  const settledAccount = !!isSettled && !!showPastDueExperience;

  const showSettledCurrentBalance =
    statuses?.isSettled && !!showPastDueExperience;

  /** Late logic */
  const isLate =
    now.isSameOrBefore(formattedCycleDate, 'date') &&
    now.isAfter(dueDate, 'date') &&
    statuses?.pastDueStatus === null &&
    !!minimumDue &&
    minimumDue > 0;

  /** Past Due: bucket 1 */
  const isBucket1 =
    !statuses?.isChargedOff &&
    statuses?.pastDueStatus === PastDueStatus.PastDue_1_30Days;

  /** Past Due: buckets 2-5 */
  const isBucket2to5 =
    !statuses?.isChargedOff &&
    (statuses?.pastDueStatus === PastDueStatus.PastDue_31_60Days ||
      statuses?.pastDueStatus === PastDueStatus.PastDue_61_90Days ||
      statuses?.pastDueStatus === PastDueStatus.PastDue_91_120Days ||
      statuses?.pastDueStatus === PastDueStatus.PastDue_121_150Days);

  /** Past Due: bucket 6 */
  const isBucket6 =
    !statuses?.isChargedOff &&
    statuses?.pastDueStatus === PastDueStatus.PastDue_151_180Days;

  /** Past Due: Payment */
  const pendingPaymentsTotalAmount =
    upcomingPayments?.reduce((totalAmount, payment) => {
      if (payment.state === ActivityState.Pending) {
        return totalAmount + Number(payment.amount);
      }
      return 0;
    }, 0) || 0;

  const hasPastDueBalancePayment =
    Math.abs(pendingPaymentsTotalAmount) >= pastDueBalance;

  /** recoveries */
  const isBucket7to1c =
    statuses?.pastDueStatus === PastDueStatus.PastDue_181_210Days ||
    statuses?.pastDueStatus === PastDueStatus.PastDue_211_240Days ||
    statuses?.pastDueStatus === PastDueStatus.PastDue_241_270Days ||
    statuses?.pastDueStatus === PastDueStatus.PastDue_271_300Days ||
    statuses?.pastDueStatus === PastDueStatus.PastDue_301_330Days ||
    statuses?.pastDueStatus === PastDueStatus.PastDue_331PlusDays;

  const Bucket7to1cNotSettled = isBucket7to1c && !statuses?.isSettled;

  const unknownBucketInRecoveries =
    statuses?.pastDueStatus === PastDueStatus.Unknown &&
    !statuses?.isSettled &&
    statuses?.isChargedOff;

  const isRecoveries =
    !isBankrupt && (Bucket7to1cNotSettled || unknownBucketInRecoveries);
  const paymentsCoverMinDue = false;

  const canMakePayment =
    !!balanceInfo?.currentBalance && balanceInfo?.currentBalance > 0;

  const canEnrollInAutopay = !isPastDue && !isChargedOff;
  const hasAutopay = !!autopay?.[0]?.id;

  return {
    loading,
    error,
    showSettledCurrentBalance,
    showPastDueCredit,
    daysUntilDue,
    dueToday,
    dueTomorrow,
    isBankrupt,
    isBucket1,
    isBucket2to5,
    isBucket6,
    isChargedOff,
    isDebitEligible,
    isDelinquent,
    isLate,
    isOverDue,
    isPaymentPlanEligible,
    isPastDue,
    isRecoveries,
    isSettled,
    noDueDate,
    minimumDue,
    nextStatementCloseDate,
    paymentPlanURL,
    pastDueBalance,
    printDueDate,
    printCycleDate,
    paymentsCoverMinDue,
    settledAccount,
    upcomingPayments,
    autopay,
    canMakePayment,
    canEnrollInAutopay,
    hasAutopay,
    hasPastDueBalancePayment,
  };
};
