import { useState } from 'react';
import {
  IssuanceState,
  IssuanceType,
  GetReplacementCardBannerDataQuery,
  GetReplacementCardBannerDataQueryVariables,
} from '@core/graphql/globalTypes';
import ReplacementCardModal from '@core/components/ReplacementCardModal/ReplacementCardModal';
import {
  CardMailedContent,
  CardDelayedContent,
  CardReturnedContent,
} from './ReplacementCardBannerContent';
import GenericBanner from '../GenericBanner';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { TrackService } from '@core/services';
import { useLocation } from 'react-router-dom';

export const GET_REPLACEMENT_CARD_BANNER_DATA_QUERY = gql`
  query GetReplacementCardBannerData($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        hasAppFraud
        isChargedOff
      }
      cardDetails {
        last4
        issuanceState
        issuanceType
      }
    }
  }
`;

const getContent = (issuanceState: IssuanceState | undefined) => {
  switch (issuanceState) {
    case IssuanceState.PreMailed:
    case IssuanceState.Mailed:
      return <CardMailedContent />;
    case IssuanceState.Delayed:
      return <CardDelayedContent />;
    case IssuanceState.Returned:
      return <CardReturnedContent />;
    default:
      return null;
  }
};

const ReplacementCardBanner = () => {
  const { data, loading } = useAccountIdQuery<
    GetReplacementCardBannerDataQuery,
    GetReplacementCardBannerDataQueryVariables
  >(GET_REPLACEMENT_CARD_BANNER_DATA_QUERY);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const location = useLocation();

  function openModal() {
    TrackService.click(
      'Account Summary: Activate banner',
      customerAndAccountIds,
    );
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }

  const { cardDetails, statuses } = data?.account || {};
  const { hasAppFraud, isChargedOff } = statuses || {};
  const { issuanceState, issuanceType } = cardDetails || {};
  const content = getContent(issuanceState);
  const isInSuccessScreen = !!location.pathname.includes('activate/success');

  // TODO: Need better error handling here than just returning nothing when content is null
  if (
    !content ||
    hasAppFraud ||
    issuanceType !== IssuanceType.Replacement ||
    loading ||
    isInSuccessScreen
  ) {
    return null;
  }

  return (
    <div data-testid="ReplacementCardBanner" onClick={openModal}>
      <GenericBanner>
        {content}
        <ReplacementCardModal
          isOpen={isModalOpen}
          issuanceState={issuanceState}
          isChargedOff={isChargedOff}
          onClose={closeModal}
        />
      </GenericBanner>
    </div>
  );
};

export default ReplacementCardBanner;
