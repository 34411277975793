import { Button, H4, P2 } from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfettiCone from '@core/assets/confettiCone.svg';

import { useEffect, useState } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';

import { ContentCard } from '@core/components/General/ContentCard';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';

const messagePreFix = 'You can pay your credit card balance with';

const AddAccountSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accountSummaryPath = useAccountSummaryPath();
  const { isMobile } = useUserDevice();
  const { error, loading, canEnrollInAutopay, hasAutopay } = usePaymentStatus();

  const { trackClick, trackEvent } = useTracking();
  const [faSetupSuccessMessage, setFASetupSuccessMessage] = useState<string>();

  const onGoHomeClick = () => {
    trackClick({
      name: 'Go home',
      feature: 'Add Account Success',
    });
    navigate(accountSummaryPath, { replace: true });
  };

  const onNextClick = () => {
    trackClick({
      name: 'Next',
      feature: 'Add Account Success',
    });
    navigate('./autopay', { replace: true });
  };

  useEffect(() => {
    trackEvent({
      eventName: `Viewed Add Account Success`,
    });
  }, []);

  const numberLast4 = location.state?.numberLast4;
  const bankName = location.state?.bankName;

  useEffect(() => {
    if (bankName && numberLast4) {
      setFASetupSuccessMessage(`${messagePreFix} ${bankName} ..${numberLast4}`);
    } else if (bankName) {
      setFASetupSuccessMessage(`${messagePreFix} your ${bankName} account`);
    } else if (numberLast4) {
      setFASetupSuccessMessage(
        `${messagePreFix} your account ending in ${numberLast4}`,
      );
    } else {
      setFASetupSuccessMessage(
        'Your bank account has been added and can be used to pay your credit card balance',
      );
    }
  }, [bankName, numberLast4]);

  if (!faSetupSuccessMessage || loading) {
    return <LoadingSpinner />;
  }

  return (
    <PageWrapper greyBackground={!isMobile}>
      <AccountBreadcrumbs items={[{ text: 'Add a Checking Account' }]} />
      <ContentCard className="pv7 flex flex-column items-center justify-center">
        <img src={ConfettiCone} alt="confetti cone" className="mb4" />
        <H4>Success!</H4>
        <P2 style={{ textAlign: 'center' }}>{faSetupSuccessMessage}</P2>
        {error || hasAutopay || !canEnrollInAutopay ?
          <Button text="Go home" onPress={onGoHomeClick} />
        : <Button text="Next" onPress={onNextClick} />}
      </ContentCard>
    </PageWrapper>
  );
};

export default AddAccountSuccess;
