import { B, BubbleIcon, Button, P3 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

interface NoScheduledPaymentsProps {
  isPastDue: boolean;
  isRecoveries: boolean;
  isSettled: boolean;
}
const NoScheduledPayments = ({
  isPastDue,
  isRecoveries,
  isSettled,
}: NoScheduledPaymentsProps) => {
  const navigate = useNavigate();
  return (
    <div className="bg-haze-lightest flex flex-column items-center mt3 pv4 ph3 ph0-ns">
      <BubbleIcon name="calendar" bubbleColor="inkLightest" iconColor="ink" />
      <P3 color="ink">
        <B>Nothing Yet</B>
      </P3>
      <div className="w-60-ns tc">
        <P3>
          {!isRecoveries || !isSettled ?
            'You haven’t scheduled a payment. When you do, you can find it here!'
          : <B>No Scheduled Payments</B>}
        </P3>
        {!isPastDue && (!isRecoveries || !isSettled) && (
          <div className="bg-white br-pill mt4">
            <Button
              variant="outlined"
              text="Set up Autopay"
              onPress={() => navigate('autopay')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NoScheduledPayments;
