import { Button } from '@missionlane/compass-ui';
import React from 'react';
import { ButtonProps } from '@missionlane/compass-ui/lib/typescript/components/Button/types';
import './ResponsiveButton.css';

const ResponsiveButton = ({ ...buttonProps }: ButtonProps): JSX.Element => {
  return (
    <div className="container">
      <Button {...buttonProps} />
    </div>
  );
};

export default ResponsiveButton;
