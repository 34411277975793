import { TrackService } from '@core/services';
import { ColorNames, Colors, Icon, B, P4, P3 } from '@missionlane/compass-ui';
import { PropsWithChildren } from 'react';

interface OpportunityTileContainerProps {
  backgroundColor: ColorNames;
}

export const OpportunityTileContainer = ({
  backgroundColor,
  children,
}: PropsWithChildren<OpportunityTileContainerProps>) => (
  <div
    className="ma2 br4 pa2 flex justify-between overflow-hidden relative"
    style={{
      backgroundColor: Colors[backgroundColor],
      boxShadow: '0px 2px 8px 0px rgba( 0, 0, 0, 0.15 )',
      minHeight: '140px',
      maxWidth: '325px',
    }}
  >
    {children}
  </div>
);

interface OpportunityTileCTAButtonProps {
  label: string;
  onClick: () => void;
}

export const OpportunityTileCTAButton = ({
  onClick,
  label,
}: OpportunityTileCTAButtonProps) => (
  <div className="flex">
    <button
      className="pointer ph3 pv1"
      style={{
        height: 28,
        backgroundColor: Colors.blue,
        color: Colors.white,
        borderWidth: 0,
        borderRadius: 20,
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <P4 color="white" style={{ margin: 0, fontSize: 14 }}>
        <B>{label}</B>
      </P4>
    </button>
  </div>
);

interface OpportunityTileCTAProps {
  contentSafeMargin: number;
  title: string;
  text: string;
  buttonLabel: string;
  onButtonClick: () => void;
  trackingName: string;
}

export const OpportunityTileCTA = ({
  contentSafeMargin,
  title,
  text,
  buttonLabel,
  onButtonClick,
  trackingName,
}: OpportunityTileCTAProps) => {
  const handleClick = () => {
    onButtonClick();
    TrackService.trackClick(title, {
      feature: trackingName,
    });
  };

  return (
    <div
      className="flex flex-column justify-between pv2 pl2"
      style={{ zIndex: 2 }}
    >
      <P3 color="ink" style={{ margin: 0, fontSize: 16 }}>
        <B>{title}</B>
      </P3>
      <P4
        style={{
          margin: 0,
          marginBottom: 12,
          marginRight: contentSafeMargin,
          fontSize: 14,
        }}
      >
        {text}
      </P4>
      <OpportunityTileCTAButton onClick={handleClick} label={buttonLabel} />
    </div>
  );
};

export const OpportunityTileCloseIcon = () => (
  <div
    className="flex items-center justify-center bg-white br4"
    style={{
      boxShadow: '0px 2px 8px 0px rgba( 0, 0, 0, 0.15 )',
      height: '25px',
      width: `${CLOSE_BUTTON_WIDTH}px`,
    }}
  >
    <Icon name="close" color="ink" size="s" />
  </div>
);
export const CLOSE_BUTTON_WIDTH = 25;

export type OpportunityTileGradient = 'silverGradient' | 'goldGradient';
type OpportunityTileDiagonalBackground = ColorNames | OpportunityTileGradient;

interface OpportunityTileDiagonalBackgroundProps {
  background: OpportunityTileDiagonalBackground;
  imgAlt: string;
  imgSource: string;
  imgWidth: number;
  imageRight: number;
  imageBottom: number;
  diagonalWidth?: number;
  diagonalHeight?: number;
  diagonalRight?: number;
}

export const OpportunityTileDiagonalBackground = ({
  background,
  imgAlt,
  imgSource,
  imgWidth,
  imageRight,
  imageBottom,
  diagonalWidth = 80,
  diagonalHeight = 175,
  diagonalRight = -21,
}: OpportunityTileDiagonalBackgroundProps) => {
  let backgroundStyle;
  switch (background) {
    case 'silverGradient':
      backgroundStyle = SILVER_GRADIENT_CSS;
      break;
    case 'goldGradient':
      backgroundStyle = GOLD_GRADIENT_CSS;
      break;
    default:
      backgroundStyle = Colors[background];
  }

  return (
    <>
      <div
        style={{
          transform: 'rotate(-148deg)',
          position: 'absolute',
          width: `${diagonalWidth}px`,
          height: `${diagonalHeight}px`,
          background: backgroundStyle,
          right: diagonalRight,
        }}
      />
      <img
        src={imgSource}
        alt={imgAlt}
        style={{
          position: 'absolute',
          width: `${imgWidth}px`,
          right: imageRight,
          bottom: imageBottom,
        }}
      />
    </>
  );
};
const SILVER_GRADIENT_CSS =
  'linear-gradient(17deg, rgba(72, 72, 72, 1) 10%, rgba(111, 111, 110, 1), rgba(183, 183, 181, 1), rgba(183, 183, 181, 1), rgba(251, 251, 251, 1), rgba(183, 183, 181, 1), rgba(183, 183, 181, 1), rgba(111, 111, 110, 1) 90%)';
const GOLD_GRADIENT_CSS =
  'linear-gradient(17deg, rgba(152, 102, 20, 1) 10%, rgba(203, 152, 62, 1), rgba(254, 203, 110, 1), rgba(254, 203, 110, 1), rgba(255, 254, 159, 1), rgba(254, 203, 110, 1), rgba(203, 152, 62, 1), rgba(152, 102, 20, 1) 90%)';
