import { B, Icon, P3 } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { centsToDollars } from '@core/utils/centsToDollars';

interface SecurityDepositFooterProps {
  securityDeposit: number | null | undefined;
  hasLargeBottomLeftRadius?: boolean;
}

export const SecurityDepositFooter = ({
  securityDeposit,
  hasLargeBottomLeftRadius,
}: SecurityDepositFooterProps) => {
  const { showSecuredCard } = useFlags();

  if (typeof securityDeposit !== 'number' || !showSecuredCard) {
    return null;
  }

  return (
    <div
      className={`${
        hasLargeBottomLeftRadius ? 'br3 br--left' : 'br2'
      } bg-haze-lightest flex justify-between items-center ph4 pv1 nt2 br--bottom`}
    >
      <div className="flex items-center">
        <span className="mr2 pt1">
          <Icon size="s" name="unlockKeyhole" />
        </span>
        <P3>Security deposit</P3>
      </div>
      <div>
        <P3 color="ink">
          <B>{centsToDollars(securityDeposit)}</B>
        </P3>
      </div>
    </div>
  );
};
