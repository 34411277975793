import { gql } from '@apollo/client';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { BreadcrumbsProps } from '@core/components/General/Breadcrumbs/Breadcrumbs';
import {
  GetMakeAchPaymentBreadcrumbsQuery,
  GetMakeAchPaymentBreadcrumbsQueryVariables,
} from '@core/graphql/globalTypes';
import { MLError } from '@core/services';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';

const GET_MAKE_ACH_PAYMENT_BREADCRUMBS_QUERY = gql`
  query GetMakeAchPaymentBreadcrumbs($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isDebitEligible
        isChargedOff
      }
    }
  }
`;

interface Props {
  items: BreadcrumbsProps['items'];
}

export const MakeAchPaymentBreadcrumbs = ({ items }: Props) => {
  const { data, loading, error } = useAccountIdQuery<
    GetMakeAchPaymentBreadcrumbsQuery,
    GetMakeAchPaymentBreadcrumbsQueryVariables
  >(GET_MAKE_ACH_PAYMENT_BREADCRUMBS_QUERY);

  if (loading) return null;
  if (error || !data || !data.account) {
    MLError.report({
      name: 'MakeAchPaymentBreadcrumbs Error',
      message: error ? error.message : 'Account status data is undefined',
    });

    return null;
  }
  const baseRoute = `/account/${data.account.id}/payments`;
  const { isChargedOff, isDebitEligible } = data.account.statuses || {};

  let breadcrumbs: BreadcrumbsProps['items'] = [];

  if (!isChargedOff) {
    breadcrumbs.push({
      text: 'Payments',
      link: baseRoute,
    });
  }

  if (isDebitEligible) {
    breadcrumbs.push({
      text: 'Payment method',
      link: `${baseRoute}/pay`,
    });
  }

  breadcrumbs = [...breadcrumbs, ...items];

  return <AccountBreadcrumbs items={breadcrumbs} />;
};
