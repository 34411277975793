import classNames from 'classnames';

interface ContentCardProps {
  className?: string;
  children: React.ReactNode;
}

/** Wraps children in a white card with box-shadow, commonly used for pages that do
 * not span the entire width of the screen (such as My Profile, Account Details, etc)
 */
export const ContentCard = ({ className, children }: ContentCardProps) => (
  <div
    className={classNames(
      'w-70-l mb4 center bg-white pa5-m pa4 shadow-2-ns',
      className,
    )}
  >
    {children}
  </div>
);
