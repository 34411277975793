export const COPY = {
  TITLE: 'Current Offer',
} as const;

export const TEST_ID = {
  SPEND_BONUS__IN_PROGRESS: 'SPEND_BONUS__IN_PROGRESS',
  SPEND_BONUS__IN_PROGRESS__HEADER__CLIP:
    'SPEND_BONUS__IN_PROGRESS__HEADER__CLIP',
  SPEND_BONUS__IN_PROGRESS__HEADER__CASHBACK:
    'SPEND_BONUS__IN_PROGRESS__HEADER__CASHBACK',
  SPEND_BONUS__IN_PROGRESS__MESSAGE__CLIP:
    'SPEND_BONUS__IN_PROGRESS__MESSAGE__CLIP',
  SPEND_BONUS__IN_PROGRESS__MESSAGE__CASHBACK:
    'SPEND_BONUS__IN_PROGRESS__MESSAGE__CASHBACK',
} as const;
