import { gql } from '@apollo/client';
import { getAccountSummaryValue } from './AccountDetailsAccountSummary/AccountDetailsAccountSummary';
import FallbackUI from '@core/components/GenericFallbacks/SectionFallbackUI';
import AccountDetailsPanel from './AccountDetailsAccountSummary/AccountDetailsPanel';
import {
  AccountSummaryCardTermsFragment,
  CardTermsRewards,
} from '@core/graphql/globalTypes';
import { DescriptionList } from '@core/components/General/DescriptionList/DescriptionList';
import RewardsDetails, {
  CARD_TERMS_REWARDS,
} from './CardTerms/Rewards/RewardsDetails';
import { ERROR_FRAGMENT } from '@core/graphql/globalFragments';
import {
  CARD_TERMS_PURCHASE_APR,
  PurchaseAprs,
} from './CardTerms/PurchaseAprs/PurchaseAprs';
import { isErrorResult } from '@core/utils/isErrorResult';
import EarlySpendBonusDetail, {
  CARD_TERMS_EARLY_SPEND_BONUS,
} from './CardTerms/EarlySpendBonus/EarlySpendBonus';
import CreditProtection from './CreditProtection';
import { ErrorBoundary } from '@core/services/Error/Error';

export const ACCOUNT_SUMMARY_CARD_TERMS_FRAGMENT = gql`
  fragment AccountSummaryCardTerms on Account {
    annualFee
    ...CardTermsEarlySpendBonus
    ...CardTermsRewards
    ...CardTermsPurchaseApr
  }
  ${ERROR_FRAGMENT}
  ${CARD_TERMS_EARLY_SPEND_BONUS}
  ${CARD_TERMS_PURCHASE_APR}
  ${CARD_TERMS_REWARDS}
`;

interface Props {
  annualFee?: AccountSummaryCardTermsFragment['annualFee'];
  promotions?: AccountSummaryCardTermsFragment['promotions'];
  purchaseAprData?: AccountSummaryCardTermsFragment['purchaseAprData'];
  rewards?: CardTermsRewards.Rewards;
}

const AccountDetailsCardTerms = ({
  annualFee,
  purchaseAprData,
  promotions,
  rewards,
}: Props) => {
  const introductoryPurchaseApr =
    !isErrorResult(promotions) ?
      promotions?.introductoryPurchaseApr
    : undefined;
  const earlySpendBonus =
    !isErrorResult(promotions) ? promotions?.earlySpendBonus : undefined;

  return (
    <AccountDetailsPanel title="Card terms">
      <ErrorBoundary fallback={FallbackUI}>
        <DescriptionList>
          {earlySpendBonus && (
            <EarlySpendBonusDetail earlySpendBonus={earlySpendBonus} />
          )}
          <DescriptionList.Item
            label="Annual fee"
            value={getAccountSummaryValue(annualFee)}
          />
          <PurchaseAprs
            purchaseAprData={purchaseAprData}
            introductoryPurchaseApr={introductoryPurchaseApr}
          />
          {rewards && <RewardsDetails rewards={rewards} />}
          <CreditProtection />
        </DescriptionList>
      </ErrorBoundary>
    </AccountDetailsPanel>
  );
};

export default AccountDetailsCardTerms;
