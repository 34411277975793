import { useFlags } from 'launchdarkly-react-client-sdk';
import SupportPhoneLink from '@core/components/General/SupportPhoneLink';

const PaymentOutageMessage = () => {
  const { showPaymentsOutage } = useFlags();

  if (showPaymentsOutage) {
    return (
      <div className="bg-yellow pv2 ph3 mt2">
        <p>
          We are having trouble accepting immediate payments on our website.
          Please schedule a payment for the future, try again in a bit, or call
          us at call us at <SupportPhoneLink className="green" /> to pay now.
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export default PaymentOutageMessage;
