import dayjs from 'dayjs';

export const getDueDateFromNextStatementCloseDate = (
  nextStatementCloseDate: string,
) => {
  const cycleDate = dayjs(nextStatementCloseDate);
  const cycleDay = cycleDate.date();
  const dueDay = cycleDay - 3;

  if (dueDay > 0) {
    return cycleDate.date(dueDay);
  } else {
    return cycleDate.date(28 + dueDay).subtract(1, 'month');
  }
};
