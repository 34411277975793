import dayjs from 'dayjs';
import { Notification, P3 } from '@missionlane/compass-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface PaymentMessageProps {
  cardLast4: string | null;
  isImmediatePayment: boolean;
  paymentDate?: string | null;
}

const SameDayPaymentMessage = (): JSX.Element => (
  <>
    <FontAwesomeIcon icon={faExclamationTriangle} color="#fdca06" />{' '}
    <strong>
      You won’t be able to cancel this payment once you submit it,
    </strong>{' '}
    so make sure you review the above information before you move on.
  </>
);

const ScheduledPaymentMessage = ({
  paymentDate,
}: {
  paymentDate?: string | null;
}): JSX.Element => (
  <strong>
    You can edit or cancel this payment until
    <br />
    11:59 p.m. ET on{' '}
    {paymentDate ?
      dayjs(paymentDate).subtract(1, 'days').format('dddd, MM/DD/YY')
    : 'the day before the payment date'}
    .
  </strong>
);

/**New design for payment message, the previous one is still used on other pages
 * and might be removed once the pages they are used on are updated with compass components
 */

const PaymentMessage = ({
  cardLast4,
  isImmediatePayment,
  paymentDate,
}: PaymentMessageProps) => {
  const getMessage = () => {
    if (isImmediatePayment) {
      return 'You won’t be able to cancel this payment once you submit it, so make sure you review the above information before you move on.';
    }
    return `You can edit or cancel this payment until 11:59 p.m. ET on ${
      paymentDate ?
        dayjs(paymentDate).subtract(1, 'days').format('MMMM DD, YYYY')
      : 'the day before the payment date'
    }.`;
  };

  return (
    <div>
      <Notification level="warning">{getMessage()}</Notification>
      <div className="mt5">
        <P3>
          By submitting this payment, you are authorizing this one-time ACH
          electronic debit toward payment of your credit card
          {cardLast4 && ` ending in ${cardLast4}`}.
        </P3>
      </div>
    </div>
  );
};

export { PaymentMessage, SameDayPaymentMessage, ScheduledPaymentMessage };
