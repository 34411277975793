import React from 'react';
import cx from 'classnames';
import './BankCheck.css';

const BankCheck = ({
  account = '000000000',
  routing = '000000000',
  bankName = 'Your Bank',
  editRouting = true,
  editAccount = false,
  className,
}) => (
  <div className={cx('bank-check mb4 bg-mint-green-20', className)}>
    <div className="bank-check-fade" />
    <div className="bank-check-boarders">
      <div className="bank-check-bank-name sans-serif ink">
        {bankName.toLowerCase().replace(/\b[a-z]/g, (l) => l.toUpperCase())}
      </div>
      <div
        className={cx(
          'bank-check-line',
          editAccount && 'bank-check-account-edit',
          editRouting && 'bank-check-routing-edit',
        )}
      />
      <div className="bank-check-numbers ink-70">
        <span className={cx('bank-check-routing ocra', editRouting && 'ink')}>
          {routing}
        </span>
        <span
          className={cx(
            'bank-check-account-routing mh2 fs-hide ocra',
            editAccount && 'ink',
          )}
        >
          {account}
        </span>
        <span
          className={cx(
            'bank-check-account ocra',
            account && account.length > 9 && 'o-0',
          )}
        >
          1234
        </span>
      </div>
    </div>
  </div>
);

export default BankCheck;
