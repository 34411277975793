import { getAccountSummaryValue } from './AccountDetailsAccountSummary/AccountDetailsAccountSummary';
import FallbackUI from '@core/components/GenericFallbacks/SectionFallbackUI';
import AccountDetailsPanel from './AccountDetailsAccountSummary/AccountDetailsPanel';
import { gql } from '@apollo/client';
import { TrackService } from '../../services';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import {
  AccountSummaryCashAdvance,
  AccountSummaryCashAdvanceFragment,
  CashAdvance,
} from '@core/graphql/globalTypes';
import EditLink from './EditLink/EditLink';
import { DescriptionList } from '@core/components/General/DescriptionList/DescriptionList';
import { isErrorResult } from '@core/utils/isErrorResult';
import { Unavailable } from './Unavailable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ErrorBoundary } from '@core/services/Error/Error';

const getCashAdvanceAprValue = (
  apr: CashAdvance['apr'] | undefined,
  aprType: CashAdvance['aprType'] | undefined,
) => {
  // If we have no apr then we can assume the whole query failed because
  // we get all of this data from the same data source
  if (!apr) return <Unavailable />;
  // The aprType is null when its not a type we want to display
  if (aprType) return `${apr} ${aprType}`;
  return apr;
};

export const ACCOUNT_SUMMARY_CASH_ADVANCE_FRAGMENT = gql`
  fragment AccountSummaryCashAdvance on Account {
    cashAdvance {
      ... on CashAdvance {
        __typename
        apr
        aprType
        creditLimit
      }
      ...ErrorFragment
    }
    cashAdvanceFeeExempt
    statuses {
      canModifyPin
    }
    balanceInfo {
      cashAdvanceAvailableCreditLimit
    }
  }
`;

interface AccountSummaryCashAdvancesProps {
  canModifyPin: boolean;
  isBankrupt?: boolean;
  cashAdvanceAvailableCreditLimit?: AccountSummaryCashAdvance.BalanceInfo['cashAdvanceAvailableCreditLimit'];
  cashAdvance?: AccountSummaryCashAdvanceFragment['cashAdvance'];
  cashAdvanceFeeExempt: AccountSummaryCashAdvanceFragment['cashAdvanceFeeExempt'];
}

const AccountDetailsCashAdvances = ({
  canModifyPin,
  cashAdvanceAvailableCreditLimit,
  cashAdvance,
  cashAdvanceFeeExempt,
}: AccountSummaryCashAdvancesProps) => {
  const { showModifyPin } = useFlags();
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const cashAdvanceFields =
    !isErrorResult(cashAdvance) ? cashAdvance : undefined;
  const { apr, aprType, creditLimit } = cashAdvanceFields || {};

  const showModifyPinLine = showModifyPin && canModifyPin;

  const handleEditPinClick = () => {
    TrackService.click('Account details: Change PIN', customerAndAccountIds);
  };

  return (
    <AccountDetailsPanel title="Cash advances">
      <ErrorBoundary fallback={FallbackUI}>
        <DescriptionList>
          {showModifyPinLine && (
            <DescriptionList.Item
              label="Change PIN (for cash advances)"
              value={
                <EditLink
                  testID="ChangePINLink"
                  to="../modify-pin"
                  onClick={handleEditPinClick}
                />
              }
            />
          )}
          <DescriptionList.Item
            label="Available cash advance"
            value={getAccountSummaryValue(cashAdvanceAvailableCreditLimit)}
          />
          <DescriptionList.Item
            label="Cash advance limit"
            value={getAccountSummaryValue(creditLimit)}
          />
          <DescriptionList.Item
            label="Cash advance APR"
            value={getCashAdvanceAprValue(apr, aprType)}
          />
          {!cashAdvanceFeeExempt && (
            <DescriptionList.Item
              label="Cash advance fee"
              value="Please refer to your Cardholder Agreement for more detail"
            />
          )}
        </DescriptionList>
      </ErrorBoundary>
    </AccountDetailsPanel>
  );
};

export default AccountDetailsCashAdvances;
