import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

// Statement close date is returned from LDC as the PT time stored in UTC (e.g.
// 4/1/2018 12 AM PT is returned as 4/1/2018 12 AM UTC rather than 3/31/2018 4 PM UTC).
// Because of this, just comparing the exported strings (thus removing
// timezone issues) is the easiest way to deal with these dates.
const isPastDate = (date: dayjs.ConfigType, dateTimestamp: string) => {
  const format = 'YYYYMMDDHHmm';
  const deadline = dayjs
    .utc(dateTimestamp)
    .hour(17)
    .minute(0)
    .second(0)
    .format(format);
  return dayjs(date).format(format) >= deadline;
};

export default isPastDate;
