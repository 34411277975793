import { Colors } from '@missionlane/compass-ui';
import { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import './AccordianItem.css';
import ResponsiveTypography from '../ResponsiveTypography';

export interface AccordianItemProps {
  title: string;
  children: React.ReactNode;
}
const AccordianItem = ({ title, children }: AccordianItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="bb pv3 b--ink-10 bw1">
      <div className="flex flex-row justify-between">
        <ResponsiveTypography type="HEADER" mobileLevel="H4" desktopLevel="H5">
          {title}
        </ResponsiveTypography>
        <button
          className={cx('icon-button', 'accordian-item-icon-button')}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon
            icon={isOpen ? faMinus : faPlus}
            size="sm"
            color={Colors.blue}
          />
        </button>
      </div>
      <div className={cx('mt2', { dn: !isOpen })}>{children}</div>
    </div>
  );
};

export default AccordianItem;
