import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { centsToDollars } from '@core/utils/centsToDollars';
import { H4, Link, B } from '@missionlane/compass-ui';
import dayjs from 'dayjs';

interface ManageUpcomingPaymentProps {
  amount: number;
  date: string;
  onEdit?: () => void;
}

const ManageUpcomingPayment = ({
  amount,
  date,
  onEdit,
}: ManageUpcomingPaymentProps) => {
  return (
    <div
      className="bg-haze-lightest pa3 pt2 flex flex-column br3"
      data-testid="ManageUpcomingPayment"
    >
      <div className="flex flex-row items-end">
        <H4>Upcoming Payment</H4>
        {onEdit && (
          <div className="ml3 pb">
            <Link onPress={onEdit} style={{ textDecorationLine: 'none' }}>
              <ResponsiveTypography
                type="PARAGRAPH"
                mobileLevel="P1"
                desktopLevel="P3"
                color="blue"
              >
                <B>Edit</B>
              </ResponsiveTypography>
            </Link>
          </div>
        )}
      </div>
      <div className="mb2 mt3-ns mt2">
        <ResponsiveTypography type="HEADER" mobileLevel="H1" desktopLevel="H2">
          {centsToDollars(amount)}
        </ResponsiveTypography>
      </div>
      <div>
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          Scheduled for {dayjs(date).format('MMMM D, YYYY')}
        </ResponsiveTypography>
      </div>
    </div>
  );
};

export default ManageUpcomingPayment;
