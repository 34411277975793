import { H3, Notification } from '@missionlane/compass-ui';
import { ResponsiveKard } from '@core/components/General/ResponsiveKard/ResponsiveKard';
import PaymentDisplay from './PaymentDisplay';
import { PaymentsSuccessButtons } from './PaymentsSuccessButtons';
import { FundingAccounts } from '@core/graphql/globalTypes';
import { PaymentMethodName } from '../Pay/Pay';

interface PaymentSuccessKard {
  amount: number | undefined;
  className?: string;
  confirmationNumber: string | null | undefined;
  fundingAccount?: FundingAccounts.FundingAccounts | null;
  isImmediate?: boolean;
  isPaymentSuccess?: boolean;
  paymentDate?: string | null;
  paymentMethod?: PaymentMethodName;
}

const PaymentSuccessKard = ({
  amount,
  confirmationNumber,
  fundingAccount,
  isImmediate,
  paymentDate,
  paymentMethod,
}: PaymentSuccessKard) => (
  <div className="flex justify-center">
    <ResponsiveKard className="w-75-l bg-white">
      <div className="ph3 ph0-l">
        <H3>You're all set!</H3>
        <PaymentDisplay
          amount={amount}
          fundingAccount={fundingAccount}
          isImmediate={isImmediate}
          isPaymentSuccess={true}
          paymentDate={paymentDate}
          paymentMethod={paymentMethod}
          className="mv5"
        />
        <Notification level="success">
          Thanks for your payment! We've sent you an email which includes your
          confirmation number {confirmationNumber} and payment details.
        </Notification>
        <PaymentsSuccessButtons
          isDebitCardPayment={paymentMethod === PaymentMethodName.DEBIT_CARD}
        />
      </div>
    </ResponsiveKard>
  </div>
);

export default PaymentSuccessKard;
