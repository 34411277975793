import { B, P3 } from '@missionlane/compass-ui';
import HorizontalLine from './HorizontalLine';

const UpdateInformationLink = () => (
  <div className="p3">
    <P3
      onPress={() =>
        window.open(
          `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/sections/360004679173-Accessing-and-updating-your-account-information`,
        )
      }
      color="blue"
    >
      <B>Questions about updating your profile info? Check out this article</B>
    </P3>
    <HorizontalLine />
  </div>
);

export default UpdateInformationLink;
