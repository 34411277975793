import { gql } from '@apollo/client';

export const FUNDING_ACCOUNT_FIELDS = gql`
  fragment FundingAccountFields on FundingAccount {
    balances {
      balance
    }
    bankName
    id
    isDefault
    numberLast4
    validationMethod
    routingNumber
  }
`;

/**
 * Fetch all ACH funding accounts for an account
 */
export const ACH_FUNDING_ACCOUNTS_QUERY = gql`
  query FundingAccounts {
    fundingAccounts(type: ACH) {
      ...FundingAccountFields
    }
  }
  ${FUNDING_ACCOUNT_FIELDS}
`;
