import { Icon, Link as CompassLink } from '@missionlane/compass-ui';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

type BaseLinkProps = Pick<LinkProps, 'to' | 'onClick'>;

export interface EditLinkProps extends BaseLinkProps {
  label?: string;
  disabled?: boolean;
  testID?: string;
}

const EditLink = ({
  label,
  to,
  onClick,
  disabled,
  testID = 'EditLink',
}: EditLinkProps) => (
  <Link
    className={classNames(
      'no-underline',
      label && 'flex justify-between items-baseline pv2',
      disabled && 'grey-lightest',
    )}
    aria-label={label || 'Edit'}
    to={disabled ? {} : to}
    onClick={disabled ? undefined : onClick}
  >
    <CompassLink testID={testID}>
      {label ? `${label} ` : ''}
      <Icon name="edit" color="blue" />
    </CompassLink>
  </Link>
);

export default EditLink;
