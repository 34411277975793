import { Notification } from '@missionlane/compass-ui';
import ExternalLink from '@core/components/General/ExternalLink';
import { CONTACT_US_LINK } from '@core/utils/constants';
import PhoneLink from '@core/components/General/PhoneLink';
import { cardSupportPhone } from '@core/utils/contact';

const ChangePhoneError = () => (
  <div className="mt4">
    <Notification level="error" variant="inline">
      We couldn't validate the number you entered. To update your phone number,
      please contact us{' '}
      <ExternalLink
        to={CONTACT_US_LINK}
        className="inline-link"
        tracking={{
          name: 'Change Phone Enter Phone: Message Us',
        }}
      >
        here
      </ExternalLink>{' '}
      or call us at{' '}
      <PhoneLink
        className="inline-link"
        phoneNumber={cardSupportPhone}
        tracking={{
          name: 'Change Phone Enter Phone: Call Us',
        }}
      />{' '}
      Monday through Friday from 9am to 8pm EST.
    </Notification>
  </div>
);

export default ChangePhoneError;
