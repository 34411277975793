import { useState } from 'react';
import { TrackService } from '@core/services';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import PageWrapper from '@core/components/Page/PageWrapper';
import { Temp_MulticardBreadcrumbs } from '@core/components/General/Breadcrumbs/temp_MulticardBreadcrumbs';
import { HomeBreadcrumb } from '@core/components/General/Breadcrumbs/common';
import { useIncomeUpdate } from '@clip/IncomeUpdate/hooks';
import { IncomeUpdateTrackingNames } from '@clip/IncomeUpdate/lib';
import {
  IncomeUpdateForm,
  IncomeUpdateError,
  SuccessAnimation,
} from '../../components';
import { H4, P3, H2, P1 } from '@missionlane/compass-ui';
import houseCoins from '../../assets/houseCoins.svg';
import { FormButtonContainer } from './FormButtonContainer';
import { Navigate, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import './EditFinancialInformation.css';
import TrackingLink from '@core/components/General/TrackingLink';
import OfferDetailsModal from '@clip/IncomeUpdate/components/OfferDetailsModal';

// TODO: move other copy to here
export const COPY = {
  LEARN_MORE_LINK: 'Learn more',
  LEARN_MORE_LINK_TEXT: 'about credit limit increases.',
};

const breadcrumbs = [
  HomeBreadcrumb,
  {
    text: 'My profile',
    link: '../my-profile',
  },
  {
    text: 'Edit financial information',
  },
];

/**
 * getTitleText this function determines what title text to display based on whether or not
 * the customer has a pie offer.
 *
 * @param {boolean} hasPie - whether or not a customer has a pie offer
 * @returns {string} - the appropriate title for their account state
 */
export const getTitleText = (hasPie: boolean) => {
  if (hasPie) {
    return 'Update Your Financial Information';
  }

  return 'Confirm Your Financial Info';
};

/**
 * getDescriptionText this function determines what description text to display based on whether or not
 * the customer has a pie offer.
 *
 * @param {boolean} hasPie - whether or not the cusotmer has a pie offer
 * @returns {string} - the appropriate description for their account state
 */
export const getDescriptionText = (hasPie: boolean) => {
  if (hasPie) {
    return 'To consider you for a credit limit increase, we need your latest financial information.';
  }

  return "Even if nothing's changed, we need your latest info to consider you for future limit increases.";
};

/**
 * EditFinancialInformation - Renders the Edit Financial Information page under the /update-income route
 * Only displays when showUpdateIncome and showClipIncomeUpdateV2
 * @returns {React.ReactNode}
 */
export const EditFinancialInformation = () => {
  const [offerOpen, setOfferOpen] = useState(false);
  const { showUpdateIncome } = useFlags();

  const {
    customerQueryData,
    customerQueryError,
    customerQueryLoading,
    updateSuccess,
    updateError,
    pieAmount,
    onSuccess,
    onError,
  } = useIncomeUpdate({
    trackingName: IncomeUpdateTrackingNames.ProfileIncome,
    enabled: showUpdateIncome,
  });

  const navigate = useNavigate();

  const getLearnMoreLink = (hasPie: boolean) => {
    if (!hasPie) return null;

    return (
      <>
        {' '}
        <TrackingLink
          trackingName="Pie Offer Modal Open"
          onClick={() => setOfferOpen(true)}
          responsive
        >
          {COPY.LEARN_MORE_LINK}
        </TrackingLink>{' '}
        <span>{COPY.LEARN_MORE_LINK_TEXT}</span>
      </>
    );
  };

  if (!showUpdateIncome || customerQueryError) {
    return <GenericFallbackFull />;
  }

  if (customerQueryLoading) {
    return (
      <PageWrapper
        greyBackground
        pageTitle={{ primaryText: 'Edit financial information' }}
      >
        <LoadingSpinner />
      </PageWrapper>
    );
  }

  if (!customerQueryData) {
    return <GenericFallbackFull />;
  }

  if (
    !customerQueryData.customer.financialInfo?.eligibleForUpdate &&
    !updateSuccess // in case we are still on success page after refetch and eligibleForUpdate is now false
  ) {
    return <Navigate to=".." />;
  }

  const hasPie = !!pieAmount;

  return (
    <PageWrapper
      greyBackground
      pageTitle={{ primaryText: 'Edit financial information' }}
    >
      <Temp_MulticardBreadcrumbs items={breadcrumbs} />
      <div
        className={cx(
          `bg-white br2 shadow-2 flex flex-column flex-row-l edit-financial-info--desktop-container ${
            updateSuccess ? 'justify-center' : ''
          }`,
        )}
      >
        {updateSuccess ?
          <div className="edit-financial-info--success-animation-container flex self-center">
            <SuccessAnimation />
          </div>
        : <>
            <div className="bg-washed-green pt6 pb5 pr5 pl5 flex justify-center-m edit-financial-info--desktop-image-container">
              <img className="mt2 mr1 ml1 mb5" src={houseCoins} alt="Income" />
            </div>
            <div className="flex flex-column pt5-ns pl5-ns pb5-ns pr5-ns pa4">
              {updateError ?
                <IncomeUpdateError />
              : null}
              {updateError || updateSuccess ? null : (
                <div>
                  <div className="mb3 dn db-m db-l">
                    <H4>{getTitleText(hasPie)}</H4>
                  </div>
                  <div className="mb2 dn db-m db-l">
                    <P3>
                      <span>{getDescriptionText(hasPie)}</span>
                      <span>{getLearnMoreLink(hasPie)}</span>
                    </P3>
                  </div>
                  <div className="mb3 dn-ns">
                    <H2>{getTitleText(hasPie)}</H2>
                  </div>
                  <div className="mb2 dn-ns">
                    <P1>
                      <span>{getDescriptionText(hasPie)}</span>
                      <span>{getLearnMoreLink(hasPie)}</span>
                    </P1>
                  </div>
                  <div className="bg-washed-green justify-center mt3 edit-financial-info--mobile-image-container">
                    <img
                      className="edit-financial-info--mobile-image"
                      src={houseCoins}
                      alt="Income"
                    />
                  </div>
                  <IncomeUpdateForm
                    annualIncome={
                      customerQueryData.customer.financialInfo?.annualIncome
                    }
                    monthlyLiving={
                      customerQueryData.customer.financialInfo?.monthlyLiving
                    }
                    renderFormButtonContainer={FormButtonContainer}
                    onSuccess={() => {
                      onSuccess().then(() => {
                        navigate(-1);
                      });
                    }}
                    onError={onError}
                    hasPie={hasPie}
                    layout="column"
                  />
                </div>
              )}
            </div>
          </>
        }
        {hasPie && (
          <OfferDetailsModal
            isOpen={offerOpen}
            onClose={() => {
              TrackService.click('Pie Offer Modal Close');
              setOfferOpen(false);
            }}
          />
        )}
      </div>
    </PageWrapper>
  );
};
