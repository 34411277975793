import { useNavigate } from 'react-router-dom';
import { AccountSummaryCard } from '@core/components/AccountSummaryCard/AccountSummaryCard';
import { GetCreditCardsSectionData } from '@core/graphql/globalTypes';
import AccountPendingCard from './AccountPendingCard/AccountPendingCard';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLFlags } from 'flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import HomeCreditCardTile from './HomeCreditCardTile';

export interface CreditCardsListProps {
  showPendingCreditCard: boolean;
  creditCards?: GetCreditCardsSectionData.CreditCards[];
}

export const CreditCardsList = ({
  showPendingCreditCard,
  creditCards = [],
}: CreditCardsListProps) => {
  const { trackClick } = useTracking();
  const navigate = useNavigate();
  const { enableAccountSummaryCardArt } = useFlags<MLFlags>();
  const { isChargedOff } = usePaymentStatus();

  /**
   * When the card is locked, a lock icon button is displayed to
   *  navigate the user to the Card Lock feature
   */
  const onClickLock = (id: string) => {
    trackClick({
      name: 'Account Summary Card: Lock icon',
      feature: 'Account Selection',
    });
    navigate(`/account/${id}/manage-card/lock-card`);
  };

  const onClickCard = (id: string) => {
    trackClick({
      name: 'Account Selection: Account Card',
      feature: 'Account Selection',
    });
    navigate(`/account/${id}/summary`);
  };

  const displayCardArt =
    enableAccountSummaryCardArt &&
    creditCards.filter((card) => Boolean(card.cardArtUrl)).length ===
      creditCards.length;

  return (
    <div style={{ width: '100%' }}>
      {showPendingCreditCard && <AccountPendingCard className="mv4" />}

      {creditCards.map(
        ({
          id,
          availableCredit,
          currentBalance,
          cardArtUrl,
          cardLast4,
          name,
          securedCardInfo,
          cardLockStatus,
        }) => {
          return (
            <div key={id} className="mv4">
              {displayCardArt && cardArtUrl ?
                <HomeCreditCardTile
                  availableCredit={availableCredit}
                  cardName={name}
                  cardArtUrl={cardArtUrl}
                  cardLast4={cardLast4}
                  currentBalance={currentBalance}
                  securityDeposit={securedCardInfo?.currentAmount?.value}
                  isLocked={Boolean(cardLockStatus?.isLocked)}
                  onUnlock={() => onClickLock(id)}
                  onClick={() => onClickCard(id)}
                />
              : <AccountSummaryCard
                  isChargedOff={isChargedOff}
                  variant="navigationArrow"
                  availableCredit={availableCredit}
                  cardName={name}
                  cardLast4={cardLast4}
                  currentBalance={currentBalance}
                  securityDeposit={securedCardInfo?.currentAmount?.value}
                  isLocked={Boolean(cardLockStatus?.isLocked)}
                  onUnlock={() => onClickLock(id)}
                  onClick={() => onClickCard(id)}
                />
              }
            </div>
          );
        },
      )}
    </div>
  );
};
