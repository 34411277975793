import { useState } from 'react';
import { Button } from '@missionlane/compass-ui';
import OfferAnswerInput, {
  AnswerInputType,
  ExtendedAnswerInputTypes,
  OfferAnswerProps,
} from './OfferAnswerInput';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import KardWrapper from '@core/components/General/Wrappers/KardWrapper';
import { CreateOfferFlowQuestionType } from '@core/graphql/globalTypes';

interface BaseOfferQuestionProps {
  questionText: string;
  onAnswerSubmitted?: (answer: string) => void;
}

interface OfferQuestionSelectInput extends BaseOfferQuestionProps {
  inputType: CreateOfferFlowQuestionType.Select;
  selectOptions: OfferAnswerProps['options'];
  minStartDaysFromNow?: never;
  unavailableDates?: never;
}

interface OfferQuestionDateInput extends BaseOfferQuestionProps {
  inputType: ExtendedAnswerInputTypes.Date;
  selectOptions?: never;
  minStartDaysFromNow?: number;
  unavailableDates?: Date[];
}

interface OfferQuestionOtherInput extends BaseOfferQuestionProps {
  inputType: Exclude<AnswerInputType, 'SELECT' | ExtendedAnswerInputTypes.Date>;
  selectOptions?: never;
  minStartDaysFromNow?: never;
  unavailableDates?: never;
}

export type OfferQuestionProps =
  | OfferQuestionSelectInput
  | OfferQuestionDateInput
  | OfferQuestionOtherInput;

const OfferQuestion = ({
  questionText,
  inputType,
  onAnswerSubmitted,
  selectOptions,
  minStartDaysFromNow,
  unavailableDates,
}: OfferQuestionProps) => {
  if (!questionText || !inputType) {
    return null;
  }
  const [answer, setAnswer] = useState<string>('');
  const [error, setError] = useState(false);
  const { isMobile } = useUserDevice();

  return (
    <KardWrapper
      withKard={!isMobile}
      className="flex flex-column mb6 w-100 h-100-ns vh-50 justify-between"
    >
      <div>
        <div className="mb3">
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H2"
            desktopLevel="H3"
          >
            {questionText}
          </ResponsiveTypography>
        </div>
        <div className="w-50-ns mv5">
          <OfferAnswerInput
            value={answer}
            onChange={setAnswer}
            options={selectOptions}
            type={inputType}
            name={questionText}
            minStartDaysFromNow={minStartDaysFromNow}
            unavailableDates={unavailableDates}
            error={error}
            setError={(e) => setError(e)}
          />
        </div>
      </div>
      <div className="mt1 w-50-ns">
        <Button
          onPress={() => onAnswerSubmitted?.(answer)}
          disabled={!answer || error}
          text="Continue"
        />
      </div>
    </KardWrapper>
  );
};

export default OfferQuestion;
