import { B, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

interface BaseBreadcrumbLinkProps {
  text: string;
}

interface BreadcrumbNavProps extends BaseBreadcrumbLinkProps {
  link: string;
  onClickOverride?: never;
}

interface BreadcrumbOverrideClickPros extends BaseBreadcrumbLinkProps {
  link?: never;
  onClickOverride: () => void;
}

type BreadcrumbLinkProps = BreadcrumbNavProps | BreadcrumbOverrideClickPros;

export const BreadcrumbLink = ({
  link,
  text,
  onClickOverride,
}: BreadcrumbLinkProps) => {
  const navigate = useNavigate();

  function handleClick() {
    link && navigate(link);
  }

  return (
    <Link
      style={{ textDecorationLine: 'none' }}
      onPress={onClickOverride || handleClick}
    >
      <B>{text}</B>
    </Link>
  );
};
