import { gql } from '@apollo/client';

export const ACCOUNT_INFORMATION_QUERY = gql`
  query AccountInformation($accountId: String!) {
    accountInformation(accountId: $accountId) {
      availableCredit
      currentBalance
    }
  }
`;
