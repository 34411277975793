import { useRef, useEffect } from 'react';
/**
 * @description A hook that returns a function that takes a callback and a timeout in ms.
 * It will clear the timeout if the component is unmounted to avoid memory leaks.
 * @returns {function} A function that takes a callback and a timeout in ms.
 * @example
 * const timeout = useTimeout();
 * timeout(() => console.log('Hello'), 1000);
 */
const useTimeout = () => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(
    () => () => {
      // Clear timeout if the component is unmounted
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  return (callback: () => void, ms: number) => {
    timeoutRef.current = setTimeout(callback, ms);

    return timeoutRef.current;
  };
};

export default useTimeout;
