import { gql } from '@apollo/client';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { CustomerIO, MLError, TrackService } from '@core/services';
import { CardActivationFraudDashboard } from './Dashboard/OnboardingDashboards';
import {
  AccountSummaryPageQuery,
  IssuanceState,
  IssuanceType,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useEffect } from 'react';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  getAccountSummaryPageTitle,
  getNewCardDashboardComponent,
  getReplacementCardDashboardComponent,
} from './helpers';
import { AccountBreadcrumbs } from '../Account/AccountBreadcrumbs';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import ChargedOffDashboard from '../ChargedOff/Dashboards/ChargedOffDashboard';
import { EarlySpendBonusBanner } from './EarlySpendBonusBanner';
import {
  activeSettlementOfferStatuses,
  filterOffersByStatus,
} from '../ChargedOff/utils';
import OnboardingDashboard from './Dashboard/OnboardingDashboard';
import CreditProtectionModal from '../CreditProtection/CreditProtectionModal';

export const ACCOUNT_SUMMARY_PAGE_QUERY = gql`
  query AccountSummaryPage($accountId: String!) {
    customer {
      id
      accounts {
        id
      }
      contactInfo {
        email
        firstName
      }
    }
    account(accountId: $accountId) {
      id
      statuses {
        hasAppFraud
        isChargedOff
        isBankrupt
      }
      cardDetails {
        last4
        issuanceType
        issuanceState
      }
    }
    accountDetails(accountId: $accountId) {
      id
      name
    }
    offers(accountId: $accountId) {
      createdAt
      offerType
      offerId
      updatedAt
      data {
        state
        expiration
        durationMonths
        acceptanceExpiration
        canceledReason
        acceptedAt
        subType
        atpUnderSIF
        originalSettlementBalance
        originalOutstandingBalance
        remainingBalance
        fundingAccountId
        paymentPlan {
          id
          authorizationText
          source
          transactions {
            amount
            date
          }
          customerId
        }
        pastPaymentPlanIds
        payments {
          amount
          txId
          type
          date
        }
      }
    }
  }
`;

const AccountSummaryPage = () => {
  const {
    showNewOnboardingExperience,
    showHcrExperience,
    enableCustomerIdNotifications,
  } = useFlags();
  const { loading, data } = useAccountIdQuery<AccountSummaryPageQuery>(
    ACCOUNT_SUMMARY_PAGE_QUERY,
    { errorPolicy: 'all' },
  );
  const { customer, account, accountDetails } = data || {};
  const { id: customerId, contactInfo, accounts } = customer || {};
  const { statuses, cardDetails, id: accountId } = account || {};
  const { issuanceType, issuanceState } = cardDetails || {};
  const { name } = accountDetails || {};
  useEffect(() => {
    if (customerId) {
      TrackService.identify(customerId, contactInfo?.email);
      // TODO: Remove this check once CIO moves to customerId based flow.
      // At the moment we need to check for accountID as CIO has a bug in which they take the following parameter in their api call if not id is provided
      if (accountId) {
        CustomerIO.identify({
          id: enableCustomerIdNotifications ? customerId : accountId,
          email: contactInfo?.email,
        });
      }
      TrackService.page('Account Summary', {
        customerId,
        accountId,
      });
    }
  }, [customerId]);

  // notify MLError if there's a new IssuanceType that is not handled here
  useEffect(() => {
    if (
      issuanceType &&
      ![IssuanceType.New, IssuanceType.Replacement].includes(issuanceType)
    ) {
      MLError.report({
        name: `Card issuanceType ${issuanceType} is not handled in Dashboard`,
      });
    }
  }, [issuanceType]);

  // notify MLError if there's a new IssuanceState that is not handled here
  useEffect(() => {
    if (
      issuanceState &&
      ![
        IssuanceState.PreMailed,
        IssuanceState.Mailed,
        IssuanceState.Delayed,
        IssuanceState.Returned,
        IssuanceState.Activated,
        IssuanceState.Canceled,
        IssuanceState.PreExpired,
      ].includes(issuanceState)
    ) {
      MLError.report({
        name: `Card issuanceState ${issuanceState} is not handled in Dashboard`,
      });
    }
  }, [issuanceState]);

  const hasMultipleAccounts = accounts && accounts.length > 1;
  const customerFirstName = contactInfo?.firstName;

  const accountPageTitle = useAccountPageTitle(name || 'Visa');
  const pageTitle = getAccountSummaryPageTitle(
    !hasMultipleAccounts,
    customerFirstName,
    accountPageTitle,
  );

  let DashboardComponent: React.FunctionComponent;
  const hasActiveOffer =
    filterOffersByStatus(data?.offers, activeSettlementOfferStatuses).length >
    0;

  if (loading) {
    DashboardComponent = LoadingSpinner;
  } else if (
    (showHcrExperience || hasActiveOffer) &&
    statuses?.isChargedOff &&
    !statuses?.isBankrupt
  ) {
    //Once settlements is released to 100% we can remove (showHcrExperience || hasActiveOffer) and just check if customer is charged off
    DashboardComponent = ChargedOffDashboard;
  } else if (statuses?.hasAppFraud) {
    DashboardComponent = CardActivationFraudDashboard;
  } else if (issuanceType === IssuanceType.New) {
    // DQ Onboarding project will be doing A/B testing, the two experiences for new accounts split here
    if (
      showNewOnboardingExperience &&
      accountId &&
      issuanceState !== IssuanceState.Activated
    ) {
      TrackService.trackEvent('Viewed New Account Onboarding Dashboard', {
        feature: 'New Account Activation A/B Testing',
        user: { ecsAccountId: accountId, ecsCustomerId: customerId },
      });
      DashboardComponent = OnboardingDashboard;
    } else {
      DashboardComponent = getNewCardDashboardComponent(issuanceState);
    }
  } else if (issuanceType === IssuanceType.Replacement) {
    DashboardComponent = getReplacementCardDashboardComponent(issuanceState);
  } else {
    DashboardComponent = GenericFallbackFull;
  }

  return (
    <PageWrapper
      pageTitle={loading ? undefined : pageTitle}
      greyBackground
      banners={[EarlySpendBonusBanner]}
    >
      {hasMultipleAccounts && <AccountBreadcrumbs />}
      <DashboardComponent />
      <CreditProtectionModal />
    </PageWrapper>
  );
};

export default AccountSummaryPage;
