import FallbackUI from '@core/components/GenericFallbacks/SectionFallbackUI';
import AccountDetailsPanel from '../AccountDetailsAccountSummary/AccountDetailsPanel';
import { StackedItem } from '../StackedItem/StackedItem';
import { AccountSummaryCardholderInfo } from '@core/graphql/globalTypes';
import AddressStackedItem from './AddressStackedItem/AddressStackedItem';
import PhoneStackedItem from './PhoneStackedItem/PhoneStackedItem';
import { ErrorBoundary } from '@core/services/Error/Error';

interface Props {
  contactInfo?: AccountSummaryCardholderInfo.ContactInfo;
}

const AccountDetailsCardholderInfo = ({ contactInfo }: Props) => {
  const { email, phoneNumber, address } = contactInfo || {};

  return (
    <AccountDetailsPanel title="Cardholder information">
      <ErrorBoundary fallback={FallbackUI}>
        <StackedItem title="Email">{email}</StackedItem>
        <PhoneStackedItem phoneNumber={phoneNumber} />
        <AddressStackedItem address={address} />
      </ErrorBoundary>
    </AccountDetailsPanel>
  );
};

export default AccountDetailsCardholderInfo;
