import { P3, B, Link, Notification } from '@missionlane/compass-ui';
import { centsToDollars } from '@core/utils/centsToDollars';
import { getUTC } from '@core/utils/timezones';

interface PastDueBodyProps {
  message: string;
  printDueDate: string | null | undefined;
  pastDueBalance: number | undefined;
  minimumDue: number | undefined;
  hasPastDueBalancePayment?: boolean;
}

const BucketsBody = ({
  message,
  printDueDate,
  pastDueBalance,
  minimumDue,
  hasPastDueBalancePayment,
}: PastDueBodyProps) => {
  const moreInfoUrl =
    'https://support.missionlane.com/hc/en-us/articles/13754717385875-Past-Due-Payment';
  const formattedDueDate = getUTC(printDueDate).format('MMM D');
  // Subtract minDue from total minimum due which has pastDue amount.
  let outstandingMinDue = 0;

  if (pastDueBalance && minimumDue) {
    outstandingMinDue = minimumDue - pastDueBalance;
  }

  return (
    <div className="mb4">
      <div className="flex flex-wrap items-end justify-between mb4">
        <div className="flex flex-column mb1 w-70 w-50-ns">
          <P3 style={styles.label}>Past Due Amount</P3>
          <P3 color="red" style={styles.label}>
            <B>{centsToDollars(pastDueBalance || 0)}</B>
          </P3>
        </div>
        <div className="flex flex-column mb1 w-30 w-50-ns">
          <P3 style={styles.label}>Due Date</P3>
          <P3 color="red" style={styles.label}>
            <B>Now</B>
          </P3>
        </div>
        <div className="flex flex-column w-70 w-50-ns">
          <P3 style={styles.label}>Minimum Due</P3>
          <P3 color="ink" style={styles.label}>
            <B>{centsToDollars(outstandingMinDue)}</B>
          </P3>
        </div>
        <div className="flex flex-column w-30 w-50-ns">
          <P3 style={styles.label}>Due Date</P3>
          <P3 color="ink" style={styles.label}>
            <B>{formattedDueDate}</B>
          </P3>
        </div>
      </div>
      {!hasPastDueBalancePayment && (
        <Notification variant="inline" level="error">
          {message}&nbsp;
          <Link onPress={() => window.open(moreInfoUrl)}>Get more info</Link>
        </Notification>
      )}
    </div>
  );
};

export default BucketsBody;

const styles = {
  label: {
    margin: 0,
  },
};
