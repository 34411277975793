import { AccountSummaryCardholderInfo } from '@core/graphql/globalTypes';
import AddressChangeButton from './AddressChangeButton/AddressChangeButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import './AddressStackedItem.css';
import { B, P3 } from '@missionlane/compass-ui';
import toTitleCase from '@core/utils/toTitleCase';

export interface AddressStackedItemProps {
  address: AccountSummaryCardholderInfo.Address | undefined;
}

function AddressStackedItem({ address }: AddressStackedItemProps) {
  const { showChangeAddress } = useFlags();
  const { street1, street2, city, state, postalCode } = address || {};
  if (!street1 || !city || !state || !postalCode) return null;

  let addressString = toTitleCase(street1);

  if (street2) {
    addressString += ` ${toTitleCase(street2)}`;
  }

  addressString += ` ${toTitleCase(city)}, ${state} ${postalCode}`;

  return (
    <div className="flex flex-column">
      <P3 color="ink">
        <B>Address</B>
      </P3>

      <div className="flex justify-between items-baseline">
        <div className="address-text-container">
          <P3>{addressString}</P3>
        </div>
        {showChangeAddress && <AddressChangeButton />}
      </div>
    </div>
  );
}

export default AddressStackedItem;
