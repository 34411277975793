import { Icon, P3 } from '@missionlane/compass-ui';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';

const NeverActiveWarning = () => {
  const { neverActive } = useClipOffer();

  if (!neverActive) {
    return null;
  }

  return (
    <div className="flex items-center mb3">
      <Icon name="alertCircle" color="mustard" size="l" />
      <P3 color="mustard" style={{ margin: 0, marginLeft: 16, maxHeight: 48 }}>
        Don't forget! You'll need to use your card at least once to be eligible
        for a credit limit increase.
      </P3>
    </div>
  );
};

export default NeverActiveWarning;
