import { gql } from '@apollo/client';

export const ERROR_FRAGMENT = gql`
  fragment ErrorFragment on ErrorResult {
    __typename
    error {
      message
      statusCode
    }
  }
`;
