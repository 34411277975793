import { ErrorBoundaryProps } from '@sentry/react';

import type { CustomerRoutesQuery } from '@core/graphql/globalTypes';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';

import * as Sentry from '../Sentry/Sentry';
import type { NotifyArgs } from './types';

export const init = () => Sentry.init();

export const setUser = (
  customer: NonNullable<CustomerRoutesQuery['customer']>,
) => Sentry.setUser(customer);

/**
 *
 * @param error - logged into Sentry message
 * @param name - identification  by Sentry
 * @param prefix - string in front in name that allows Sentry to identify more specific errors
 * @returns NotifiableError - object wih name and a message containing a json stringified serialized error
 */
export const report = (args: NotifyArgs) => Sentry.captureException(args);

export const addBreadcrumb = (message: string, metadata?: Sentry.Metadata) =>
  Sentry.addBreadcrumb({ message }, metadata);

export const ErrorBoundary = ({
  fallback = <GenericFallbackFull />,
  ...props
}: Omit<ErrorBoundaryProps, 'showDialog'>) => {
  return (
    <Sentry.ErrorBoundary
      showDialog={process.env.NODE_ENV !== 'production'}
      fallback={fallback}
      {...props}
    />
  );
};
