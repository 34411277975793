import { useNavigate } from 'react-router-dom';
import { MenuLabel } from './MenuLabel';
import { MenuItem } from './MenuItem';

export const PersonalSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <MenuLabel label="Personal" />
      <MenuItem
        trackingName="Profile drop down: My profile"
        onSelect={() => navigate('/my-profile')}
        icon="user"
      >
        My profile
      </MenuItem>
    </>
  );
};
