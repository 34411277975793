import * as Yup from 'yup';

import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import PageWrapper from '@core/components/Page/PageWrapper';
import PhoneLink from '@core/components/General/PhoneLink';
import { cardSupportPhone } from '@core/utils/contact';
import { Notification, P3, Spacer } from '@missionlane/compass-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { ContactUsMessage } from '../../Login/ContactUsMessage';
import LoginActionButtons from '../../Login/LoginActionButtons';
import LoginContainer from '../../Login/LoginContainer';
import LoginInfoSection from '../../Login/LoginInfoSection';
import SendCodeOptions from '../../Login/SendCodeOptions';
import { MLError, TrackService } from '@core/services';
import { OktaResponseError, OTPFactor } from '../../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  method: OTPFactor;
}

const validationSchema = Yup.object().shape({
  method: Yup.string().required('Please select a delivery method'),
});

export const MfaRequestPage = () => {
  const [error, setError] = useState<OktaResponseError>();

  const { otpCancel, otpRequest, factors } = useRiskAuth();
  const navigate = useNavigate();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      method: 'sms',
    },
  });

  const {
    handleSubmit: hookFormSubmit,
    setValue,
    watch: subscribeToValue,
    formState: { isValid, isSubmitting },
  } = form;

  if (!factors) {
    otpCancel();
  }

  const phoneNumberLastFour = factors?.find(
    (f) => f.factorType === 'sms',
  )?.phoneNumberLastFour;

  const handleSubmit = hookFormSubmit(async ({ method }) => {
    if (!method) return;

    const chosenFactor = factors?.find(
      ({ factorType }) => factorType === method,
    );

    TrackService.track({
      event: 'Risk Auth OTP Request Submit',
      properties: {
        factor: chosenFactor,
      },
    });

    if (!chosenFactor) {
      MLError.report({ name: 'No factor chosen for Risk Auth OTP' });
    } else {
      try {
        const result = await otpRequest(chosenFactor);
        if (result.success) {
          TrackService.track({
            event: 'Risk Auth OTP Request Success',
            properties: {
              factor: chosenFactor,
            },
          });
          navigate('../enter', { replace: true });
        } else {
          TrackService.track({
            event: 'Risk Auth OTP Request Failure',
            properties: {
              factor: chosenFactor,
            },
          });

          throw new Error(result.error || 'Risk Auth OTP Request Failure');
        }
      } catch (e) {
        setError(e as OktaResponseError);
      }
    }
  });

  return (
    <PageWrapper>
      <LoginContainer>
        <P3>
          To keep your account safe, we need to confirm your identity. First,
          we’ll send a security code to your phone number
          {phoneNumberLastFour ?
            <>
              {' '}
              ending in <strong>{phoneNumberLastFour}</strong>.{' '}
            </>
          : <> on file.</>}
        </P3>
        <Spacer size="xm" />
        <FormProvider {...form}>
          <SendCodeOptions
            fieldName="method"
            onChange={(selected) => {
              setValue('method', selected);
            }}
            selected={subscribeToValue('method')}
          />

          {error && (
            <div className="mt4">
              <Notification level="error">
                Sorry there's an issue on our end. Resend your code or{' '}
                <PhoneLink
                  label="give us a call."
                  phoneNumber={cardSupportPhone}
                  hideNumber
                />
              </Notification>
            </div>
          )}

          <Spacer size="m" />
          <LoginActionButtons
            submitButton={{
              onPress: handleSubmit,
              loading: isSubmitting,
              text: 'Send my code',
              disabled: !isValid,
            }}
            onCancel={otpCancel}
          />

          <LoginInfoSection
            headerText="Why am I seeing this?"
            message="For added security, Mission Lane sometimes requires a one-time
              security code to be sent to your phone, so that we can be
              confident that only you can sign in."
          />

          <ContactUsMessage />
        </FormProvider>
      </LoginContainer>
    </PageWrapper>
  );
};
