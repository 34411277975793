import { H3 } from '@missionlane/compass-ui';
import ShowingMoneyInHand from '@core/assets/showingMoneyInHand.svg';
import HourglassWithWings from '@core/assets/hourglassWithWings.svg';
import ConfettiCone from '@core/assets/confettiCone.svg';
import MagnifyingGlassWithX from '@core/assets/magnifyingGlassWithX.svg';
import { CFUStatus } from '@clip/CashFlowUnderwritingExperience/network/types';

interface CFUHeaderProps {
  lineIncreaseAmount: number | null | undefined;
}

const getInitialContent = () => {
  return (
    <div className="cfu-header-container">
      <div className="cfu-header">
        <H3>
          You could get a <span className="green">bonus</span> credit line
          increase!
        </H3>
      </div>
      <img
        src={ShowingMoneyInHand}
        alt="showing money in hand"
        className="cfu-header-img"
      />
    </div>
  );
};

const getInProgressContent = () => {
  return (
    <div className="cfu-header-container">
      <div className="cfu-header">
        <H3>We're reviewing your info now</H3>
      </div>
      <img
        src={HourglassWithWings}
        alt="hourglass with wings"
        className="cfu-header-img"
      />
    </div>
  );
};

const getApprovedContent = (data: CFUHeaderProps) => {
  if (!data?.lineIncreaseAmount) return null;
  return (
    <div className="cfu-header-container">
      <div className="cfu-header">
        <H3>
          You earned a bonus
          <span className="green">{` $${data.lineIncreaseAmount}`}</span>
          &nbsp;line increase!
        </H3>
      </div>
      <img src={ConfettiCone} alt="confetti cone" className="cfu-header-img" />
    </div>
  );
};

const getDeclinedContent = () => {
  return (
    <div className="cfu-header-container">
      <div className="cfu-header">
        <H3>We can't increase your line right now.</H3>
      </div>
      <img
        src={MagnifyingGlassWithX}
        alt="magnifying glass x in center"
        className="cfu-header-img"
      />
    </div>
  );
};

const CFU_HEADER: Record<
  CFUStatus,
  (data: CFUHeaderProps) => JSX.Element | null
> = {
  INITIAL: getInitialContent,
  IN_PROGRESS: getInProgressContent,
  DECLINED: getDeclinedContent,
  APPROVED: getApprovedContent,
};

export const getCfuHeaderRenderer = (status: CFUStatus) => CFU_HEADER[status];
