import { Controller, useFormContext } from 'react-hook-form';
import { Select, SelectProps } from '@missionlane/compass-ui';

const ControlledSelectField = ({
  label,
  error,
  name,
  options,
  placeholder,
  ...selectProps
}: Omit<SelectProps, 'onChange'>) => {
  const { control, getValues } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Select
            {...field}
            {...selectProps}
            options={options}
            onChange={field.onChange}
            name={name}
            placeholder={placeholder}
            value={getValues()[name]}
            label={label}
            error={error}
          />
        );
      }}
    />
  );
};

export default ControlledSelectField;
