import { EventTrackingProperties } from '@core/services/TrackService/useTracking';
import * as Yup from 'yup';

const REQUIRED_YUP_MESSAGE = 'This field cannot be blank.';
const TWO_DIGIT_FORMAT_MESSAGE = 'Please enter 2 digits';
const FOUR_DIGIT_FORMAT_MESSAGE = 'Please enter 4 digits';
const FIVE_DIGIT_FORMAT_MESSAGE = 'Please enter 5 digits';
const VALID_YEAR_MESSAGE = 'Please enter a valid year';
const VALID_MONTH_MESSAGE = 'Please enter a valid month';
const VALID_DAY_MESSAGE = 'Please enter a valid day';

const errorEventTrackingName = 'Activate Card Form Enter';
const errorMessage = 'Error Message';

/**
 * Returns the Yup validation schema for ActivationForm
 *
 * @param trackEvent - trackEvent method from UseTracking() hook; reports a field when it fails validation
 * @returns Yup validation schema
 */
export const activationSchemaValidationGenerator = (
  trackEvent: ({
    eventName,
    name,
    feature,
    error,
  }: EventTrackingProperties) => void,
) =>
  Yup.object().shape({
    last4OfCard: Yup.string()
      .trim()
      .length(4, () => {
        trackEvent({
          eventName: `${errorEventTrackingName} Last 4 of new card ${errorMessage}`,
          name: FOUR_DIGIT_FORMAT_MESSAGE,
        });
        return FOUR_DIGIT_FORMAT_MESSAGE;
      })
      .required(REQUIRED_YUP_MESSAGE),
    last4OfSSN: Yup.string()
      .trim()
      .length(4, () => {
        trackEvent({
          eventName: `${errorEventTrackingName} Last 4 of social security ${errorMessage}`,
          name: FOUR_DIGIT_FORMAT_MESSAGE,
        });
        return FOUR_DIGIT_FORMAT_MESSAGE;
      })
      .required(REQUIRED_YUP_MESSAGE),
    zipCode: Yup.string()
      .trim()
      .length(5, () => {
        trackEvent({
          eventName: `${errorEventTrackingName} Zipcode ${errorMessage}`,
          name: FIVE_DIGIT_FORMAT_MESSAGE,
        });
        return FIVE_DIGIT_FORMAT_MESSAGE;
      })
      .required(REQUIRED_YUP_MESSAGE),
    dob: Yup.object().shape({
      year: Yup.string()
        .trim()
        .length(4, () => {
          trackEvent({
            eventName: `${errorEventTrackingName} DOB - Year ${errorMessage}`,
            name: FOUR_DIGIT_FORMAT_MESSAGE,
          });
          return FOUR_DIGIT_FORMAT_MESSAGE;
        })
        .matches(/^(19|20)\d{2}$/, VALID_YEAR_MESSAGE)
        .required(REQUIRED_YUP_MESSAGE),
      month: Yup.string()
        .trim()
        .length(2, () => {
          trackEvent({
            eventName: `${errorEventTrackingName} DOB - Month ${errorMessage}`,
            name: TWO_DIGIT_FORMAT_MESSAGE,
          });
          return TWO_DIGIT_FORMAT_MESSAGE;
        })
        .matches(/^(0?[1-9]|1[0-2])$/, VALID_MONTH_MESSAGE)
        .required(REQUIRED_YUP_MESSAGE),
      day: Yup.string()
        .trim()
        .length(2, () => {
          trackEvent({
            eventName: `${errorEventTrackingName} DOB - Day ${errorMessage}`,
            name: TWO_DIGIT_FORMAT_MESSAGE,
          });
          return TWO_DIGIT_FORMAT_MESSAGE;
        })
        .matches(/^(0?[1-9]|[12][0-9]|3[01])$/, VALID_DAY_MESSAGE)
        .required(REQUIRED_YUP_MESSAGE),
    }),
  });
