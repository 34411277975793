import * as Yup from 'yup';
import { TrackService } from '@core/services';
import {
  NO_PAYMENT_AMOUNT,
  NO_PAYMENT_BANK,
  NO_PAYMENT_DATE,
} from '@core/utils/constants';
import AutoPayAmount from '../AutoPayAmount';
import autoPaymentDateOptions from '../utils/autoPaymentDateOptions';
import { Form, Formik, FormikErrors } from 'formik';
import { AddUpdateAutopay, PaymentType } from '@core/graphql/globalTypes';
import {
  AutoPayFormPaymentType,
  AutopayFormFlowManagerDataFundingAccount,
} from './types';
import dayjs from 'dayjs';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import { B, H4, Notification, Select } from '@missionlane/compass-ui';
import AccountPicker from '@payments/components/BankAccount/AccountPicker/AccountPicker';
import PaymentFlowCard from '../../Payments/PaymentFlowCard';
import { useAutopay } from '../AutopayContext';
import { BankAccountFlow } from '@payments/components/BankAccount/AddBankAccount/AddAccount';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useNavigate } from 'react-router-dom';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

const buildInitialValues = (
  data: Partial<AddUpdateAutopay.Autopay>,
  fundingAccounts: AutopayFormFlowManagerDataFundingAccount[],
  printDueDate: string | null,
): FormValues => {
  return {
    paymentType: data.paymentType,
    amount: data.amount ? data.amount / 100 : 0,
    dayOfMonth: data.dayOfMonth || parseInt(dayjs(printDueDate).format('D')),
    fundingAccount: data.fundingAccount || fundingAccounts[0],
  };
};

const validationSchema = Yup.object().shape({
  fundingAccount: Yup.object().required(NO_PAYMENT_BANK),
  paymentType: Yup.string().required(NO_PAYMENT_AMOUNT),
  amount: Yup.number().when('paymentType', (paymentType) => {
    if (paymentType === PaymentType.MonthlyFixed) {
      return Yup.number()
        .min(25, 'Your entry must be at least $25.')
        .max(4999.99, 'Your entry cannot be more than $4,999.99.');
    }
    return Yup.number();
  }),
  dayOfMonth: Yup.number().required(NO_PAYMENT_DATE),
});

interface FormValues {
  amount: number;
  fundingAccount: AutopayFormFlowManagerDataFundingAccount;
  paymentType?: AutoPayFormPaymentType;
  dayOfMonth: number;
}

function dueDate(autopayStartDateRange: number[]): string {
  const dueIsDateNextMonth =
    autopayStartDateRange[autopayStartDateRange.length - 1] <
    autopayStartDateRange[0] - 1;

  const date = new Date();

  if (dueIsDateNextMonth) {
    date.setMonth(date.getMonth() + 1);
  }

  return dayjs(date).format('YYYY-MM-DD');
}

const AutoPayForm = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const navigate = useNavigate();
  const {
    autopayFlowType,
    autopayContext,
    setAutopay,
    autopayLoading,
    autopayPageTitle,
    setAutopayFlowType,
  } = useAutopay();

  const {
    autopayStartDateRange = [],
    paymentInfo,
    fundingAccounts,
    autopay,
  } = autopayContext || {};

  if (autopay?.id && autopayFlowType !== 'MODIFY') {
    setAutopayFlowType('MODIFY');
  }

  const breadCrumbItems =
    autopayFlowType === 'MODIFY' ?
      [
        { text: 'Payments', link: '../..' },
        { text: 'Manage Autopay', link: 'manage' },
        { text: 'Modify Your Plan' },
      ]
    : [{ text: 'Payments', link: '..' }, { text: 'Set up Autopay' }];

  const currentFlow =
    autopayFlowType === 'MODIFY' ?
      BankAccountFlow.MODIFY_AUTOPAY
    : BankAccountFlow.SETUP_AUTOPAY;

  const getNextPaymentDate = (dayOfMonth: number) => {
    const currentDate = dayjs();
    return currentDate
      .date(dayOfMonth)
      .add(currentDate.date() < dayOfMonth ? 0 : 1, 'month')
      .format('MMM D, YYYY');
  };

  const dateOptions = autoPaymentDateOptions(
    !paymentInfo?.printDueDate ?
      dueDate(autopayStartDateRange)
    : paymentInfo.printDueDate,
    autopayStartDateRange,
  );

  function onSubmit(values: FormValues) {
    TrackService.click(
      'Set Up Auto Pay: Review Auto Pay',
      customerAndAccountIds,
    );

    setAutopay({
      autopay: {
        ...autopayContext?.autopay,
        amount: values.amount * 100,
        paymentType: values.paymentType,
        dayOfMonth: values.dayOfMonth,
        fundingAccount: values.fundingAccount,
      },
    });
    navigate('confirm');
  }

  const initialValues = buildInitialValues(
    autopayContext?.autopay || {},
    fundingAccounts ?? [],
    !paymentInfo?.printDueDate ?
      dueDate(autopayStartDateRange)
    : paymentInfo.printDueDate,
  );

  const buildErrors = (errors: FormikErrors<FormValues>) => (
    <div className="mv4">
      <Notification variant="inline" level="error">
        {errors.paymentType && <div>{errors.paymentType}</div>}
        {errors.dayOfMonth && <div>{errors.dayOfMonth}</div>}
        {errors.fundingAccount && <div>{errors.fundingAccount as string}</div>}
      </Notification>
    </div>
  );
  const pageHeader =
    autopayFlowType === 'MODIFY' ? 'Modify Your Plan' : 'Set up Autopay';

  if (autopayLoading) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper
      pageTitle={autopayPageTitle}
      greyBackground
      trackingProperties={{
        featureName: 'Payments: Autopay',
        pageName:
          // duplicating this here instead of using pageHeader because we don't want to
          // accidentally change tracking names
          autopayFlowType === 'MODIFY' ? 'Modify Your Plan' : 'Set Up Autopay',
      }}
    >
      <div>
        <AccountBreadcrumbs items={breadCrumbItems} />
        <PaymentFlowCard header={pageHeader}>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
          >
            {({ values, setFieldValue, errors, isValid, handleSubmit }) => {
              const displayErrorPanel =
                errors.paymentType ||
                errors.dayOfMonth ||
                errors.fundingAccount;

              return (
                <Form>
                  <div className="flex-ns flex-column">
                    <div className="mb4">
                      <H4>1. Select a payment amount</H4>
                    </div>
                    <AutoPayAmount
                      onPaymentTypeChange={(id) => {
                        setFieldValue('paymentType', id);
                      }}
                      onAmountChange={(cents) => {
                        setFieldValue(
                          'amount',
                          cents ? cents / 100 : undefined,
                        );
                      }}
                      selected={values.paymentType}
                      amount={values.amount}
                      error={errors.amount}
                    />
                  </div>

                  <div className="flex-ns flex-column">
                    <div className="mv4">
                      <H4>2. Select a payment date</H4>
                    </div>
                    <Select
                      label="Select a date"
                      onChange={(selected: string) =>
                        setFieldValue('dayOfMonth', selected)
                      }
                      options={dateOptions}
                      value={values.dayOfMonth}
                    />
                    <div className="mt4">
                      <Notification variant="inline" level="info">
                        Your first payment date will be on{' '}
                        <B>{getNextPaymentDate(values.dayOfMonth)}.</B>
                      </Notification>
                    </div>
                  </div>
                  <div className="flex-ns flex-column">
                    <div className="mv4">
                      <H4>3. Pay from</H4>
                    </div>
                    <AccountPicker
                      className=""
                      onChange={(acct) => setFieldValue('fundingAccount', acct)}
                      fundingAccounts={fundingAccounts ?? []}
                      selectedId={values.fundingAccount?.id}
                      currentFlow={currentFlow}
                    />
                  </div>

                  {displayErrorPanel && buildErrors(errors)}

                  <PaymentConfirmationButtons
                    onSubmit={handleSubmit}
                    onCancel={() => navigate('..')}
                    disabled={!isValid}
                    text="Review Autopay"
                    leftAlign
                  />
                </Form>
              );
            }}
          </Formik>
        </PaymentFlowCard>
      </div>
    </PageWrapper>
  );
};

export default AutoPayForm;
