import { gql } from '@apollo/client';

export const ENROLL_IN_CREDIT_PROTECTION = gql`
  mutation EnrollInCreditProtection(
    $enrollmentChannel: CreditProtectionEnrollmentChannel!
    $snapshotContent: String!
    $accountId: String!
  ) {
    enrollInCreditProtection(
      enrollmentChannel: $enrollmentChannel
      snapshotContent: $snapshotContent
      accountId: $accountId
    ) {
      success
    }
  }
`;
