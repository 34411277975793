import cx from 'classnames';
import { Icon, P2, Spacing } from '@missionlane/compass-ui';
import { SecurityDepositFooter } from '../AccountSummaryCard/SecurityDepositFooter';
import AccountBalanceContent from '../AccountSummaryCard/AccountBalanceContent';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import isNil from '@core/utils/isNil';
import AlertMessageWithLink from '../AccountSummaryCard/AlertMessageWithLink';

interface HomeCreditCardTileProps {
  onClick?: () => void;
  onUnlock?: () => void;
  cardArtUrl: string;
  cardName: string | null;
  cardLast4: string | null;
  securityDeposit?: number | null;
  isLocked: boolean;
  currentBalance: number | null;
  availableCredit: number | null;
}

const HomeCreditCardTile = ({
  onClick,
  cardArtUrl,
  cardLast4,
  cardName,
  securityDeposit,
  currentBalance,
  availableCredit,
}: HomeCreditCardTileProps) => {
  const { isMobile } = useUserDevice();
  return (
    <div
      className={cx(`bg-white br3 flex shadow-2`, onClick && 'pointer')}
      data-testid="Home Credit Card Tile"
      onClick={onClick}
    >
      <div className="w-100">
        {/* Kard Body */}
        <div className="pa5-ns pa4">
          {isMobile ?
            <MobileContent
              cardArtUrl={cardArtUrl}
              cardLast4={cardLast4}
              cardName={cardName}
              isMobile={isMobile}
              currentBalance={currentBalance}
              availableCredit={availableCredit}
            />
          : <WebContent
              cardArtUrl={cardArtUrl}
              cardLast4={cardLast4}
              cardName={cardName}
              isMobile={isMobile}
              currentBalance={currentBalance}
              availableCredit={availableCredit}
            />
          }
        </div>
        <SecurityDepositFooter
          securityDeposit={securityDeposit}
          hasLargeBottomLeftRadius={!!onClick}
        />
      </div>
      <div
        className={'w2 br br-right-3 flex items-center justify-center bg-pine'}
      >
        <Icon name="forward" color="white" />
      </div>
    </div>
  );
};

export default HomeCreditCardTile;

interface CardImgProps {
  cardArtUrl: string;
  isMobile: boolean;
}

const CardImg = ({ cardArtUrl, isMobile }: CardImgProps) => {
  return (
    <img
      width={isMobile ? 73 : 200}
      height={isMobile ? 55 : 'auto'}
      className={cx(['shadow-2', isMobile ? 'br2' : 'br3'])}
      style={{ marginRight: isMobile ? 12 : Spacing.m }}
      src={cardArtUrl}
    />
  );
};

interface HeaderProps extends CardImgProps {
  cardLast4: string | null;
  cardName?: string | null;
  currentBalance?: number | null;
  availableCredit?: number | null;
}

const WebContent = ({
  cardArtUrl,
  cardLast4,
  cardName,
  isMobile,
  availableCredit,
  currentBalance,
}: HeaderProps) => {
  const canDisplayBalance = !isNil(currentBalance) && !isNil(availableCredit);

  const isGreenCard = cardArtUrl.includes('green');
  return (
    <div className="w-100 flex flex-row">
      <CardImg cardArtUrl={cardArtUrl} isMobile={isMobile} />

      <div className="w-100 flex flex-column justify-between">
        <div>
          <P2 color="ink">
            {cardName?.replace('Mission Lane ', '') ?? 'Visa'}
          </P2>
          <P2 color={isGreenCard ? 'green' : 'ink'}>
            {cardLast4 ? `...${cardLast4}` : ''}
          </P2>
        </div>
        <div>
          {canDisplayBalance ?
            <AccountBalanceContent
              currentBalance={currentBalance}
              availableCredit={availableCredit}
            />
          : <AlertMessageWithLink
              linkText="message us."
              message="We can't load your balance right now. Please try again later or"
              url="https://support.missionlane.com/hc/en-us/requests/new"
            />
          }
        </div>
      </div>
    </div>
  );
};

const MobileContent = ({
  cardArtUrl,
  cardLast4,
  cardName,
  isMobile,
  availableCredit,
  currentBalance,
}: HeaderProps) => {
  const canDisplayBalance = !isNil(currentBalance) && !isNil(availableCredit);

  return (
    <>
      <div
        style={{ marginBottom: Spacing.m }}
        className={cx(['flex flex-row', isMobile && 'items-center'])}
      >
        <CardImg cardArtUrl={cardArtUrl} isMobile={isMobile} />
        <div>
          <P2 color="ink">
            {cardName?.replace('Mission Lane ', '') ?? 'Visa'}
          </P2>
          <P2 color="green">{cardLast4 ? `...${cardLast4}` : ''}</P2>
        </div>
      </div>
      {canDisplayBalance ?
        <AccountBalanceContent
          currentBalance={currentBalance}
          availableCredit={availableCredit}
        />
      : <AlertMessageWithLink
          linkText="message us."
          message="We can't load your balance right now. Please try again later or"
          url="https://support.missionlane.com/hc/en-us/requests/new"
        />
      }
    </>
  );
};
