import { useEffect } from 'react';
import currency from 'currency.js';
import dayjs from 'dayjs';

import { RewardType, SpendBonusStatus } from '@core/graphql/globalTypes';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import Kard from '@core/components/General/Kard/Kard';

import { DismissOfferButton } from '../DismissOfferButton';
import track, { EventNames } from '../track';
import { TEST_ID, COPY } from './consts';
import '../../assets/SpendBonus.css';

export const SpendBonusIncomplete = () => {
  const spendBonusResult = useSpendBonus();

  useEffect(() => {
    track({
      eventName: EventNames.OfferViewed,
      status: SpendBonusStatus.NotCompleted,
      rewardType: RewardType.Clip,
    });
  }, []);

  if (
    spendBonusResult.isEnabled === false ||
    spendBonusResult.spendBonus.spendProgress == null
  ) {
    return null;
  }

  const {
    spendBonus: { dueDate, spendTarget },
  } = spendBonusResult;

  const formattedDueDate = dayjs(dueDate).format('MMMM D');
  const formattedSpendProgress = currency(spendTarget, {
    precision: 0,
  }).format();

  return (
    <Kard
      header={{ textPrimary: COPY.TITLE, level: 'H4', className: 'mb2 mr5' }}
      testID={TEST_ID.SPEND_BONUS__INCOMPLETE}
      className="mb3 w-100 relative"
    >
      <DismissOfferButton />
      <p
        className="spend-bonus__message"
        data-testid={TEST_ID.SPEND_BONUS__INCOMPLETE__MESSAGE__CLIP}
      >
        You didn’t spend {formattedSpendProgress} by {formattedDueDate} before
        your offer expired, but don’t worry! Your account will be regularly
        evaluated for credit limit increases in the future.
      </p>
    </Kard>
  );
};
