import { P3 } from '@missionlane/compass-ui';
import { TrackService } from '@core/services';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import Modal from '@core/components/General/Modal/Modal';

interface SecurityDepositModalProps {
  isModalOpen: boolean;
  setModalClosed(): any;
}

const SecurityDepositModal = (props: SecurityDepositModalProps) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { isModalOpen, setModalClosed } = props;

  if (isModalOpen) {
    TrackService.modal('View Deposit Explanation', customerAndAccountIds);
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        headerText="Security Deposit"
        onClose={setModalClosed}
        primaryButton={{ text: 'Close', onPress: setModalClosed }}
      >
        <P3>
          Your security deposit is held as a collateral for your secured card
          credit line. As you build your credit and get into good standing,
          Mission Lane will return your deposit upon graduation. You may also
          close your account to retrieve your deposit.
        </P3>
      </Modal>
    </>
  );
};

export default SecurityDepositModal;
