import SupportPhoneLink from '@core/components/General/SupportPhoneLink';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { B, Colors, Fonts, H4, Icon, P3 } from '@missionlane/compass-ui';
import Kard from '@core/components/General/Kard/Kard';
import TrackingLink, {
  TrackingLinkProps,
} from '@core/components/General/TrackingLink';
import List from '@core/components/General/List/List';
import { ReactNode } from 'react';

interface ContactHelpLinkProps extends TrackingLinkProps {
  children: ReactNode;
  className?: string;
}

const ContactHelpLink = ({
  children,
  className,
  to,
  trackingName,
  trackingProperties,
}: ContactHelpLinkProps) => (
  <div className={className}>
    <TrackingLink
      to={to}
      trackingName={trackingName}
      trackingProperties={trackingProperties}
      target="_blank"
    >
      <span className="flex justify-between items-center">
        <B style={{ fontFamily: Fonts.semiBold, color: Colors.ink }}>
          {children}
        </B>
        <Icon name="forward" color="blue" />
      </span>
    </TrackingLink>
  </div>
);

const ContactHelpKard = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  return (
    <div className="mt3 mt5-l">
      <H4>How can we help?</H4>
      <Kard className="mt3 mb5">
        <List>
          <ContactHelpLink
            to={`${process.env.REACT_APP_ZENDESK_URL}/hc/en-us`}
            trackingName="Account Summary: Help Center FAQ link"
            trackingProperties={customerAndAccountIds}
          >
            Help Center FAQ
          </ContactHelpLink>
          <ContactHelpLink
            to={`${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/requests/new`}
            trackingName="Account Summary: Send us a message link"
            trackingProperties={customerAndAccountIds}
            target="_blank"
          >
            Send us a message
          </ContactHelpLink>
          <P3>
            <SupportPhoneLink
              className="ink b"
              feature="Help"
              label="Call support:"
              tracking={{
                name: 'Account Summary: Call Support link',
                properties: customerAndAccountIds,
              }}
            >
              <div className="flex ml-auto">
                <Icon name="forward" color="blue" />
              </div>
            </SupportPhoneLink>
          </P3>
        </List>
      </Kard>
    </div>
  );
};

export default ContactHelpKard;
