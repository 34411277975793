import { gql } from '@apollo/client';

export const GET_SECURITY_DEPOSIT_QUERY = gql`
  query GetSecurityDeposit($accountId: String!) {
    account(accountId: $accountId) {
      id
      securityDeposit {
        currentAmount
      }
    }
  }
`;
