import { gql } from '@apollo/client';
import { PropsWithChildren, useState } from 'react';
import { B, Notification } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  GetEsbBannerInfoQuery,
  GetEsbBannerInfoQueryVariables,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { TrackService } from '@core/services';

const INITIAL_BANNER_NAME = 'ESB Banner #1 - Bonus Promotion';
const REMINDER_BANNER_NAME = 'ESB Banner #2 - Bonus Countdown';

export const GET_ESB_BANNER_INFO_QUERY = gql`
  query GetEsbBannerInfo($accountId: String!) {
    account(accountId: $accountId) {
      id
      promotions {
        ... on Promotions {
          __typename
          earlySpendBonus {
            ... on EarlySpendBonus {
              __typename
              cta {
                amountToSpend
                spendByDate
                bonusAmount
                daysUntilExpiration
              }
            }
          }
        }
      }
    }
  }
`;

export const EarlySpendBonusBanner = () => {
  const { showEsbInfoBanner, showEsbReminderBanner } = useFlags();
  const { data, loading } = useAccountIdQuery<
    GetEsbBannerInfoQuery,
    GetEsbBannerInfoQueryVariables
  >(GET_ESB_BANNER_INFO_QUERY);

  if (
    !loading &&
    data?.account?.promotions?.__typename === 'Promotions' &&
    data.account.promotions.earlySpendBonus?.__typename === 'EarlySpendBonus' &&
    data.account.promotions.earlySpendBonus.cta !== null
  ) {
    const { amountToSpend, spendByDate, bonusAmount, daysUntilExpiration } =
      data.account.promotions.earlySpendBonus.cta;

    const trackingProperties = {
      accountId: data.account.id,
      amountToSpend,
      spendByDate,
      bonusAmount,
      daysUntilExpiration,
    };

    if (daysUntilExpiration <= 7) {
      if (!showEsbReminderBanner) return null;

      return (
        <DismissableBanner
          bannerKey={`${REMINDER_BANNER_NAME} - ${data.account.id}`}
          trackingProperties={{
            eventName: REMINDER_BANNER_NAME,
            ...trackingProperties,
          }}
        >
          <B>{daysUntilExpiration} days left!</B> If you haven't already, spend{' '}
          {amountToSpend} on purchases to get your {bonusAmount} early spending
          bonus as a statement credit.
        </DismissableBanner>
      );
    }

    if (showEsbInfoBanner) {
      return (
        <DismissableBanner
          bannerKey={`${INITIAL_BANNER_NAME} - ${data.account.id}`}
          trackingProperties={{
            eventName: INITIAL_BANNER_NAME,
            ...trackingProperties,
          }}
        >
          Spend {amountToSpend} on purchases by {spendByDate} to{' '}
          <B>earn your {bonusAmount} early spending bonus</B>!
        </DismissableBanner>
      );
    }
  }

  return null;
};

interface DismissableBannerProps extends PropsWithChildren {
  bannerKey: string;
  trackingProperties: { eventName: string; [key: string]: any };
}

const DismissableBanner = ({
  bannerKey,
  children,
  trackingProperties,
}: DismissableBannerProps) => {
  const [show, setShow] = useState<boolean>(
    // check if the banner was previously dismissed
    window.localStorage.getItem(bannerKey) !== 'dismissed',
  );

  const onDismiss = () => {
    // store the dismissal state in the browser
    window.localStorage.setItem(bannerKey, 'dismissed');
    setShow(false);
    TrackService.click(
      `${trackingProperties.eventName}: Close`,
      trackingProperties,
    );
  };

  return (
    <Notification
      level="info"
      variant="banner"
      show={show}
      onDismiss={onDismiss}
    >
      {children}
    </Notification>
  );
};
