export const confettiJson = {
  nm: 'newScene',
  ddd: 0,
  h: 800,
  w: 800,
  meta: { g: '@lottiefiles/toolkit-js 0.33.2' },
  layers: [
    {
      ty: 3,
      nm: 'Master',
      sr: 1,
      st: 0,
      op: 600,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [400, 461.6569999999999, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 0 },
      },
      ef: [],
      ind: 1,
    },
    {
      ty: 4,
      nm: 'Star_03',
      sr: 1,
      st: 14,
      op: 90,
      ip: 90,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [231.37, 449.226, 0] },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0, y: 1 },
              s: [0, 0, 100],
              t: 14,
            },
            { s: [100, 100, 100], t: 54 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-72.808, 4.062, 0],
              t: 14,
              ti: [0, 0, 0],
              to: [7.80815124511719, -75.0615844726562, 0],
            },
            { s: [2.192, -72.938, 0], t: 84 },
          ],
        },
        r: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.006, y: 1 },
              s: [-720],
              t: 14,
            },
            { s: [-1149], t: 90 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100],
              t: 64,
            },
            { s: [0], t: 84 },
          ],
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Eyes_Closed',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.571, -9.612],
                    [-27.799, -1.99],
                    [-36.683, 5.428],
                  ],
                },
              },
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.95, -9.308],
                    [-4.935, -1.817],
                    [3.838, 5.731],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 14,
                  },
                  { s: [0], t: 34 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [247.846, 433.746], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Eyes_Open',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.013, -0.202],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.096, -0.1],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.733, -0.323],
                        ],
                      },
                    ],
                    t: 34,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.054, -2.972],
                          [2.972, 0.054],
                          [-0.054, 2.973],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [-0.054, 2.976],
                          [-2.972, -0.054],
                          [0.054, -2.973],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.869, 5.385],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 44,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.054, -2.972],
                          [2.972, 0.054],
                          [-0.054, 2.973],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [-0.054, 2.976],
                          [-2.972, -0.054],
                          [0.054, -2.973],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.869, 5.385],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 54,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 68,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 71,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 74,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 77,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 80,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 83,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 86,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 89,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 92,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 95,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 98,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 101,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 104,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 107,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 110,
                  },
                ],
              },
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.023, -0.016],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [0.014, -0.036],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.014, -0.105],
                        ],
                      },
                    ],
                    t: 34,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.003, -2.973],
                          [2.973, 0.003],
                          [-0.003, 2.973],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [-0.003, 2.976],
                          [-2.974, -0.003],
                          [0.003, -2.973],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [-0.006, 5.385],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 44,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.003, -2.973],
                          [2.973, 0.003],
                          [-0.003, 2.973],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [-0.003, 2.976],
                          [-2.974, -0.003],
                          [0.003, -2.973],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [-0.006, 5.385],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 54,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 68,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 71,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 74,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 77,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 80,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 83,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 86,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 89,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 92,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 95,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 98,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 101,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 104,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 107,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 110,
                  },
                ],
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 14,
                  },
                  { s: [100], t: 34 },
                ],
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [242.997, 431.73], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Open Smile',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.109, -0.906],
                          [13.65, 0.572],
                          [1.781, 12.531],
                          [0, 0],
                          [-24, -0.295],
                        ],
                        o: [
                          [0, 0],
                          [-1.541, 12.793],
                          [-13.007, -0.545],
                          [-0.128, -0.903],
                          [0, 0],
                          [22.624, 0.278],
                        ],
                        v: [
                          [23.024, -8.94],
                          [22.994, -8.863],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-28.716, -8.86],
                          [-2.671, 14.6],
                        ],
                      },
                    ],
                    t: 54,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 17.108],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 65,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 68,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 71,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 74,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 77,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 80,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 83,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 86,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 89,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 92,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 95,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 98,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 101,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 104,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 107,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 110,
                  },
                ],
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 14,
                  },
                  { s: [100], t: 54 },
                ],
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 14,
                  },
                  { s: [100], t: 54 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [233.421, 461.195], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Smile',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-13.602, 0.167],
                    [-0.817, 13.364],
                  ],
                  o: [
                    [1.145, 13.34],
                    [13.603, -0.166],
                    [0, 0],
                  ],
                  v: [
                    [-25.719, -11.551],
                    [0.292, 12.014],
                    [25.719, -12.182],
                  ],
                },
              },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 2,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [55],
                    t: 14,
                  },
                  { s: [100], t: 34 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [45],
                    t: 14,
                  },
                  { s: [0], t: 34 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 14,
                  },
                  { s: [0], t: 54 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [230.43, 463.945], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Body_shader',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [10.322, 1.475],
                    [0, 0],
                    [4.634, 9.335],
                    [0, 0],
                    [3.041, -0.007],
                    [0, 0],
                    [-4.62, -2.413],
                    [0, 0],
                    [1.789, 10.268],
                    [0, 0],
                    [-7.447, 7.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-10.319, -1.474],
                    [0, 0],
                    [-2.319, -4.67],
                    [0, 0],
                    [6.08, -0.016],
                    [0, 0],
                    [9.237, 4.826],
                    [0, 0],
                    [-1.79, -10.269],
                    [0, 0],
                    [7.447, -7.294],
                  ],
                  v: [
                    [30.878, -26.746],
                    [4.089, -30.567],
                    [-23.103, -50.22],
                    [-35.144, -74.456],
                    [-43.549, -81.447],
                    [-43.189, 60.499],
                    [-26.405, 64.095],
                    [-2.419, 76.628],
                    [11.128, 66.733],
                    [6.486, 40.071],
                    [16.773, 8.136],
                    [36.102, -10.803],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [275.242, 440.626], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Body',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-4.636, -9.336],
                    [0, 0],
                    [-10.32, -1.474],
                    [0, 0],
                    [7.448, -7.294],
                    [0, 0],
                    [-1.789, -10.269],
                    [0, 0],
                    [9.237, 4.826],
                    [0, 0],
                    [9.213, -4.873],
                    [0, 0],
                    [-1.735, 10.277],
                    [0, 0],
                    [7.482, 7.258],
                    [0, 0],
                    [-10.314, 1.526],
                    [0, 0],
                    [-4.59, 9.36],
                  ],
                  o: [
                    [4.59, -9.359],
                    [0, 0],
                    [4.634, 9.335],
                    [0, 0],
                    [10.322, 1.475],
                    [0, 0],
                    [-7.446, 7.297],
                    [0, 0],
                    [1.788, 10.269],
                    [0, 0],
                    [-9.237, -4.826],
                    [0, 0],
                    [-9.215, 4.873],
                    [0, 0],
                    [1.736, -10.28],
                    [0, 0],
                    [-7.482, -7.256],
                    [0, 0],
                    [10.315, -1.525],
                    [0, 0],
                  ],
                  v: [
                    [-8.533, -73.371],
                    [8.244, -73.413],
                    [20.285, -49.178],
                    [47.477, -29.525],
                    [74.265, -25.704],
                    [79.489, -9.76],
                    [60.16, 9.178],
                    [49.874, 41.114],
                    [54.515, 67.775],
                    [40.969, 77.671],
                    [16.983, 65.137],
                    [-16.569, 65.22],
                    [-40.491, 77.876],
                    [-54.088, 68.051],
                    [-49.58, 41.366],
                    [-60.029, 9.483],
                    [-79.455, -9.358],
                    [-74.312, -25.327],
                    [-47.543, -29.285],
                    [-20.447, -49.076],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [231.695, 439.583], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 2,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Star_02',
      sr: 1,
      st: 7,
      op: 90,
      ip: 90,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [231.37, 449.226, 0] },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0, y: 1 },
              s: [0, 0, 100],
              t: 7,
            },
            { s: [100, 100, 100], t: 47 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.833, y: 0.833 },
              s: [-55.808, -64.938, 0],
              t: 7,
              ti: [-2, -323.166656494141, 0],
              to: [-20, -147.83332824707, 0],
            },
            { s: [-234.808, 76.062, 0], t: 90 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0.167, y: 0.167 }, i: { x: 0, y: 1 }, s: [-720], t: 7 },
            { s: [-1509], t: 107 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100],
              t: 79,
            },
            { s: [0], t: 89 },
          ],
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Eyes_Closed',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.571, -9.612],
                    [-27.799, -1.99],
                    [-36.683, 5.428],
                  ],
                },
              },
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.95, -9.308],
                    [-4.935, -1.817],
                    [3.838, 5.731],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 7,
                  },
                  { s: [0], t: 27 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [247.846, 433.746], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Eyes_Open',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.013, -0.202],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.096, -0.1],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.733, -0.323],
                        ],
                      },
                    ],
                    t: 27,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.054, -2.972],
                          [2.972, 0.054],
                          [-0.054, 2.973],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [-0.054, 2.976],
                          [-2.972, -0.054],
                          [0.054, -2.973],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.869, 5.385],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 37,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.054, -2.972],
                          [2.972, 0.054],
                          [-0.054, 2.973],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [-0.054, 2.976],
                          [-2.972, -0.054],
                          [0.054, -2.973],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.869, 5.385],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 47,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 55,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 61,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 64,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 67,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 70,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 73,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 76,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 79,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 82,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 85,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 88,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 91,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 94,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 97,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 100,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 103,
                  },
                ],
              },
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.023, -0.016],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [0.014, -0.036],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.014, -0.105],
                        ],
                      },
                    ],
                    t: 27,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.003, -2.973],
                          [2.973, 0.003],
                          [-0.003, 2.973],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [-0.003, 2.976],
                          [-2.974, -0.003],
                          [0.003, -2.973],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [-0.006, 5.385],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 37,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.003, -2.973],
                          [2.973, 0.003],
                          [-0.003, 2.973],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [-0.003, 2.976],
                          [-2.974, -0.003],
                          [0.003, -2.973],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [-0.006, 5.385],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 47,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 55,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 61,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 64,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 67,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 70,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 73,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 76,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 79,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 82,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 85,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 88,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 91,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 94,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 97,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 100,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 103,
                  },
                ],
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 7,
                  },
                  { s: [100], t: 27 },
                ],
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [242.997, 431.73], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Open Smile',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.109, -0.906],
                          [13.65, 0.572],
                          [1.781, 12.531],
                          [0, 0],
                          [-24, -0.295],
                        ],
                        o: [
                          [0, 0],
                          [-1.541, 12.793],
                          [-13.007, -0.545],
                          [-0.128, -0.903],
                          [0, 0],
                          [22.624, 0.278],
                        ],
                        v: [
                          [23.024, -8.94],
                          [22.994, -8.863],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-28.716, -8.86],
                          [-2.671, 14.6],
                        ],
                      },
                    ],
                    t: 47,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 55,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 17.108],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 58,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 61,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 64,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 67,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 70,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 73,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 76,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 79,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 82,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 85,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 88,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 91,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 94,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 97,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 100,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 103,
                  },
                ],
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 7,
                  },
                  { s: [100], t: 47 },
                ],
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 7,
                  },
                  { s: [100], t: 47 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [233.421, 461.195], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Smile',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-13.602, 0.167],
                    [-0.817, 13.364],
                  ],
                  o: [
                    [1.145, 13.34],
                    [13.603, -0.166],
                    [0, 0],
                  ],
                  v: [
                    [-25.719, -11.551],
                    [0.292, 12.014],
                    [25.719, -12.182],
                  ],
                },
              },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 2,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [55],
                    t: 7,
                  },
                  { s: [100], t: 27 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [45],
                    t: 7,
                  },
                  { s: [0], t: 27 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 7,
                  },
                  { s: [0], t: 47 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [230.43, 463.945], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Body_shader',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [10.322, 1.475],
                    [0, 0],
                    [4.634, 9.335],
                    [0, 0],
                    [3.041, -0.007],
                    [0, 0],
                    [-4.62, -2.413],
                    [0, 0],
                    [1.789, 10.268],
                    [0, 0],
                    [-7.447, 7.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-10.319, -1.474],
                    [0, 0],
                    [-2.319, -4.67],
                    [0, 0],
                    [6.08, -0.016],
                    [0, 0],
                    [9.237, 4.826],
                    [0, 0],
                    [-1.79, -10.269],
                    [0, 0],
                    [7.447, -7.294],
                  ],
                  v: [
                    [30.878, -26.746],
                    [4.089, -30.567],
                    [-23.103, -50.22],
                    [-35.144, -74.456],
                    [-43.549, -81.447],
                    [-43.189, 60.499],
                    [-26.405, 64.095],
                    [-2.419, 76.628],
                    [11.128, 66.733],
                    [6.486, 40.071],
                    [16.773, 8.136],
                    [36.102, -10.803],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [275.242, 440.626], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Body',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-4.636, -9.336],
                    [0, 0],
                    [-10.32, -1.474],
                    [0, 0],
                    [7.448, -7.294],
                    [0, 0],
                    [-1.789, -10.269],
                    [0, 0],
                    [9.237, 4.826],
                    [0, 0],
                    [9.213, -4.873],
                    [0, 0],
                    [-1.735, 10.277],
                    [0, 0],
                    [7.482, 7.258],
                    [0, 0],
                    [-10.314, 1.526],
                    [0, 0],
                    [-4.59, 9.36],
                  ],
                  o: [
                    [4.59, -9.359],
                    [0, 0],
                    [4.634, 9.335],
                    [0, 0],
                    [10.322, 1.475],
                    [0, 0],
                    [-7.446, 7.297],
                    [0, 0],
                    [1.788, 10.269],
                    [0, 0],
                    [-9.237, -4.826],
                    [0, 0],
                    [-9.215, 4.873],
                    [0, 0],
                    [1.736, -10.28],
                    [0, 0],
                    [-7.482, -7.256],
                    [0, 0],
                    [10.315, -1.525],
                    [0, 0],
                  ],
                  v: [
                    [-8.533, -73.371],
                    [8.244, -73.413],
                    [20.285, -49.178],
                    [47.477, -29.525],
                    [74.265, -25.704],
                    [79.489, -9.76],
                    [60.16, 9.178],
                    [49.874, 41.114],
                    [54.515, 67.775],
                    [40.969, 77.671],
                    [16.983, 65.137],
                    [-16.569, 65.22],
                    [-40.491, 77.876],
                    [-54.088, 68.051],
                    [-49.58, 41.366],
                    [-60.029, 9.483],
                    [-79.455, -9.358],
                    [-74.312, -25.327],
                    [-47.543, -29.285],
                    [-20.447, -49.076],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [231.695, 439.583], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 3,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Star_01',
      sr: 1,
      st: 0,
      op: 90,
      ip: 90,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [231.37, 449.226, 0] },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0, y: 1 },
              s: [0, 0, 100],
              t: 0,
            },
            { s: [100, 100, 100], t: 40 },
          ],
        },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.833, y: 0.833 },
              s: [31.192, -0.938, 0],
              t: 0,
              ti: [-7, -196.16667175293, 0],
              to: [27, -133.83332824707, 0],
            },
            { s: [223.192, 87.062, 0], t: 90 },
          ],
        },
        r: {
          a: 1,
          k: [
            { o: { x: 0.167, y: 0.167 }, i: { x: 0, y: 1 }, s: [-720], t: 0 },
            { s: [60], t: 90 },
          ],
        },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100],
              t: 66,
            },
            { s: [0], t: 75 },
          ],
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Eyes_Closed',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-36.571, -9.612],
                    [-27.799, -1.99],
                    [-36.683, 5.428],
                  ],
                },
              },
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.95, -9.308],
                    [-4.935, -1.817],
                    [3.838, 5.731],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  { s: [0], t: 20 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [247.846, 433.746], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Eyes_Open',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.013, -0.202],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.096, -0.1],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.733, -0.323],
                        ],
                      },
                    ],
                    t: 20,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.054, -2.972],
                          [2.972, 0.054],
                          [-0.054, 2.973],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [-0.054, 2.976],
                          [-2.972, -0.054],
                          [0.054, -2.973],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.869, 5.385],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 30,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.054, -2.972],
                          [2.972, 0.054],
                          [-0.054, 2.973],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [-0.054, 2.976],
                          [-2.972, -0.054],
                          [0.054, -2.973],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.869, 5.385],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 40,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 48,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 54,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 57,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 63,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 66,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 69,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 72,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 75,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 78,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 81,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 84,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 87,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.769, -0.053],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 93,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0.049, -2.972],
                          [2.972, 0.054],
                          [0.096, -0.006],
                          [-2.976, -0.054],
                        ],
                        o: [
                          [0.013, -0.015],
                          [-2.972, -0.054],
                          [0.083, -2.972],
                          [2.971, 0.054],
                        ],
                        v: [
                          [-18.385, 0.097],
                          [-23.644, -0.884],
                          [-29.155, -0.099],
                          [-23.671, -5.385],
                        ],
                      },
                    ],
                    t: 96,
                  },
                ],
              },
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.023, -0.016],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [0.014, -0.036],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.014, -0.105],
                        ],
                      },
                    ],
                    t: 20,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.003, -2.973],
                          [2.973, 0.003],
                          [-0.003, 2.973],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [-0.003, 2.976],
                          [-2.974, -0.003],
                          [0.003, -2.973],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [-0.006, 5.385],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 30,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.003, -2.973],
                          [2.973, 0.003],
                          [-0.003, 2.973],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [-0.003, 2.976],
                          [-2.974, -0.003],
                          [0.003, -2.973],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [-0.006, 5.385],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 40,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 48,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 54,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 57,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 63,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 66,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 69,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 72,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 75,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 78,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 81,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 84,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 87,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.02, -0.021],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 93,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0.116, -2.97],
                          [2.973, 0.003],
                          [0.014, 0.057],
                          [-2.976, -0.003],
                        ],
                        o: [
                          [0.054, 0.078],
                          [-2.974, -0.003],
                          [-0.236, -3.255],
                          [2.971, 0.003],
                        ],
                        v: [
                          [5.386, 0.004],
                          [0.145, -0.852],
                          [-5.386, -0.006],
                          [0.007, -5.386],
                        ],
                      },
                    ],
                    t: 96,
                  },
                ],
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 0,
                  },
                  { s: [100], t: 20 },
                ],
              },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [242.997, 431.73], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Open Smile',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.109, -0.906],
                          [13.65, 0.572],
                          [1.781, 12.531],
                          [0, 0],
                          [-24, -0.295],
                        ],
                        o: [
                          [0, 0],
                          [-1.541, 12.793],
                          [-13.007, -0.545],
                          [-0.128, -0.903],
                          [0, 0],
                          [22.624, 0.278],
                        ],
                        v: [
                          [23.024, -8.94],
                          [22.994, -8.863],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-28.716, -8.86],
                          [-2.671, 14.6],
                        ],
                      },
                    ],
                    t: 40,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 48,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 17.108],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 51,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 54,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 57,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 63,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 66,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 69,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 72,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 75,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 78,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 81,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 84,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 87,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.123, -0.904],
                          [13.118, 0.071],
                          [1.781, 12.531],
                          [-0.771, 0.001],
                          [-13.559, -0.008],
                        ],
                        o: [
                          [0.771, -0.001],
                          [-1.708, 12.576],
                          [-13.016, -0.067],
                          [-0.128, -0.903],
                          [0, 0],
                          [13.343, 0.006],
                        ],
                        v: [
                          [21.337, -9.257],
                          [22.625, -7.808],
                          [-2.946, 14.608],
                          [-28.522, -7.528],
                          [-27.263, -9.176],
                          [-2.671, -5.545],
                        ],
                      },
                    ],
                    t: 93,
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0.125, -0.904],
                          [13.019, -0.021],
                          [1.781, 12.531],
                          [-0.913, 0.001],
                          [-11.625, 0.045],
                        ],
                        o: [
                          [0.913, -0.002],
                          [-1.74, 12.536],
                          [-13.018, 0.021],
                          [-0.128, -0.903],
                          [0, 0],
                          [11.625, -0.045],
                        ],
                        v: [
                          [21.024, -9.315],
                          [22.557, -7.613],
                          [-2.946, 17.295],
                          [-28.522, -7.528],
                          [-26.994, -9.235],
                          [-2.671, -9.275],
                        ],
                      },
                    ],
                    t: 96,
                  },
                ],
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 0,
                  },
                  { s: [100], t: 40 },
                ],
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 0,
                  },
                  { s: [100], t: 40 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [233.421, 461.195], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Smile',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-13.602, 0.167],
                    [-0.817, 13.364],
                  ],
                  o: [
                    [1.145, 13.34],
                    [13.603, -0.166],
                    [0, 0],
                  ],
                  v: [
                    [-25.719, -11.551],
                    [0.292, 12.014],
                    [25.719, -12.182],
                  ],
                },
              },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 2,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [55],
                    t: 0,
                  },
                  { s: [100], t: 20 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [45],
                    t: 0,
                  },
                  { s: [0], t: 20 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    h: 1,
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  { s: [0], t: 40 },
                ],
              },
              w: { a: 0, k: 2.641 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [230.43, 463.945], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Body_shader',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [10.322, 1.475],
                    [0, 0],
                    [4.634, 9.335],
                    [0, 0],
                    [3.041, -0.007],
                    [0, 0],
                    [-4.62, -2.413],
                    [0, 0],
                    [1.789, 10.268],
                    [0, 0],
                    [-7.447, 7.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-10.319, -1.474],
                    [0, 0],
                    [-2.319, -4.67],
                    [0, 0],
                    [6.08, -0.016],
                    [0, 0],
                    [9.237, 4.826],
                    [0, 0],
                    [-1.79, -10.269],
                    [0, 0],
                    [7.447, -7.294],
                  ],
                  v: [
                    [30.878, -26.746],
                    [4.089, -30.567],
                    [-23.103, -50.22],
                    [-35.144, -74.456],
                    [-43.549, -81.447],
                    [-43.189, 60.499],
                    [-26.405, 64.095],
                    [-2.419, 76.628],
                    [11.128, 66.733],
                    [6.486, 40.071],
                    [16.773, 8.136],
                    [36.102, -10.803],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [275.242, 440.626], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Body',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-4.636, -9.336],
                    [0, 0],
                    [-10.32, -1.474],
                    [0, 0],
                    [7.448, -7.294],
                    [0, 0],
                    [-1.789, -10.269],
                    [0, 0],
                    [9.237, 4.826],
                    [0, 0],
                    [9.213, -4.873],
                    [0, 0],
                    [-1.735, 10.277],
                    [0, 0],
                    [7.482, 7.258],
                    [0, 0],
                    [-10.314, 1.526],
                    [0, 0],
                    [-4.59, 9.36],
                  ],
                  o: [
                    [4.59, -9.359],
                    [0, 0],
                    [4.634, 9.335],
                    [0, 0],
                    [10.322, 1.475],
                    [0, 0],
                    [-7.446, 7.297],
                    [0, 0],
                    [1.788, 10.269],
                    [0, 0],
                    [-9.237, -4.826],
                    [0, 0],
                    [-9.215, 4.873],
                    [0, 0],
                    [1.736, -10.28],
                    [0, 0],
                    [-7.482, -7.256],
                    [0, 0],
                    [10.315, -1.525],
                    [0, 0],
                  ],
                  v: [
                    [-8.533, -73.371],
                    [8.244, -73.413],
                    [20.285, -49.178],
                    [47.477, -29.525],
                    [74.265, -25.704],
                    [79.489, -9.76],
                    [60.16, 9.178],
                    [49.874, 41.114],
                    [54.515, 67.775],
                    [40.969, 77.671],
                    [16.983, 65.137],
                    [-16.569, 65.22],
                    [-40.491, 77.876],
                    [-54.088, 68.051],
                    [-49.58, 41.366],
                    [-60.029, 9.483],
                    [-79.455, -9.358],
                    [-74.312, -25.327],
                    [-47.543, -29.285],
                    [-20.447, -49.076],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [231.695, 439.583], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 4,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_08',
      sr: 1,
      st: 24,
      op: 450,
      ip: 14,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-3.733, 3.212],
                    [3.516, 3.448],
                    [1.007, -0.109],
                    [-0.831, -1.602],
                    [-1.804, -0.048],
                    [-1.498, 2.82],
                    [1.857, 2.598],
                    [1.894, 4.895],
                    [-2.186, 3.33],
                    [0.394, 3.963],
                    [4.488, 1.512],
                    [0.67, 2.591],
                    [-1.061, 1.667],
                    [3.352, 6.121],
                    [-0.017, 5.733],
                    [-0.824, 5.056],
                    [1.288, 6.795],
                    [2.266, 9.62],
                    [0.775, 2.384],
                    [-2.086, 5.33],
                  ],
                  o: [
                    [4.912, 0.354],
                    [3.734, -3.212],
                    [-0.723, -0.709],
                    [-1.794, 0.194],
                    [0.831, 1.602],
                    [3.192, 0.085],
                    [1.498, -2.82],
                    [-3.052, -4.27],
                    [-1.438, -3.716],
                    [2.186, -3.33],
                    [-0.468, -4.712],
                    [-2.536, -0.855],
                    [-0.495, -1.913],
                    [3.747, -5.888],
                    [-2.754, -5.028],
                    [0.015, -5.123],
                    [1.113, -6.827],
                    [-1.84, -9.71],
                    [-0.575, -2.44],
                    [-1.769, -5.444],
                    [0, 0],
                  ],
                  v: [
                    [19.657, 33.614],
                    [33.618, 30.114],
                    [35.047, 17.054],
                    [32.352, 15.881],
                    [30.503, 20.092],
                    [35.067, 22.494],
                    [42.924, 17.911],
                    [42.323, 8.835],
                    [30.752, -1.506],
                    [33.523, -12.651],
                    [37.839, -23.525],
                    [28.4, -33.073],
                    [22.087, -37.425],
                    [23.799, -42.917],
                    [27.536, -62.474],
                    [17.953, -76.278],
                    [24.534, -89.921],
                    [16.335, -108.508],
                    [24.585, -136.227],
                    [21.753, -143.18],
                    [24.179, -159.818],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 14,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 46.381,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 68.762,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 70,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 103,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 104,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 643,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 644,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 676.381,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 698.762,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 700,
                  },
                  { s: [0], t: 733 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.9412, 0.7608, 0.3373] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 14,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 24,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 68.762,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 70,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 103,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 104,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 643,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 644,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 654,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 698.762,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 700,
                  },
                  { s: [100], t: 733 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 14,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 25,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 67,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 70,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 103,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 104,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 643,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 644,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 655,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 697,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 700,
                  },
                  { s: [100], t: 733 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 5,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_07',
      sr: 1,
      st: 22,
      op: 450,
      ip: 12,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 6',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-3.987, 2.255],
                    [-3.851, -0.605],
                    [-3.81, 0.826],
                    [0.199, 3.894],
                    [-0.057, 1.725],
                    [-3.528, 0.655],
                    [-3.35, 1.286],
                    [-0.385, 4.173],
                    [-1.666, 2.264],
                    [-4.338, 1.184],
                    [0.652, 6.571],
                    [-0.575, 1.771],
                    [-4.258, 0.478],
                    [-2.004, 3.788],
                    [0.798, 2.901],
                    [-1.515, 4.958],
                    [-6.098, 3.216],
                    [-0.018, 8.318],
                    [-2.756, 4.522],
                  ],
                  o: [
                    [-1.536, -4.314],
                    [3.393, -1.919],
                    [3.852, 0.605],
                    [3.811, -0.825],
                    [-0.088, -1.723],
                    [0.118, -3.586],
                    [3.529, -0.654],
                    [3.912, -1.503],
                    [0.258, -2.799],
                    [2.666, -3.621],
                    [6.371, -1.74],
                    [-0.183, -1.853],
                    [1.322, -4.076],
                    [4.258, -0.479],
                    [1.407, -2.659],
                    [-1.374, -4.999],
                    [2.015, -6.594],
                    [7.358, -3.88],
                    [0.011, -5.295],
                    [0, 0],
                  ],
                  v: [
                    [168.47, 147.842],
                    [172.892, 135.992],
                    [184.314, 134.937],
                    [195.915, 135.611],
                    [203.185, 127.697],
                    [202.336, 122.605],
                    [209.757, 115.984],
                    [220.374, 114.415],
                    [227.58, 104.899],
                    [228.797, 96.697],
                    [241.534, 93.832],
                    [249.311, 77.395],
                    [249.402, 71.854],
                    [260.021, 65.997],
                    [271.274, 60.989],
                    [271.241, 52.188],
                    [269.636, 36.947],
                    [284.659, 23.584],
                    [299.994, 5.52],
                    [299.485, -10.017],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 12,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 41.048,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 60.096,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 68,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 101,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 102,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 641,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 642,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 671.048,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 690.096,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 698,
                  },
                  { s: [0], t: 731 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 12,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 22,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 60.096,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 68,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 101,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 102,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 641,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 642,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 652,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 690.096,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 698,
                  },
                  { s: [100], t: 731 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 12,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 23.904,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 68,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 101,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 102,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 641,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 642,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 653.904,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 692,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 698,
                  },
                  { s: [100], t: 731 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 6,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_06',
      sr: 1,
      st: 20,
      op: 450,
      ip: 10,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 7',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-5.281, 4.43],
                    [-0.914, 3.014],
                    [5.89, 1.428],
                    [1.278, -0.706],
                    [-0.398, -2.024],
                    [-1.595, -1.307],
                    [-5.767, 2.717],
                    [-1.596, 6.171],
                    [3.096, 3.922],
                    [3.061, 0.395],
                    [-0.362, -3.113],
                    [-0.844, -0.845],
                    [-5.948, 1.68],
                    [-4.203, 4.531],
                    [-0.402, 6.099],
                    [7.253, 2.417],
                    [1.355, -3.406],
                    [-1.315, -1.81],
                    [-5.511, 1.088],
                    [-3.533, 4.367],
                    [0.195, 7.031],
                    [5.769, 4.024],
                    [2.472, -0.019],
                    [1.072, -2.228],
                    [-1.583, -1.944],
                    [-2.424, -0.639],
                    [-4.257, 7.616],
                    [6.316, 6.022],
                    [2.637, -1.208],
                    [-1.496, -2.496],
                    [-2.893, 0.316],
                    [-2.212, 1.892],
                    [-0.927, 3.55],
                    [2.473, 2.71],
                  ],
                  o: [
                    [6.373, 2.623],
                    [2.413, -2.024],
                    [1.757, -5.8],
                    [-1.419, -0.345],
                    [-1.805, 0.998],
                    [0.397, 2.024],
                    [4.929, 4.043],
                    [5.767, -2.716],
                    [1.252, -4.838],
                    [-1.912, -2.422],
                    [-3.109, -0.402],
                    [0.138, 1.187],
                    [4.367, 4.372],
                    [5.948, -1.679],
                    [4.157, -4.48],
                    [0.503, -7.629],
                    [-3.478, -1.159],
                    [-0.826, 2.079],
                    [3.303, 4.544],
                    [5.511, -1.089],
                    [4.423, -5.47],
                    [-0.195, -7.031],
                    [-2.027, -1.414],
                    [-2.472, 0.019],
                    [-1.087, 2.258],
                    [1.582, 1.944],
                    [8.437, 2.224],
                    [4.257, -7.617],
                    [-2.098, -2.001],
                    [-2.645, 1.213],
                    [1.495, 2.497],
                    [2.892, -0.316],
                    [2.788, -2.385],
                    [0.926, -3.551],
                    [0, 0],
                  ],
                  v: [
                    [155.107, 26.434],
                    [174.284, 22.087],
                    [179.759, 14.55],
                    [171.698, 0.45],
                    [167.431, 0.768],
                    [165.247, 6.125],
                    [168.605, 11.174],
                    [186.637, 13.021],
                    [198.146, -1.482],
                    [195.964, -15.777],
                    [188.107, -20.228],
                    [181.374, -15.649],
                    [183.12, -12.598],
                    [200.404, -8.883],
                    [215.596, -19.099],
                    [223.786, -35.098],
                    [211.8, -52.728],
                    [202.231, -49.982],
                    [203.534, -43.686],
                    [218.597, -38.425],
                    [232.409, -47.474],
                    [239.401, -66.919],
                    [230.087, -85.004],
                    [223.214, -87.454],
                    [217.117, -83.941],
                    [218.253, -76.909],
                    [224.63, -73.166],
                    [247.016, -82.674],
                    [243.387, -106.723],
                    [235.556, -109.098],
                    [233.618, -101.608],
                    [241.18, -98.275],
                    [248.909, -102.043],
                    [254.954, -111.038],
                    [252.759, -121.366],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 10,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 40,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 60.001,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 66,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 99,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 100,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 639,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 640,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 670,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 690.001,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 696,
                  },
                  { s: [0], t: 729 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.1647, 0.7765, 0.7294] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 10,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 20,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 60.001,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 66,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 99,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 100,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 639,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 640,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 650,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 690.001,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 696,
                  },
                  { s: [100], t: 729 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 10,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 22,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 66,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 99,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 100,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 639,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 640,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 652,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 692,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 696,
                  },
                  { s: [100], t: 729 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 7,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_05',
      sr: 1,
      st: 18,
      op: 450,
      ip: 8,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 4',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [4.131, 7.797],
                    [-5.227, 7.109],
                    [-8.766, -1.006],
                    [-2.056, -1.844],
                    [1.011, -2.57],
                    [2.071, -1.017],
                    [11.297, 8.656],
                    [-0.667, 11.76],
                    [-11.746, 0.887],
                    [-2.541, -1.671],
                    [0.36, -3.02],
                    [2.745, -1.724],
                    [12.069, 6.836],
                    [4.344, 10.921],
                    [-2.995, 9.212],
                    [-2.692, 2.073],
                    [-2.84, 0.366],
                    [-3.616, -4.508],
                    [6.115, -4.567],
                    [7.593, 0.777],
                    [7.683, 3.722],
                    [3.783, 5.39],
                    [0.942, 5.093],
                    [-3.631, 7.488],
                    [-8.288, 0.753],
                  ],
                  o: [
                    [-8.772, 0.947],
                    [-4.13, -7.797],
                    [5.226, -7.109],
                    [2.743, 0.315],
                    [2.056, 1.843],
                    [-0.844, 2.146],
                    [-12.773, 6.277],
                    [-9.35, -7.164],
                    [0.667, -11.759],
                    [3.033, -0.229],
                    [2.541, 1.671],
                    [-0.384, 3.219],
                    [-11.744, 7.379],
                    [-10.227, -5.792],
                    [-3.581, -9.002],
                    [1.05, -3.231],
                    [2.268, -1.747],
                    [5.732, -0.739],
                    [4.777, 5.954],
                    [-6.116, 4.567],
                    [-8.493, -0.868],
                    [-5.926, -2.871],
                    [-2.976, -4.239],
                    [-1.513, -8.184],
                    [3.631, -7.489],
                    [0, 0],
                  ],
                  v: [
                    [21.947, 19.676],
                    [-0.004, 7.853],
                    [1.987, -17.173],
                    [25.207, -27.138],
                    [32.799, -24.171],
                    [34.978, -16.7],
                    [29.978, -12.152],
                    [-10.184, -17.613],
                    [-25.547, -47.891],
                    [-3.824, -71.879],
                    [4.928, -69.965],
                    [8.88, -62.255],
                    [3.165, -54.794],
                    [-36.376, -57.061],
                    [-59.814, -82.497],
                    [-60.73, -110.966],
                    [-55.446, -119.498],
                    [-47.461, -122.373],
                    [-31.596, -117.55],
                    [-35.706, -97.459],
                    [-57.634, -93.077],
                    [-82.148, -100.034],
                    [-97.524, -112.094],
                    [-103.116, -126.461],
                    [-100.635, -150.883],
                    [-81.46, -165.113],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 8,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 34.667,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 51.334,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 64,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 97,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 98,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 637,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 638,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 664.667,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 681.334,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 694,
                  },
                  { s: [0], t: 727 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.1647, 0.7765, 0.7294] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 8,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 18,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 51.334,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 64,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 97,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 98,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 637,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 638,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 648,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 681.334,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 694,
                  },
                  { s: [100], t: 727 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 8,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 19.666,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 53,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 64,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 97,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 98,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 637,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 638,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 649.666,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 683,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 694,
                  },
                  { s: [100], t: 727 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 8,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_04',
      sr: 1,
      st: 16,
      op: 450,
      ip: 6,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 3',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-0.913, 5.269],
                    [1.635, 5.641],
                    [2.07, 9.641],
                    [-1.235, 6.159],
                    [3.608, 2.85],
                    [2.872, 6.752],
                    [1.643, 6.444],
                    [3.337, 2.11],
                    [2.014, 7.467],
                    [0.352, 3.318],
                    [3.517, 3.226],
                    [3.39, 3.021],
                    [1.953, 8.137],
                  ],
                  o: [
                    [-4.197, -3.313],
                    [1.002, -5.787],
                    [-2.745, -9.471],
                    [-1.319, -6.142],
                    [0.904, -4.508],
                    [-5.759, -4.546],
                    [-2.603, -6.119],
                    [-0.976, -3.826],
                    [-6.538, -4.133],
                    [-0.869, -3.222],
                    [-0.503, -4.746],
                    [-3.347, -3.069],
                    [-6.248, -5.568],
                    [0, 0],
                  ],
                  v: [
                    [-57.252, 23.762],
                    [-62.715, 9.49],
                    [-58.964, -7.44],
                    [-80.304, -26.391],
                    [-74.597, -44.016],
                    [-79.12, -56.32],
                    [-97.537, -67.369],
                    [-96.248, -86.954],
                    [-103.868, -95.641],
                    [-120.542, -110.88],
                    [-121.281, -120.84],
                    [-127.589, -133.348],
                    [-138.641, -141.284],
                    [-149.101, -163.655],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 6,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 33.144,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 50.286,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 95,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 96,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 635,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 636,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 663.144,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 680.286,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 692,
                  },
                  { s: [0], t: 725 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 6,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 16,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 50.286,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 95,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 96,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 635,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 636,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 646,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 680.286,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 692,
                  },
                  { s: [100], t: 725 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 6,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 17.714,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 52,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 62,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 95,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 96,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 635,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 636,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 647.714,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 682,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 692,
                  },
                  { s: [100], t: 725 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 9,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_03',
      sr: 1,
      st: 14,
      op: 450,
      ip: 4,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 2',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [6.04, 1.773],
                    [1.254, -6.169],
                    [-0.817, -1.005],
                    [-1.139, 2.006],
                    [1.203, 1.968],
                    [4.066, -0.354],
                    [1.495, -3.798],
                    [3.934, -5.435],
                    [5.091, 0.029],
                    [3.978, -3.177],
                    [-1.497, -5.865],
                    [2.314, -2.52],
                    [2.526, -0.02],
                    [4.235, -7.851],
                    [6.161, -3.967],
                    [5.996, -2.631],
                    [6.393, -6.106],
                    [8.746, -9.115],
                    [2.019, -2.488],
                    [7.167, -1.468],
                  ],
                  o: [
                    [-3.035, -5.515],
                    [-6.041, -1.772],
                    [-0.258, 1.269],
                    [1.455, 1.789],
                    [1.14, -2.005],
                    [-2.128, -3.483],
                    [-4.066, 0.354],
                    [-2.458, 6.242],
                    [-2.986, 4.125],
                    [-5.092, -0.03],
                    [-4.729, 3.778],
                    [0.846, 3.314],
                    [-1.708, 1.861],
                    [-8.921, 0.074],
                    [-3.479, 6.449],
                    [-5.505, 3.545],
                    [-8.096, 3.552],
                    [-9.136, 8.724],
                    [-2.218, 2.313],
                    [-4.609, 5.682],
                    [0, 0],
                  ],
                  v: [
                    [-89.377, 35.497],
                    [-102.837, 22.955],
                    [-117.839, 30.501],
                    [-117.223, 34.207],
                    [-111.421, 33.263],
                    [-112.017, 26.697],
                    [-122.395, 21.455],
                    [-131.712, 28.41],
                    [-134.761, 48.01],
                    [-148.642, 52.786],
                    [-163.307, 55.715],
                    [-166.986, 72.477],
                    [-167.266, 82.274],
                    [-174.347, 84.255],
                    [-197.923, 93.843],
                    [-206.068, 113.718],
                    [-225.276, 116.143],
                    [-239.515, 137.858],
                    [-274.982, 148.278],
                    [-280.472, 156.15],
                    [-300.005, 165.113],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 4,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 33.524,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 53.048,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 93,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 94,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 633,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 634,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 663.524,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 683.048,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 690,
                  },
                  { s: [0], t: 723 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 4,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 14,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 53.048,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 93,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 94,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 633,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 634,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 644,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 683.048,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 690,
                  },
                  { s: [100], t: 723 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 4,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 15.952,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 55,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 93,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 94,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 633,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 634,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 645.952,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 685,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 690,
                  },
                  { s: [100], t: 723 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 10,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_02',
      sr: 1,
      st: 12,
      op: 450,
      ip: 2,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 5',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-7.933, 14.672],
                    [0.349, 16.675],
                    [5.473, 5.656],
                    [4.908, 1.164],
                    [4.876, -2.608],
                    [-1.549, -5.308],
                    [-6.55, -0.009],
                    [-1.872, 0.928],
                    [-0.997, 4.469],
                    [2.661, 12.031],
                    [2.233, 3.641],
                    [3.652, 2.818],
                    [8.412, 2.835],
                  ],
                  o: [
                    [11.892, -11.695],
                    [7.932, -14.672],
                    [-0.166, -7.869],
                    [-3.508, -3.625],
                    [-5.381, -1.276],
                    [-4.876, 2.608],
                    [1.834, 6.288],
                    [2.089, 0.003],
                    [4.103, -2.034],
                    [2.684, -12.026],
                    [-0.923, -4.171],
                    [-2.412, -3.933],
                    [-7.029, -5.423],
                    [0, 0],
                  ],
                  v: [
                    [119.191, 30.962],
                    [149.872, -8.329],
                    [162.384, -56.202],
                    [155.159, -78.093],
                    [141.943, -85.036],
                    [125.763, -83.813],
                    [119.055, -70.028],
                    [134.91, -60.892],
                    [141.017, -62.022],
                    [147.861, -73.389],
                    [147.896, -110.061],
                    [143.478, -122.026],
                    [133.96, -131.966],
                    [110.582, -144.469],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 2,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 27.715,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 43.429,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 58,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 91,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 92,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 631,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 632,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 657.715,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 673.429,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 688,
                  },
                  { s: [0], t: 721 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.9412, 0.7569, 0.0745] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 2,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 12,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 43.429,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 58,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 91,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 92,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 631,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 632,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 642,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 673.429,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 688,
                  },
                  { s: [100], t: 721 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 2,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 13.571,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 45,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 58,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 91,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 92,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 631,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 632,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 643.571,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 675,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 688,
                  },
                  { s: [100], t: 721 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 11,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks_01',
      sr: 1,
      st: 10,
      op: 450,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [312.5, 177.5, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-41, -125.553, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [11.69, 6.335],
                    [-0.042, 3.716],
                    [-1.247, 1.266],
                    [-2.612, -1.139],
                    [1.105, -2.627],
                    [5.141, 2.153],
                    [1.829, 8.959],
                    [3.076, 2.136],
                    [3.312, 2.666],
                    [6.996, 6.602],
                    [3.62, 1.755],
                    [1.41, 3.485],
                    [2.977, 4.448],
                    [4.619, 1.026],
                    [4.136, 2.297],
                    [3.748, 5.272],
                    [6.456, 0.411],
                  ],
                  o: [
                    [-11.36, 6.845],
                    [-3.267, -1.77],
                    [0.02, -1.777],
                    [2, -2.029],
                    [2.611, 1.139],
                    [-2.791, 6.636],
                    [-9.286, -3.889],
                    [-0.75, -3.67],
                    [-3.493, -2.425],
                    [-7.686, -6.189],
                    [-2.926, -2.762],
                    [-3.383, -1.641],
                    [-2.008, -4.961],
                    [-2.632, -3.932],
                    [-4.619, -1.026],
                    [-5.656, -3.142],
                    [-3.749, -5.273],
                    [0, 0],
                  ],
                  v: [
                    [-87.104, 119.435],
                    [-125.246, 120.525],
                    [-131.776, 112.185],
                    [-129.676, 107.427],
                    [-121.832, 105.665],
                    [-118.75, 112.73],
                    [-140.586, 115.83],
                    [-152.583, 96.072],
                    [-157.893, 86.484],
                    [-169.572, 81.55],
                    [-177.838, 56.291],
                    [-188.771, 51.385],
                    [-196.302, 43.331],
                    [-200.687, 27.987],
                    [-212.76, 21.213],
                    [-226.362, 17.374],
                    [-239.172, 3.039],
                    [-254.425, -7.871],
                  ],
                },
              },
            },
            {
              ty: 'st',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 10,
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 24.762,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 39.524,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 89,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 629,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 630,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 654.762,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 669.524,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 686,
                  },
                  { s: [0], t: 719 },
                ],
              },
              w: { a: 0, k: 4.829 },
              c: { a: 0, k: [0.9412, 0.7569, 0.0745] },
            },
            {
              ty: 'tm',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Filter - Trim',
              nm: 'Trim Paths 1',
              ix: 3,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 10,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 39.524,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 89,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 629,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 630,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [0],
                    t: 640,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 669.524,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 686,
                  },
                  { s: [100], t: 719 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 11.477,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 41,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 89,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 1 },
                    s: [0],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 629,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 630,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 641.477,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 671,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 686,
                  },
                  { s: [100], t: 719 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [312.078, 177.185], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 12,
      parent: 1,
    },
    {
      ty: 4,
      nm: 'Fireworks',
      sr: 1,
      st: 0,
      op: 450,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [225.631, 140.499, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.833, y: 0.833 },
              s: [7.784, -100.71, 0],
              t: 0,
              ti: [0, -33.3333320617676, 0],
              to: [0, 33.3333320617676, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [7.784, 99.29, 0],
              t: 56,
              ti: [0, 0, 0],
              to: [0, 0, 0],
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.667, y: 1 },
              s: [7.784, 99.29, 0],
              t: 89,
              ti: [0, 33.3333320617676, 0],
              to: [0, -33.3333320617676, 0],
            },
            { s: [7.784, -100.71, 0], t: 90 },
          ],
        },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [100],
              t: 53,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 56,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 90,
            },
            { s: [100], t: 93 },
          ],
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.091, 10.526],
                    [-8.034, 1.938],
                    [-1.091, -10.526],
                    [8.034, -1.938],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.1647, 0.7765, 0.7294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [218.951, 244.775],
                    t: 0,
                    ti: [47.2194023132324, 5.068359375],
                    to: [-47.2194023132324, -5.068359375],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [-64.365, 214.365],
                    t: 50,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [-64.365, 214.365],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [-64.365, 214.365],
                    t: 89,
                    ti: [-47.2194023132324, -5.068359375],
                    to: [47.2194023132324, 5.068359375],
                  },
                  { s: [218.951, 244.775], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [-1996],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 50,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [-1996], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 15.834,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 50,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [10.032, -3.369],
                    [3.65, 7.414],
                    [-10.032, 3.37],
                    [-3.65, -7.414],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9412, 0.7569, 0.0745] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [217.823, 241.687],
                    t: 0,
                    ti: [25.8665370941162, 35.7955741882324],
                    to: [-25.8665370941162, -35.7955741882324],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [62.624, 26.913],
                    t: 33,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [62.624, 26.913],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [62.624, 26.913],
                    t: 89,
                    ti: [-25.8665370941162, -35.7955741882324],
                    to: [25.8665370941162, 35.7955741882324],
                  },
                  { s: [217.823, 241.687], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [1909],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 33,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [1909], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 10.45,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 33,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.439, -9.079],
                    [7.591, 3.266],
                    [-5.438, 9.079],
                    [-7.591, -3.265],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [216.419, 243.707],
                    t: 0,
                    ti: [-15.654296875, 39.6614570617676],
                    to: [15.654296875, -39.6614570617676],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [310.345, 5.738],
                    t: 44,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [310.345, 5.738],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [310.345, 5.738],
                    t: 89,
                    ti: [15.654296875, -39.6614570617676],
                    to: [-15.654296875, 39.6614570617676],
                  },
                  { s: [216.419, 243.707], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [-1082],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 44,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [-1082], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 13.934,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 44,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 4',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [10.032, -3.369],
                    [3.649, 7.414],
                    [-10.032, 3.37],
                    [-3.65, -7.414],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9608, 0.0706, 0.2431] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [220.852, 243.393],
                    t: 0,
                    ti: [-47.1861991882324, 13.3990888595581],
                    to: [47.1861991882324, -13.3990888595581],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [503.969, 162.999],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [503.969, 162.999],
                    t: 89,
                    ti: [47.1861991882324, -13.3990888595581],
                    to: [-47.1861991882324, 13.3990888595581],
                  },
                  { s: [220.852, 243.393], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [-1636],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [-1636], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 17.733,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 5',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.635, -8.958],
                    [7.518, 3.43],
                    [-5.635, 8.958],
                    [-7.518, -3.431],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.1647, 0.7765, 0.7294] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [216.978, 239.574],
                    t: 0,
                    ti: [8.22330760955811, 43.4095039367676],
                    to: [-8.22330760955811, -43.4095039367676],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [167.638, -20.883],
                    t: 34,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [167.638, -20.883],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [167.638, -20.883],
                    t: 89,
                    ti: [-8.22330760955811, -43.4095039367676],
                    to: [8.22330760955811, 43.4095039367676],
                  },
                  { s: [216.978, 239.574], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [791],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 34,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [791], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 10.767,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 34,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 6',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [12.714, -4.27],
                    [4.625, 9.396],
                    [-12.714, 4.27],
                    [-4.625, -9.396],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9412, 0.7569, 0.0745] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [216.807, 247.359],
                    t: 0,
                    ti: [-14.984375, 45.6302070617676],
                    to: [14.984375, -45.6302070617676],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [306.713, -26.422],
                    t: 35,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [306.713, -26.422],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [306.713, -26.422],
                    t: 89,
                    ti: [14.984375, -45.6302070617676],
                    to: [-14.984375, 45.6302070617676],
                  },
                  { s: [216.807, 247.359], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [-1349],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 35,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [-1349], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 11.084,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 35,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 7',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [16.462, -5.529],
                    [5.988, 12.166],
                    [-16.462, 5.529],
                    [-5.99, -12.166],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9412, 0.7608, 0.3373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [220.337, 243.096],
                    t: 0,
                    ti: [-46.0084648132324, 22.9010410308838],
                    to: [46.0084648132324, -22.9010410308838],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [496.388, 105.69],
                    t: 50,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [496.388, 105.69],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [496.388, 105.69],
                    t: 89,
                    ti: [46.0084648132324, -22.9010410308838],
                    to: [-46.0084648132324, 22.9010410308838],
                  },
                  { s: [220.337, 243.096], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [1085],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 50,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [1085], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 15.834,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 50,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 8',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [7.21, -15.799],
                    [12.976, 3.938],
                    [-7.209, 15.799],
                    [-12.976, -3.938],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9412, 0.7608, 0.3373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [216.862, 239.004],
                    t: 0,
                    ti: [-47.6022148132324, 3.48763012886047],
                    to: [47.6022148132324, -3.48763012886047],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [502.475, 218.079],
                    t: 28,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [502.475, 218.079],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [502.475, 218.079],
                    t: 89,
                    ti: [47.6022148132324, -3.48763012886047],
                    to: [-47.6022148132324, 3.48763012886047],
                  },
                  { s: [216.862, 239.004], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [544],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 28,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [544], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 8.867,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 28,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 9',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [11.889, 12.659],
                    [-8.563, 10.515],
                    [-11.889, -12.659],
                    [8.562, -10.516],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9412, 0.7608, 0.3373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [219.276, 237.668],
                    t: 0,
                    ti: [0.17578125, 44.1373710632324],
                    to: [-0.17578125, -44.1373710632324],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [218.221, -27.157],
                    t: 42,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [218.221, -27.157],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [218.221, -27.157],
                    t: 89,
                    ti: [-0.17578125, -44.1373710632324],
                    to: [0.17578125, 44.1373710632324],
                  },
                  { s: [219.276, 237.668], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [-1017],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 42,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [-1017], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 13.301,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 42,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 10',
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [11.889, 12.66],
                    [-8.562, 10.516],
                    [-11.889, -12.66],
                    [8.562, -10.515],
                  ],
                },
              },
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9412, 0.7608, 0.3373] },
              r: 2,
              o: { a: 0, k: 100 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [219.081, 240.186],
                    t: 0,
                    ti: [44.55078125, 41.734375],
                    to: [-44.55078125, -41.734375],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [-48.224, -10.22],
                    t: 25,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [-48.224, -10.22],
                    t: 56,
                    ti: [0, 0],
                    to: [0, 0],
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [-48.224, -10.22],
                    t: 89,
                    ti: [-44.55078125, -41.734375],
                    to: [44.55078125, 41.734375],
                  },
                  { s: [219.081, 240.186], t: 90 },
                ],
                ix: 2,
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0, y: 1 },
                    s: [1408],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 25,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [1408], t: 90 },
                ],
                ix: 6,
              },
              sa: { a: 0, k: 0, ix: 5 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 7.917,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 25,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 56,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 89,
                  },
                  { s: [100], t: 90 },
                ],
                ix: 7,
              },
            },
          ],
        },
      ],
      ind: 13,
      parent: 1,
    },
    {
      ty: 1,
      nm: 'White Solid 1',
      sr: 1,
      st: 0,
      op: 0,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [400, 400, 0] },
        s: { a: 0, k: [100, 100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [400, 398, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      sc: '#00000000',
      sh: 800,
      sw: 800,
      ind: 14,
    },
  ],
  v: '4.5.6',
  fr: 30,
  op: 90,
  ip: 0,
  assets: [],
};
