import { ReactNode } from 'react';

interface LockupProps {
  children: ReactNode;
  id?: string;
}

const Lockup = ({ id, children }: LockupProps) => (
  <div className="pa6 tc" id={id}>
    <div className="ink sans-serif fw4">{children}</div>
  </div>
);

export default Lockup;
