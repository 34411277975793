import { useOktaAuth } from '@okta/okta-react';
import IdleTimer from 'react-idle-timer';
import { CustomerIO, MLError } from '@core/services';
import type { PropsWithChildren } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';

const IDLE_TIMEOUT = 1000 * 60 * 10;

const AppIdleTimer = ({ children }: PropsWithChildren) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { trackPage } = useTracking();

  const onIdle = async () => {
    if (authState?.isAuthenticated) {
      trackPage({ name: 'Idle Logout', feature: 'App' });
      try {
        await oktaAuth.signOut();
        CustomerIO.reset();
      } catch (error: unknown) {
        if (error instanceof Error)
          MLError.report({
            name: error.name,
            error,
          });
      }
    }
  };

  return (
    <div>
      <IdleTimer element={document} onIdle={onIdle} timeout={IDLE_TIMEOUT} />
      {children}
    </div>
  );
};

export default AppIdleTimer;
