import { useCallback, useEffect, useState } from 'react';
import {
  ApolloError,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PlaidLinkError,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link';
import {
  AddPlaidFundingAccountMutation,
  AddPlaidFundingAccountMutationVariables,
  Channel,
  GetExchangePublicTokenQuery,
  GetExchangePublicTokenQueryVariables,
  GetPlaidLinkTokenQuery,
} from '@core/graphql/globalTypes';
import { ADD_PLAID_FUNDING_ACCOUNT_MUTATION } from '../queries';
import AddAccountBanner, {
  AddAccountBannerStatuses,
} from '../AddBankAccount/AddAccountBanner';
import PlaidModal from '@payments/components/Plaid/PlaidModal/PlaidModal';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import { B, BubbleIcon, Icon, P3 } from '@missionlane/compass-ui';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLError } from '@core/services';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAccount } from '@core/components/Auth/AccountContext';
import { OPPORTUNITY_TILE_CAROUSEL_QUERY } from '@core/components/AccountSummaryPage/Dashboard/OpportunityTileCarousel/useOpportunityTileManager';

const redirectUri = process.env.REACT_APP_PLAID_OAUTH;

let accessTokenId = '';
let externalFundingAccountId = '';

export const GET_PLAID_LINK_TOKEN = gql`
  query GetPlaidLinkToken {
    plaidLinkToken(
      accountFilter: "{depository: ['checking']}"
      products: ["AUTH", "ASSETS"]
      businessProduct: "CreditCard"
      customizationName: "account_validation"
      redirectUri: "${redirectUri}"
      subContext: ["FUNDING"]
    ) {
      token
      productRegistration {
        productAccess {
          accessTokenId
        }
      }
    }
  }
`;

export const GET_EXCHANGE_PUBLIC_TOKEN_QUERY = gql`
  query GetExchangePublicToken($accessTokenId: String!, $publicToken: String!) {
    exchangePublicToken(
      accessTokenId: $accessTokenId
      publicToken: $publicToken
    ) {
      accessTokenId
    }
  }
`;

type AddPlaidAccountProps = {
  onConnectAccount?: () => void;
  onOpenModal?: () => void;
};

const AddPlaidAccount = ({
  onConnectAccount,
  onOpenModal,
}: AddPlaidAccountProps) => {
  const { allowActivationFundingAccountFlow } = useFlags();
  const navigate = useNavigate();
  const [bannerStatus, setBannerStatus] = useState<AddAccountBannerStatuses>();
  const [plaidLoading, setPlaidLoading] = useState(false);
  const [showPlaidModal, setShowPlaidModal] = useState(false);
  const location = useLocation();
  const account = useAccount();

  const { trackError } = useTracking();

  const { data: plaidLinkTokenData } =
    useQuery<GetPlaidLinkTokenQuery>(GET_PLAID_LINK_TOKEN);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      externalFundingAccountId = metadata.accounts[0].id;
      if (
        plaidLinkTokenData?.plaidLinkToken.productRegistration.productAccess[0]
          .accessTokenId
      ) {
        accessTokenId =
          plaidLinkTokenData.plaidLinkToken.productRegistration.productAccess[0]
            .accessTokenId;
        getAccessToken({
          variables: {
            publicToken: public_token,
            accessTokenId,
          },
        });
      } else {
        displayErrorBanner("Access token id doesn't exist");
      }
    },
    [plaidLinkTokenData],
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      MLError.report({
        name: 'PlaidOnExit',
        message: JSON.stringify(metadata),
      });
      setPlaidLoading(false);
      if (error && error.error_code === 'INVALID_LINK_TOKEN') {
        setBannerStatus(AddAccountBannerStatuses.ERROR);
      }
    },
    [],
  );

  const { open: openPlaid } = usePlaidLink({
    token: plaidLinkTokenData?.plaidLinkToken.token || '',
    onSuccess,
    onExit,
  });

  const [getAccessToken] = useLazyQuery<
    GetExchangePublicTokenQuery,
    GetExchangePublicTokenQueryVariables
  >(GET_EXCHANGE_PUBLIC_TOKEN_QUERY, {
    onCompleted: async (accessTokenData) => {
      if (accessTokenData.exchangePublicToken) {
        try {
          onConnectAccount && onConnectAccount();

          await addFundingAccount({
            variables: {
              externalFundingAccountId,
              accessTokenId,
              channel: Channel.Web,
            },
          });
        } catch (error: unknown) {
          let errorMessage = 'Failed to add plaid funding account';
          // we already notify MLError of ApolloErrors in apolloClient
          if (error instanceof Error && !(error instanceof ApolloError)) {
            MLError.report({
              name: error.name,
              error,
            });
            errorMessage = error.message;
          }
          displayErrorBanner(errorMessage);
        }
      } else {
        displayErrorBanner('Plaid exchange public token does not exist');
      }
    },
    onError: () => {
      displayErrorBanner('Failed to exchange public token');
    },
  });

  const [addFundingAccount] = useMutation<
    AddPlaidFundingAccountMutation,
    AddPlaidFundingAccountMutationVariables
  >(ADD_PLAID_FUNDING_ACCOUNT_MUTATION, {
    onCompleted: (addAccountResponse) => {
      setShowPlaidModal(false);
      setTimeout(() => {
        setPlaidLoading(false);
      }, 1000);
      if (allowActivationFundingAccountFlow) {
        const { numberLast4, bankName } =
          addAccountResponse?.linkFundingAccount || {};
        navigate('./success', {
          state: {
            numberLast4,
            bankName,
          },
        });
      } else {
        setBannerStatus(AddAccountBannerStatuses.SUCCESS);
      }
    },
    onError: () => {
      displayErrorBanner('Failed to add plaid funding account');
    },
    refetchQueries: [
      { query: ACH_FUNDING_ACCOUNTS_QUERY },
      { query: GET_PLAID_LINK_TOKEN },
      {
        query: OPPORTUNITY_TILE_CAROUSEL_QUERY,
        variables: { accountId: account.accountId },
      },
    ],
  });

  function closeBanner() {
    setBannerStatus(undefined);
  }

  function displayErrorBanner(error: string) {
    setShowPlaidModal(false);
    //Adding a timeout prevents the user from seeing the modal changing from loading to the initial state.
    setTimeout(() => {
      setPlaidLoading(false);
    }, 1000);
    setBannerStatus(AddAccountBannerStatuses.ERROR);
    trackError({
      name: 'Failed to link plaid account',
      feature: 'Change Funding - Account Added via Plaid',
      error: {
        code: 'PAY0005',
        message: error,
        name: 'Failed to link plaid account',
      },
    });
  }

  function launchPlaid() {
    if (plaidLinkTokenData?.plaidLinkToken.token) {
      setPlaidLoading(true);
      openPlaid();
    } else {
      displayErrorBanner('Token is missing');
    }
  }

  function openPlaidModal() {
    onOpenModal && onOpenModal();
    setBannerStatus(undefined);
    setShowPlaidModal(true);
  }

  useEffect(() => {
    if (plaidLinkTokenData) {
      sessionStorage.setItem(
        'plaidLinkTokenData',
        JSON.stringify(plaidLinkTokenData),
      );
      sessionStorage.setItem('redirectOAuth', location.pathname);
    }
  }, [plaidLinkTokenData]);

  return (
    <div>
      {(bannerStatus === AddAccountBannerStatuses.SUCCESS ||
        bannerStatus === AddAccountBannerStatuses.ERROR) && (
        <AddAccountBanner
          bannerStatus={bannerStatus}
          closeBanner={closeBanner}
          setShowPlaidModal={setShowPlaidModal}
        />
      )}
      <PlaidModal
        isOpen={showPlaidModal}
        loading={plaidLoading}
        setShowPlaidModal={setShowPlaidModal}
        launchPlaid={launchPlaid}
      />
      <div className="flex items-center" onClick={openPlaidModal}>
        <div className="pr3">
          <BubbleIcon name="plus" iconColor="blue" bubbleColor="blueWashed" />
        </div>
        <div className="flex-auto">
          <div>
            <P3 color="ink" style={{ marginVertical: 0 }}>
              <B>Link a Bank Account</B>
            </P3>
          </div>
          <div className="flex justify-between">
            <P3 style={{ marginVertical: 0 }}>
              Connect account through Plaid (recommended)
            </P3>
          </div>
        </div>
        <Icon name="forward" color="blue"></Icon>
      </div>
    </div>
  );
};

export default AddPlaidAccount;
