import { gql } from '@apollo/client';
import { ACCOUNT_SUMMARY_CARDHOLDER_INFO_FRAGMENT } from '@core/components/AccountDetailsPage/fragments';

export const getCustomerInfo = gql`
  query GetAddressInformation {
    customer {
      id
      ...AccountSummaryCardholderInfo
    }
  }
  ${ACCOUNT_SUMMARY_CARDHOLDER_INFO_FRAGMENT}
`;
