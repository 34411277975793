import cx from 'classnames';

import { ActivityType, Transactions } from '@core/graphql/globalTypes';
import toTitleCase from '@core/utils/toTitleCase';
import { Icon, Spacing } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { gql } from '@apollo/client';
import { centsToDollars } from '@core/utils/centsToDollars';
import getEffectivePaymentDate from '@core/utils/getEffectivePaymentDate';
import { getUTC } from '@core/utils/timezones';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { formatTxDate } from './formatTxDate';
import { TrackService } from '@core/services';
import { ButtonClickedEvent } from '@core/services/TrackService/models/events';
import {
  TestSeed,
  useTestProperties,
} from '@core/utils/hooks/useTestProperties';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const getTime = (transaction: Transactions.Activities) =>
  transaction.state === 'PENDING' ?
    getEffectivePaymentDate(transaction.date)
  : getUTC(transaction.date);

export const TRANSACTION_ITEM_FRAGMENT = gql`
  fragment TransactionItem on Activity {
    id
    state
    type
    description
    amount
    date
    ... on Transaction {
      transactionId
      merchantLocation
    }
    ... on Payment {
      reference
      transactionMethod
      fundingAccount {
        id
        numberLast4
        bankName
      }
    }
  }
`;

interface Props {
  transaction: Transactions.Activities;
}

const getTransactionText = (
  isPending: boolean,
  transactionItem?: Transactions.Activities,
) => {
  if (transactionItem?.__typename !== 'Transaction') return;
  const { merchantLocation } = transactionItem || {};

  const pendingText = `${isPending ? 'Pending - ' : ''}`;
  const merchantLocationText = `${merchantLocation ? `${merchantLocation}` : ''}`;

  return `${pendingText}${merchantLocationText}`;
};

const getPaymentText = (
  isPending: boolean,
  transaction: Transactions.Activities,
) => {
  let paymentText: string | undefined;
  if (transaction.__typename === 'Payment') {
    if (
      transaction.fundingAccount?.bankName &&
      transaction.fundingAccount?.numberLast4
    )
      paymentText = `From ${toTitleCase(transaction.fundingAccount?.bankName)}..${transaction.fundingAccount?.numberLast4}`;
  }
  return isPending ? `Pending - ${paymentText}` : paymentText;
};

const thankYouText = ' - thank you';

const TransactionItem = ({ transaction }: Props) => {
  const navigate = useNavigate();
  const { showTransactionDetail } = useFlags();
  const { state, type, description } = transaction;
  const isPosted = state === 'POSTED';
  const isPending = state === 'PENDING';
  const isPayment = type === 'PAYMENT';
  const testProperties = useTestProperties(TestSeed.SEED_1);

  const txId =
    transaction.__typename === 'Transaction' ?
      transaction.transactionId
    : transaction.id;

  const txOrPaymentText = isPayment ? 'Payment' : 'Transaction';

  const buttonClickEventProperties = {
    feature_name: `Transaction Details`,
    button_name: `Navigate to ${txOrPaymentText} details`,
    label: transaction.amount.toString(),
    id: txId,
    testProperties,
  };

  const onViewDetails = () => {
    TrackService.track(new ButtonClickedEvent(buttonClickEventProperties));
    navigate(`../transactions/${txId}`, {
      state: { transaction },
    });
  };

  const isCredit = transaction.type === ActivityType.Credit;

  const PaymentNoThankYou =
    (
      transaction.__typename !== 'Transaction' &&
      description.toLowerCase().includes(thankYouText)
    ) ?
      description.toLowerCase().replace(thankYouText, '')
    : description;

  return (
    <li className="list flex item-start mb3 bb b--ink-40 pb3">
      <div className="w-100 flex flex-row justify-between">
        {/* Why minWidth: 0? See https://css-tricks.com/flexbox-truncated-text/ */}
        <div className="flex flex-column" style={{ minWidth: 0 }}>
          <p
            className={cx(
              'mv0 pb1 pr2 b truncate',
              isPosted && {
                b: true,
                green: isPayment,
                ink: !isPayment,
              },
            )}
          >
            {toTitleCase(PaymentNoThankYou)}
          </p>
          <p className="f8 mv0">
            {isPayment ?
              getPaymentText(isPending, transaction)
            : getTransactionText(isPending, transaction)}
          </p>
          <p className="f8 mv0">
            {showTransactionDetail ?
              formatTxDate(
                transaction.date,
                isPending,
                transaction.type === ActivityType.Payment,
                isCredit,
              )
            : isPending ?
              'Pending'
            : isCredit ?
              'Refund'
            : getTime(transaction).format('MMM D, YYYY')}
          </p>
        </div>
        {showTransactionDetail ?
          <TransactionItemAmountClickable
            isPayment={isPayment}
            isPosted={isPosted}
            amount={transaction.amount}
            onViewDetails={onViewDetails}
            isCredit={isCredit || transaction.type === ActivityType.Reward}
          />
        : <TransactionItemAmount
            isPayment={isPayment}
            isPosted={isPosted}
            amount={transaction.amount}
            isCredit={isCredit || transaction.type === ActivityType.Reward}
          />
        }
      </div>
    </li>
  );
};

export default TransactionItem;

interface TransactionItemAmountProps {
  isPosted: boolean;
  isPayment: boolean;
  isCredit: boolean;
  amount: number;
}

interface TransactionItemAmountClickableProps
  extends TransactionItemAmountProps {
  onViewDetails: () => void;
}

const TransactionItemAmount = ({
  isPayment,
  isPosted,
  isCredit,
  amount,
}: TransactionItemAmountProps) => (
  <p
    className={cx(
      'f8 mv0',
      isPosted && {
        ink: !isPayment,
        green: isPayment,
      },
    )}
  >
    {isPayment || isCredit ? '+' : ''}
    {centsToDollars(isPayment || isCredit ? Math.abs(amount) : amount)}
  </p>
);

const TransactionItemAmountClickable = ({
  isPayment,
  isPosted,
  isCredit,
  amount,
  onViewDetails,
}: TransactionItemAmountClickableProps) => {
  return (
    <div
      aria-label="View transaction"
      role="button"
      onClick={onViewDetails}
      className="h-100 pointer"
    >
      <div className="flex items-center">
        <TransactionItemAmount
          isPayment={isPayment}
          isPosted={isPosted}
          amount={amount}
          isCredit={isCredit}
        />
        <Icon
          style={{ marginLeft: Spacing.m }}
          color="blue"
          size="s"
          name="forward"
        />
      </div>
    </div>
  );
};
