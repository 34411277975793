import { B, Button, H4, Icon, P2, Spacer } from '@missionlane/compass-ui';
import Kard from '@core/components/General/Kard/Kard';
import { ServicingOffersTransaction } from '@core/graphql/globalTypes';
import dayjs from 'dayjs';
import { centsToDollars } from '@core/utils/centsToDollars';
import { useNavigate } from 'react-router-dom';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { pluralize } from '@core/utils/formatters';

interface UpcomingPaymentKardProps {
  upcomingPaymentList?: ServicingOffersTransaction[];
  hasReturnedPayment?: boolean;
}

const UpcomingPaymentKard = ({
  upcomingPaymentList = [],
  hasReturnedPayment,
}: UpcomingPaymentKardProps) => {
  const navigate = useNavigate();
  const { isMobile } = useUserDevice();

  if (upcomingPaymentList && !upcomingPaymentList.length) {
    return (
      <Kard>
        <H4>
          <B>Upcoming Payment</B>
        </H4>
        <div className="flex row items-center mt4">
          <Icon name="calendar" color="greyLight" />
          <ResponsiveTypography
            className="ml3"
            type="PARAGRAPH"
            mobileLevel="P2"
            desktopLevel="P3"
            color="greyLight"
          >
            <B>You have no scheduled payments</B>
          </ResponsiveTypography>
        </div>
        <div className="flex row items-center mt3 nb3">
          <Icon name="alertCircle" color="blue" size="s" />
          <Button
            size="small"
            text="Review previous payments"
            variant="text"
            onPress={() => navigate('../payment-plan')}
            icon={{ name: 'forward' }}
          />
        </div>
      </Kard>
    );
  }

  const { date, amount } = upcomingPaymentList[0];
  const formattedNextPaymentAmount = centsToDollars(amount);
  const formattedNextPaymentDate = date && dayjs(date).format('MMMM D, YYYY');

  return (
    <Kard>
      <H4>
        <B>Upcoming Payment</B>
      </H4>
      <Spacer size="m" />
      {formattedNextPaymentAmount && formattedNextPaymentDate && (
        <div className="flex flex-column mr2">
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H1"
            desktopLevel="H2"
          >
            {formattedNextPaymentAmount}
          </ResponsiveTypography>
          <span>
            <P2>Scheduled for {formattedNextPaymentDate}</P2>
          </span>
        </div>
      )}
      <div className="mt3 mb2">
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
          color="ink"
        >
          <span className="mr1">
            <Icon name="calendar" size="s" />
          </span>
          <B>{`${upcomingPaymentList.length} ${pluralize(
            'payment',
            upcomingPaymentList.length,
          )} scheduled`}</B>
        </ResponsiveTypography>
      </div>
      <div className="flex items-center nl2">
        {hasReturnedPayment && (
          <Icon name="alertTriangleSolid" size="s" color="red" />
        )}
        <Button
          size={isMobile ? 'small' : 'standard'}
          variant="text"
          icon={{ name: 'forward' }}
          text="Review and edit payments"
          onPress={() => navigate('../payment-plan')}
        />
      </div>
    </Kard>
  );
};

export default UpcomingPaymentKard;
