import { gql } from '@apollo/client';
import { ReactElement } from 'react';
import FallbackUI from '@core/components/GenericFallbacks/SectionFallbackUI';
import { DescriptionList } from '../../General/DescriptionList/DescriptionList';
import AccountDetailsPanel from './AccountDetailsPanel';
import { AccountSummaryDetails } from '@core/graphql/globalTypes';
import SecurityDeposit from './SecurityDeposit/SecurityDeposit';
import { Unavailable } from '../Unavailable';
import { centsToDollars } from '@core/utils/centsToDollars';
import { formatDate } from '@core/utils/formatters';
import { B, Icon, Link } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { PAGE_NAME } from '../constants';
import { ErrorBoundary } from '@core/services/Error/Error';

const DATE_FORMAT = 'ddd, MMM D, YYYY';

export const ACCOUNT_SUMMARY_DETAILS_FRAGMENT = gql`
  fragment AccountSummaryDetails on Account {
    availableCredit
    creditLimit
    balanceInfo {
      currentBalance
      pendingBalance
      statementBalance
    }
    paymentInfo {
      lastPaymentDate
      lastPaymentAmount
      lastStatementCloseDate
      nextStatementCloseDate
    }
  }
`;

export function getAccountSummaryValue(
  value: number | undefined | null,
  type: 'percentage' | 'currency' = 'currency',
): string | ReactElement {
  if (value === null || value === undefined) {
    return <Unavailable />;
  }

  if (type === 'percentage') {
    return `${value.toFixed(2)}%`;
  }

  return centsToDollars(value);
}

interface Props {
  availableCredit?: number;
  creditLimit: number;
  balanceInfo?: AccountSummaryDetails.BalanceInfo;
  paymentInfo?: AccountSummaryDetails.PaymentInfo;
  isChargedOff?: boolean;
}

const AccountDetailsAccountSummary = ({
  availableCredit,
  creditLimit,
  balanceInfo,
  paymentInfo,
  isChargedOff,
}: Props) => {
  const { showHcrExperience } = useFlags();
  const navigate = useNavigate();

  const { currentBalance, pendingBalance, statementBalance } =
    balanceInfo || {};
  const {
    lastPaymentDate,
    lastPaymentAmount,
    lastStatementCloseDate,
    nextStatementCloseDate,
  } = paymentInfo || {};

  return (
    <ErrorBoundary fallback={FallbackUI}>
      <AccountDetailsPanel title="Account summary">
        {showHcrExperience && isChargedOff && (
          <div className="flex mb3">
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P2"
              desktopLevel="P3"
              style={{ marginTop: 0 }}
            >
              <Link
                style={{ textDecorationLine: 'none' }}
                onPress={() =>
                  navigate('../transactions', {
                    state: {
                      isChargedOff,
                      fromPage: PAGE_NAME,
                    },
                  })
                }
              >
                <div className="flex flex-row items-center">
                  <B>See all transactions</B>
                  <Icon name="forward" color="blue" />
                </div>
              </Link>
            </ResponsiveTypography>
          </div>
        )}
        <DescriptionList>
          <DescriptionList.Item
            label="Available credit"
            value={getAccountSummaryValue(availableCredit)}
          />
          <SecurityDeposit />
          <DescriptionList.Item
            label="Current balance"
            value={getAccountSummaryValue(currentBalance)}
          />
          <DescriptionList.Item
            label="Pending balance"
            value={getAccountSummaryValue(pendingBalance)}
          />
          <DescriptionList.Item
            label="Credit line"
            value={getAccountSummaryValue(creditLimit)}
          />
          {/** if the lastPaymentDate is null, we know they have not
           * made a previous payment so we display nothing
           *
           * if it's undefined, we likely have had an error fetching the data,
           * so we'll display the "Unavailable" text
           */}
          {lastPaymentDate !== null && (
            <DescriptionList.Item
              label="Last payment"
              subTitle={
                lastPaymentDate ?
                  formatDate(lastPaymentDate, DATE_FORMAT)
                : undefined
              }
              value={getAccountSummaryValue(lastPaymentAmount)}
            />
          )}
          <DescriptionList.Item
            label="Last statement balance"
            subTitle={
              lastStatementCloseDate ?
                `Closed on ${formatDate(lastStatementCloseDate, DATE_FORMAT)}`
              : ''
            }
            value={getAccountSummaryValue(statementBalance)}
          />
          <DescriptionList.Item
            label="Next statement date"
            subTitle={
              nextStatementCloseDate ?
                formatDate(nextStatementCloseDate, DATE_FORMAT)
              : <Unavailable />
            }
            value=""
          />
        </DescriptionList>
      </AccountDetailsPanel>
    </ErrorBoundary>
  );
};

export default AccountDetailsAccountSummary;
