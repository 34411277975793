import { recoveriesPhone } from '@core/utils/contact';
import GenericServiceFallBackFull from '@core/components/GenericFallbacks/GenericServiceFallBackFull';

const ERROR_MESSAGE = `Sorry! We are having difficulties completing your request at this time. Please try again later or contact us at ${recoveriesPhone} for additional information.`;

const KatabatError = () => (
  <GenericServiceFallBackFull customContent={<>{ERROR_MESSAGE}</>} />
);

export default KatabatError;
