import { FundingAccounts } from '@core/graphql/globalTypes';
import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react';

type Cancellablepayment = {
  amount?: number;
  confirmationId?: string;
  id?: string;
  date?: string;
  fundingAccount?: FundingAccounts.FundingAccounts | null;
  cardLast4?: string | null;
};

type CancelPaymentContextType = {
  paymentToCancel: Cancellablepayment;
  setPaymentToCancel: (payment: Partial<Cancellablepayment>) => void;
};

export const CancelPaymentContext = createContext<CancelPaymentContextType>({
  paymentToCancel: {},
  setPaymentToCancel: () => {},
});

export const CancelPaymentProvider = ({ children }: PropsWithChildren) => {
  const [paymentToCancel, setPaymentToCancel] = useState<
    Partial<Cancellablepayment>
  >({});

  return (
    <CancelPaymentContext.Provider
      value={{
        paymentToCancel: paymentToCancel,
        setPaymentToCancel: setPaymentToCancel,
      }}
    >
      {children}
    </CancelPaymentContext.Provider>
  );
};

export const useCancelPayment = () => useContext(CancelPaymentContext);
