import { H3 } from '@missionlane/compass-ui';
import React from 'react';
interface LoginContainerProps {
  children: React.ReactNode;
}

const LoginContainer = ({ children }: LoginContainerProps) => {
  return (
    <div className="flex justify-center items-center">
      <div
        style={{ width: '25rem' }}
        className="center ph3 ph0-l pt3 pt4-l pb3"
      >
        <div className="mb4">
          <H3>Log in to your account</H3>
        </div>
        <div className="mt4">{children}</div>
      </div>
    </div>
  );
};

export default LoginContainer;
