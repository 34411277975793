import { LoadingPage } from '@core/components/General/Loading';

import { gql } from '@apollo/client';
import {
  ManageAutoPayQuery,
  ManageAutoPayQueryVariables,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatCardLast4 } from '@core/utils/formatters';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { TrackService } from '@core/services';
import PaymentFlowCard from '../../Payments/PaymentFlowCard';
import AutoPayPaymentDisplay from '../AutoPayPaymentDisplay';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAutopay } from '../AutopayContext';

export const MANAGE_AUTO_PAY_QUERY = gql`
  query ManageAutoPay($accountId: String!) {
    account(accountId: $accountId) {
      id
      cardDetails {
        last4
        issuanceType
        issuanceState
        last4
      }
      autopay {
        id
        amount
        paymentType
        dayOfMonth
        eftaUrl
        nextPaymentDate
        fundingAccount {
          id
          bankName
          numberLast4
        }
      }
    }
  }
`;

const ManageAutoPay = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { showMulticardExperience } = useFlags();
  const { setAutopayFlowType } = useAutopay();
  const navigate = useNavigate();
  const { data, loading } = useAccountIdQuery<
    ManageAutoPayQuery,
    ManageAutoPayQueryVariables
  >(MANAGE_AUTO_PAY_QUERY);

  setAutopayFlowType('MODIFY');

  if (loading) {
    return <LoadingPage />;
  }

  const { autopay: autopays = [], cardDetails } = data?.account || {};
  const autopay = autopays[0];

  if (!autopay) {
    return <Navigate to="/" />;
  }

  const pageTitle =
    showMulticardExperience ?
      {
        primaryText: 'Manage Autopay',
        secondaryText: formatCardLast4(cardDetails?.last4),
      }
    : undefined;

  return (
    <PageWrapper
      pageTitle={pageTitle}
      greyBackground
      trackingProperties={{
        featureName: 'Payments: Autopay',
        pageName: 'Manage Autopay',
      }}
    >
      <AccountBreadcrumbs
        items={[
          { text: 'Payments', link: '../..' },
          { text: 'Manage Autopay' },
        ]}
      />
      <PaymentFlowCard header="Your Autopay Plan">
        <AutoPayPaymentDisplay
          active
          amount={autopay.amount}
          paymentType={autopay.paymentType}
          dayOfMonth={autopay.dayOfMonth}
          fundingAccount={autopay.fundingAccount}
          paymentDateLabel="Next Payment"
          nextPaymentDate={autopay.nextPaymentDate}
        />
        <PaymentConfirmationButtons
          onSubmit={() => {
            TrackService.click('Modify Auto Pay', customerAndAccountIds);
            navigate('..');
          }}
          onCancel={() => {
            TrackService.click('Cancel Auto Pay', customerAndAccountIds);
            navigate('../cancel');
          }}
          text="Modify"
          cancelButtonText="Cancel Autopay"
          leftAlign
        />
      </PaymentFlowCard>
    </PageWrapper>
  );
};
export default ManageAutoPay;
