import { useQuery } from '@apollo/client';
import { GET_CUSTOMER } from '../api';
import { Customer } from '@core/graphql/globalTypes';

type GetCusomterInfoQuery = {
  skip?: boolean;
};

export const useGetCustomerQuery = (
  props: GetCusomterInfoQuery = { skip: false },
) =>
  useQuery<{ customer: Customer }>(GET_CUSTOMER, {
    skip: props.skip,
  });
