import { gql } from '@apollo/client';

export const CREATE_OFFER_FLOW = gql`
  mutation CreateOfferFlow(
    $flowId: String!
    $accountId: String!
    $sessionId: String
    $answer: String
    $action: CreateOfferFlowAction
  ) {
    createOfferFlow(
      flowId: $flowId
      accountId: $accountId
      sessionId: $sessionId
      answer: $answer
      action: $action
    ) {
      canBacktrack
      offer {
        offerId
        offerType
        data {
          state
          expiration
          durationMonths
          acceptanceExpiration
          acceptedAt
          subType
          originalSettlementBalance
          originalOutstandingBalance
          remainingBalance
          fundingAccountId
          paymentPlan {
            id
            authorizationText
            source
            transactions {
              amount
              date
            }
            customerId
          }
          pastPaymentPlanIds
          payments {
            amount
            txId
            type
            date
          }
        }
        createdAt
        updatedAt
      }
      question {
        text
        inputType
      }
      sessionId
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer(
    $offerId: String!
    $requestBody: UpdateDetailsWrapper!
    $channel: ServicingOfferChannel!
  ) {
    updateOffer(
      offerId: $offerId
      requestBody: $requestBody
      channel: $channel
    ) {
      offerId
      data {
        fundingAccountId
        paymentPlan {
          id
          authorizationText
          source
          transactions {
            amount
            date
          }
          customerId
        }
      }
    }
  }
`;
