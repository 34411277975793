import Lockup from '@core/components/General/Lockup';
import PageWrapper from '@core/components/Page/PageWrapper';
import { LoadingIndicator } from '@missionlane/compass-ui';

const LoadingSpinner = () => (
  <Lockup id="loading">
    <span data-testid="loading">
      <LoadingIndicator size="large" />
    </span>
  </Lockup>
);

export const LoadingSpinnerPage = () => (
  <PageWrapper>
    <LoadingSpinner />
  </PageWrapper>
);

export default LoadingSpinner;
