import { useFlags } from 'launchdarkly-react-client-sdk';
import { GenericServiceFallBackFullPage } from '@core/components/GenericFallbacks/GenericServiceFallBackFull';
import ModifyPINPage from './ModifyPINPage';

const ModifyPinFeature = () => {
  const { showModifyPin } = useFlags();

  if (!showModifyPin) {
    return <GenericServiceFallBackFullPage />;
  }

  return <ModifyPINPage />;
};

export default ModifyPinFeature;
