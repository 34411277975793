import { Notification } from '@missionlane/compass-ui';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';

const HasPastDueBalancePaymentBanner = () => {
  const { isPastDue, hasPastDueBalancePayment, isChargedOff } =
    usePaymentStatus();

  const displayBanner = isPastDue && hasPastDueBalancePayment && !isChargedOff;

  return (
    <Notification level="success" show={displayBanner} variant="banner">
      Congrats, you’ll be able to use your card again soon! We just need to
      process your payment first.
    </Notification>
  );
};

export default HasPastDueBalancePaymentBanner;
