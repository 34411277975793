import Body from '@clip/ClipProgress/components/ClipOffer/Guidance/Body/Body';
import Title from '@clip/ClipProgress/components/ClipOffer/Guidance/Title/Title';
import Footer from '@clip/ClipProgress/components/ClipOffer/Guidance/Footer/Footer';
import Kard from '@core/components/General/Kard/Kard';
import SeeOfferDetailsLink from '@clip/ClipProgress/components/ClipOffer/SeeOfferDetailsLink';

export const CLIP_OFFER_GUIDANCE_TEST_ID = 'CLIP_OFFER_GUIDANCE_TEST_ID';

const ClipOfferGuidance = () => {
  return (
    <Kard className="mb3 w-100" testID={CLIP_OFFER_GUIDANCE_TEST_ID}>
      <div className="flex flex-column">
        <Title />
        <Body />
        <Footer />
        <SeeOfferDetailsLink />
      </div>
    </Kard>
  );
};

export default ClipOfferGuidance;
