import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import { ClipOfferUiStates } from '@clip/ClipProgress/hooks/types/ClipOffer';
import ClipOfferGuidance from '@clip/ClipProgress/components/ClipOffer/Guidance/ClipOfferGuidance';
import Streak from '@clip/ClipProgress/components/ClipOffer/Streak/Streak';

const ClipOffer = () => {
  const clipOffer = useClipOffer();

  if (clipOffer.uiState === ClipOfferUiStates.TEST) {
    return <ClipOfferGuidance />;
  }

  if (
    clipOffer.uiState === ClipOfferUiStates.STREAK &&
    clipOffer.currentPaymentStreak != null
  ) {
    return <Streak />;
  }

  return null;
};

export default ClipOffer;
