import { B, P3, H5, Spacer } from '@missionlane/compass-ui';

import { ReplacementCardLink } from './ReplacementCardLink';

export const LockCardCopy = () => (
  <>
    <H5 color="ink">
      <B>
        Block all new transactions. This includes cash advances and recurring
        card transactions like subscriptions, and utility or other bill
        payments.
      </B>
    </H5>
    <Spacer size="m" />
    <P3>
      This won't affect your ability to make payments toward your balance
      through one-time or scheduled payments, or autopay.
    </P3>
    <Spacer size="m" />
    <P3>
      Locking your card will not affect your ability to receive refunds or incur
      fees.
    </P3>
    <Spacer size="m" />
    <ReplacementCardLink />
  </>
);
