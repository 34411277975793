import AutoPayPaymentDisplay from '../AutoPayPaymentDisplay';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import { GET_AUTOPAY } from '../queries';
import { useAccount } from '@core/components/Auth/AccountContext';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import {
  CancelAutopayMutation,
  CancelAutopayMutationVariables,
} from '@core/graphql/globalTypes';
import { P3 } from '@missionlane/compass-ui';
import PaymentFlowCard from '../../Payments/PaymentFlowCard';
import { Notification } from '@missionlane/compass-ui';
import { useAutopay } from '../AutopayContext';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useNavigate } from 'react-router-dom';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

const CANCEL_AUTOPAY_MUTATION = gql`
  mutation CancelAutopay($scheduleId: String!) {
    cancelAutopay(scheduleId: $scheduleId) {
      ...AutopayFields
    }
  }
  ${AUTOPAY_FIELDS}
`;

const CancelAutoPayConfirmation = () => {
  const navigate = useNavigate();
  const { accountId } = useAccount();
  const { autopayContext, autopayPageTitle } = useAutopay();
  const [cancelAutopay, { data: cancelAutopayResponse, error, loading }] =
    useMutation<CancelAutopayMutation, CancelAutopayMutationVariables>(
      CANCEL_AUTOPAY_MUTATION,
      {
        variables: { scheduleId: autopayContext?.autopay?.id || '' },
        refetchQueries: [{ query: GET_AUTOPAY, variables: { accountId } }],
      },
    );

  const breadCrumbItems = [
    { text: 'Payments', link: '../..' },
    { text: 'Manage Autopay', link: '../manage' },
    { text: 'Cancel Autopay' },
  ];

  useEffect(() => {
    if (cancelAutopayResponse?.cancelAutopay) {
      navigate('../canceled');
    }
  }, [cancelAutopayResponse]);

  const { amount, paymentType, dayOfMonth, fundingAccount, nextPaymentDate } =
    autopayContext?.autopay || {};

  if (amount === undefined || !paymentType || !dayOfMonth || !fundingAccount) {
    return <GenericFallbackFull />;
  }

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper
      pageTitle={autopayPageTitle}
      greyBackground
      trackingProperties={{
        featureName: 'Payments: Autopay',
        pageName: 'Cancel Autopay: Confirm',
      }}
    >
      <AccountBreadcrumbs items={breadCrumbItems} />
      <PaymentFlowCard header="Are you sure?">
        <P3 style={{ marginTop: 0 }}>
          Please review and confirm that you want to cancel this automatic
          payment.
        </P3>
        <div className="pt3">
          <AutoPayPaymentDisplay
            amount={amount}
            paymentType={paymentType}
            dayOfMonth={dayOfMonth}
            fundingAccount={fundingAccount}
            paymentDateLabel="Next Payment"
            nextPaymentDate={nextPaymentDate}
          />
        </div>
        {error && (
          <Notification variant="inline" level="error">
            {error.message || (error as unknown as string)}
          </Notification>
        )}
        <PaymentConfirmationButtons
          onSubmit={cancelAutopay}
          onCancel={() => navigate('../manage')}
          disabled={loading}
          text="Yes, Cancel Autopay"
          cancelButtonText="Go Back"
          leftAlign
          critical
        />
      </PaymentFlowCard>
    </PageWrapper>
  );
};

export default CancelAutoPayConfirmation;
