import { Button, H2, H4, P3 } from '@missionlane/compass-ui';
import { TrackService } from '@core/services';
import { TrackingLinkProps } from '@core/components/General/TrackingLink';
import GenericFallbackKard from '@core/components/GenericFallbacks/GenericFallbackKard';

interface Props {
  paymentPlanUrl: string | undefined;
  trackingProperties: TrackingLinkProps['trackingProperties'];
}

const PaymentKardBodyRecoveries = ({
  paymentPlanUrl,
  trackingProperties,
}: Props) => {
  if (!paymentPlanUrl) return <GenericFallbackKard />;

  return (
    <div className="flex flex-column">
      <div className="dn-ns">
        <H2>Your account is charged off.</H2>
      </div>
      <div className="dn db-ns">
        <H4>Your account is charged off.</H4>
      </div>
      <P3>
        Your account can no longer be used. Please make a payment or set up a
        plan.
      </P3>
      <div className="db flex-ns mt2">
        <Button
          size="small"
          text="See My Options"
          onPress={() => {
            TrackService.click('Katabat SSO', trackingProperties);
            window.location.href = paymentPlanUrl;
          }}
        />
      </div>
    </div>
  );
};

export default PaymentKardBodyRecoveries;
