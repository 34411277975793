import TransactionList from './TransactionList';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import dayjs from 'dayjs';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import {
  ActivityType,
  TransactionsQuery,
  TransactionsQueryVariables,
} from '@core/graphql/globalTypes';
import PageWrapper from '../Page/PageWrapper';
import { AccountBreadcrumbs } from '../Account/AccountBreadcrumbs';
import { ContentCard } from '../General/ContentCard';
import { useAccountPageTitle } from '../Account/useAccountPageTitle';
import { H4, Spacer } from '@missionlane/compass-ui';
import { useState } from 'react';
import './TransactionsPage.css';
import { TransactionFilters } from './TransactionFilters';
import { StatementsButton } from './StatementsButton';
import { TransactionsSearch } from './TransactionsSearch';
import { TRANSACTION_ITEM_FRAGMENT } from './TransactionItem';
import { useLocation } from 'react-router-dom';
import { PAGE_NAME as ACCOUNT_DETAILS_PAGE_NAME } from '../AccountDetailsPage/constants';
import { ErrorBoundary } from '@core/services/Error/Error';

const TRANSACTIONS_QUERY = gql`
  query Transactions($accountId: String!, $fromDate: String, $toDate: String) {
    account(accountId: $accountId) {
      id
      activities(fromDate: $fromDate, toDate: $toDate) {
        ...TransactionItem
      }
    }
  }
  ${TRANSACTION_ITEM_FRAGMENT}
`;

const Transactions = () => {
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<ActivityType | null>(null);

  const { data, loading, error } = useAccountIdQuery<
    TransactionsQuery,
    TransactionsQueryVariables
  >(TRANSACTIONS_QUERY, {
    variables: {
      // fetch 1 year of transactions
      fromDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const filteredTransactions = data?.account?.activities.filter(
    ({ description, type }) => {
      if (searchValue) {
        return description.toLowerCase().includes(searchValue.toLowerCase());
      }
      if (filterValue) {
        return type === filterValue;
      }
      return true;
    },
  );

  const noTransactionsMessage = {
    textPrimary: searchValue ? 'No activity found.' : 'No activity yet.',
  };

  // throw the error so the ErrorBoundary in TransactionsPage can catch it
  if (error) throw error;

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <div className="flex items-center justify-end mb2">
        <TransactionsSearch
          searchOpen={searchOpen}
          searchValue={searchValue}
          setSearchOpen={setSearchOpen}
          setSearchValue={setSearchValue}
        />
        {!searchOpen && (
          <>
            <Spacer size="xs" />
            <TransactionFilters
              onSelectFilter={setFilterValue}
              selectedFilter={filterValue}
            />
          </>
        )}
      </div>
      <TransactionList
        transactions={filteredTransactions}
        noTransactionsMessage={noTransactionsMessage}
      />
    </>
  );
};

const TransactionsPage = () => {
  const location = useLocation();
  const pageTitle = useAccountPageTitle('Transactions');

  const cameFromChargedOffAccountDetails =
    location.state?.isChargedOff &&
    location.state?.fromPage === ACCOUNT_DETAILS_PAGE_NAME;

  const breadcrumbItems =
    cameFromChargedOffAccountDetails ?
      [
        { text: 'Account details', link: '../details' },
        { text: 'Transactions' },
      ]
    : [{ text: 'Transactions' }];

  return (
    <PageWrapper
      greyBackground
      pageTitle={pageTitle}
      trackingProperties={{
        pageName: 'All Transactions',
        featureName: 'Transactions',
      }}
    >
      <AccountBreadcrumbs items={breadcrumbItems} />
      <ErrorBoundary>
        <ContentCard>
          <div className="flex items-center justify-between">
            <H4>Transactions</H4>
            <StatementsButton />
          </div>
          <Transactions />
        </ContentCard>
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default TransactionsPage;
