import { gql } from '@apollo/client';

const redirectUri = process.env.REACT_APP_PLAID_OAUTH_CLIP_CFU;

export const GET_PLAID_LINK_TOKEN_CLIP_CFU = gql`
  query GetPlaidLinkTokenClipCFU {
    plaidLinkToken(
      accountFilter: "{depository: ['checking']}"
      products: ["AUTH", "ASSETS"]
      businessProduct: "CreditCard"
      subContext: ["CLIP"]
      customizationName: "cash_flow_underwriting"
      redirectUri: "${redirectUri}"
    ) {
      token
      productRegistration {
        productAccess {
          accessTokenId
        }
      }
    }
  }
`;
