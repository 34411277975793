import { type IncomeUpdateFormContainerProps } from '../../../components';
import { Button } from '@missionlane/compass-ui';
import { ButtonVariant } from '@missionlane/compass-ui/lib/typescript/components/Button/types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import './FormButtonContainer.css';

const getSubmitButtonVariant = (onMobile: boolean): ButtonVariant => {
  if (onMobile) {
    return 'contained';
  }

  return 'outlined';
};

const getCancelButtonVariant = (onMobile: boolean): ButtonVariant => {
  if (onMobile) {
    return 'outlined';
  }

  return 'text';
};

const MOBILE_BREAK_POINT_PX = 600;

const isOnMobile = () => window.innerWidth <= MOBILE_BREAK_POINT_PX;

export const FormButtonContainer = ({
  onSubmit,
  loading,
  submitDisabled,
}: IncomeUpdateFormContainerProps) => {
  const navigate = useNavigate();
  const [onMobile, setOnMobile] = useState(isOnMobile());

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    function handleResize() {
      if (isOnMobile()) {
        setOnMobile(true);
        return;
      }

      setOnMobile(false);
    }

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={cx(
        `flex ${
          onMobile ? 'flex-column' : 'flex-start flew-row'
        } edit-financial-info--form-button-container--container`,
      )}
    >
      {/* @ts-ignore - It appears that ButtonVariant is not assignable to the variant prop. It has something to do with the default button props thinking variant is a string */}
      <Button
        onPress={onSubmit}
        variant={getSubmitButtonVariant(onMobile)}
        text="Submit"
        size="standard"
        loading={loading}
        disabled={submitDisabled}
      />
      {/* @ts-ignore - It appears that ButtonVariant is not assignable to the variant prop. It has something to do with the default button props thinking variant is a string */}
      <Button
        onPress={() => navigate(-1)}
        variant={getCancelButtonVariant(onMobile)}
        text="Cancel"
      />
    </div>
  );
};
