import { useAccount } from '@core/components/Auth/AccountContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Returns the current path to the account summary page based on
 * the value of the showMulticardExperience feature flag
 */
export const useAccountSummaryPath = () => {
  const { showMulticardExperience } = useFlags();
  const { accountId } = useAccount();
  return showMulticardExperience ? `/account/${accountId}/summary` : '/';
};
