import confetti, { Options as CanvasConfettiOptions } from 'canvas-confetti';
import { useEffect } from 'react';
import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import BalanceKard from '../Kards/BalanceKard';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { B, Button, Colors, Link } from '@missionlane/compass-ui';
import DashboardFooter from './DashboardFooter';
import { useNavigate } from 'react-router-dom';
import { ChargedOffBannerVariant as FaqVariant } from '@core/components/Banners/AuthenticatedBanners/ChargedOffBanner';
import { TrackService } from '@core/services';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

const FulfilledPIFDashboard = () => {
  const { currentBalance } = useSettlementOffers();
  const { isMobile } = useUserDevice();
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.trackPage('Charged Off Dashboard - PIF Offer Complete', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });

    const confettiTimer = setTimeout(() => {
      fireConfettiCannons([
        {
          particleRatio: 0.25,
          spread: 26,
          startVelocity: 55,
        },
        {
          particleRatio: 0.2,
          spread: 60,
        },
        {
          particleRatio: 0.35,
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        },
        {
          particleRatio: 0.1,
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        },
        {
          particleRatio: 0.1,
          spread: 120,
          startVelocity: 45,
        },
      ]);
    }, 1000);

    // Cleanup any outstanding timer or animation on unmount
    return () => {
      clearTimeout(confettiTimer);
      confetti.reset();
    };
  }, []);

  const congratulatoryKardHeader: KardHeader = {
    level: isMobile ? 'H2' : 'H3',
    textPrimary: 'You did it! Your account balance is',
    textSecondary: 'paid in full.',
    className: 'mb3',
  };

  return (
    <>
      <div className="flex flex-column flex-row-ns">
        <div className="w-50-ns mb2 mb0-ns mr4-ns">
          <BalanceKard isPIF currentBalance={currentBalance || 0} />
        </div>
        <div className="w-50-ns">
          <Kard header={congratulatoryKardHeader}>
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P3"
            >
              <B color="ink">Congratulations!</B> Paying off your full account
              balance is a huge step in your credit journey.{' '}
              <Link
                onPress={() =>
                  navigate('../payment-plan/faq', {
                    state: {
                      faqVariant: FaqVariant.FULFILLED_PIF,
                    },
                  })
                }
              >
                What happens now?
              </Link>
            </ResponsiveTypography>
          </Kard>
        </div>
      </div>
      <DashboardFooter
        CallToAction={
          <div style={{ marginLeft: '-8px', marginBottom: '24px' }}>
            <Button
              size="small"
              text="Access Account Details"
              variant="text"
              onPress={() => navigate('../details')}
              icon={{ name: 'forward' }}
            />
          </div>
        }
        Disclaimer={
          <ResponsiveTypography
            className="flex"
            type="PARAGRAPH"
            mobileLevel="P3"
            desktopLevel="P4"
          >
            This is an attempt to collect a debt and any information obtained
            will be used for that purpose.
          </ResponsiveTypography>
        }
      />
    </>
  );
};

type ConfettiCannon = {
  particleRatio: number;
} & CanvasConfettiOptions;

/**
 * Takes in a list of canvas-confetti configs and fires them off one by one
 */
const fireConfettiCannons = async (confettiCannons: ConfettiCannon[]) => {
  const colors = [
    Colors.pine,
    Colors.pine, // use twice as much primary color as anything else
    Colors.yellow,
    Colors.green,
    Colors.greenLight,
  ];

  const defaultOptions: CanvasConfettiOptions = {
    colors,
    // use two thirds squares, one third circles
    shapes: ['square', 'square', 'circle'],
  };
  return await Promise.all(
    confettiCannons.map(async ({ particleRatio, ...otherOptions }) => {
      await confetti({
        ...defaultOptions,
        ...otherOptions,
        particleCount: Math.floor(particleRatio * 200),
      });
    }),
  );
};

export default FulfilledPIFDashboard;
