import { PlaidInstitution } from 'react-plaid-link';

export type SegmentTrackEvent = {
  event: string;
  properties?: any;
};

/** Feature Events */

type FeatureEventProps = {
  feature_name: string;
  page?: string;
  status?: string;
};

export class FeatureViewedEvent {
  event = 'Feature Viewed';
  properties: FeatureEventProps;
  constructor(properties: FeatureEventProps) {
    this.properties = properties;
  }
}
export class FeatureRenderedEvent {
  event = 'Feature Rendered';
  properties: FeatureEventProps;
  constructor(properties: FeatureEventProps) {
    this.properties = properties;
  }
}

/** Plaid Link Events */

type PlaidLinkEventProps = {
  feature_name: string;
  status?: string;
  error?: string;
  institution?: PlaidInstitution;
};
type PlaidLinkOpenedEventProps = Pick<PlaidLinkEventProps, 'feature_name'>;
type PlaidLinkClosedEventProps = PlaidLinkEventProps;
type PlaidLinkCompletedEventProps = Pick<
  PlaidLinkEventProps,
  'feature_name' | 'institution'
>;

export class PlaidLinkOpenedEvent {
  event = 'Plaid Link Opened';
  properties: PlaidLinkOpenedEventProps;
  constructor(properties: PlaidLinkOpenedEventProps) {
    this.properties = properties;
  }
}

export class PlaidLinkClosedEvent {
  event = 'Plaid Link Closed';
  properties: PlaidLinkClosedEventProps;
  constructor(properties: PlaidLinkClosedEventProps) {
    this.properties = properties;
  }
}

export class PlaidLinkCompletedEvent {
  event = 'Plaid Link Completed';
  properties: PlaidLinkCompletedEventProps;
  constructor(properties: PlaidLinkCompletedEventProps) {
    this.properties = properties;
  }
}

/** Button Events */

interface ButtonEventProps {
  feature_name: string;
  button_name: string;
  label?: string;
}

export class ButtonClickedEvent {
  event = 'Button Clicked';
  properties: ButtonEventProps;
  constructor(properties: ButtonEventProps) {
    this.properties = properties;
  }
}
