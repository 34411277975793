import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

export const CREDIT_PROTECTION_FAQ_5_TITLE =
  'Does Credit Protection apply to all of my credit card accounts?';

const CreditProtectionFAQ_5 = () => {
  return (
    <div className="mt4">
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        No, Credit Protection only applies to the specific Mission Lane credit
        card account(s) you’ve enrolled in. For example, if you enroll in Credit
        Protection for a new Mission Lane credit card, other Mission Lane
        accounts you hold are not automatically covered. You can check
        eligibility and separately enroll other Mission Lane credit card
        accounts online or in the Mission Lane mobile app. Credit Protection
        does not apply to credit card accounts outside of Mission Lane.
      </ResponsiveTypography>
    </div>
  );
};

export default CreditProtectionFAQ_5;
