import { B, Button, H2, Icon, P2 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { TrackService } from '@core/services';
import { useEffect } from 'react';
import { UpdatePhone } from '@core/graphql/globalTypes';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface ChangePhoneSuccessProps {
  updatedPhoneNumber: UpdatePhone.UpdatePhone['phoneNumber'];
}

const ChangePhoneSuccess = ({
  updatedPhoneNumber,
}: ChangePhoneSuccessProps) => {
  const navigate = useNavigate();
  const { isMobile } = useUserDevice();

  useEffect(() => {
    TrackService.page('Change Phone Success');
  }, []);

  return (
    <div
      className={!isMobile ? 'mb4 center bg-white pa5-m pa4 shadow-2-ns' : ''}
    >
      <div className="flex flex-auto flex-column flex-row-ns mv2">
        <div className="w-30-ns tc tl-ns mb4">
          <Icon name="checkCircleLight" color="green" size={118} />
        </div>
        <div>
          <div className="tc tl-ns ml2-ns">
            <H2>Success!</H2>
            <br />
            <div className="mt5-ns">
              <P2>
                We've updated your phone number for every card you have with us.
                We’ll send all future correspondence to{' '}
                <B testID="UpdatedPhoneNumber" color="ink">
                  {updatedPhoneNumber}
                </B>
                .
              </P2>
            </div>
          </div>
          <div
            className="pt4-ns fl-ns center mt4 mt0-ns"
            style={{ width: 258 }}
          >
            <Button
              onPress={() => {
                TrackService.click('Change Phone Success: Done');
                navigate('/', { replace: true });
              }}
              text="Done"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePhoneSuccess;
