import { B, P1 } from '@missionlane/compass-ui';
import Kard from '@core/components/General/Kard/Kard';

interface AccountPendingCardProps {
  className?: string;
}

const AccountPendingCard = ({ className }: AccountPendingCardProps) => (
  <Kard
    className={className}
    header={{ textPrimary: 'New Card Pending' }}
    disabled
    variant="navigationArrow"
  >
    <P1>
      <B color="ink">Congrats on your new card!</B> Check back tomorrow to see
      your new account details here.
    </P1>
  </Kard>
);

export default AccountPendingCard;
