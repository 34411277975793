import { Link, Icon, Colors, P3 } from '@missionlane/compass-ui';
interface ModalLinkProps {
  text: string;
}

const ModalLink = ({ text }: ModalLinkProps) => (
  <P3>
    <Link style={{ color: Colors.green, textDecorationLine: 'none' }}>
      {text}
      <span className="pl1 v-mid">
        <Icon name="arrowRight" color="green" />
      </span>
    </Link>
  </P3>
);

const CardMailedContent = () => (
  <div>
    <ModalLink text="Activate your new card" />
  </div>
);

const CardDelayedContent = () => (
  <div>
    <ModalLink text="Activate your new card" />
  </div>
);

const CardReturnedContent = () => (
  <div>
    <ModalLink text="We couldn't deliver your card" />
  </div>
);

export { CardMailedContent, CardDelayedContent, CardReturnedContent };
