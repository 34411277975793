import { getET } from './timezones';

// if Pending payment, we check to see if the east coast time is past 5pm and convert
// the payment date to the following day

const getEffectivePaymentDate = (timestamp: string) => {
  const dateInET = getET(timestamp);
  return dateInET.get('hour') < 17 ? dateInET : dateInET.add(1, 'day');
};

export default getEffectivePaymentDate;
