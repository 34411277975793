import { Icon, P3, Spacing } from '@missionlane/compass-ui';
import { BreadcrumbLink } from './BreadcrumbLink';

interface BaseBreadcrumbProps {
  text: string;
}

interface BreadcrumbLinkProps extends BaseBreadcrumbProps {
  link: string;
  onClickOverride?: never;
}

interface BreadcrumbOverrideClickProps extends BaseBreadcrumbProps {
  link?: never;
  onClickOverride: () => void;
}

interface TextBreadCrumbProps extends BaseBreadcrumbProps {
  link?: never;
  onClickOverride?: never;
}

export type Breadcrumb =
  | BreadcrumbLinkProps
  | BreadcrumbOverrideClickProps
  | TextBreadCrumbProps;

interface BreadcrumbProps {
  crumb: Breadcrumb;
  isLastCrumb: boolean;
}

export const Breadcrumb = ({ crumb, isLastCrumb }: BreadcrumbProps) => {
  const { link, text, onClickOverride } = crumb;

  return (
    <P3 style={itemStyles}>
      {link || onClickOverride ?
        <>
          {link && <BreadcrumbLink text={text} link={link} />}
          {onClickOverride && (
            <BreadcrumbLink text={text} onClickOverride={onClickOverride} />
          )}
        </>
      : <span>{text}</span>}

      {!isLastCrumb && (
        <span className="ph1">
          <Icon name="forward" color="inkLight" size={12} />
        </span>
      )}
    </P3>
  );
};

const itemStyles = {
  marginVertical: Spacing.xs,
};
