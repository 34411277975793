import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, FC, ReactNode, useCallback, useState } from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { RtfTreatment } from './types';
import { ACCOUNT_INFORMATION_QUERY } from './queries';
import { useAccount } from '@core/components/Auth/AccountContext';
import {
  AccountInformation,
  AccountInformationQuery,
} from '@core/graphql/globalTypes';

interface RefreshOptions {
  /** Total number of refetches to perform */
  refetches?: number;
  /** Milliseconds between each refetch */
  refetchInterval?: number;
}

type MaybeAccountInformation = AccountInformation.AccountInformation | null;

interface AccountInformationContext {
  accountInformation: MaybeAccountInformation;
  /**
   * Initial loading state. True when query is loading and data is null.
   * Will not be set to true during refresh.
   */
  loading: boolean;
  refreshEnabled: boolean;
  /** Enable the refresh of account information by consumers of this context */
  enableRefresh?: () => void;
  /**
   * Begin the refresh process, which continuously refetches data
   * from the network until total refetches are exhausted or data
   * is significantly updated.
   * @param options various options for refresh strategy
   */
  refresh?: (options?: RefreshOptions) => void;
  /**
   * An estimated Real-time Funds treatment status. Since this status is
   * not available from an API, it is surmised by the front-end.
   */
  rtfTreatment: RtfTreatment;
}

export const AccountInformationContext =
  createContext<AccountInformationContext>({
    accountInformation: null,
    loading: false,
    refreshEnabled: false,
    rtfTreatment: null,
  });

const AccountInformationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { acctInfoRefresh } = useFlags();
  const { accountId } = useAccount();

  const [accountInformation, setAccountInformation] =
    useState<AccountInformationContext['accountInformation']>(null);
  const [refreshEnabled, setRefreshEnabled] =
    useState<AccountInformationContext['refreshEnabled']>(false);
  const [rtfTreatment, setRtfTreatment] =
    useState<AccountInformationContext['rtfTreatment']>(null);

  const query = useQuery<AccountInformationQuery>(ACCOUNT_INFORMATION_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'network-only',
    skip: !accountId,
    onCompleted: (data) => setAccountInformation(data.accountInformation),
  });

  const enableRefresh = () => setRefreshEnabled(true);

  const poll = useCallback(
    (resolve: () => void) => {
      if (NetworkStatus[query.networkStatus] === 'ready') {
        resolve();
      } else {
        setTimeout(() => poll(resolve), 40);
      }
    },
    [query.networkStatus],
  );

  /** Delay control until network status of query is "ready" */
  const waitForReadyStatus = () => new Promise<void>(poll);

  /**
   * Compare data from the most recent network result with that
   * stored in state to determine whether it has significantly
   * changed. Should be updated according to business need.
   * @param data latest network data
   */
  const dataWasUpdated = (data: MaybeAccountInformation) =>
    /* initial account information has been set */
    !!accountInformation &&
    /* available credit has been updated */
    data?.availableCredit !== accountInformation.availableCredit;

  const shouldSetAccountInfo = (
    retriesRemaining: number,
    data: MaybeAccountInformation,
  ) => retriesRemaining === 0 || (!!data && dataWasUpdated(data));

  /**
   * Surmise the available funds treatment that was applied to the
   * account depending on changes to data. If data has not changed
   * in a meaningful way, it can be assumed that the account was
   * given standard treatment.
   */
  const estimateAndSetTreatment = (data: MaybeAccountInformation) =>
    setRtfTreatment(() => (dataWasUpdated(data) ? 'INSTANT' : null));

  /**
   * Continuously refetch account information until total retries are
   * exhausted or network data has changed sufficiently, whichever
   * comes first.
   * @param refetches number of retries remaining
   * @param refetchInterval delay between each retry attempt
   */
  const refetch = (refetches = 1, refetchInterval = 1000) => {
    if (refetches > 0) {
      setTimeout(async () => {
        await waitForReadyStatus();
        const { accountInformation: ai } = (await query.refetch()).data;
        if (shouldSetAccountInfo(refetches, ai)) {
          setAccountInformation(() => {
            /* set treatment as a side effect of setting state */
            estimateAndSetTreatment(ai);
            return ai;
          });
        } else {
          refetch(refetches - 1, refetchInterval);
        }
      }, refetchInterval);
    }
  };

  const refresh = (options?: RefreshOptions) => {
    if (acctInfoRefresh) {
      setRefreshEnabled(false);
      refetch(options?.refetches, options?.refetchInterval);
    }
  };

  return (
    <AccountInformationContext.Provider
      value={{
        accountInformation,
        loading: !accountInformation && query.loading,
        refreshEnabled,
        enableRefresh,
        refresh,
        rtfTreatment,
      }}
    >
      {children}
    </AccountInformationContext.Provider>
  );
};

export default AccountInformationProvider;
