import { ClipOffer as BaseClipOffer } from '@core/graphql/globalTypes';

export enum ClipOfferStatusCodes {
  IN_PROGRESS = 'IN_PROGRESS',
  EVALUATION_PENDING = 'EVALUATION_PENDING',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  APPROVED = 'APPROVED',
  INELIGIBLE = 'INELIGIBLE', // formerly DECLINED
  MISSED_PAYMENT = 'MISSED_PAYMENT', // formerly DISQUALIFIED
  OVERLIMIT = 'OVERLIMIT',
}

export enum ClipOfferUiStates {
  TEST = 'TEST',
  STREAK = 'STREAK',
  CONTROL = 'CONTROL',
}

export enum ClipOfferPaymentStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  UPCOMING = 'UPCOMING',
  FUTURE = 'FUTURE',
  MISSED = 'MISSED',
  DISABLED = 'DISABLED',
}

export type ClipOfferTrackerItems = BaseClipOffer.TrackerItems;

export interface ClipOffer extends Omit<BaseClipOffer.ClipOffer, 'status'> {
  clipStatus: ClipOfferStatusCodes;
}
