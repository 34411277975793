import { FormProvider, useForm } from 'react-hook-form';
import { MoneyInput } from '../MoneyInput';
import { incomeUpdateResolver } from './incomeUpdateResolver';
import { AlimonyTooltip } from '../AlimonyTooltip';
import { useUpdateIncomeMutation } from '../../hooks';
import { convertCurrencyInputToInt, formatCurrency } from '../../utils';
import './IncomeUpdateForm.css';
import { Colors, P3, B } from '@missionlane/compass-ui';
import {
  MonthlyLivingWarning,
  isMonthlyLivingValid,
} from './MonthlyLivingWarning';

import AnomalousIncomeOrMonthlyLivingWarning, {
  useAnomalousIncomeOrMonthlyLiving,
} from './AnomalousIncomeOrMonthlyLivingWarning';

export const TOTAL_ANNUAL_INCOME_INPUT_TEST_ID =
  'TOTAL_ANNUAL_INCOME_INPUT_TEST_ID';
export const MONTHLY_RENT_OR_MORTAGE_COST_INPUT_TEST_ID =
  'MONTHLY_RENT_OR_MORTAGE_COST_INPUT_TEST_ID';

type FormValues = {
  annualIncome: string;
  monthlyLiving: string;
};

type IncomeUpdateFormOnSubmit = (
  e?: React.BaseSyntheticEvent<object, any, any> | undefined
) => Promise<void>;

export type IncomeUpdateFormContainerProps = {
  onSubmit: IncomeUpdateFormOnSubmit;
  submitDisabled: boolean;
  loading: boolean;
};

export type FormButtonContainerProps = IncomeUpdateFormContainerProps & {
  hasPie: boolean;
};

type Layout = 'row' | 'column';

type IncomeUpdateFormProps = {
  renderFormButtonContainer: (
    props: FormButtonContainerProps
  ) => React.ReactNode;
  annualIncome: number | null | undefined;
  monthlyLiving: number | null | undefined;
  onSuccess: () => void;
  onError: () => void;
  hasPie: boolean;
  layout: Layout;
};

/**
 * getContainerPaddingClass this function determines when to apply padding based on the forms usage.
 * This allows us to prevent input jumping when errors occur on the income banner.
 *
 * @param {boolean} hasError - whether or not the form has an error
 * @param {Layout} layout - which direction the form is flowing in
 * @param {hasWarning} - whether or not the income update monthly living warning is displaying
 * @returns {string} - a string that represents what padding we should apply
 */
const getContainerPaddingClass = (
  hasError: boolean,
  layout: Layout,
  hasWarning: boolean
) => {
  if (hasError && layout === 'row') {
    return '';
  }

  if (hasWarning) {
    return 'mb4';
  }

  return 'pb4 mb2';
};

export const IncomeUpdateForm = ({
  renderFormButtonContainer,
  annualIncome,
  monthlyLiving,
  onSuccess,
  onError,
  layout,
  hasPie,
}: IncomeUpdateFormProps) => {
  const { checkForAnomaly, anomalyDetected } =
    useAnomalousIncomeOrMonthlyLiving();

  const form = useForm<FormValues>({
    resolver: incomeUpdateResolver,
    mode: 'all',
    defaultValues: {
      annualIncome: annualIncome ? formatCurrency(`${annualIncome}`) : '',
      monthlyLiving: monthlyLiving ? formatCurrency(`${monthlyLiving}`) : '',
    },
  });

  const {
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = form;

  const [updateIncome, { loading }] = useUpdateIncomeMutation();

  const onSubmit = handleSubmit((data) => {
    const parsedFormData = {
      annualIncome: convertCurrencyInputToInt(data.annualIncome),
      monthlyLiving: convertCurrencyInputToInt(data.monthlyLiving),
    };

    return updateIncome({
      variables: parsedFormData,
    })
      .then((res) => {
        onSuccess();
        return res;
      })
      .catch((e) => {
        onError();
        return e;
      });
  });

  const hasError = !!(
    errors.annualIncome?.message || errors.monthlyLiving?.message
  );

  const hasWarning = !isMonthlyLivingValid(watch('monthlyLiving'));

  const containerPadding = getContainerPaddingClass(
    hasError,
    layout,
    hasWarning
  );

  const submitDisabled = !!(
    errors.annualIncome?.message ||
    errors.monthlyLiving?.message ||
    !form.watch('annualIncome') ||
    !form.watch('monthlyLiving')
  );

  return (
    <div className="flex flex-column" id="income-update-form">
      <div className={`flex form-input-gap ${containerPadding} flex-wrap`}>
        <FormProvider {...form}>
          <div className={`input-max-width-${layout}`}>
            <div className="flex items-end mb2 mt3">
              <P3 style={{ color: Colors.ink, margin: 0 }}>
                <B>Total Annual Income</B>
              </P3>
              <AlimonyTooltip />
            </div>
            <div className="income-update-form__money-input-wrapper">
              <MoneyInput
                testID={TOTAL_ANNUAL_INCOME_INPUT_TEST_ID}
                name="annualIncome"
                icon="dollarSign"
                suffix="per year"
                error={errors.annualIncome?.message}
                onBlur={() => {
                  checkForAnomaly({
                    nextMonthlyLiving: getValues('monthlyLiving'),
                    nextAnnualIncome: getValues('annualIncome'),
                    currentAnnualIncome: annualIncome,
                    currentMonthlyLiving: monthlyLiving,
                    field: 'annualIncome',
                  });
                }}
              />
            </div>
          </div>

          <div className={`input-max-width-${layout}`}>
            <div className="flex items-center mb2 mt3">
              <P3 style={{ color: Colors.ink, margin: 0 }}>
                <B>Monthly Rent or Mortgage Payment</B>
              </P3>
            </div>
            <div className="income-update-form__money-input-wrapper">
              <MoneyInput
                testID={MONTHLY_RENT_OR_MORTAGE_COST_INPUT_TEST_ID}
                name="monthlyLiving"
                icon="dollarSign"
                suffix="per month"
                error={errors.monthlyLiving?.message}
                onBlur={() => {
                  checkForAnomaly({
                    nextMonthlyLiving: getValues('monthlyLiving'),
                    nextAnnualIncome: getValues('annualIncome'),
                    currentAnnualIncome: annualIncome,
                    currentMonthlyLiving: monthlyLiving,
                    field: 'monthlyLiving',
                  });
                }}
              />
            </div>
          </div>
        </FormProvider>
      </div>
      <div
        className={`${
          layout === 'row' ? 'pr3' : ''
        } monthly-living-warning-${layout}`}
      >
        <MonthlyLivingWarning monthlyLiving={watch('monthlyLiving')} />
        {(anomalyDetected.annualIncome || anomalyDetected.monthlyLiving) && (
          <AnomalousIncomeOrMonthlyLivingWarning />
        )}
        {hasWarning &&
          renderFormButtonContainer({
            submitDisabled,
            onSubmit,
            loading,
            hasPie,
          })}
      </div>
      {!hasWarning &&
        renderFormButtonContainer({
          submitDisabled,
          onSubmit,
          loading,
          hasPie,
        })}
    </div>
  );
};
