import React, { ReactNode } from 'react';
interface FeatureContainerProps {
  children: ReactNode;
}

const FeatureContainer = ({ children }: FeatureContainerProps) => {
  return (
    <div
      className="flex justify-center items-center center mb5"
      style={{ maxWidth: '25rem' }}
    >
      <div className="pa5 bg-white">{children}</div>
    </div>
  );
};

export default FeatureContainer;
