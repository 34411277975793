import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { B, H4, P4 } from '@missionlane/compass-ui';
import Kard from '@core/components/General/Kard/Kard';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import LogoTransUnion from '@core/assets/logoTransunion.png';
import ProgressBar from './ProgressBar/ProgressBar';
import { CreditScoreQuery } from '@core/graphql/globalTypes';

interface CreditScoreProps {
  className?: string;
}

export const CREDIT_SCORE_QUERY = gql`
  query CreditScore {
    customer {
      id
      creditInfo {
        vantageScore
        fetchedAtDateTime
      }
    }
  }
`;

const headerPrimaryText = 'Credit Score';

const CreditScore = ({ className }: CreditScoreProps) => {
  const { data, loading } = useQuery<CreditScoreQuery>(CREDIT_SCORE_QUERY);
  const { vantageScore, fetchedAtDateTime } = data?.customer?.creditInfo || {};

  if (loading) {
    return (
      <Kard header={{ textPrimary: headerPrimaryText, level: 'H4' }}>
        <LoadingSpinner />
      </Kard>
    );
  }
  if (!vantageScore || !fetchedAtDateTime) return null;

  const formattedDate = dayjs(fetchedAtDateTime).format('MM/DD/YY');

  return (
    <Kard className={className}>
      <div className="flex justify-between items-start mb2">
        <H4>
          {vantageScore} {headerPrimaryText}
        </H4>
        <img
          // negative margin to vertically center image with text
          className="nt2 nt1-l"
          src={LogoTransUnion}
          alt="TransUnion Logo"
        />
      </div>
      <ProgressBar progress={vantageScore / 850} />
      <P4>
        <B color="ink">Updated {formattedDate}.&nbsp;</B>
        Calculated by TransUnion using the VantageScore 3.0 model, which ranges
        from 300 to 850.
      </P4>
    </Kard>
  );
};

export default CreditScore;
