import TrackingLink, {
  TrackingLinkProps,
} from '@core/components/General/TrackingLink';

type CardAction = {
  /** content displayed as children of Link component */
  label: string | React.ReactNode;
  /** function that will be called when the link is clicked */
  linkTo: string;
  /** metadata to be used for segment tracking in the link */
  tracking: Pick<TrackingLinkProps, 'trackingName' | 'trackingProperties'>;
};

export interface AccountSummaryCardFooterProps {
  cardAction: CardAction;
}

const AccountSummaryCardAction = ({
  cardAction,
}: AccountSummaryCardFooterProps) => {
  return (
    <div className="w-100 mt3 flex justify-end">
      <TrackingLink
        to={cardAction.linkTo}
        trackingName={cardAction?.tracking.trackingName}
        trackingProperties={cardAction?.tracking.trackingProperties}
      >
        {cardAction.label}
      </TrackingLink>
    </div>
  );
};

export default AccountSummaryCardAction;
