import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  PaymentHistoryModalQuery,
  PaymentHistoryModalQueryVariables,
} from '@core/graphql/globalTypes';
import { Button } from '@missionlane/compass-ui';
import PageWrapper from '@core/components/Page/PageWrapper';
import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import PaymentsOverviewItems from '@payments/components/PaymentsOverview//PaymentsOverviewItems';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { gql } from '@apollo/client';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';
import { MLError } from '@core/services';

export const PAYMENT_HISTORY_QUERY = gql`
  query PaymentHistoryModal(
    $accountId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    account(accountId: $accountId) {
      id
      payments(fromDate: $fromDate, toDate: $toDate) {
        ...PaymentFields
      }
    }
  }
  ${PAYMENT_FIELDS}
`;

const CompletedPayments = ({}) => {
  const navigate = useNavigate();
  const { data, loading, error } = useAccountIdQuery<
    PaymentHistoryModalQuery,
    PaymentHistoryModalQueryVariables
  >(PAYMENT_HISTORY_QUERY, {
    variables: {
      // fetch 1 year of payments
      fromDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
    },
  });
  const pageTitle = useAccountPageTitle('Completed Payments');

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !data) {
    MLError.report({
      name: 'Completed Payments Query Error',
      message: error ? error.message : 'Payment data is undefined',
    });

    throw new Error(
      `Completed Payments Query Error - ${
        error ? error.message : 'Payment data is undefined'
      }`,
    );
  }

  const breadcrumbsItems = [
    { text: 'Payments', link: '..' },
    { text: 'Completed Payments' },
  ];

  const completedPaymentsKardHeader: KardHeader = {
    textPrimary: 'Completed Payments',
    level: 'H4',
    className: 'mb2',
  };

  const payments = data.account?.payments || [];

  return (
    <PageWrapper
      pageTitle={pageTitle}
      trackingProperties={{
        featureName: 'Payments',
        pageName: 'Completed Payments',
      }}
    >
      <AccountBreadcrumbs items={breadcrumbsItems} />
      <div className="flex justify-center">
        <Kard
          header={completedPaymentsKardHeader}
          testID="completed-payments-kard"
          className="w-100 w-75-l"
        >
          <PaymentsOverviewItems payments={payments} />
          <div className="w-20-l mt5">
            <Button
              text="Back"
              onPress={() => {
                navigate('..');
              }}
            />
          </div>
        </Kard>
      </div>
    </PageWrapper>
  );
};

export default CompletedPayments;
