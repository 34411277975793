import { CONTACT_US_LINK } from '@core/utils/constants';
import SupportPhoneLink from '@core/components/General/SupportPhoneLink';

export const IncomeUpdateError = () => {
  return (
    <div
      data-testid="update-income-error-panel"
      className="db tl f6 ink bg-white bg-washed-red b--red red white pv3 ph3 br1 sans-serif"
    >
      <ul className="list pa0 ma0">
        <li>
          We're having trouble updating your income right now. Please send us a{' '}
          <a className="link blue hover-indigo" href={CONTACT_US_LINK}>
            message
          </a>
          , or call us at{' '}
          <SupportPhoneLink className="blue hover-indigo link" /> Monday through
          Friday from 9am to 8pm ET.
        </li>
      </ul>
    </div>
  );
};
