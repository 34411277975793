import LoadingSpinner from '../../General/LoadingSpinner';
import { useQuery } from '@apollo/client';
import { useAccount } from '../../Auth/AccountContext';
import KatabatError from './KatabatError';
import PageWrapper from '@core/components/Page/PageWrapper';
import { PAYMENT_PLAN_QUERY, PaymentPlanQueryType } from '@core/queries';

/**
 * This component is used for redirecting a user who clicks on a katabat link
 * from external comms (e.g. emails) to the correct paymentPlanUrl
 */
const KatabatExternalRoute = () => {
  const { accountId } = useAccount();
  const { loading, error, data } = useQuery<PaymentPlanQueryType>(
    PAYMENT_PLAN_QUERY,
    {
      variables: { accountId },
    },
  );

  if (loading) return <LoadingSpinner />;

  if (error) {
    return <KatabatError />;
  }

  const paymentPlanUrl = data?.account?.paymentPlanUrl;
  if (!paymentPlanUrl) {
    return <KatabatError />;
  }

  window.location.href = paymentPlanUrl;
  return <LoadingSpinner />;
};

const KatabatExternalRouteWithPageWrapper = () => (
  <PageWrapper>
    <KatabatExternalRoute />
  </PageWrapper>
);

export default KatabatExternalRouteWithPageWrapper;
