import { Button, Spacing } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

export const StatementsButton = () => {
  const navigate = useNavigate();

  return (
    // offset the button padding
    <div style={{ position: 'relative', left: `${Spacing.s}px` }}>
      <Button
        variant="text"
        icon={{ position: 'trailing', name: 'document' }}
        text="Statements"
        onPress={() => navigate('../statements')}
      />
    </div>
  );
};
