import dayjs from 'dayjs';
import { getOrdinalFromDayOfMonth } from '@core/utils/getOrdinalDate';

export interface DateOption {
  value: number;
  label: string;
  disabled: boolean;
}

const autoPaymentDateOptions = (
  dueDate: string | null,
  autopayStartDateRange: number[],
): DateOption[] => {
  // build an array from 1-31, starting at the same number as
  // autopayStartDateRange[0]
  return Array.from(
    { length: 31 },
    (_, i) => (i + autopayStartDateRange[0]) % 31,
  ).map((day) => {
    // modulo causes day 31 in the array to be 0, change it back to 31
    if (day === 0) day = 31;
    // if the day is in the autopayStartDateRange, return the enabled value
    if (autopayStartDateRange.includes(day)) {
      return {
        value: day,
        label: `${getOrdinalFromDayOfMonth(day)}${
          dueDate && day.toString() === dayjs(dueDate).format('D') ?
            ' (due date)'
          : ''
        }`,
        disabled: false,
      };
    } else {
      // if it's not in the autopayStartDateRange, return the disabled value
      return {
        value: day,
        label: `${getOrdinalFromDayOfMonth(day)} (unavailable)`,
        disabled: true,
      };
    }
  });
};

export default autoPaymentDateOptions;
