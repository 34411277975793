import partOfDay from '@core/utils/partOfDay';
import { Title } from '@core/components/TitleBar/TitleBar';
import {
  GetCreditCardsSectionDataQuery,
  GetCreditCardsSectionData,
} from '@core/graphql/globalTypes';
import isNil from '@core/utils/isNil';

export function getCustomerGreeting(customerFirstName?: string): Title {
  if (customerFirstName) {
    return {
      primaryText: `Good ${partOfDay()},`,
      secondaryText: customerFirstName,
    };
  }
  return {
    primaryText: `Good ${partOfDay()}`,
  };
}

export const checkIfSomeCreditCardsAreHydrated = (
  creditCards: GetCreditCardsSectionDataQuery['creditCards'],
) =>
  creditCards.some(
    (cc) => !isNil(cc.currentBalance) && !isNil(cc.availableCredit),
  );

/**
 * Checks if there is a credit application with a null accountId, which is an
 * identifier for an account that has not yet been created in ECS
 *
 * @returns {GetCreditCardsSectionData.CreditApplications | undefined}
 * Either the pending account or undefined if none exists
 */
export const findPendingAccountFromApprovedCreditApplications = (
  creditApplications:
    | GetCreditCardsSectionData.CreditApplications[]
    | null
    | undefined,
) =>
  creditApplications &&
  creditApplications.find((creditApp) => creditApp.accountId === null);
