import { B, Button } from '@missionlane/compass-ui';
import { ReactComponent as ApprovedConfetti } from '../../../assets/approved.svg';
import { useEffect, useState } from 'react';
import Disclosures from './Disclosures';
import { PaymentPlanStepProps } from './CreatePaymentPlanFlow';
import { useMutation } from '@apollo/client';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import {
  UpdateOfferMutation,
  MutationUpdateOfferArgs,
  ServicingOfferChannel,
} from '@core/graphql/globalTypes';
import { UPDATE_OFFER } from '../gql/mutations';
import LoadingModal from '@core/components/General/LoadingModal';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { getOfferEndDate } from '../utils/getOfferEndDate';
import { TrackService } from '@core/services';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

enum ConfirmationStep {
  DISCLOSURES = 'DISCLOSURES',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
}

interface OfferConfirmationProps extends PaymentPlanStepProps {
  fundingAccountLast4?: string;
}

const OfferConfirmation = ({
  nextStep,
  offer,
  fundingAccountLast4,
}: OfferConfirmationProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDisplayComplete, setModalDisplayComplete] = useState(false);
  const [acceptOfferComplete, setAcceptOfferComplete] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState<ConfirmationStep>(
    ConfirmationStep.DISCLOSURES,
  );

  const [acceptOffer, { error }] = useMutation<
    UpdateOfferMutation,
    MutationUpdateOfferArgs
  >(UPDATE_OFFER, {
    onCompleted: () => {
      setAcceptOfferComplete(true);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (acceptOfferComplete && modalDisplayComplete) {
      setConfirmationStep(ConfirmationStep.OFFER_ACCEPTED);
    }
  }, [acceptOfferComplete, modalDisplayComplete]);

  useEffect(() => {
    if (confirmationStep === ConfirmationStep.OFFER_ACCEPTED) {
      TrackService.trackPage('Create Program - Offer Accepted', {
        feature: SETTLEMENTS_FEATURE_NAME,
      });
    }
  }, [confirmationStep]);

  const { originalSettlementBalance, paymentPlan } = offer?.data || {};

  if (
    error ||
    !offer ||
    !originalSettlementBalance ||
    !paymentPlan ||
    !paymentPlan.transactions?.length
  ) {
    return <GenericFallbackFull className="bg-haze-lightest" />;
  }

  const handleOfferAccepted = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      setModalDisplayComplete(true);
    }, 2000);

    acceptOffer({
      variables: {
        offerId: offer.offerId,
        channel: ServicingOfferChannel.Web,
        requestBody: {
          paymentPlanCreate: null,
          paymentPlanUpdate: null,
          fundingAccountUpdate: null,
          acceptOffer: true,
          cancelOffer: false,
          declineOffer: false,
        },
      },
    });
  };

  if (confirmationStep === ConfirmationStep.OFFER_ACCEPTED) {
    return (
      <div className="flex flex-column items-center h-100-ns vh-50 justify-between mb7">
        <div className="flex flex-column items-center mt0-ns">
          <ApprovedConfetti className="w-50" />
          <div className="mt0">
            <ResponsiveTypography
              type="HEADER"
              mobileLevel="H2"
              desktopLevel="H3"
            >
              You’re All Set!
            </ResponsiveTypography>
          </div>
          <div className="pa3">
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
            >
              <B color="ink">Bravo!</B> You’re on your way to{' '}
              <B color="ink">being rid of your debt.</B>
            </ResponsiveTypography>
          </div>
        </div>

        <div className="w-100 w-50-ns flex-ns justify-center-ns">
          <Button text="Return to Dashboard" onPress={() => nextStep?.()} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Disclosures
        originalSettlementBalance={originalSettlementBalance}
        endDate={getOfferEndDate(paymentPlan)}
        onOfferAccepted={handleOfferAccepted}
        fundingAccountLast4={fundingAccountLast4}
      />
      <LoadingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        primaryText="Hang tight, we’re finalizing your plan."
        secondaryText="This can take a few seconds so please don’t exit or refresh the page."
      />
    </div>
  );
};

export default OfferConfirmation;
