import toTitleCase from '@core/utils/toTitleCase';
import { P3 } from '@missionlane/compass-ui';

interface Props {
  merchant: {
    name: string | null;
    address?: {
      city: string | null;
      state: string | null;
    } | null;
  };
}

export const MerchantDetails = ({ merchant }: Props) => (
  <P3 style={{ marginVertical: 0 }}>
    {merchant.name && toTitleCase(merchant.name)}
    {merchant.name && merchant.address?.city ? ', ' : ''}
    {merchant.address?.city &&
      `${toTitleCase(merchant.address.city)}, ${merchant.address.state}`}
  </P3>
);
