import { Icon, TypographyProps } from '@missionlane/compass-ui';
import { Colors, P3 } from '@missionlane/compass-ui';
import { useState } from 'react';

export interface TooltipProps {
  message: string;
  textStyles?: TypographyProps['style'];
}

const Tooltip = ({ message, textStyles }: TooltipProps) => {
  const [showTip, setShowTip] = useState(false);

  return (
    <span
      className="mh1"
      onMouseEnter={() => {
        setShowTip(true);
      }}
      onMouseLeave={() => {
        setShowTip(false);
      }}
    >
      <Icon name="helpCircle" color="blue" />
      {showTip && (
        <span
          className="absolute z-1 w5 ph3 pv2 br2"
          style={{ backgroundColor: Colors.pine }}
        >
          <P3 style={textStyles}>{message}</P3>
        </span>
      )}
    </span>
  );
};

export default Tooltip;
