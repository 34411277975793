import { FormState, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import ControlledSelectField from './ControlledSelectField';
import states from './states';
import * as yup from 'yup';
import { Notification } from '@missionlane/compass-ui';
import HookFormTextField from '../../Form/HookFormTextField/HookFormTextField';
import { ResponsiveButton } from '@core/components/Button';
import './AddressForm.css';
import ExternalLink from '@core/components/General/ExternalLink';
import PhoneLink from '@core/components/General/PhoneLink';
import { cardSupportPhone } from '@core/utils/contact';
import { TrackService } from '@core/services';
import { CONTACT_US_LINK } from '@core/utils/constants';
import { AddressType } from '@core/components/ChangeAddress/AddressForm/AddressAutocomplete/types';
import AddressAutocomplete from './AddressAutocomplete/AddressAutocomplete';

yup.setLocale({
  mixed: {
    required: 'This field cannot be blank.',
  },
});

const setValueOptions = {
  shouldValidate: true,
};

export type UpdateAddressFormValues = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
};

const InvalidDataError = () => (
  <div className="mt4">
    <Notification level="error" variant="inline">
      We couldn't validate the address you entered. To update your address,
      please contact us{' '}
      <ExternalLink to={CONTACT_US_LINK} className="inline-link">
        here
      </ExternalLink>{' '}
      or call us at{' '}
      <PhoneLink className="inline-link" phoneNumber={cardSupportPhone} />{' '}
      Monday through Friday from 9am to 8pm EST.
    </Notification>
  </div>
);

export interface AddressFormProps {
  error?: Error;
  onSubmit: () => void;
  onCancel: () => void;
  setValue: UseFormSetValue<UpdateAddressFormValues>;
  getValues: UseFormGetValues<UpdateAddressFormValues>;
  formState: Pick<
    FormState<UpdateAddressFormValues>,
    'errors' | 'isSubmitting' | 'isValid'
  >;
}

function AddressForm({
  onSubmit,
  onCancel,
  error,
  formState,
  setValue,
  getValues,
}: AddressFormProps) {
  const { errors: formErrors, isSubmitting, isValid } = formState;
  const { street1 } = getValues() || {};

  const handleButtonPress = () => {
    TrackService.click('Address Form Confirm');
    onSubmit();
  };

  const handleAutocompleteOnSelect = (address: AddressType) => {
    setValue('street1', address.street_line, setValueOptions);
    setValue('street2', address.secondary, setValueOptions);
    setValue('city', address.city, setValueOptions);
    setValue('state', address.state, setValueOptions);
    setValue('postalCode', address.zipcode, setValueOptions);
  };

  return (
    <form>
      <AddressAutocomplete
        errors={formState.errors}
        onSelect={handleAutocompleteOnSelect}
        street1={street1}
      />
      <HookFormTextField
        name="street2"
        label="Apartment # / Suite"
        error={formErrors?.street2?.message}
        placeholder=""
      />
      <HookFormTextField
        name="city"
        label="City"
        error={formErrors?.city?.message}
        testID="city"
        placeholder=""
      />
      <ControlledSelectField
        name="state"
        label="State"
        options={states}
        error={formErrors?.state?.message}
        placeholder={'Select a state'}
        // TODO: need to add testID to compass-ui
        // testID="state"
      />
      <HookFormTextField
        name="postalCode"
        label="Zip Code"
        error={formErrors?.postalCode?.message}
        maxLength={5}
        testID="postalCode"
        placeholder=""
      />
      {error && <InvalidDataError />}

      <div className="button-container">
        <div className="submit-button-container">
          <ResponsiveButton
            onPress={handleButtonPress}
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid}
            text="Confirm"
          />
        </div>

        <ResponsiveButton onPress={onCancel} variant="text" text="Cancel" />
      </div>
    </form>
  );
}

export default AddressForm;
