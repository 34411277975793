import { useState } from 'react';
import TrackingLink from '../TrackingLink';
import { SocialLink, socialLinks } from './footerLinks';
import { Colors } from '@missionlane/compass-ui';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import ExternalBrandIcon from '../ExternalBrandIcon/ExternalBrandIcon';

const ExternalBrandIconLink = ({ href, label }: Omit<SocialLink, 'id'>) => {
  const [isHovered, setIsHovered] = useState(false);
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TrackingLink
        to={href}
        target="_blank"
        trackingName={`Footer: ${label} link`}
        trackingProperties={customerAndAccountIds}
      >
        <ExternalBrandIcon
          name={label}
          color={isHovered ? Colors.blueLight : Colors.white}
          size={20}
        />
      </TrackingLink>
    </div>
  );
};

export const SocialMediaLinks = () => (
  <ul className="list flex pa0 mv4">
    {socialLinks.map(({ id, href, label }) => (
      <li key={id} className="ml3">
        <ExternalBrandIconLink href={href} label={label} />
      </li>
    ))}
  </ul>
);
