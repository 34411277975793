import React from 'react';
import { Link } from 'react-router-dom';
import { Link as CompassLink, H3, Icon, P3 } from '@missionlane/compass-ui';

interface ForgotPasswordContainerProps {
  children: React.ReactNode;
  header: string;
  signInButtonHeader?: boolean;
}

const ForgotPasswordContainer = ({
  children,
  header,
  signInButtonHeader = true,
}: ForgotPasswordContainerProps) => (
  <div className="flex justify-center items-center">
    <div style={{ width: '25rem' }} className="center ph3 ph0-l pt3 pt4-l">
      {signInButtonHeader && (
        <Link to="../signin">
          <P3>
            <span className="inline-flex items-center">
              <Icon name="back" color="blue" size={12} />
              <CompassLink>Back to Sign in</CompassLink>
            </span>
          </P3>
        </Link>
      )}
      <div className="mb4">
        <H3>{header}</H3>
      </div>
      <div className="mt4">{children}</div>
    </div>
  </div>
);

export default ForgotPasswordContainer;
