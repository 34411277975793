import { SpendBonus } from '@clip/SpendBonus/hooks';
import { TrackService } from '@core/services';

const FEATURE_NAME = 'Spend Bonus';

export enum EventNames {
  OfferViewed = 'Offer Viewed',
  HideOffer = 'Hide Offer Clicked',
  // Opt Out has tracking in the StepModal component
}

export default async ({
  rewardType,
  eventName,
  status,
}: {
  eventName: EventNames;
  status: SpendBonus['status'];
  rewardType: SpendBonus['rewardType'];
}): Promise<void> =>
  TrackService.track({
    event: eventName,
    properties: {
      name: `${FEATURE_NAME}: ${eventName}`,
      feature_name: FEATURE_NAME,
      reward_type: rewardType,
      offer_status: status,
      platform: 'web',
    },
  });
