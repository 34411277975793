// This LaunchDarklyClient exists for the sole purpose of using LD flag checks outside of components.
// If you are able to use the react-client-sdk, use that instead.

import * as LDClient from 'launchdarkly-js-client-sdk';

const LaunchDarklyClient = LDClient.initialize(
  process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID || '',
  { key: 'user_key', anonymous: true },
);

LaunchDarklyClient.on('change:graphql-uri-weighting', (value, previous) => {
  console.log('graphql-uri-weighting changed to:', value, 'from: ' + previous);
});

export default LaunchDarklyClient;
