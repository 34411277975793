import { gql } from '@apollo/client';

export const ACCOUNT_SUMMARY_CARDHOLDER_INFO_FRAGMENT = gql`
  fragment AccountSummaryCardholderInfo on Customer {
    contactInfo {
      email
      phoneNumber
      firstName
      lastName
      address {
        city
        postalCode
        state
        street1
        street2
      }
    }
  }
`;
