import {
  useQuery,
  useLazyQuery,
  ApolloError,
  useMutation,
} from '@apollo/client';
import AddAccountBanner, {
  AddAccountBannerStatuses,
} from '@payments/components/BankAccount/AddBankAccount/AddAccountBanner';
import {
  GET_PLAID_LINK_TOKEN,
  GET_EXCHANGE_PUBLIC_TOKEN_QUERY,
} from '@payments/components/BankAccount/AddPlaidAccount/AddPlaidAccount';
import { ADD_PLAID_FUNDING_ACCOUNT_MUTATION } from '@payments/components/BankAccount/queries';
import {
  AddPlaidFundingAccountMutation,
  AddPlaidFundingAccountMutationVariables,
  Channel,
  GetExchangePublicTokenQuery,
  GetExchangePublicTokenQueryVariables,
  GetPlaidLinkTokenQuery,
} from '@core/graphql/globalTypes';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExit,
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  usePlaidLink,
} from 'react-plaid-link';
import { useLocation } from 'react-router-dom';
import PlaidModal from './PlaidModal/PlaidModal';
import { MLError } from '@core/services';

interface PlaidContainerProps {
  children: (openPlaidModal: () => void) => ReactNode;
}

let accessTokenId = '';
let externalFundingAccountId = '';

const PlaidContainer = ({ children }: PlaidContainerProps) => {
  const [bannerStatus, setBannerStatus] = useState<AddAccountBannerStatuses>();
  const [plaidLoading, setPlaidLoading] = useState(false);
  const [showPlaidModal, setShowPlaidModal] = useState(false);
  const location = useLocation();

  const { data: plaidLinkTokenData } =
    useQuery<GetPlaidLinkTokenQuery>(GET_PLAID_LINK_TOKEN);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      externalFundingAccountId = metadata.accounts[0].id;
      if (
        plaidLinkTokenData?.plaidLinkToken.productRegistration.productAccess[0]
          .accessTokenId
      ) {
        accessTokenId =
          plaidLinkTokenData.plaidLinkToken.productRegistration.productAccess[0]
            .accessTokenId;
        getAccessToken({
          variables: {
            publicToken: public_token,
            accessTokenId,
          },
        });
      } else {
        displayErrorBanner();
      }
    },
    [plaidLinkTokenData],
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      MLError.report({
        name: 'PlaidOnExit',
        message: JSON.stringify(metadata),
      });
      setPlaidLoading(false);
      if (error && error.error_code === 'INVALID_LINK_TOKEN') {
        setBannerStatus(AddAccountBannerStatuses.ERROR);
      }
    },
    [],
  );

  const { open: openPlaid } = usePlaidLink({
    token: plaidLinkTokenData?.plaidLinkToken.token || '',
    onSuccess,
    onExit,
  });

  const [getAccessToken] = useLazyQuery<
    GetExchangePublicTokenQuery,
    GetExchangePublicTokenQueryVariables
  >(GET_EXCHANGE_PUBLIC_TOKEN_QUERY, {
    onCompleted: async (accessTokenData) => {
      if (accessTokenData.exchangePublicToken) {
        try {
          await addFundingAccount({
            variables: {
              externalFundingAccountId,
              accessTokenId,
              channel: Channel.Web,
            },
          });
        } catch (error: unknown) {
          displayErrorBanner();
          // we already notify MLError of ApolloErrors in apolloClient
          if (error instanceof Error && !(error instanceof ApolloError))
            MLError.report({
              name: error.name,
              error,
            });
        }
      } else {
        displayErrorBanner();
      }
    },
    onError: () => {
      displayErrorBanner();
    },
  });

  const [addFundingAccount] = useMutation<
    AddPlaidFundingAccountMutation,
    AddPlaidFundingAccountMutationVariables
  >(ADD_PLAID_FUNDING_ACCOUNT_MUTATION, {
    onCompleted: () => {
      setShowPlaidModal(false);
      setBannerStatus(AddAccountBannerStatuses.SUCCESS);
      setTimeout(() => {
        setPlaidLoading(false);
      }, 1000);
    },
    onError: () => {
      displayErrorBanner();
    },
    refetchQueries: [
      { query: ACH_FUNDING_ACCOUNTS_QUERY },
      { query: GET_PLAID_LINK_TOKEN },
    ],
  });

  function closeBanner() {
    setBannerStatus(undefined);
  }

  function displayErrorBanner() {
    setShowPlaidModal(false);
    //Adding a timeout prevents the user from seeing the modal changing from loading to the initial state.
    setTimeout(() => {
      setPlaidLoading(false);
    }, 1000);
    setBannerStatus(AddAccountBannerStatuses.ERROR);
  }

  function launchPlaid() {
    if (plaidLinkTokenData?.plaidLinkToken.token) {
      setPlaidLoading(true);
      openPlaid();
    } else {
      displayErrorBanner();
    }
  }

  function openPlaidModal() {
    setBannerStatus(undefined);
    setShowPlaidModal(true);
  }

  useEffect(() => {
    if (plaidLinkTokenData) {
      sessionStorage.setItem(
        'plaidLinkTokenData',
        JSON.stringify(plaidLinkTokenData),
      );
      sessionStorage.setItem('redirectOAuth', location.pathname);
    }
  }, [plaidLinkTokenData]);

  return (
    <div>
      {(bannerStatus === AddAccountBannerStatuses.SUCCESS ||
        bannerStatus === AddAccountBannerStatuses.ERROR) && (
        <AddAccountBanner
          bannerStatus={bannerStatus}
          closeBanner={closeBanner}
          setShowPlaidModal={setShowPlaidModal}
        />
      )}
      <PlaidModal
        isOpen={showPlaidModal}
        loading={plaidLoading}
        setShowPlaidModal={setShowPlaidModal}
        launchPlaid={launchPlaid}
      />
      {children(openPlaidModal)}
    </div>
  );
};

export default PlaidContainer;
