export enum CFUStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export type CashFlowUnderwritingProgress = {
  eligible: boolean;
  evaluationStatus: CFUStatus;
  offerExpirationDate: string | null; // 'INITIAL'
  lineIncreaseAmount: number | null; // 'APPROVED'
  nextEvaluationDate: string | null; // 'APPROVED' | 'DECLINED'
};
