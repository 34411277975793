import { ApolloError, gql, useQuery } from '@apollo/client';
import AccountDetailsBody from './AccountDetailsBody';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useEffect, useMemo, useState } from 'react';
import { TrackService } from '@core/services';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { useAccountPageTitle } from '@core/components/Account/useAccountPageTitle';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { PendingInfoNotification } from './PendingInfoNotification';
import { AccountDetailsWarningNotification } from './AccountDetailsWarningNotification';
import { ACCOUNT_SUMMARY_DETAILS_FRAGMENT } from './AccountDetailsAccountSummary/AccountDetailsAccountSummary';
import { ACCOUNT_SUMMARY_CASH_ADVANCE_FRAGMENT } from './AccountDetailsCashAdvances';
import { ACCOUNT_SUMMARY_CARD_TERMS_FRAGMENT } from './AccountDetailsCardTerms';
import { ACCOUNT_SUMMARY_CARDHOLDER_INFO_FRAGMENT } from './fragments';
import { AccountDetailsPageQuery } from '@core/graphql/globalTypes';
import { isErrorResult } from '@core/utils/isErrorResult';
import { CAG_DISCLOSURE_FRAGMENT } from './CagDisclosureMessage';
import { PAGE_NAME } from './constants';
import { ErrorBoundary } from '@core/services/Error/Error';

export const ACCOUNT_DETAILS_PAGE_QUERY = gql`
  query AccountDetailsPage($accountId: String!) {
    customer {
      id
      contactInfo {
        firstName
        lastName
      }
      ...AccountSummaryCardholderInfo
    }
    account(accountId: $accountId) {
      id
      statuses {
        hasAppFraud
        isChargedOff
      }
      ...AccountSummaryCardTerms
      ...AccountSummaryDetails
      ...AccountSummaryCashAdvance
    }
    cagByAccountId(accountId: $accountId) {
      url
      ...CagDisclosure
    }
  }
  ${ACCOUNT_SUMMARY_CARD_TERMS_FRAGMENT}
  ${ACCOUNT_SUMMARY_DETAILS_FRAGMENT}
  ${ACCOUNT_SUMMARY_CASH_ADVANCE_FRAGMENT}
  ${ACCOUNT_SUMMARY_CARDHOLDER_INFO_FRAGMENT}
  ${CAG_DISCLOSURE_FRAGMENT}
`;

const breadcrumbs = [
  {
    text: PAGE_NAME,
  },
];

const AccountDetailsPage = () => {
  const { customerId, accountId } = useCustomerAndAccountIdentifiers();
  const [hasWarning, setHasWarning] = useState<boolean>(false);
  const [hasPendingInfo, setHasPendingInfo] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery<AccountDetailsPageQuery>(
    ACCOUNT_DETAILS_PAGE_QUERY,
    {
      errorPolicy: 'all',
      variables: { accountId },
      skip: !accountId,
    },
  );

  const onRefresh = async () => {
    setHasWarning(false);
    await refetch();
  };

  const pageTitle = useAccountPageTitle(PAGE_NAME);

  useEffect(() => {
    TrackService.page('Account details', { customerId, accountId });
  }, []);

  const hasErrorResult = useMemo(
    () => getHasErrorResult(data, error),
    [data, error],
  );

  /**
   * once we're done loading, if we have any errors or ErrorResults
   * in the query result, display the warning message
   */
  useEffect(() => {
    if (!loading && hasErrorResult) {
      setHasWarning(true);
    }
  }, [hasErrorResult, loading]);

  /**
   * once we're done loading, if we have any pending information
   * in the query result, display the pending info message
   */
  useEffect(() => {
    if (!loading && getHasPendingInfo(data)) {
      setHasPendingInfo(true);
    }
  }, [data, loading]);

  return (
    <PageWrapper
      greyBackground
      pageTitle={pageTitle}
      banners={[
        () =>
          AccountDetailsWarningNotification({
            hasWarning,
            setHasWarning,
            onRefresh,
          }),
        () => PendingInfoNotification({ hasPendingInfo, setHasPendingInfo }),
      ]}
    >
      <ErrorBoundary>
        <AccountBreadcrumbs items={breadcrumbs} />
        <AccountDetailsBody data={data} loading={loading} />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default AccountDetailsPage;

/** Recursive function to check if any field in the query result is an ErrorResult */
function getHasErrorResult(data?: object, error?: ApolloError) {
  // if we have an ApolloError, we obviously have an error result, so exit early and return true
  if (error) return true;
  // if we don't have any data, we don't have any ErrorResults, so exit and return false
  if (!data) return false;
  // if the top-level __typename for the data object is ErrorResult, we have an error,
  // so exit and return true
  if (isErrorResult(data)) return true;
  // for each key of the data object, check if there is an ErrorResult. If so, exit and
  // return true. If not, move down one level and check for ErrorResult until we reach
  // the end of any nested objects
  for (const key in data) {
    const childData = data[key as keyof typeof data];
    if (childData && typeof childData === 'object') {
      // we don't need to pass `error` through here since we've already returned true
      // if error is defined
      if (getHasErrorResult(childData)) return true;
    }
  }
  // if no ErrorResults were found, we have no errors! return false
  return false;
}

/**
 * When an account is created with Intro APR, the intro APR is not applied until
 * TSYS runs their batch processing (8pm EST).
 *
 * Before that time, we know they have an intro APR but we don't know what that
 * intro APR percentage is or when it ends, so we display a "some info pending" banner
 */
function getHasPendingInfo(data?: AccountDetailsPageQuery) {
  return (
    // it's a successful promotions query
    data?.account?.promotions?.__typename === 'Promotions' &&
    // and it's a successful introductoryPurchaseApr query
    data.account.promotions.introductoryPurchaseApr?.__typename ===
    'IntroductoryPurchaseApr' &&
    // and it's a successful futurePurchaseAprData query
    data.account.promotions.introductoryPurchaseApr.futurePurchaseAprData
      ?.__typename === 'FuturePurchaseApr' &&
    // but the futurePurchaseApr value is null
    data.account.promotions.introductoryPurchaseApr.futurePurchaseAprData ===
    null
  );
}
