import { H4, P2, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import Kard from '@core/components/General/Kard/Kard';
import { centsToDollars } from '@core/utils/centsToDollars';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

interface Props {
  currentBalance: number;
  isPIF?: boolean;
  testID?: string;
}

const BalanceKard = ({
  currentBalance,
  isPIF = false,
  testID = 'BalanceKard',
}: Props) => {
  const navigate = useNavigate();
  return (
    <Kard testID={testID}>
      <div className="mb2 mb3-ns">
        <H4>Total Balance Due</H4>
      </div>
      <ResponsiveTypography
        type="HEADER"
        mobileLevel="H1"
        desktopLevel="H2"
        color={isPIF ? 'ink' : 'red'}
      >
        {centsToDollars(currentBalance)}
      </ResponsiveTypography>
      {isPIF ?
        <P2>Paid in full</P2>
      : <P2>
          Your account is charged off and can't be used.{' '}
          <Link onPress={() => navigate('../payment-plan/faq')}>
            What does this mean?
          </Link>
        </P2>
      }
    </Kard>
  );
};

export default BalanceKard;
