import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { Notification } from '@missionlane/compass-ui';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import { useContext } from 'react';
import { CancelPaymentContext } from './CancelPaymentContext';

import { formatCardLast4 } from '@core/utils/formatters';
import PaymentFlowCard from '../Payments/PaymentFlowCard';
import { useNavigate } from 'react-router-dom';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import PageWrapper from '@core/components/Page/PageWrapper';

const CancelPaymentSuccess = () => {
  const {
    paymentToCancel: {
      amount,
      confirmationId,
      fundingAccount,
      date,
      cardLast4,
    },
  } = useContext(CancelPaymentContext);
  const accountSummaryPath = useAccountSummaryPath();
  const navigate = useNavigate();

  if (!amount || !confirmationId || !fundingAccount || !date) {
    return <GenericFallbackFullPage />;
  }

  return (
    <PageWrapper
      pageTitle={{
        primaryText: 'Cancel Payment',
        secondaryText: formatCardLast4(cardLast4),
      }}
      greyBackground
      trackingProperties={{
        featureName: 'Payments: Cancel Payment',
        pageName: 'Cancel Payment: Success',
      }}
    >
      <div className="flex justify-center sans-serif lh-copy">
        <PaymentFlowCard header="Payment Canceled">
          <div className="mb4">
            <Notification variant="inline" level="success">
              We&rsquo;ve sent you an email which includes your confirmation
              number ({confirmationId}) and cancellation details.
            </Notification>
          </div>
          <PaymentConfirmationButtons
            leftAlign
            text="Done"
            cancelButtonText="Schedule a new payment"
            onSubmit={() => {
              navigate(accountSummaryPath);
            }}
            onCancel={() => {
              navigate('../../../../payments/pay/make-ach-payment');
            }}
          />
        </PaymentFlowCard>
      </div>
    </PageWrapper>
  );
};

export default CancelPaymentSuccess;
