import { gql } from '@apollo/client';
import { FUNDING_ACCOUNT_FIELDS } from '@payments/graphql/fundingAccountQueries';

export const ADD_FUNDING_ACCOUNT_MUTATION = gql`
  mutation AddFundingAccount(
    $accountNumber: String!
    $routingNumber: String!
    $status: FundingAccountStatus
    $channel: Channel
  ) {
    addFundingAccount(
      accountNumber: $accountNumber
      routingNumber: $routingNumber
      status: $status
      channel: $channel
    ) {
      ...FundingAccountFields
    }
  }
  ${FUNDING_ACCOUNT_FIELDS}
`;

export const ADD_PLAID_FUNDING_ACCOUNT_MUTATION = gql`
  mutation AddPlaidFundingAccount(
    $accessTokenId: String!
    $externalFundingAccountId: String!
    $channel: Channel
  ) {
    linkFundingAccount(
      accessTokenId: $accessTokenId
      externalFundingAccountId: $externalFundingAccountId
      channel: $channel
    ) {
      ...FundingAccountFields
    }
  }
  ${FUNDING_ACCOUNT_FIELDS}
`;
