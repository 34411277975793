import { useGetCustomerQuery } from './useGetCustomerQuery';
import { TrackService } from '@core/services';
import { useEffect, useState } from 'react';
import { IncomeUpdateTrackingNames } from '../lib';
import { OfferNames } from '@core/graphql/globalTypes';

type UseIncomeUpdate = {
  trackingName: IncomeUpdateTrackingNames;
  enabled: boolean;
};

export type UseIncomeUpdateResult = ReturnType<typeof useIncomeUpdate>;

/**
 * useIncomeUpdate this hook acts as a controller for income update functionality.
 * It will request user data, emit tracking events, and provide success and error handlers that
 * can be re-used whereever we need to update income
 *
 * @param {Object} UseIncomeUpdate - income update hook config
 * @param {IncomeUpdateTrackingNames} UseIncomeUpdate.trackingName - the tracking event which varies based on where the hook is used.
 * @param {boolean} UseIncomeUpdate.enabled - where or not the hook should execute requests and emit events
 * @returns
 */
export const useIncomeUpdate = ({ trackingName, enabled }: UseIncomeUpdate) => {
  const { data, loading, error, refetch } = useGetCustomerQuery({
    skip: !enabled,
  });

  const [updateError, setUpdateError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const pieAmount = data?.customer.financialInfo?.offers?.find(
    (offer) => offer?.name === OfferNames.PieOffer,
  )?.amount;

  const onSuccess = () => {
    setUpdateSuccess(true);
    TrackService.click(`${trackingName}: Submit`);
    return new Promise((resolve) => {
      setTimeout(() => {
        refetch().finally(() => {
          resolve(null);
        });
      }, 3000);
    });
  };

  const onError = () => {
    setUpdateError(true);
  };

  useEffect(() => {
    if (enabled && data?.customer?.financialInfo?.shouldGetCLIPTout) {
      TrackService.page(trackingName);
    }
  }, [enabled, data?.customer?.financialInfo?.shouldGetCLIPTout]);

  return {
    customerQueryData: data,
    customerQueryError: error,
    customerQueryLoading: loading,
    updateSuccess,
    updateError,
    pieAmount,
    onError,
    onSuccess,
  };
};
