import { Notification } from '@missionlane/compass-ui';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const AddBankAccountBanner = () => {
  const location = useLocation();
  const [showBanner, setShowBanner] = useState<boolean>(
    !!location.state?.addBankAccountSuccess,
  );

  return (
    <Notification
      level="success"
      onDismiss={() => setShowBanner(false)}
      show={showBanner}
      variant="banner"
    >
      Checking account added!
    </Notification>
  );
};
export default AddBankAccountBanner;
