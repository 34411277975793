import {
  B,
  BubbleIcon,
  Fonts,
  H4,
  Icon,
  P3,
  Spacer,
} from '@missionlane/compass-ui';
import Kard from '@core/components/General/Kard/Kard';
import './OfferDetailsKard.css';
import { FundingAccountsQuery } from '@core/graphql/globalTypes';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import { useNavigate } from 'react-router-dom';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface FundingAccountKardProps {
  fundingAccountId?: number;
}

const FundingAccountKard = ({ fundingAccountId }: FundingAccountKardProps) => {
  const { isMobile } = useUserDevice();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<FundingAccountsQuery>(
    ACH_FUNDING_ACCOUNTS_QUERY,
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  const fundingAccount = data?.fundingAccounts?.find(
    (acc) => acc.id === fundingAccountId,
  );

  if (!fundingAccountId || error || !fundingAccount) {
    return null;
  }

  return (
    <Kard>
      <H4>
        <B>Your Funding Account</B>
      </H4>
      <Spacer size="m" />
      <div
        className="ba bw--1px b--grey-lightest br3 pa3 flex items-center pointer"
        onClick={() => navigate('../payment-plan/edit-funding-account')}
      >
        <div className="pr3">
          <BubbleIcon name="bank" iconColor="green" bubbleColor="greenWashed" />
        </div>
        <div className="flex flex-auto justify-between items-center">
          <div className="flex flex-column pr2">
            <P3
              color="ink"
              style={{
                marginVertical: 0,
                fontFamily: Fonts.semiBold,
                maxWidth: isMobile ? 200 : undefined,
              }}
            >
              {`${fundingAccount.bankName}...${fundingAccount.numberLast4}`}
            </P3>
            <P3 style={{ marginVertical: 0 }}>Checking</P3>
          </div>
          <Icon name="edit" color="blue" />
        </div>
      </div>
    </Kard>
  );
};

export default FundingAccountKard;
