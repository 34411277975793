import { Link } from '@missionlane/compass-ui';
import autopay from '@core/assets/autopay.svg';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
} from './LayoutComponents';
import { useNavigate } from 'react-router-dom';
import { TrackService } from '@core/services';
import { useEffect } from 'react';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';

export const TRY_AUTOPAY_TILE_EVENT = 'Opportunity Tile: Try Autopay ';

const AutopayTile = ({ onDismiss }: CarouselTileProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.trackEvent('Offer Viewed', {
      feature: TRY_AUTOPAY_TILE_EVENT,
    });
  }, []);

  return (
    <OpportunityTileContainer backgroundColor="yellowWashed">
      <OpportunityTileDiagonalBackground
        background="yellow"
        imgAlt="autopay calendar"
        imgSource={autopay}
        imgWidth={IMAGE_WIDTH}
        imageRight={IMAGE_RIGHT}
        imageBottom={IMAGE_BOTTOM}
      />
      <OpportunityTileCTA
        trackingName={TRY_AUTOPAY_TILE_EVENT}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title="Try autopay"
        text="Experience the ease of automatic payments."
        onButtonClick={() => navigate('../payments/autopay')}
        buttonLabel="Set up"
      />
      <Link
        onPress={() => onDismiss(TRY_AUTOPAY_TILE_EVENT)}
        style={{
          zIndex: 2,
        }}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default AutopayTile;

const IMAGE_WIDTH = 125;
const IMAGE_RIGHT = 6;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;
const IMAGE_BOTTOM = 6;
