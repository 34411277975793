import { DescriptionListDetailItem } from './DescriptionListDetailItem';
import { DescriptionListItem } from './DescriptionListItem';

interface DescriptionListProps {
  children: React.ReactNode;
}

/** Simple dl (description list) HTML element which accepts
 * children. Children should be DescriptionListItems
 *
 * Includes static Item and DetailItem properties so that
 * DescriptionListItem and DescriptionListDetailItem can be used
 * with dot notation (e.g. DescriptionList.Item)
 */
export const DescriptionList = ({ children }: DescriptionListProps) => {
  return <dl className="ma0">{children}</dl>;
};

DescriptionList.Item = DescriptionListItem;
DescriptionList.DetailItem = DescriptionListDetailItem;
