import { useState } from 'react';
import ChangeAddressSuccessPage from '../ChangeAddressSuccessPage/ChangeAddressSuccessPage';
import ChangeAddressPage from '../ChangeAddressPage';
import { gql, useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { GetChangeAddressMfaQuery } from '@core/graphql/globalTypes';

export const GET_CHANGE_ADDRESS_MFA_QUERY = gql`
  query GetChangeAddressMfa {
    customer {
      id
      contactInfo {
        addressChangeEligibility {
          isEligible
          mfaRequired
        }
      }
    }
  }
`;

const ChangeAddressContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading } = useQuery<GetChangeAddressMfaQuery>(
    GET_CHANGE_ADDRESS_MFA_QUERY,
    {
      // Need to request it everytime to handle post /verify redirect
      fetchPolicy: 'network-only',
    },
  );
  const [changeAddressPage, setChangeAddressPage] = useState<
    'CHANGE_ADDRESS_FORM' | 'CHANGE_ADDRESS_SUCCESS'
  >('CHANGE_ADDRESS_FORM');

  const handleSuccess = () => {
    setChangeAddressPage('CHANGE_ADDRESS_SUCCESS');
  };

  const renderPage = () => {
    switch (changeAddressPage) {
      case 'CHANGE_ADDRESS_SUCCESS':
        return <ChangeAddressSuccessPage />;
      case 'CHANGE_ADDRESS_FORM':
      default:
        return <ChangeAddressPage onSuccess={handleSuccess} />;
    }
  };

  if (loading) return <LoadingSpinner />;

  const addressChangeEligibility =
    data?.customer?.contactInfo?.addressChangeEligibility;

  if (!addressChangeEligibility) {
    throw new Error(
      'ChangeAddressContainer: unable to get addressChangeEligibility data',
    );
  }

  const { mfaRequired, isEligible } = addressChangeEligibility;

  if (!isEligible) {
    navigate('/');
    return null;
  }

  if (mfaRequired) {
    const preservedPrevState = location.state;
    navigate('/verify', {
      replace: true,
      state: { ...preservedPrevState, from: '/change-address' },
    });
    return null;
  }

  return (
    <div className="center mw7 mb5">
      <div className="pa5 bg-white">{renderPage()}</div>
    </div>
  );
};

export default ChangeAddressContainer;
