import { gql, useQuery } from '@apollo/client';
import { H4 } from '@missionlane/compass-ui';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import {
  GetCreditCardsSectionData,
  GetCreditCardsSectionDataQuery,
} from '@core/graphql/globalTypes';
import { CreditCardsList } from './CreditCardsList';
import {
  checkIfSomeCreditCardsAreHydrated,
  findPendingAccountFromApprovedCreditApplications,
} from './helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

const GET_CREDIT_CARDS_SECTION_DATA_QUERY = gql`
  query GetCreditCardsSectionData {
    creditCards {
      id
      availableCredit
      currentBalance
      cardLast4
      name
      cardArtUrl
      securedCardInfo {
        currentAmount {
          value
        }
      }
      cardLockStatus {
        isLocked
      }
    }
    customer {
      id
      creditApplications {
        accountId
        outcome
      }
    }
  }
`;

const CreditCardsSection = () => {
  const { loading, data } = useQuery<GetCreditCardsSectionDataQuery>(
    GET_CREDIT_CARDS_SECTION_DATA_QUERY,
    {
      errorPolicy: 'all',
    },
  );
  const { enableAccountSummaryCardArt } = useFlags();

  const creditCards = data?.creditCards;
  if (
    !loading &&
    (!creditCards ||
      !creditCards?.length ||
      !checkIfSomeCreditCardsAreHydrated(creditCards))
  ) {
    throw new Error('No credit card data to display');
  }
  const creditApplications = data?.customer?.creditApplications;
  const hasPendingCreditCard =
    !!findPendingAccountFromApprovedCreditApplications(creditApplications);

  // checking if all of the accounts have a cardArtUrl to know which experience to display
  const allEqual = (cc?: GetCreditCardsSectionData.Query['creditCards']) =>
    cc?.every(({ cardArtUrl }) => typeof cardArtUrl === 'string');

  const hasDynamicCardArt =
    allEqual(creditCards) && enableAccountSummaryCardArt;

  return (
    <div
      className={
        hasDynamicCardArt && 'flex flex-column justify-center items-center'
      }
    >
      <div className={hasDynamicCardArt && 'self-start'}>
        <H4>Credit cards</H4>
      </div>
      {loading ?
        <LoadingSpinner />
      : <CreditCardsList
          creditCards={creditCards}
          showPendingCreditCard={hasPendingCreditCard}
        />
      }
    </div>
  );
};

export default CreditCardsSection;
