import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  blocked: boolean;
  redirectTo?: string;
  children: React.ReactNode;
}

/** a route which can only be accessed if `blocked === false`,
 * and redirects to redirectTo if `blocked === true` */
export const ProtectedRoute = ({
  blocked = false,
  redirectTo = '/',
  children,
}: ProtectedRouteProps) => {
  if (blocked) {
    return <Navigate to={redirectTo} />;
  } else {
    return <>{children}</>;
  }
};
