import { BubbleIcon, P3 } from '@missionlane/compass-ui';
import {
  ManageFundingAccounts as ManageFundingAccountNs,
  Balance,
} from '@core/graphql/globalTypes';
import { formatUSD } from '@core/utils/formatters';
import ActionMenu from './ActionMenu/ActionMenu';
import './ManageFundingAccounts.css';
import { useState } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MANAGE_FUNDING_ACCOUNT_FEATURE_NAME } from './ManageFundingAccounts';

type ManageAccountBalance = Omit<Balance, 'accountId'>;

const formatBankName = (bankName: string, last4: string) => {
  const titleCaseBankName = bankName
    .toLowerCase()
    .replace(/\b\w/g, (word) => word.toUpperCase());

  return `${titleCaseBankName} ...${last4}`;
};

const getCheckingDescription = (
  balances?: Array<ManageAccountBalance> | null,
): string => {
  const maybeCurrBalance = !!balances?.length && balances[0].balance;
  const currBalance =
    typeof maybeCurrBalance === 'number' ? maybeCurrBalance : null;

  if (currBalance) {
    return 'Checking ' + formatUSD(`${currBalance}`);
  }

  return 'Checking';
};

const FundingAccountListItem = ({
  fundingAccount,
  canDeleteFundingAccount,
  setBanners,
}: {
  fundingAccount: ManageFundingAccountNs.FundingAccounts;
  canDeleteFundingAccount?: boolean;
  setBanners: (banners: React.ComponentType[]) => void;
}) => {
  const [displayActionMenu, setDisplayActionMenu] = useState(false);
  const { trackClick } = useTracking();

  const onFundingAccountClick = () => {
    trackClick({
      name: `Funding Account Selection`,
      feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
    });
    setDisplayActionMenu(true);
  };

  return (
    <div
      className="flex justify-between ba b--grey-lightest br3 pa3 flex-grow-1 items-center gap-16 pointer"
      onClick={onFundingAccountClick}
    >
      <BubbleIcon name="bank" iconColor="green" bubbleColor="greenWashed" />
      <div className="flex flex-grow-1 flex-column">
        <P3 color="ink" style={{ fontWeight: '600', marginVertical: 0 }}>
          {formatBankName(fundingAccount.bankName, fundingAccount.numberLast4)}
        </P3>
        <P3 style={{ marginVertical: 0 }}>
          {getCheckingDescription(fundingAccount.balances)}
        </P3>
      </div>
      <div className="relative">
        <ActionMenu
          setDisplayActionMenu={setDisplayActionMenu}
          displayActionMenu={displayActionMenu}
          canDeleteFundingAccount={canDeleteFundingAccount}
          fundingAccountId={`${fundingAccount.id}`}
          setBanners={setBanners}
        />
      </div>
    </div>
  );
};

export default FundingAccountListItem;
