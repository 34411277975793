import { H2, H3, Notification, P2 } from '@missionlane/compass-ui';
import { RtfTreatment } from '@core/components/AccountInformation/types';
import AnimatedCurrency from '@core/components/AnimatedCurrency';
import { StyleProp, TextStyle } from 'react-native';
import { centsToDollars } from '@core/utils/centsToDollars';

export interface AccountBalanceContentProps {
  currentBalance: number;
  availableCredit: number;
  rtfTreatment?: RtfTreatment;
  hasPastDueBalancePayment?: boolean;
  isPastDue?: boolean;
  isLightTheme?: boolean;
  isChargedOff?: boolean | null;
}

const AccountBalanceContent = ({
  currentBalance,
  availableCredit,
  rtfTreatment,
  hasPastDueBalancePayment,
  isPastDue,
  isChargedOff,
}: AccountBalanceContentProps) => {
  return (
    <>
      <div className="flex items-end justify-between w-100">
        <div className="flex flex-column">
          <H2>{centsToDollars(currentBalance)}</H2>
          <P2 style={styles.label}>Balance</P2>
        </div>
        <div className="flex flex-column tr">
          <H3 style={styles.availableAmount}>
            {availableCredit > 0 ?
              <AnimatedCurrency targetValue={availableCredit} />
            : '$0.00'}
          </H3>
          <P2 style={[styles.label, { textAlign: 'right' }]}>Available</P2>
        </div>
      </div>
      {rtfTreatment === 'INSTANT' && (
        <Notification variant="inline" level="info">
          Your current balance will update after your payment processes.
        </Notification>
      )}
      {isPastDue && hasPastDueBalancePayment && !isChargedOff && (
        <div className="mt3">
          <Notification variant="inline" level="info">
            Congrats, you’ll be able to use your card again soon! We just need
            to process your payment first.
          </Notification>
        </div>
      )}
    </>
  );
};

export default AccountBalanceContent;

const styles = {
  label: {
    margin: 0,
  } as StyleProp<TextStyle>,
  availableAmount: {
    display: undefined,
    alignSelf: 'flex-end',
  } as StyleProp<TextStyle>,
};
