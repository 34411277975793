import { makeVar, useReactiveVar } from '@apollo/client';
import { Notification } from '@missionlane/compass-ui';
import { useEffect } from 'react';

import useTimeout from '@clip/shared/hooks/useTimeout';

const DEFAULT_TIMEOUT = 10000; // 10 seconds

const dynamicVar = makeVar<[string | null, number]>([null, DEFAULT_TIMEOUT]);

export const setErrorBanner = (error: string | null, ms = DEFAULT_TIMEOUT) => {
  dynamicVar([error, ms]);
};

const ErrorBanner = () => {
  const [errorMessage, ms] = useReactiveVar(dynamicVar);
  const timeout = useTimeout();

  useEffect(() => {
    if (errorMessage != null) {
      timeout(() => {
        setErrorBanner(null);
      }, ms);
    }
  }, [errorMessage, ms]);

  if (errorMessage == null) return null;

  return (
    <Notification
      onDismiss={() => setErrorBanner(null)}
      show={errorMessage != null}
      variant="banner"
      level="error"
    >
      {errorMessage}
    </Notification>
  );
};

export default ErrorBanner;
