export type OTPFactor = 'sms' | 'call';

// https://developer.okta.com/docs/reference/error-codes/
type ErrorSummary =
  | 'Authentication failed'
  | 'Invalid token provided'
  | 'Invalid session'
  | 'An SMS message was recently sent. Please wait 30 seconds before trying again.'
  | 'Invalid Passcode/Answer'
  | 'User Locked'
  | 'The password does not meet the complexity requirements of the current password policy';

export const OktaErrorCodes = [
  'E0000004', // 'Authentication failed'
  'E0000011', // 'Invalid token provided'
  'E0000005', // 'Invalid session'
  'E0000109', // 'An SMS message was recently sent. Please wait 30 seconds before trying again.'
  'E0000068', // 'Invalid Passcode/Answer' (OTP)
  'E0000069', // 'User Locked'
  'E0000080', // 'The password does not meet the complexity requirements of the current password policy'
];

export type OktaErrorCode = (typeof OktaErrorCodes)[number];

export type OktaResponseError = {
  message: string;
  name: 'AuthApiError';
  errorSummary: ErrorSummary;
  errorCode: OktaErrorCode;
};

export function isOktaError(e: unknown): e is OktaResponseError {
  // Okta's error response name is AuthApiError
  return (e as OktaResponseError).name === 'AuthApiError';
}
