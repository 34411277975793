export const COPY = {
  TITLE_CLIP: 'Sorry, we can’t increase your credit limit right now.',
  TITLE_CASHBACK: 'We can’t offer you cash back rewards right now.',
} as const;

export const TEST_ID = {
  SPEND_BONUS__INELIGIBLE: 'SPEND_BONUS__INELIGIBLE',
  SPEND_BONUS__INELIGIBLE__MESSAGE__CLIP:
    'SPEND_BONUS__INELIGIBLE__MESSAGE__CLIP',
  SPEND_BONUS__INELIGIBLE__MESSAGE__CASHBACK:
    'SPEND_BONUS__INELIGIBLE__MESSAGE__CASHBACK',
} as const;
