import PageWrapper from '@core/components/Page/PageWrapper';
import { ResponsiveKard } from '@core/components/General/ResponsiveKard/ResponsiveKard';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import { Link, Button } from '@missionlane/compass-ui';
import { ClipOfferUiStates } from '@clip/ClipProgress/hooks/types/ClipOffer';
import { useNavigate } from 'react-router-dom';
import { TrackService } from '@core/services';

import Lottie from 'lottie-react';
import { confettiJson } from './utils/confetti';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TurnOnAutopay = () => {
  const navigate = useNavigate();
  const { uiState } = useClipOffer();

  return (
    <p>
      You can even{' '}
      <Link
        style={{
          textDecorationLine: 'none',
          fontSize: 16,
        }}
        onPress={() => {
          TrackService.click('ClipOffer: Enable Autopay', {
            uiState,
          });
          navigate('../../../../payments/autopay');
        }}
      >
        turn on autopay
      </Link>{' '}
      to avoid forgetting a payment.
    </p>
  );
};

const GreatJobUser = () => {
  const { userFirstName } = useClipOffer();
  return <p>Great job, {userFirstName}!</p>;
};

export const getSuccesContent = (streak: number) => {
  if (streak === 0) {
    return {
      title: 'Way to pay on time!',
      p1: 'Making your payments on time is a great way to demonstrate responsible credit usage.',
    };
  }

  return {
    title: 'Look at those on time payments!',
    p1: "Don't forget: making your payments on time is a great way to demonstrate responsible credit usage.",
  };
};

const StreakGroupSuccess = () => {
  const { currentPaymentStreak } = useClipOffer();

  if (typeof currentPaymentStreak !== 'number') {
    return null;
  }

  const content = getSuccesContent(currentPaymentStreak);

  return (
    <div>
      <h3 className="mb5">{content.title}</h3>
      <p className="mw6">{content.p1}</p>
      <TurnOnAutopay />
      <GreatJobUser />
    </div>
  );
};

const TestGroupSuccess = () => {
  const { currentPaymentStreak } = useClipOffer();

  if (typeof currentPaymentStreak !== 'number') {
    return null;
  }

  const content = getSuccesContent(currentPaymentStreak);

  return (
    <div>
      <h3 className="mb5">{content.title}</h3>
      <p className="mw6">{content.p1}</p>
      <p className="mw6">
        Keep making payments on time to increase your chances for a{' '}
        <span className="green">credit limit increase</span>!
      </p>
      <TurnOnAutopay />
      <GreatJobUser />
    </div>
  );
};

const PAYMENT_SUCCESS_STREAK_CONTENT: Record<
  ClipOfferUiStates,
  (() => JSX.Element | null) | null
> = {
  [ClipOfferUiStates.STREAK]: StreakGroupSuccess,
  [ClipOfferUiStates.TEST]: TestGroupSuccess,
  [ClipOfferUiStates.CONTROL]: null,
} as const;

const PaymentSuccessStreak = () => {
  const navigate = useNavigate();
  const { uiState, currentPaymentStreak } = useClipOffer();
  const { lm7537EnablePaymentSuccessScreen } = useFlags();

  const Content = PAYMENT_SUCCESS_STREAK_CONTENT[uiState];

  if (
    !Content ||
    currentPaymentStreak === null ||
    !lm7537EnablePaymentSuccessScreen
  ) {
    return null;
  }

  return (
    <PageWrapper greyBackground>
      <div className="flex justify-center mt7">
        <Lottie
          animationData={confettiJson}
          autoPlay
          loop={false}
          style={{
            position: 'absolute',
            top: 0,
          }}
        />
        <div>
          <ResponsiveKard className="bg-white">
            <Content />
            <div className="flex justify-end">
              <Button
                text="Done"
                onPress={() => navigate('../../../../summary')}
              />
            </div>
          </ResponsiveKard>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PaymentSuccessStreak;
