import { Notification, B } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const MONTHLY_LIVING_WARNING_LIMIT = 10000;
export const MONTHLY_LIVING_WARNING_LIMIT_CONTENT_TEST_ID =
  'MONTHLY_LIVING_WARNING_LIMIT_CONTENT_TEST_ID';

export const isMonthlyLivingValid = (monthlyLiving: string) => {
  if (!monthlyLiving) {
    return true;
  }

  const parsed = monthlyLiving.replace(',', '');

  if (isNaN(+parsed) || +parsed >= MONTHLY_LIVING_WARNING_LIMIT) {
    return false;
  }

  return true;
};

export const MonthlyLivingWarning = ({
  monthlyLiving,
}: {
  monthlyLiving: string;
}) => {
  const { enableMonthlyLivingWarning } = useFlags();

  if (!enableMonthlyLivingWarning || isMonthlyLivingValid(monthlyLiving)) {
    return null;
  }

  return (
    <div className="mb4">
      <Notification variant="inline" level="warning">
        <B testID={MONTHLY_LIVING_WARNING_LIMIT_CONTENT_TEST_ID}>
          Your rent or mortgage payment looks high.
        </B>{' '}
        Please make sure you're reporting how much you spend <B>per month,</B>{' '}
        not per year.
      </Notification>
    </div>
  );
};
