import { gql } from '@apollo/client';

export const OFFERS_QUERY = gql`
  query Offers($accountId: String!, $offerType: String) {
    fundingAccounts(type: ACH) {
      id
      numberLast4
    }
    account(accountId: $accountId) {
      id
      cardDetails {
        last4
        name
      }
      upcomingPayments {
        id
        amount
        state
      }
      balanceInfo {
        currentBalance
      }
    }
    customer {
      id
      contactInfo {
        firstName
      }
    }
    offerEligibility(accountId: $accountId, offerType: $offerType) {
      eligible
      ineligibleReasons
      offerType
      flows {
        flowId
        name
      }
    }
    offers(accountId: $accountId) {
      createdAt
      offerType
      offerId
      updatedAt
      data {
        state
        expiration
        durationMonths
        acceptanceExpiration
        canceledReason
        acceptedAt
        subType
        atpUnderSIF
        originalSettlementBalance
        originalOutstandingBalance
        remainingBalance
        fundingAccountId
        paymentPlan {
          id
          authorizationText
          source
          transactions {
            amount
            date
          }
          customerId
        }
        pastPaymentPlanIds
        payments {
          amount
          txId
          type
          date
        }
      }
    }
  }
`;

export const OFFER_PAYMENTS_QUERY = gql`
  query OfferPayments(
    $accountId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    account(accountId: $accountId) {
      id
      payments(fromDate: $fromDate, toDate: $toDate) {
        id
        state
        amount
        date
      }
    }
  }
`;

export const ACCOUNT_QUERY = gql`
  query Account($accountId: String!) {
    account(accountId: $accountId) {
      id
      balanceInfo {
        currentBalance
      }
      autopay {
        id
      }
      upcomingPayments {
        id
        state
      }
    }
    offerEligibility(accountId: $accountId) {
      eligible
      offerType
      ineligibleReasons
    }
  }
`;
