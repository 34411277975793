import { ForwardedRef, forwardRef } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { B, Colors, Icon, P3 } from '@missionlane/compass-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

type BaseMenuTriggerProps = DropdownMenu.DropdownMenuTriggerProps;
interface DesktopMenuTriggerProps extends BaseMenuTriggerProps {
  label: string;
}
export const DesktopMenuTrigger = forwardRef(
  (
    { label, ...dropdownMenuTriggerProps }: DesktopMenuTriggerProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button {...dropdownMenuTriggerProps} ref={forwardedRef}>
      {/* TODO: Add this icon to compass-ui */}
      <FontAwesomeIcon className="mr1" icon={faUser} color={Colors.white} />
      <P3 color="white" style={{ fontSize: 18, lineHeight: 22.5 }}>
        <B>{label}</B>
      </P3>
    </button>
  ),
);

DesktopMenuTrigger.displayName = 'DesktopMenuTrigger';

export const MobileMenuTrigger = forwardRef(
  (
    dropdownMenuTriggerProps: BaseMenuTriggerProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      data-testid="MobileMenuTrigger"
      {...dropdownMenuTriggerProps}
      ref={forwardedRef}
    >
      <Icon name="bars" color="white" size="l" />
    </button>
  ),
);

MobileMenuTrigger.displayName = 'MobileMenuTrigger';
