import { H2, H4, P3 } from '@missionlane/compass-ui';

export const PaymentKardBodyClosed = () => {
  return (
    <div className="flex flex-column">
      <div className="dn-ns">
        <H2>Your account is closed.</H2>
      </div>
      <div className="dn db-ns">
        <H4>Your account is closed.</H4>
      </div>
      <P3>
        Your account can no longer be used. Please call us if you have any
        questions.
      </P3>
    </div>
  );
};

export default PaymentKardBodyClosed;
