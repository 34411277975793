import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAccountIdQuery } from '../../../../utils/hooks/useAccountIdQuery';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import OnBoardingAccount from '../OnBoardingAccount/OnBoardingAccount';
import FinancialLiteracyKard from '@core/components/FinancialLiteracyKard/FinancialLiteracyKard';
import ContactHelpKard from '@core/components/ContactHelpKard/ContactHelpKard';
import { FundingAccounts } from '../../../../graphql/globalTypes';
import { Scaffolding } from '../OnboardingDashboards';
import OnboardingAutoPayKard from '../OnboardingAutoPayKard/OnboardingAutoPayKard';
import type { PropsWithChildren } from 'react';

const displayAutoPayKard = (
  enablePlaidOnboarding: boolean,
  fundingAccounts: FundingAccounts.FundingAccounts[],
) => {
  return (
    !enablePlaidOnboarding ||
    (enablePlaidOnboarding && fundingAccounts.length > 0)
  );
};

const AutoPayContainer = ({ children }: PropsWithChildren) => {
  const { enablePlaidOnboarding } = useFlags();
  const { loading, data } = useAccountIdQuery(ACH_FUNDING_ACCOUNTS_QUERY);

  const fundingAccounts = data?.fundingAccounts || [];

  if (loading) return null;

  return (
    <Scaffolding
      leftColumn={children}
      rightColumn={
        <>
          {enablePlaidOnboarding && (
            <OnBoardingAccount fundingAccounts={fundingAccounts} />
          )}
          {displayAutoPayKard(enablePlaidOnboarding, fundingAccounts) && (
            <OnboardingAutoPayKard className="mb5" />
          )}
          <FinancialLiteracyKard />
          <ContactHelpKard />
        </>
      }
    />
  );
};

export default AutoPayContainer;
