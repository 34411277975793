import { Button } from '@missionlane/compass-ui';

type LoginSubmitButton = {
  onPress: () => void;
  loading: boolean;
  text: string;
  disabled?: boolean;
};

interface LoginActionButtonsProps {
  submitButton: LoginSubmitButton;
  onCancel?: () => void;
}

const LoginActionButtons = ({
  submitButton,
  onCancel,
}: LoginActionButtonsProps) => {
  const { onPress, loading, text, disabled } = submitButton;

  return (
    <div className="flex pv3 items-center">
      <div className="mr3">
        <Button
          text={text}
          loading={loading}
          onPress={onPress}
          disabled={disabled}
        />
      </div>
      {onCancel && <Button variant="text" text="Cancel" onPress={onCancel} />}
    </div>
  );
};

export default LoginActionButtons;
