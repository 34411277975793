import { gql } from '@apollo/client';

const AUTOPAY_FIELDS = gql`
  fragment AutopayFields on Autopay {
    id
    amount
    paymentType
    nextPaymentDate
    dayOfMonth
    fundingAccount {
      id
      numberLast4
      bankName
      isDefault
    }
  }
`;

export default AUTOPAY_FIELDS;
