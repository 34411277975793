export const COPY = {
  TITLE: 'This offer has ended.',
} as const;

export const TEST_ID = {
  SPEND_BONUS__INCOMPLETE: 'SPEND_BONUS__INCOMPLETE',
  SPEND_BONUS__INCOMPLETE__MESSAGE__CLIP:
    'SPEND_BONUS__INCOMPLETE__MESSAGE__CLIP',
  SPEND_BONUS__INCOMPLETE__MESSAGE__CASHBACK:
    'SPEND_BONUS__INCOMPLETE__MESSAGE__CASHBACK',
} as const;
