import { gql } from '@apollo/client';

export const UPDATE_INCOME = gql`
  mutation UpdateIncome($annualIncome: Int!, $monthlyLiving: Int!) {
    updateFinancialInfo(
      financialInfo: {
        annualIncome: $annualIncome
        monthlyLiving: $monthlyLiving
      }
    ) {
      annualIncome
      monthlyLiving
      shouldGetCLIPTout
      eligibleForUpdate
    }
  }
`;
