import { useMutation } from '@apollo/client';

import {
  HideSpendBonusMutation,
  HideSpendBonusMutationVariables,
  OptOutSpendBonusMutation,
} from '@core/graphql/globalTypes';
import {
  optOutSpendBonusMutation,
  hideSpendBonusMutation,
} from '@clip/SpendBonus/api';
import { getSpendBonusQuery } from '@clip/SpendBonus/api/queries/getSpendBonusQuery';
import { setErrorBanner } from '@core/components/Banners/ErrorBanner/ErrorBanner';

export type OptOutSpendBonusVars = {
  offerId: string;
};

export const useSpendBonusMutation = () => {
  const [optOutSpendBonus, optOutSpendBonusResult] = useMutation<
    OptOutSpendBonusMutation,
    OptOutSpendBonusVars
  >(optOutSpendBonusMutation, { refetchQueries: [getSpendBonusQuery] });

  const [hideSpendBonus, hideSpendBonusResult] = useMutation<
    HideSpendBonusMutation,
    HideSpendBonusMutationVariables
  >(hideSpendBonusMutation, {
    refetchQueries: [getSpendBonusQuery],
  });

  const showError = () =>
    setErrorBanner('Sorry, we ran into an issue. Please try again later.');

  const optOut = async (variables: HideSpendBonusMutationVariables) => {
    if (optOutSpendBonusResult.loading) return;

    try {
      optOutSpendBonus({ variables });
    } catch (error) {
      showError();
    }
  };

  const hideOffer = async (variables: HideSpendBonusMutationVariables) => {
    if (hideSpendBonusResult.loading) return;

    try {
      hideSpendBonus({ variables });
    } catch (error) {
      showError();
    }
  };

  return {
    // optOut
    optOut,
    optOutLoading: optOutSpendBonusResult.loading,
    optOutError: optOutSpendBonusResult.error,
    // hideOffer
    hideOffer,
    hideOfferLoading: hideSpendBonusResult.loading,
    hideOfferError: hideSpendBonusResult.error,
  };
};

export type UseSpendBonusMutationReturn = ReturnType<
  typeof useSpendBonusMutation
>;
