import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider/RiskAuthProvider';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import isMissionLaneUrl from '@core/utils/isMissionLaneUrl';

interface RequireAuthProps {
  children: JSX.Element;
}

// Okta-react-example: https://github.com/okta/okta-react/blob/routing-poc/test/apps/react-routing/react-router-dom-v6/src/components/SecureRoute.tsx
const RequireAuth = ({ children }: RequireAuthProps) => {
  const { isAuthenticated: isRiskAuthAuthenticated } = useRiskAuth();
  const { oktaAuth, authState } = useOktaAuth();
  const [searchParams] = useSearchParams();

  // okta and riskAuth are not loaded from their context providers yet. Happens when entering the site from a refresh
  if (authState?.isAuthenticated === null || isRiskAuthAuthenticated === null) {
    return <LoadingSpinnerPage />;
  }

  if (
    authState?.isAuthenticated === false ||
    isRiskAuthAuthenticated === false
  ) {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin,
    );

    oktaAuth.setOriginalUri(originalUri);

    return <Navigate to="/signin" />;
  }

  // Ensure a user who was given a link from an external ML app to authenticate gets
  // redirected to that app via the redirectTo param if they are already authenticated
  const redirectToSearchParam = searchParams.get('redirectTo');
  const decodedRedirectTo =
    redirectToSearchParam && decodeURIComponent(redirectToSearchParam);

  if (decodedRedirectTo && isMissionLaneUrl(decodedRedirectTo)) {
    window.location.replace(decodedRedirectTo);
  }

  return children;
};

export default RequireAuth;
