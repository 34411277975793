import { Routes, Route, Navigate } from 'react-router-dom';

import ChangeAddressFeature from '@core/components/ChangeAddress/ChangeAddressFeature';
import { ZendeskExternalRoute } from '@core/components/ExternalRoutes';
import MfaFeature from '@core/components/Auth/MFA/MfaFeature/MfaFeature';
import PlaidOAuth from '@payments/components/Plaid/PlaidOAuth';
import { EditFinancialInformation } from '@clip/IncomeUpdate';
import ChangePhonePage from '@core/components/ChangePhonePage/ChangePhonePage';
import PageNotFound from '@core/components/ErrorBoundary/PageNotFound';

import MyProfilePage from '../../MyProfile/MyProfilePage';
import CreditProtectionRedirect from '@core/components/CreditProtection/CreditProtectionRedirect';

export const CustomerRoutes = () => (
  <Routes>
    <Route path="verify" element={<MfaFeature />} />
    <Route path="change-address" element={<ChangeAddressFeature />} />
    <Route path="change-phone" element={<ChangePhonePage />} />
    <Route path="update-income" element={<EditFinancialInformation />} />
    <Route path="funding-account-validation" element={<PlaidOAuth />} />
    <Route path="plaid-login-clip-oauth" element={<PlaidOAuth />} />
    <Route path="zendesk" element={<ZendeskExternalRoute />} />
    <Route path="my-profile" element={<MyProfilePage />} />
    <Route
      path="credit-protection/redirect"
      element={<CreditProtectionRedirect />}
    ></Route>

    {/**
     * This route is meant to redirect to the home page when the user clicks on
     * the "my-progress" universal deep-link and do not have the app installed.
     **/}
    <Route path="my-progress" element={<Navigate replace to="/" />} />
    {/* This is no longer a valid url but can be accessed from old email links */}
    <Route path="katabat" element={<Navigate replace to="/" />} />
    {/**
     * Keep this route at the end of the list to catch all other routes
     **/}
    <Route path="/*" element={<PageNotFound />} />
  </Routes>
);
