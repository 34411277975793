import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TrackService } from '@core/services';
import { Link, P1, P3, TypographyProps } from '@missionlane/compass-ui';

export interface TrackingLinkProps {
  to?: string;
  target?: HTMLAnchorElement['target'];
  onClick?: () => void;
  children: React.ReactNode;
  trackingName: string;
  trackingProperties?: Record<string, any>;
  style?: TypographyProps['style'];
  responsive?: boolean;
}

const TrackingLink = ({
  to,
  target,
  children,
  onClick,
  trackingName,
  trackingProperties,
  style,
  responsive,
}: TrackingLinkProps) => {
  const navigate = useNavigate();

  function handleClick() {
    TrackService.click(trackingName, trackingProperties);

    if (to?.indexOf('http') === 0 || to?.indexOf('tel:') === 0) {
      // for external links, update the window location directly
      if (target === '_blank') {
        window.open(to, target);
      } else {
        window.location.href = to;
      }
    } else if (to) {
      navigate(to);
    }

    onClick?.();
  }

  return (
    <Link
      style={[{ textDecorationLine: 'none' }, style]}
      onPress={handleClick}
      data-to={to}
    >
      {responsive ?
        <>
          {/* LARGE SCREEN */}
          <div className="dn di-m di-l">
            <P3 color="blue">{children}</P3>
          </div>
          {/* SMALL SCREEN */}
          <div className="di dn-ns">
            <P1 color="blue">{children}</P1>
          </div>
        </>
      : <P3 color="blue">{children}</P3>}
    </Link>
  );
};

export default TrackingLink;
