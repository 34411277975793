import { ReactNode } from 'react';
import { Button, H2, H3, Icon } from '@missionlane/compass-ui';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { ContainedButtonProps } from '@missionlane/compass-ui/lib/typescript/components/Button/types';

interface SuccessPanelProps {
  title: string;
  actionButton?: Pick<ContainedButtonProps, 'text' | 'onPress'>;
  children?: ReactNode;
}

const SuccessPanel = ({ title, actionButton, children }: SuccessPanelProps) => {
  const { isMobile } = useUserDevice();
  const HeaderComponent = isMobile ? H2 : H3;
  return (
    <div className="flex flex-column items-center flex-row-ns items-start-ns center bg-white w-100 pa4 pa5-l">
      <div className="mr5-ns mb3 mb0-ns">
        <Icon name="checkCircleLight" color="green" size={118} />
      </div>
      <div>
        <div className="flex flex-column items-center items-stretch-ns tc tl-ns">
          <div className="mb2">
            <HeaderComponent>{title}</HeaderComponent>
          </div>
          <div className="content flex flex-column">{children}</div>
        </div>
        {actionButton && (
          <div className="mt4 flex-ns">
            <Button text={actionButton.text} onPress={actionButton.onPress} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SuccessPanel;
