import { useAccount } from '@core/components/Auth/AccountContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { MutationHookOptions, useLazyQuery } from '@apollo/client';
import { GET_CASH_FLOW_UNDERWRITING_PROGRESS } from '../network/queries';
import { GetCashFlowUnderwritingProgressQuery } from '../network/types';

export const useCfuProgress = (
  onCompleted: MutationHookOptions['onCompleted'],
) => {
  const { accountId } = useAccount();
  const { showClipCfuExperience } = useFlags();

  const [getCFUProgress, { data, loading, error }] =
    useLazyQuery<GetCashFlowUnderwritingProgressQuery>(
      GET_CASH_FLOW_UNDERWRITING_PROGRESS,
      {
        variables: { accountId },
        fetchPolicy: 'cache-and-network',
        onCompleted,
      },
    );

  useEffect(() => {
    if (!showClipCfuExperience) {
      return;
    }

    getCFUProgress();
  }, [showClipCfuExperience, getCFUProgress]);

  return {
    getCFUProgress,
    data,
    loading,
    error,
  };
};
