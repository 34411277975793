import { ResponsiveKard } from '@core/components/General/ResponsiveKard/ResponsiveKard';
import { ReactNode } from 'react';

interface AutopayKard {
  children: ReactNode;
  header: string;
  headerClassName?: string;
}

const PaymentFlowCard = ({
  children,
  header,
  headerClassName,
}: AutopayKard) => {
  return (
    <ResponsiveKard
      header={header}
      className="w-60-l flex flex-column center lh-copy mb6 bg-white pa4"
      headerClassName={headerClassName}
    >
      {children}
    </ResponsiveKard>
  );
};

export default PaymentFlowCard;
