import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export const clipFlags: LDFlagSet = {
  'show-spend-bonus': false,
  'show-spend-bonus-progress': false,
  enableMonthlyLivingWarning: false,
  'clip-tracker-relaunch': false,
  'show-clip-experience': false,
  'clip-tracker-relaunch-control': false,
  'lm-7537-enable-payment-success-screen': false,
};
