import cx from 'classnames';
import { ReactElement, useEffect } from 'react';
import { TrackService } from '@core/services';
import personInBox from '../../assets/ErrorCardOops.svg';
import { CONTACT_US_LINK } from '@core/utils/constants';
import { Title } from '@core/components/TitleBar/TitleBar';
import PageWrapper from '@core/components/Page/PageWrapper';

const DefaultContent = () => {
  return (
    <>
      This service is unavailable right now. Please try again in a bit or
      <a className="nowrap pointer link" href={CONTACT_US_LINK}>
        &nbsp;contact us&nbsp;
      </a>
      if you need help now.
    </>
  );
};

interface Props {
  customContent?: ReactElement;
}

const GenericServiceFallBackFull = ({ customContent }: Props) => {
  useEffect(() => {
    TrackService.page('Service Error');
  }, []);

  return (
    <div
      className={cx(
        'ink pa4 sans-serif h-100 w-100 flex items-center justify-center',
      )}
      data-testid="generic-service-fallback-full"
    >
      <div className="tc">
        <h2 className="mb5 pt3 f2-l">{"It's not you. It's us."}</h2>
        <img src={personInBox} className="mb4" alt="" />
        <p className="lh-copy mb0 light-ink">
          {customContent ? customContent : <DefaultContent />}
        </p>
      </div>
    </div>
  );
};

interface GenericServiceFallBackFullPageProps extends Props {
  pageTitle?: Title;
}

export const GenericServiceFallBackFullPage = (
  props: GenericServiceFallBackFullPageProps,
) => {
  const { pageTitle, ...otherProps } = props;
  return (
    <PageWrapper pageTitle={pageTitle}>
      <GenericServiceFallBackFull {...otherProps} />
    </PageWrapper>
  );
};

export default GenericServiceFallBackFull;
