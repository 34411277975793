import dayjs from 'dayjs';

const partOfDay = () => {
  const hour = dayjs().hour();
  const morning = 2;
  const afternoon = 12;
  const evening = 17;
  if (hour >= morning && hour < afternoon) return 'morning';
  if (hour >= afternoon && hour < evening) return 'afternoon';
  return 'evening';
};

export default partOfDay;
