import { ResponsiveKard } from '@core/components/General/ResponsiveKard/ResponsiveKard';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import { BubbleIcon, Button, H3, P3, Spacer } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { useEffect } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';

const breadcrumbs = [
  {
    text: 'Payments',
    link: '../../..',
  },
  { text: 'Make a Payment', link: '../..' },
  { text: 'Set up Autopay' },
];

export const AutopayPrompt = () => {
  const { trackEvent, trackClick } = useTracking();
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const { set, get } = useLocalStorage();

  useEffect(() => {
    trackEvent({
      eventName: 'Offer Viewed',
      name: 'Autopay offer',
      feature: 'Payments: Make Payment: Autopay offer',
    });
  }, []);

  const dismissedCount = get('dismissedAutopayPromptCount') || 0;

  const setUpAutopay = () => {
    trackClick({
      name: 'Set up',
      feature: 'Payments: Make Payment: Autopay offer',
    });
    navigate('../../autopay');
  };

  const dismissAutopayPrompt = () => {
    const newDismissedCount = dismissedCount + 1;
    trackClick({
      name: 'No thanks',
      feature: 'Payments: Make Payment: Autopay offer',
      dismissedCount: newDismissedCount,
    });
    // increment the dismissed count in localstorage
    set('dismissedAutopayPromptCount', newDismissedCount);
    navigate(summaryPath);
  };

  return (
    <PageWrapper greyBackground>
      <AccountBreadcrumbs items={breadcrumbs} />
      <div className="flex justify-center">
        <ResponsiveKard className="w-75-l bg-white">
          <div className="ph3 ph0-l flex">
            <BubbleIcon
              name="autopay"
              bubbleColor="greenWashed"
              iconColor="green"
              size={80}
            />
            <Spacer size="xm" />
            <div>
              <H3>Set up recurring payments with Autopay?</H3>
              <Spacer size="m" />
              <P3>
                An autopay plan can simply act as a backup should you need it.
                You can always make additional payments or edit your autopay
                plan.
              </P3>
              <Spacer size="m" />
              <div className="flex justify-end">
                <Button
                  variant="text"
                  text="No thanks"
                  onPress={dismissAutopayPrompt}
                />
                <Spacer size="m" />
                <Button text="Set up" onPress={setUpAutopay} />
              </div>
            </div>
          </div>
        </ResponsiveKard>
      </div>
    </PageWrapper>
  );
};
