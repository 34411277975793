import { useLayoutEffect, useState } from 'react';

import useTimeout from '@clip/shared/hooks/useTimeout';

import './ProgressBar.css';

export interface ProgressBarProps {
  value: number;
  max?: number;
  min?: number;
}

export const TEST_ID = 'progress-bar';

const ProgressBar = ({ value, max = 100, min = 0 }: ProgressBarProps) => {
  const [width, setWidth] = useState<string>('0%');
  const timeout = useTimeout();

  useLayoutEffect(() => {
    const calcPercentage = (value / max) * 100;
    // returns the highest value as minimum
    const minPercentage = Math.max(min, calcPercentage);
    // returns the lowest value as maximum
    const maxPercentage = Math.min(minPercentage, 100);

    timeout(() => setWidth(`${maxPercentage}%`), 500);
  }, [value, max, min]);

  return (
    <div className="progress-bar__wrapper">
      <div
        data-testid={TEST_ID}
        className="progress-bar__bar"
        style={{ width }}
      />
    </div>
  );
};

export default ProgressBar;
