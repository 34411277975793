import React, { useEffect } from 'react';
import { EventProperties } from '@segment/analytics-next';
import cx from 'classnames';

import { TrackService } from '@core/services';
import { Title } from '@core/components/TitleBar/TitleBar';
import PageWrapper from '@core/components/Page/PageWrapper';
import { Button, P1 } from '@missionlane/compass-ui';

import cableGuy from '../../assets/CableGuy.svg';
import { SupportLink } from '../General/SupportLink';

interface GenericFallbackFullProps {
  className?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
  onBackButtonPress?: () => void;
  eventTrackingProperties?: EventProperties;
}

const GenericFallbackFull = ({
  onBackButtonPress,
  className,
  eventTrackingProperties,
}: GenericFallbackFullProps) => {
  useEffect(() => {
    // Ensures that scroll to top on mobile if the user had scrolled down prior to the
    // error boundary throwing
    document.querySelector('body')?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    TrackService.page('Full page error (cable guy)', eventTrackingProperties);
  }, []);

  const refreshToDefaultPage = () => {
    window.location.href = '/';
  };

  return (
    <div className={cx('bg-white pa6-l pa4 mt4-l', className)}>
      <div className="flex-l">
        <img src={cableGuy} alt="error" className="db ml-auto mr-auto mb4" />
        <div className="w-45-l">
          <P1>
            We’re having trouble showing you this information. Please try again
            in a bit or <SupportLink>contact us</SupportLink> if you need help
            now.
          </P1>
          <div className="mt5 w-25-l">
            <Button
              text="Back"
              onPress={onBackButtonPress || refreshToDefaultPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface GenericFallbackFullPageProps extends GenericFallbackFullProps {
  pageTitle?: Title;
}

export const GenericFallbackFullPage = (
  props: GenericFallbackFullPageProps,
) => {
  const { pageTitle, ...restProps } = props;

  return (
    <PageWrapper pageTitle={pageTitle}>
      <GenericFallbackFull {...restProps} />
    </PageWrapper>
  );
};

export default GenericFallbackFull;
