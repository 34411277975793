import { useEffect, useRef, useState } from 'react';
import useClickOutside from './useClickOutside';
import { Icon, P3, Link, Colors } from '@missionlane/compass-ui';
import Modal from '@core/components/General/Modal/Modal';
import { useAccount } from '@core/components/Auth/AccountContext';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  DisableFundingAccount,
  GetUpcomingPayments,
  Channel,
} from '@core/graphql/globalTypes';
import { Notification } from '@missionlane/compass-ui';
import { MANAGE_FUNDING_ACCOUNT_FEATURE_NAME } from '../ManageFundingAccounts';
import '../ManageFundingAccounts.css';
import { useTracking } from '@core/services/TrackService/useTracking';

const disableFundingAccountMutation = gql`
  mutation disableFundingAccount(
    $fundingAccountId: String!
    $channel: Channel
  ) {
    disableFundingAccount(
      fundingAccountId: $fundingAccountId
      channel: $channel
    ) {
      id
    }
  }
`;

const getUpcomingPayments = gql`
  query GetUpcomingPayments($accountId: String!) {
    account(accountId: $accountId) {
      id
      upcomingPayments {
        id
        amount
        type
        date
        state
        fundingAccount {
          id
        }
      }
    }
  }
`;

const SuccessNotification =
  ({ onDismiss }: { onDismiss: () => void }) =>
  () => (
    <Notification variant="banner" level="success" show onDismiss={onDismiss}>
      Bank account removed!
    </Notification>
  );

const ErrorNotification =
  ({ onDismiss }: { onDismiss: () => void }) =>
  () => (
    <Notification variant="banner" level="error" show onDismiss={onDismiss}>
      Something went wrong, please try again later
    </Notification>
  );

const AccountHasPendingPaymentsErrorNotification =
  ({ onDismiss }: { onDismiss: () => void }) =>
  () => (
    <Notification variant="banner" level="error" show onDismiss={onDismiss}>
      Bank account cannot be deleted since it has a pending payment
    </Notification>
  );

const ActionMenu = ({
  canDeleteFundingAccount,
  fundingAccountId,
  setBanners,
  displayActionMenu,
  setDisplayActionMenu,
}: {
  canDeleteFundingAccount?: boolean;
  fundingAccountId: string;
  setBanners: (banners: React.ComponentType[]) => void;
  displayActionMenu: boolean;
  setDisplayActionMenu: (state: boolean) => void;
}) => {
  const { accountId } = useAccount();
  const [disableAccountDeletion, setDisableAccountDeletion] = useState(false);
  const { trackError, trackClick, trackEvent } = useTracking();
  const actionMenuRef = useRef<HTMLDivElement | null>(null);

  const { disableClickOutside, enableClickOutside } = useClickOutside(
    actionMenuRef,
    () => setDisplayActionMenu(false),
  );

  const dismissBanner = () => setBanners([]);

  const [displayModal, setDisplayModal] = useState(false);
  const closeModal = () => {
    setDisplayModal(false);
    enableClickOutside();
  };

  const {
    data: upcomingPaymentsData,
    error: upcomingPaymentsError,
    loading: upcomingPaymentsLoading,
  } = useQuery<GetUpcomingPayments.Query, GetUpcomingPayments.Variables>(
    getUpcomingPayments,
    {
      variables: { accountId },
    },
  );

  useEffect(() => {
    if (
      upcomingPaymentsLoading ||
      upcomingPaymentsError ||
      !upcomingPaymentsData?.account?.id
    ) {
      setDisableAccountDeletion(true);
      return;
    }

    if (!upcomingPaymentsData.account.upcomingPayments.length) {
      setDisableAccountDeletion(false);
      return;
    }

    const disable = upcomingPaymentsData.account.upcomingPayments.some(
      (payment) =>
        `${payment.fundingAccount?.id}` === fundingAccountId &&
        payment.state === 'PENDING',
    );

    setDisableAccountDeletion(disable);
  }, [
    upcomingPaymentsData,
    upcomingPaymentsError,
    upcomingPaymentsLoading,
    fundingAccountId,
  ]);

  const [disableFundingAccount] = useMutation<
    DisableFundingAccount.Mutation,
    DisableFundingAccount.Variables
  >(disableFundingAccountMutation, {
    onCompleted: () => {
      setBanners([SuccessNotification({ onDismiss: dismissBanner })]);
      trackEvent({
        feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
        eventName: 'Successfully removed funding account',
      });

      closeModal();
    },
    onError: (error) => {
      trackError({
        feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
        name: 'Failed to remove funding account',
        error: {
          name: 'Failed to remove funding account',
          message: error.message,
          code: 'PAY0015',
        },
      });

      setBanners([ErrorNotification({ onDismiss: dismissBanner })]);

      closeModal();
    },
    update: (cache, { data }) => {
      if (data?.disableFundingAccount) {
        const normalizedDisabledFundingAccount = cache.identify(
          data.disableFundingAccount,
        );
        cache.evict({ id: normalizedDisabledFundingAccount });
        cache.gc();
      }
    },
  });

  const onRemoveAccount = () => {
    trackClick({
      feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
      name: 'Confirm delete funding account',
    });

    if (disableAccountDeletion) {
      trackError({
        feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
        name: 'Failed to delete funding account due to pending transaction',
        error: {
          name: 'Failed to delete funding account due to pending transaction',
          message:
            'Bank account cannot be deleted since it has a pending payment',
        },
      });

      setBanners([
        AccountHasPendingPaymentsErrorNotification({
          onDismiss: dismissBanner,
        }),
      ]);

      closeModal();
      return;
    }

    disableFundingAccount({
      variables: {
        fundingAccountId,
        channel: Channel.Web,
      },
    });
  };

  if (upcomingPaymentsError) {
    throw upcomingPaymentsError;
  }

  if (!canDeleteFundingAccount) {
    return null;
  }

  const closeDeleteFundingAccountModal = () => {
    trackClick({
      feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
      name: 'Cancel delete funding account modal',
    });

    closeModal();
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          trackClick({
            feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
            name: 'Open delete funding account modal',
          });
          setDisplayActionMenu(true);
        }}
        className="pa0 bg-white pointer bn"
      >
        <Icon name="more" />
      </button>

      {displayActionMenu && (
        <div
          id="action-menu-dropdown"
          className="absolute bg-white shadow-2 pa3 action-menu br2"
          ref={actionMenuRef}
        >
          <div className="flex flex-column">
            <div className="flex items-center pointer gap-12">
              <Icon name="cancel" size="s" color="ink" />
              <P3 color="ink" style={{ marginVertical: 0 }}>
                <Link
                  style={{
                    textDecorationLine: 'none',
                    fontWeight: '500',
                    color: Colors.ink,
                  }}
                  onPress={() => {
                    trackClick({
                      feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
                      name: 'Open delete funding account modal',
                    });
                    disableClickOutside();
                    setDisplayActionMenu(false);
                    setDisplayModal(true);
                  }}
                >
                  Remove
                </Link>
              </P3>
            </div>
          </div>
        </div>
      )}

      <Modal
        headerText="Are you sure you want to remove this bank account?"
        onClose={closeDeleteFundingAccountModal}
        isOpen={displayModal}
        maxWidth="640px"
        primaryButton={{
          onPress: onRemoveAccount,
          text: 'Yes, remove',
          isCritical: true,
        }}
        secondaryButton={{
          onPress: closeDeleteFundingAccountModal,
          text: 'Nevermind',
        }}
      >
        <P3>
          You'll no longer see this bank account as a payment option, and any
          upcoming payments will be canceled.
        </P3>
      </Modal>
    </>
  );
};

export default ActionMenu;
