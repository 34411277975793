import { Button, Colors, H4, Icon, P3, Spacing } from '@missionlane/compass-ui';
import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import classNames from 'classnames';
import { useState } from 'react';
import { StyleProp, TextStyle } from 'react-native';
import { TrackService } from '@core/services';
import { ButtonClickedEvent } from '@core/services/TrackService/models/events';
import { CFU_FEATURE_NAME } from '@core/utils/constants';
import './AccountStandingInfoSection.css';

export const infoItems: {
  text: string;
  detailText: string;
  iconName: IconNames;
}[] = [
  {
    text: 'Pay on time',
    detailText:
      'Making on-time payments is one of the most important things you can do.',
    iconName: 'alarmClock',
  },
  {
    text: 'Stay below your limit',
    detailText: 'Remaining under your credit limit shows responsible card use.',
    iconName: 'arrowDown',
  },
  {
    text: 'Resolve any card-restricting issues',
    detailText:
      'If your account has any restrictions due to issues, such as a lost or stolen card or fraudulent activity, please reach out to us as soon as you can to resolve them.',
    iconName: 'phoneVolume',
  },
  {
    text: 'Maintain your ability to pay',
    detailText:
      'Balance your income and obligations, like housing expenses and outstanding debt.',
    iconName: 'percent',
  },
  {
    text: 'Keep your account open',
    detailText:
      'Closing your account will deactivate your credit card. Events like filing for bankruptcy and charging off on your balance will also result in a closed account.',
    iconName: 'check',
  },
];

const textStyle: StyleProp<TextStyle> = {
  margin: 0,
  lineHeight: 16,
  fontWeight: '600',
  color: Colors.ink,
};

export const buttonClickEventProperties = {
  feature_name: CFU_FEATURE_NAME,
  button_name: 'Expand Acccount Standing Info Section',
  label: 'See More',
};

export const AccountStandingInfoSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleButtonPress = () => {
    if (!isExpanded) {
      TrackService.track(new ButtonClickedEvent(buttonClickEventProperties));
    }
    setIsExpanded((value) => !value);
  };

  return (
    <div className="hr-border mt4 pt4">
      <H4 style={{ display: undefined }}>
        Here's how to bring your account into{' '}
        <span style={{ color: Colors.green }}>good standing:</span>
      </H4>
      <div className="mt4">
        {infoItems.map((item) => (
          <div className="flex flex-column" key={item.text}>
            <div
              className={classNames('flex flex-row items-start', {
                mb3: !isExpanded,
              })}
            >
              <Icon
                name={item.iconName}
                color="green"
                size="s"
                style={{ marginRight: Spacing.s }}
              />
              <P3 color="ink" style={textStyle}>
                {item.text}
              </P3>
            </div>
            {isExpanded && (
              <div className="detail-text">
                <P3>{item.detailText}</P3>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex p0">
        <div className="expand-btn-container">
          <Button
            text={isExpanded ? 'See Less' : 'See More'}
            variant="text"
            size="small"
            icon={{ name: isExpanded ? 'chevronUp' : 'chevronDown' }}
            onPress={handleButtonPress}
            accessibilityLabel="See more information on how to keep your account in good standing"
          />
        </div>
      </div>
    </div>
  );
};
