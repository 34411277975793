import { B, BubbleIcon, ColorNames, P3 } from '@missionlane/compass-ui';
import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import { ReactNode } from 'react';

type ColorTheme = {
  color: ColorNames;
  colorWashed: ColorNames;
  colorSecondaryLabel?: boolean;
};
export interface ScheduledPaymentListItemProps {
  iconName: IconNames;
  label: string;
  secondaryLabel?: string;
  description: string;
  linkComponent?: ReactNode;
  colorTheme?: ColorTheme;
  testID?: string;
}

export const ScheduledPaymentListItem = ({
  iconName,
  label,
  secondaryLabel,
  description,
  linkComponent,
  colorTheme = { color: 'green', colorWashed: 'greenWashed' },
  testID,
}: ScheduledPaymentListItemProps) => (
  <div className="flex items-center" data-testid={testID}>
    <div className="pr3">
      <BubbleIcon
        name={iconName}
        iconColor={colorTheme.color}
        bubbleColor={colorTheme.colorWashed}
      />
    </div>
    <div className="w-100">
      <div className="flex flex-row justify-between mb1">
        <P3 color={colorTheme.color} style={{ margin: 0 }}>
          <B>{label}</B>
        </P3>
        <P3
          color={colorTheme?.colorSecondaryLabel ? colorTheme.color : 'ink'}
          style={{ margin: 0 }}
        >
          {secondaryLabel}
        </P3>
      </div>
      <div className="flex flex-row justify-between items-center">
        <P3 color="inkLight" style={{ margin: 0 }}>
          {description}
        </P3>
        {linkComponent}
      </div>
    </div>
  </div>
);
