import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitterSquare,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { IconProps } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import { Colors } from '@missionlane/compass-ui';

// Names Reference - https://fontawesome.com/search?o=r&f=brands
const ExternalBrandIconMap = {
  Facebook: faFacebookSquare,
  Instagram: faInstagram,
  Twitter: faTwitterSquare,
  LinkedIn: faLinkedin,
  YouTube: faYoutube,
};

export type ExternalBrandIconNames = keyof typeof ExternalBrandIconMap;

// Ported from compass-ui:
// https://github.com/missionlane/compass-ui/blob/main/src/components/Icon/Icon.tsx#L182
const getIconFontSize = (iconSize: ExternalBrandIconProps['size']) => {
  if (typeof iconSize === 'number') return iconSize;

  switch (iconSize) {
    case 'l':
      return 32;
    case 'm':
      return 24;
    case 's':
    default:
      return 16;
  }
};

interface ExternalBrandIconProps extends Omit<IconProps, 'name' | 'color'> {
  name: ExternalBrandIconNames;
  // we arent overriding fontawesome colors in this project
  //  so this allows us to pass compass color hex strings
  color: FontAwesomeIconProps['color'];
}

/**
 * Temporary component until we can safely upgrade to compass-ui v0.5.8, which has breaking Typography changes.
 * When we upgrade, we will have access to these icons in the Icon component.
 */
const ExternalBrandIcon = ({
  ariaHidden = true,
  color = Colors.inkLight,
  focusable = true,
  name,
  size,
  testID = 'ExternalBrandIconComponent',
  ...otherProps
}: ExternalBrandIconProps) => (
  <FontAwesomeIcon
    {...otherProps}
    focusable={!ariaHidden && focusable}
    icon={ExternalBrandIconMap[name]}
    // fontawesome size prop no longer supports numeric sizing as of v6.2.0 - https://fontawesome.com/v6/docs/web/style/size
    style={{ fontSize: getIconFontSize(size) }}
    color={color}
    title={name}
    role="img"
    aria-hidden={ariaHidden}
    data-testid={testID}
  />
);

export default ExternalBrandIcon;
