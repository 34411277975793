import {
  FieldType,
  FieldValue,
  FormValues,
  Validation,
  ValidationErrorOverrides,
  VGSCollectCustomizeableFormatField,
  VGSCollectField,
} from './types';

/**
 * Convert a generic VGS Collect field error message into
 * a Mission Lane specific version
 */
export const parseValidationError = (
  vgsErrMsg?: string,
  validationMessageOverrides?: ValidationErrorOverrides,
): string => {
  switch (vgsErrMsg) {
    case 'is required':
      return validationMessageOverrides?.[Validation.Required] || 'Required';
    case 'is not a valid card number':
      return (
        validationMessageOverrides?.[Validation.CardNumber] ||
        'Not a valid card number'
      );
    case 'is not a valid security code':
      return (
        validationMessageOverrides?.[Validation.CardSecurityCode] ||
        'Not a valid security code'
      );
    case 'is not a valid expiration date':
      return (
        validationMessageOverrides?.[Validation.CardExpirationDate] ||
        'Not a valid expiration date'
      );
    case 'is not a valid US zip/postal code':
      return (
        validationMessageOverrides?.[Validation.PostalCode] ||
        'Not a valid zip code'
      );
    case "the value doesn't match a pattern":
      return (
        validationMessageOverrides?.[Validation.Regex] || 'Input is invalid'
      );
    case 'comparison failed':
      return (
        validationMessageOverrides?.[Validation.Compare] || "Inputs don't match"
      );
    case undefined:
      return '';
    default:
      return validationMessageOverrides?.[Validation.Unknown] || vgsErrMsg;
  }
};

export const isFormValid = (formValues?: FormValues): boolean => {
  if (formValues) {
    const fieldValues = Object.values<FieldValue>(formValues);
    return fieldValues.every((field) => field.isValid);
  } else {
    return false;
  }
};

export const isCustomizeableFormatField = (
  vgsCollectField: VGSCollectField | VGSCollectCustomizeableFormatField,
  fieldType: FieldType,
): vgsCollectField is VGSCollectCustomizeableFormatField => {
  const customizeableFormatFieldTypes = [
    FieldType.Text,
    FieldType.TextArea,
    FieldType.Password,
    FieldType.PostalCode,
  ];
  const typeCastField = vgsCollectField as VGSCollectCustomizeableFormatField;
  return (
    typeCastField.replacePattern !== undefined &&
    typeCastField.mask !== undefined &&
    customizeableFormatFieldTypes.includes(fieldType)
  );
};
