import { Link } from '@missionlane/compass-ui';
import { useState } from 'react';
import OfferDetailsModal from '@clip/ClipProgress/components/OfferDetailsModal/OfferDetailsModal';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';

const SeeOfferDetailsLink = () => {
  const { clipStatus, isInGracePeriod } = useClipOffer();
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  if (
    clipStatus === ClipOfferStatusCodes.APPROVED ||
    clipStatus === ClipOfferStatusCodes.OVERLIMIT ||
    clipStatus === ClipOfferStatusCodes.INELIGIBLE ||
    clipStatus === ClipOfferStatusCodes.MISSED_PAYMENT ||
    isInGracePeriod
  ) {
    return null;
  }

  return (
    <>
      <Link
        style={{
          textDecorationLine: 'none',
          fontWeight: 'bold',
        }}
        onPress={toggleModal}
      >
        More Details
      </Link>
      <OfferDetailsModal isOpen={modalOpen} onClose={toggleModal} />
    </>
  );
};

export default SeeOfferDetailsLink;
