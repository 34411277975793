import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { MLError, TrackService } from '@core/services';
import {
  IRiskAuthService,
  OtpFactor,
  OtpResponse,
} from '@core/services/RiskAuthService';
import { IRiskAuthContext } from './types';
import { useNavigate } from 'react-router-dom';
import { getOriginalUriRedirect, getSearchParamsFromUri } from './helpers';
import { serializeError } from 'serialize-error';
import { RiskAuthServiceError } from '@core/services/RiskAuthService/errors';

interface RiskAuthProviderProps extends PropsWithChildren {
  riskAuth: IRiskAuthService;
}

export const RiskAuthContext = createContext<IRiskAuthContext>({
  isAuthenticated: null,
  error: undefined,
  setError: () => {},
  factors: [],
  otpRequest: () =>
    Promise.resolve({ success: false, error: 'Something went wrong' }),
  otpEnter: () =>
    Promise.resolve({ success: false, error: 'Something went wrong' }),
  otpResend: () =>
    Promise.resolve({ success: false, error: 'Something went wrong' }),
  otpCancel: () => null,
  threatMetrixSessionId: null,
});

export const useRiskAuth = () => useContext(RiskAuthContext);

export const RiskAuthProvider = ({
  children,
  riskAuth,
}: RiskAuthProviderProps) => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [factors, setFactors] = useState<OtpFactor[]>([]);
  const [selectedFactor, setSelectedFactor] = useState<OtpFactor>();
  const [error, setError] = useState<Error>();

  // get the original URI and search params from Okta storage
  const originalUri = oktaAuth.getOriginalUri();
  const searchParams = getSearchParamsFromUri(originalUri);
  const loginIntention = searchParams.get('loginIntention') || 'LOGIN';

  // Ensures we rehydrate our url with any query params on redirect if auth is cancelled
  const cancelAuth = () => {
    oktaAuth.clearStorage();
    // clear the original Uri stored in Okta so we don't duplicate search params
    oktaAuth.removeOriginalUri();
    // Navigate to signin (plus any preserved search params)
    navigate(`/signin?${searchParams.toString()}`, { replace: true });
  };

  // Memoize this as the profileUser takes time
  const threatMetrixSessionId = useMemo(() => {
    return riskAuth.profileUser();
  }, []);

  const sessionId = authState?.idToken?.idToken || '';

  const handleOriginalUriRedirect = () => {
    // if there's a valid redirectTo param in the originalUri, redirect to that URL on successful authentication
    // else, strip out the loginIntention and (invalid) redirectTo params and preserve any
    // other existing url search params
    const redirectTo = searchParams?.get('redirectTo');
    const { url, isExternal } = getOriginalUriRedirect(originalUri, redirectTo);
    if (isExternal) {
      window.location.replace(url);
    } else {
      navigate(url, { replace: true });
    }
  };

  // Code for after user is authenticated through risk auth
  const handleRiskAuthComplete = () => {
    TrackService.track({
      event: 'User Authentication Complete',
      properties: {},
    });

    // reset the oktaAuth originalUri so we don't redirect again when coming back to dashboard
    oktaAuth.removeOriginalUri();

    // complete the auth flow by redirecting back to the originalUri if one is stored.
    // an originalUri is stored when logging in or when landing on a private path that needs
    // to be re-authenticated. if no originalUri is stored, stay on the current URL
    if (originalUri) {
      handleOriginalUriRedirect();
    }

    setIsAuthenticated(true);
  };

  // Code flow for after okta is authenticated
  const handleOktaAuthenticated = async () => {
    // Just in case we didnt clear it in the beginning of auth flow
    if (error) setError(undefined);
    try {
      const response = await riskAuth.evaluateSession(
        threatMetrixSessionId,
        loginIntention,
        sessionId,
      );

      if (
        response.multifactorAuthenticationState === 'MFA_NOT_REQUIRED' ||
        response.multifactorAuthenticationState === 'MFA_COMPLETED'
      ) {
        TrackService.track({ event: 'Risk Auth Login Success' });
        return handleRiskAuthComplete();
      }
      if (response.multifactorAuthenticationState === 'MFA_REQUIRED') {
        const responseFactors = response.factors as OtpFactor[];
        setFactors(responseFactors);
        TrackService.track({ event: 'Risk Auth OTP Required', properties: {} });
        navigate('/mfa/request', { replace: true });
      }
    } catch (error) {
      TrackService.track({
        event: 'Risk Auth Error',
        properties: { error: serializeError(error) },
      });
      if (error instanceof RiskAuthServiceError) {
        MLError.report({
          name: error.name,
          error,
        });
        setError(error);
      }
      // Send the user to /signin so we don't accidentally show the app
      cancelAuth();
    }
  };

  // Trigger code flow after okta is authenticated
  useEffect(() => {
    if (authState?.isAuthenticated === true) {
      handleOktaAuthenticated();
    } else if (authState?.isAuthenticated === false) {
      setIsAuthenticated(false);
    }
  }, [authState?.isAuthenticated]);

  // Send the MFA code
  const otpRequest = async (
    factor: OtpFactor,
    intention?: string,
  ): Promise<OtpResponse> => {
    try {
      const response = await riskAuth.otpRequest(
        sessionId,
        factor,
        intention || loginIntention,
      );
      if (response.success) {
        setSelectedFactor(factor);
      }
      return response;
    } catch (e) {
      // Not reporting to MLError as RiskAuthService is doing this for now
      return {
        success: false,
        error: 'Something went wrong. Please try again',
      };
    }
  };

  // Enter the MFA code
  const otpEnter = async (
    factorId: string,
    passCode: string,
    intention?: string,
  ): Promise<OtpResponse> => {
    try {
      const response = await riskAuth.otpEnter(
        sessionId,
        factorId,
        passCode,
        intention || loginIntention,
      );
      if (response.success) {
        handleRiskAuthComplete();
      }
      return response;
    } catch (e) {
      // Not reporting to MLError as RiskAuthService is doing this for now
      return {
        success: false,
        error: 'Something went wrong, please try again.',
      };
    }
  };

  // Resend the code
  const otpResend = async (intention?: string): Promise<OtpResponse> => {
    try {
      if (!selectedFactor) {
        return {
          success: false,
          error: 'Something went wrong. Please try again.',
        };
      }
      return await otpRequest(selectedFactor, intention);
    } catch (e) {
      return {
        success: false,
        error: 'Something went wrong. Please try again',
      };
    }
  };

  return (
    <RiskAuthContext.Provider
      value={{
        isAuthenticated,
        error,
        setError,
        factors,
        selectedFactor,
        otpRequest,
        otpEnter,
        otpCancel: cancelAuth,
        otpResend,
        threatMetrixSessionId,
      }}
    >
      {children}
    </RiskAuthContext.Provider>
  );
};
