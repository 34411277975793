import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup, RadioGroupProps } from '@missionlane/compass-ui';
import { RadioValue } from '@missionlane/compass-ui/lib/typescript/components/Input/RadioButton/Radio';

const HookFormRadioGroup = ({
  name,
  options,
  onChange,
  value,
  error,
  ...radioGroupProps
}: RadioGroupProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { onChange: hookFormOnChange } = field;
        return (
          <RadioGroup
            name={name}
            value={value}
            options={options}
            error={error}
            onChange={(radioValue: RadioValue) => {
              onChange(radioValue);
              hookFormOnChange(radioValue);
            }}
            {...radioGroupProps}
          />
        );
      }}
    />
  );
};

export default HookFormRadioGroup;
