import * as FullStory from '@fullstory/browser';
import { useEffect, useState } from 'react';

import Routes from './Routes/Routes';
import AppIdleTimer from './AppIdleTimer';
import CookieManager from '../Auth/CookieManager';

import './App.css';
import { useSearchParams } from 'react-router-dom';
import { CustomerIO } from '@core/services';

// Initialize FullStory
FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID as string,
  devMode: process.env.NODE_ENV !== 'production',
});

// 'page' is the only possible scope, but by calling it at the top level,
// we can ensure it's set on every page.
// https://developer.fullstory.com/browser/v1/set-page-properties/
FullStory.setVars('page', {
  'commit-id': process.env.REACT_APP_GIT_COMMIT ?? 'development',
});

const App = () => {
  const [searchParams] = useSearchParams();
  const [hasTracked, setHasTracked] = useState(false);

  useEffect(() => {
    const trackDeepLink = async () => {
      if (!hasTracked) {
        const linkId = searchParams.get('link_id');
        if (linkId) await CustomerIO.trackCIOCampaignDeepLink(linkId);
        setHasTracked(true);
      }
    };
    trackDeepLink();
  }, [searchParams, hasTracked]);

  return (
    <AppIdleTimer>
      {/* CookieManager sets the id_token as a cookie after Okta authentication. Can we move this to our custom LoginCallback component? */}
      <CookieManager />
      <Routes />
    </AppIdleTimer>
  );
};

export default App;
