interface LocalStorageSchema {
  username?: string;
  'tmx-base-session-id'?: string;
  dismissedAutopayCardAt?: string;
  dismissedAutopayPromptCount?: number;
}

type Keys = keyof LocalStorageSchema;

export const useLocalStorage = (): { set: typeof set; get: typeof get } => {
  const set = (key: Keys, value: string | number | Record<string, unknown>) => {
    const stringifiedValue = JSON.stringify(value);
    localStorage.setItem(key, stringifiedValue);
  };

  const get = (key: Keys) => {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  };

  return { set, get };
};
