import { gql } from '@apollo/client';
import { Button, Icon, LoadingIndicator } from '@missionlane/compass-ui';
import PageWrapper from '@core/components/Page/PageWrapper';
import PaymentFlowCard from '@payments/components/Payments/PaymentFlowCard';
import {
  ActivityState,
  ScheduledPaymentsQuery,
  ScheduledPaymentsQueryVariables,
} from '@core/graphql/globalTypes';
import { useNavigate } from 'react-router-dom';
import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import dayjs from 'dayjs';
import { getAmountDisplay } from '@payments/components/Autopay/utils/helpers';
import { ScheduledPaymentListItemProps } from './ScheduledPaymentListItem';
import TrackingLink from '@core/components/General/TrackingLink';
import { getOrdinalFromDayOfMonth } from '@core/utils/getOrdinalDate';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { centsToDollars } from '@core/utils/centsToDollars';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { useMemo } from 'react';
import ScheduledPaymentsList from './ScheduledPaymentsList';
import { canCancelPayment } from '@payments/utils/canCancelPayment';

export const SCHEDULED_PAYMENTS_QUERY = gql`
  query ScheduledPayments($accountId: String!) {
    account(accountId: $accountId) {
      id
      upcomingPayments {
        ...PaymentFields
      }
      autopay {
        ...AutopayFields
      }
    }
  }
  ${PAYMENT_FIELDS}
  ${AUTOPAY_FIELDS}
`;

const ScheduledPayments = () => {
  const navigate = useNavigate();
  const { data, loading } = useAccountIdQuery<
    ScheduledPaymentsQuery,
    ScheduledPaymentsQueryVariables
  >(SCHEDULED_PAYMENTS_QUERY);

  const { autopay = [], upcomingPayments = [] } = data?.account || {};
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  const mappedUpcomingPayments = useMemo(
    () =>
      upcomingPayments.reduce<ScheduledPaymentListItemProps[]>(
        (payments, payment) => {
          if (payment.state === ActivityState.Scheduled) {
            return [
              ...payments,
              {
                iconName: 'calendar',
                label: 'One-Time',
                secondaryLabel: centsToDollars(payment.amount),
                description: dayjs(payment.date).format('MMM DD, YYYY'),
                linkComponent:
                  canCancelPayment(payment) ?
                    <TrackingLink
                      to={`./cancel-payment/${payment.id}`}
                      trackingName="Cancel Scheduled Payment"
                      trackingProperties={customerAndAccountIds}
                    >
                      Cancel
                    </TrackingLink>
                  : null,
              },
            ];
          }
          return payments;
        },
        [],
      ),
    [upcomingPayments],
  );

  const mappedAutopay: ScheduledPaymentListItemProps[] = useMemo(
    () =>
      autopay.map((autopayItem) => {
        return {
          iconName: 'autopay',
          label: 'Autopay',
          secondaryLabel: getAmountDisplay(
            autopayItem.paymentType,
            autopayItem.amount,
          ),
          description: `${dayjs(autopayItem.nextPaymentDate).format(
            'MMM DD, YYYY',
          )} (every ${getOrdinalFromDayOfMonth(autopayItem.dayOfMonth)})`,
          linkComponent: (
            <TrackingLink
              to="../autopay/manage"
              trackingName="Edit Autopay from Scheduled Payments"
              trackingProperties={customerAndAccountIds}
            >
              <Icon name="edit" color="blue" />
            </TrackingLink>
          ),
        };
      }),
    [autopay],
  );

  const scheduledPaymentsData: ScheduledPaymentListItemProps[] = [
    ...mappedUpcomingPayments,
    ...mappedAutopay,
  ];

  if (loading) {
    return (
      <div data-testid="loading">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <PageWrapper
      greyBackground
      trackingProperties={{
        featureName: 'Payments',
        pageName: 'Scheduled Payments ',
      }}
    >
      <AccountBreadcrumbs
        items={[
          { text: 'Payments', link: '..' },
          { text: 'Scheduled Payments' },
        ]}
      />
      <PaymentFlowCard header="Scheduled Payments" headerClassName="mb3">
        <ScheduledPaymentsList scheduledPayments={scheduledPaymentsData} />
        <div className="w-40 mt4">
          <Button
            variant="contained"
            text="Back"
            onPress={() => {
              navigate('../../summary');
            }}
          />
        </div>
      </PaymentFlowCard>
    </PageWrapper>
  );
};

export default ScheduledPayments;
