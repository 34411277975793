import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import PageWrapper from '@core/components/Page/PageWrapper';
import { B, Button, Notification } from '@missionlane/compass-ui';
import OfferTransactions from '../OfferTransactions';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { usePaymentPlanContext } from '../contexts/PaymentPlanContext';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import KardWrapper from '@core/components/General/Wrappers/KardWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { useMutation } from '@apollo/client';
import {
  ServicingOffersPaymentSource,
  ServicingOffersTransactionInput,
  UpdateOfferMutation,
  MutationUpdateOfferArgs,
  ServicingOfferChannel,
} from '@core/graphql/globalTypes';
import { UPDATE_OFFER } from '../gql/mutations';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '@core/components/General/LoadingModal';
import { createXhtmlDocFromHtmlString } from '@core/utils/createXhtmlDocFromHtml';
import { isPaymentPlanTransaction } from '../utils/isPaymentPlanTransaction';
import { NotificationLevel } from '@missionlane/compass-ui/lib/typescript/components/Notification/types';
import { centsToDollars } from '@core/utils/centsToDollars';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { Title } from '@core/components/TitleBar/TitleBar';

const ConfirmPaymentPlan = () => {
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [modalDisplayComplete, setModalDisplayComplete] = useState(false);
  const [disableConfirmationButton, setDisableConfirmationButton] =
    useState(false);
  const [updateOfferComplete, setUpdateOfferComplete] = useState(false);
  const [displayError, setDisplayError] = useState<string | null>(null);
  const { paymentPlan, setPaymentPlan } = usePaymentPlanContext();
  const eftaRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useUserDevice();
  const navigate = useNavigate();
  const ERROR_TEXT =
    'We had trouble processing your payment plan. Please make sure your information is correct, or check back later.';
  const [updatePaymentPlan] = useMutation<
    UpdateOfferMutation,
    MutationUpdateOfferArgs
  >(UPDATE_OFFER, {
    onCompleted: () => {
      setUpdateOfferComplete(true);
    },
    onError: () => {
      setLoadingModalOpen(false);
      setDisplayError(ERROR_TEXT);
    },
  });

  useEffect(() => {
    if (
      paymentPlan?.statuses?.overCurrentBalance ||
      paymentPlan?.statuses?.underRemainingBalance
    ) {
      setDisableConfirmationButton(true);
    } else {
      setDisableConfirmationButton(false);
    }
  }, [paymentPlan?.statuses]);

  useEffect(() => {
    if (updateOfferComplete && modalDisplayComplete) {
      navigate('../../../summary');
    }
  }, [updateOfferComplete, modalDisplayComplete]);

  const handleConfirmation = () => {
    const requestTransactions = paymentPlan?.updatedTransactions?.reduce(
      (accumulator: ServicingOffersTransactionInput[], tx) => {
        if (tx) {
          return [
            ...accumulator,
            {
              amount: tx.amount,
              date: tx.date,
            },
          ];
        }
        return accumulator;
      },
      [],
    );

    setLoadingModalOpen(true);
    setTimeout(() => {
      setModalDisplayComplete(true);
    }, 2000);
    if (eftaRef.current?.innerHTML) {
      updatePaymentPlan({
        variables: {
          offerId: paymentPlan?.offerId || '',
          channel: ServicingOfferChannel.Web,
          requestBody: {
            acceptOffer: false,
            cancelOffer: false,
            declineOffer: false,
            fundingAccountUpdate: null,
            paymentPlanCreate: null,
            paymentPlanUpdate: {
              authorizationText: createXhtmlDocFromHtmlString(
                eftaRef.current.innerHTML,
              ),
              source: ServicingOffersPaymentSource.Web,
              transactions: requestTransactions || [],
            },
          },
        },
      });
    } else {
      setDisplayError(ERROR_TEXT);
    }
  };

  const fundingAccountLast4 = paymentPlan?.fundingAccounts?.find(
    (fundingAccount) => fundingAccount.id === paymentPlan?.fundingAccountId,
  )?.numberLast4;

  const pageTitle: Title = {
    primaryText: 'Payment Schedule',
  };

  const getTotalAmountNotification = () => {
    let level: NotificationLevel = 'error';
    let notificationText: ReactNode = null;

    if (
      paymentPlan?.totalScheduledAmount &&
      paymentPlan?.currentBalance &&
      paymentPlan?.remainingBalance &&
      paymentPlan?.statuses
    ) {
      const {
        totalScheduledAmount,
        currentBalance,
        remainingBalance,
        pendingPaymentAmount,
        statuses: {
          overCurrentBalance,
          overRemainingBalanceSIF,
          underRemainingBalance,
        },
      } = paymentPlan;

      if (underRemainingBalance) {
        notificationText = (
          <span>
            Oh no! The total is{' '}
            <B>
              lower than your balance by{' '}
              {centsToDollars(remainingBalance - totalScheduledAmount)}.
            </B>{' '}
            Please increase any of your remaining payments to meet your
            settlement balance.
          </span>
        );
      } else if (overCurrentBalance) {
        notificationText = (
          <span>
            Oops! The total is{' '}
            <B>
              higher than your balance by{' '}
              {centsToDollars(
                totalScheduledAmount -
                  (currentBalance - Math.abs(pendingPaymentAmount || 0)),
              )}
              .
            </B>{' '}
            Please lower any of your remaining payments.
          </span>
        );
      } else if (overRemainingBalanceSIF) {
        level = 'success';
        notificationText = (
          <span>
            Great job, you’re getting ahead!{' '}
            <B>
              You’ll be overpaying by{' '}
              {centsToDollars(totalScheduledAmount - remainingBalance)}.
            </B>
            You can adjust any payment, or we’ll apply it to your total
            outstanding balance of {centsToDollars(currentBalance)}.
          </span>
        );
      }
    }

    return notificationText ?
        <div className="mv3">
          <Notification variant="inline" level={level}>
            {notificationText}
          </Notification>
        </div>
      : null;
  };

  if (
    !paymentPlan?.updatedTransactions ||
    !paymentPlan.updatedTransactions.length
  ) {
    return <GenericFallbackFullPage />;
  }

  return (
    <PageWrapper pageTitle={pageTitle} greyBackground={!isMobile}>
      <AccountBreadcrumbs
        items={[
          { text: 'Payment Schedule', link: '../../' },
          { text: 'Confirm' },
        ]}
      />
      <div className="flex flex-column items-center">
        <KardWrapper withKard={!isMobile} className="payment-plan-kard">
          <OfferTransactions
            transactions={paymentPlan.updatedTransactions}
            onEdit={(transaction) => {
              if (isPaymentPlanTransaction(transaction)) {
                setPaymentPlan({
                  transactionToEdit: transaction,
                });
                navigate('../');
              }
            }}
            onAdd={() => {
              setPaymentPlan({
                transactionToEdit: undefined,
              });
              navigate('../');
            }}
          />
          <div>{getTotalAmountNotification()}</div>
          <div className="mt3 pt3 bt payment-plan-border" ref={eftaRef}>
            <Paragraph>
              This new payment schedule includes all of your plan’s upcoming
              payments. It doesn’t include any posted or returned payments.
            </Paragraph>
            <Paragraph>
              By confirming this updated payment schedule, you authorize Mission
              Lane to make recurring electronic debits from your bank account
              ending in {fundingAccountLast4} towards your Mission Lane Visa
              account ending in {paymentPlan?.cardLast4} for these dates and
              amounts.
            </Paragraph>
            <Paragraph>
              I understand that I may edit or cancel a scheduled payment until
              11:59pm ET on the day before the payment date. You can edit or
              cancel payments by logging into your account online and clicking
              “Review and edit payments.”
            </Paragraph>
          </div>
          {displayError && (
            <Notification variant="inline" level="error">
              {displayError}
            </Notification>
          )}
          <div className="pt2 flex-ns w-100">
            <Button
              onPress={handleConfirmation}
              text="Confirm New Payment Schedule"
              disabled={disableConfirmationButton}
            />
          </div>
        </KardWrapper>
      </div>
      <LoadingModal
        isOpen={loadingModalOpen}
        onClose={() => {
          setLoadingModalOpen(false);
        }}
        primaryText="We’re updating your plan."
        secondaryText="This can take a minute or two, so please don't exit or refresh the page."
      />
    </PageWrapper>
  );
};

const Paragraph = ({ children }: { children: ReactNode }) => (
  <ResponsiveTypography
    className="mb3"
    type="PARAGRAPH"
    mobileLevel="P1"
    desktopLevel="P3"
  >
    {children}
  </ResponsiveTypography>
);

export default ConfirmPaymentPlan;
