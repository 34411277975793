import { gql, useQuery } from '@apollo/client';
import { Breadcrumb } from '@core/components/General/Breadcrumbs/Breadcrumb';
import Breadcrumbs from '@core/components/General/Breadcrumbs/Breadcrumbs';
import { HomeBreadcrumb } from '@core/components/General/Breadcrumbs/common';
import { GetMyProfileInfoQuery } from '@core/graphql/globalTypes';
import PageWrapper from '../Page/PageWrapper';
import LoadingSpinner from '../General/LoadingSpinner';
import MyProfilePageContent from './MyProfilePageContent';

const breadcrumbs: Breadcrumb[] = [
  HomeBreadcrumb,
  {
    text: 'My profile',
  },
];

export const GET_MY_PROFILE_INFO_QUERY = gql`
  query GetMyProfileInfo {
    customer {
      id
      contactInfo {
        address {
          city
          postalCode
          state
          street1
          street2
        }
        email
        phoneNumber
      }
      financialInfo {
        annualIncome
        monthlyLiving
        eligibleForUpdate
      }
    }
  }
`;

const MyProfilePageInner = () => {
  const { data, error, loading } = useQuery<GetMyProfileInfoQuery>(
    GET_MY_PROFILE_INFO_QUERY,
    { errorPolicy: 'all' },
  );

  if (loading) return <LoadingSpinner />;

  if (!data?.customer) {
    throw error;
  }

  const {
    customer: { contactInfo, financialInfo },
  } = data;
  const { address, email, phoneNumber } = contactInfo;

  return (
    <MyProfilePageContent
      financialInfo={financialInfo}
      address={address}
      email={email}
      phoneNumber={phoneNumber}
    />
  );
};

const MyProfilePage = () => (
  <PageWrapper pageTitle={{ primaryText: 'My profile' }} greyBackground>
    <Breadcrumbs items={breadcrumbs} />
    <MyProfilePageInner />
  </PageWrapper>
);

export default MyProfilePage;
