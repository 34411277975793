import { Icon, Spacing } from '@missionlane/compass-ui';
import { useState } from 'react';
import { Colors, P3 } from '@missionlane/compass-ui';

export const ALIMONY_TOOLTIP =
  'Alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.';

export const ALIMONY_TOOLTIP_TRIGGER_TEST_ID =
  'ALIMONY_TOOLTIP_TRIGGER_TEST_ID';

const desktopTooltipStyles = {
  backgroundColor: Colors.pine,
  padding: '1.5rem',
  maxWidth: 411,
  marginLeft: Spacing.s,
};

const mobileTooltipStyles = {
  backgroundColor: Colors.pine,
  maxWidth: 411,
  left: 0,
  padding: '0.75rem',
  marginLeft: 105,
  marginRight: Spacing.m,
  marginTop: Spacing.xm,
};

const tooltipTextStyles = {
  color: Colors.white,
};

export const AlimonyTooltip = () => {
  const [showTip, setShowTip] = useState(false);
  const displayTooltip = () => setShowTip(true);
  const closeTooltip = () => setShowTip(false);

  return (
    <>
      <span
        className="mh1 di-ns dn"
        onMouseEnter={displayTooltip}
        onMouseLeave={closeTooltip}
        data-testid={ALIMONY_TOOLTIP_TRIGGER_TEST_ID}
      >
        <Icon name="infoCircleSolid" color="blue" />
        {showTip && (
          <span className="absolute z-1 br2" style={desktopTooltipStyles}>
            <P3 style={tooltipTextStyles}>{ALIMONY_TOOLTIP}</P3>
          </span>
        )}
      </span>
      <span
        className="mh1 dn-ns di"
        onMouseEnter={displayTooltip}
        onMouseLeave={closeTooltip}
        data-testid={ALIMONY_TOOLTIP_TRIGGER_TEST_ID}
      >
        <Icon name="infoCircleSolid" color="blue" />
        {showTip && (
          <span className="absolute z-1 br2" style={mobileTooltipStyles}>
            <P3 style={tooltipTextStyles}>{ALIMONY_TOOLTIP}</P3>
          </span>
        )}
      </span>
    </>
  );
};
