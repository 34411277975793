import { LDClient } from 'launchdarkly-js-client-sdk';
import { LD_CONSTANTS } from '../types/LaunchDarkly';
import { MLError } from '@core/services';
import {
  TestSeed,
  useTestProperties,
} from '@core/utils/hooks/useTestProperties';
import { TrackServiceTestProperties } from '@core/services/TrackService/TrackService';

type LDCustomer = {
  id: string;
  contactInfo: {
    email: string | null;
  };
};

const buildTestingContext = (
  testProps: TrackServiceTestProperties | undefined,
) => {
  if (testProps?.variant?.name && testProps?.variant?.value) {
    return { [testProps.variant.name]: testProps.variant.value };
  }
  return {};
};

export async function identifyLDUser(
  ldClient: LDClient | undefined,
  customer: LDCustomer,
) {
  try {
    ldClient?.waitForInitialization();
    if (ldClient === undefined) {
      MLError.report({
        name: 'LaunchDarkly initialized an undefined Client in identifyLDUser',
      });
    } else {
      const user = ldClient.getContext();

      const seed1Props = useTestProperties(
        TestSeed.SEED_1,
        undefined,
        customer.id,
      );
      const seed1V2Props = useTestProperties(
        TestSeed.SEED_1_V2,
        undefined,
        customer.id,
      );

      const customContext = {
        ...buildTestingContext(seed1Props),
        ...buildTestingContext(seed1V2Props),
      };

      if (user.key === LD_CONSTANTS.preAuthUserKey) {
        ldClient.identify({
          key: customer.id,
          email: customer.contactInfo.email || 'Customer email is not defined',
          custom: {
            ...customContext,
          },
        });
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      MLError.report({
        name: `LaunchDarkly Error in identifyLDUser: ${error}`,
        error,
      });
    }
  }
}
