import dayjs from 'dayjs';
import AutoPayPaymentDisplay from '@payments/components/Autopay/AutoPayPaymentDisplay';
import hasUnactivatedCard from '@core/utils/hasUnactivatedCard';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import { GET_ACTIVE_CARD_STATUS } from './queries';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { B, Button, Notification, P3 } from '@missionlane/compass-ui';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import PaymentFlowCard from '../Payments/PaymentFlowCard';
import { useAutopay } from './AutopayContext';
import PageWrapper from '@core/components/Page/PageWrapper';

const AutopaySuccess = () => {
  const { autopayContext, autopayLoading, autopayPageTitle } = useAutopay();
  const { data, error, loading } = useAccountIdQuery(GET_ACTIVE_CARD_STATUS);
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const onBackToSummary = () => navigate(summaryPath);
  const { nextPaymentDate, amount, dayOfMonth, fundingAccount, paymentType } =
    autopayContext?.autopay || {};

  if (loading || autopayLoading) return <LoadingSpinner />;

  if (
    error ||
    amount === undefined ||
    !dayOfMonth ||
    !paymentType ||
    !fundingAccount
  ) {
    return <GenericFallbackFull />;
  }

  const cardIsActivated = !hasUnactivatedCard(
    data?.account?.cardDetails?.issuanceType,
  );
  return (
    <PageWrapper
      pageTitle={autopayPageTitle}
      greyBackground
      trackingProperties={{
        pageName: 'Set Up Autopay: Success',
        featureName: 'Payments: Autopay',
      }}
    >
      <PaymentFlowCard header="Autopay Set!">
        <div className="ph0-l flex">
          <div className="center">
            <div className="mb4">
              {cardIsActivated && (
                <P3>
                  Your first payment date will be on
                  <B> {dayjs(nextPaymentDate).format('MMM D, YYYY')}. </B>
                </P3>
              )}
            </div>
            <div>
              <AutoPayPaymentDisplay
                amount={amount}
                paymentType={paymentType}
                dayOfMonth={dayOfMonth}
                fundingAccount={fundingAccount}
                paymentDateLabel="First payment"
              />
            </div>
            <Notification variant="inline" level="success">
              We've emailed your autopay schedule to you. Your confirmation
              number is {autopayContext?.confirmationId}
            </Notification>
            <div className="mt4 w-25-l">
              <Button
                onPress={onBackToSummary}
                variant="contained"
                text="Done"
              />
            </div>
          </div>
        </div>
      </PaymentFlowCard>
    </PageWrapper>
  );
};

export default AutopaySuccess;
