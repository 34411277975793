import {
  useSpendBonusQuery,
  UseSpendBonusQueryReturnDisabled,
  UseSpendBonusQueryReturnEnabled,
} from './useSpendBonusQuery';
import {
  useSpendBonusMutation,
  UseSpendBonusMutationReturn,
} from './useSpendBonusMutation';

export type UseSpendBonusReturnDisabled = UseSpendBonusQueryReturnDisabled;
export type UseSpendBonusReturnEnabled = UseSpendBonusQueryReturnEnabled &
  UseSpendBonusMutationReturn;

export type UseSpendBonusReturn =
  | UseSpendBonusReturnDisabled
  | UseSpendBonusReturnEnabled;

export const useSpendBonus = (): UseSpendBonusReturn => {
  const useSpendBonusMutationResult = useSpendBonusMutation();
  const useSpendBonusQueryResult = useSpendBonusQuery();

  if (useSpendBonusQueryResult.isEnabled === false) {
    return useSpendBonusQueryResult;
  }

  return {
    ...useSpendBonusQueryResult,
    ...useSpendBonusMutationResult,
  };
};
