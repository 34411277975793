import { Fonts, P3 } from '@missionlane/compass-ui';
import React from 'react';

interface LoginInfoSectionProps {
  headerText: string;
  message: string | React.ReactNode;
}

const LoginInfoSection = ({ headerText, message }: LoginInfoSectionProps) => (
  <div className="mb4 mb5-ns">
    <div className="mt4 mb2">
      <P3 color="ink" style={{ fontFamily: Fonts.semiBold }}>
        {headerText}
      </P3>
    </div>
    <P3>{message}</P3>
  </div>
);

export default LoginInfoSection;
