import { MLError } from '@core/services';

interface CIOUser {
  id: string;
  email?: string | null;
}

export const identify = ({ id, email }: CIOUser) =>
  // @ts-expect-error
  _cio.identify({ id, email });

// @ts-expect-error
export const reset = () => _cio.reset();

export const trackPage = () =>
  // @ts-expect-error
  _cio.page(window.location.href);

/**
 * This function is used for tracking the click event when the link has a link_id parameter.
 * This is needed for the tracking of email campaigns with deep links included.
 * Customer IO's default tracking for this re-routes the link into their domain for tracking and this causes Universal links to not work properly.
 * More information can be found here https://customer.io/docs/journeys/universal-links/#tracking-universal-links-in-customerio
 * @param linkId id included in untracked links sent from CIO campaigns. Its the link_id parameter in the url
 */
export const trackCIOCampaignDeepLink = async (linkId: string) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CUSTOMERIO_TRACKING_LINK}/click/${linkId}`,
      {
        method: 'POST',
      },
    );
  } catch (error) {
    if (error instanceof Error)
      MLError.report({
        name: error.name,
        error,
      });
  }
};
