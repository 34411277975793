import PageWrapper from '@core/components/Page/PageWrapper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GenericServiceFallBackFullPage } from '../../../GenericFallbacks/GenericServiceFallBackFull';
import MfaContainer from '../MfaContainer/MfaContainer';
import { HomeBreadcrumb } from '@core/components/General/Breadcrumbs/common';
import { Breadcrumb } from '@core/components/General/Breadcrumbs/Breadcrumb';
import { Temp_MulticardBreadcrumbs } from '@core/components/General/Breadcrumbs/temp_MulticardBreadcrumbs';

const breadcrumbs: Breadcrumb[] = [
  HomeBreadcrumb,
  {
    text: 'My profile',
    link: '../my-profile',
  },
  {
    text: 'Edit address',
  },
];

const MfaFeature = () => {
  const { showMfa } = useFlags();

  if (!showMfa) {
    return <GenericServiceFallBackFullPage />;
  }

  return (
    <PageWrapper pageTitle={{ primaryText: 'Edit address' }} greyBackground>
      <Temp_MulticardBreadcrumbs items={breadcrumbs} />
      <MfaContainer />
    </PageWrapper>
  );
};

export default MfaFeature;
