import { ClipOfferUiStates } from './ClipOffer';

// https://missionlane.atlassian.net/wiki/spaces/ENG/pages/2234384554/Clip+Progress+Tracker+Relaunch#Decision-table
export const getClipUiState = ({
  streakGroupFlag = false,
  testGroupFlag = false,
  eligibility = false,
}: {
  streakGroupFlag: boolean;
  testGroupFlag: boolean;
  eligibility: boolean;
}): ClipOfferUiStates => {
  // true && true && true
  if (testGroupFlag && streakGroupFlag && eligibility) {
    return ClipOfferUiStates.TEST;
  }

  // true && true && false
  if (testGroupFlag && streakGroupFlag && !eligibility) {
    return ClipOfferUiStates.STREAK;
  }

  // true && false && true
  if (testGroupFlag && !streakGroupFlag && eligibility) {
    return ClipOfferUiStates.TEST;
  }

  // true && false && false
  if (testGroupFlag && !streakGroupFlag && !eligibility) {
    return ClipOfferUiStates.CONTROL;
  }

  // false && true && true
  if (!testGroupFlag && streakGroupFlag && eligibility) {
    return ClipOfferUiStates.STREAK;
  }

  // false && true && false
  if (!testGroupFlag && streakGroupFlag && !eligibility) {
    return ClipOfferUiStates.STREAK;
  }

  // false && false && true
  if (!testGroupFlag && !streakGroupFlag && eligibility) {
    return ClipOfferUiStates.CONTROL;
  }

  // false && false && false
  if (!testGroupFlag && !streakGroupFlag && !eligibility) {
    return ClipOfferUiStates.CONTROL;
  }

  // default - never reached
  return ClipOfferUiStates.CONTROL;
};

export default getClipUiState;
