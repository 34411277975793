import { gql } from '@apollo/client';
import { Title } from '@core/components/TitleBar/TitleBar';
import {
  GetAccountPageTitleQuery,
  GetAccountPageTitleQueryVariables,
} from '@core/graphql/globalTypes';
import { formatCardLast4 } from '@core/utils/formatters';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';

const GET_ACCOUNT_PAGE_TITLE_QUERY = gql`
  query GetAccountPageTitle($accountId: String!) {
    accountDetails(accountId: $accountId) {
      id
      cardLast4
    }
  }
`;

export const useAccountPageTitle = (pageName: string): Title => {
  const { data } = useAccountIdQuery<
    GetAccountPageTitleQuery,
    GetAccountPageTitleQueryVariables
  >(GET_ACCOUNT_PAGE_TITLE_QUERY);

  return {
    primaryText: pageName,
    secondaryText: formatCardLast4(data?.accountDetails?.cardLast4),
  };
};
