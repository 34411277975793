import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import KardWrapper from '@core/components/General/Wrappers/KardWrapper';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { Button, Notification } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { usePaymentPlanContext } from '../contexts/PaymentPlanContext';
import { useEffect } from 'react';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

const DeleteOfferTransaction = () => {
  const { isMobile } = useUserDevice();
  const navigate = useNavigate();
  const { paymentPlan, setPaymentPlan, updateTransactions } =
    usePaymentPlanContext();

  useEffect(() => {
    if (paymentPlan?.transactionToEdit?.state === 'DELETE') {
      updateTransactions();
      navigate('../confirm');
    }
  }, [paymentPlan?.transactionToEdit?.state]);

  const onDelete = () => {
    if (paymentPlan?.transactionToEdit) {
      setPaymentPlan({
        transactionToEdit: {
          amount: paymentPlan?.transactionToEdit?.amount,
          date: paymentPlan?.transactionToEdit?.date,
          state: 'DELETE',
        },
      });
    }
  };

  return (
    <PageWrapper
      pageTitle={{ primaryText: 'Edit Your Scheduled Payment' }}
      greyBackground={!isMobile}
      trackingProperties={{
        featureName: SETTLEMENTS_FEATURE_NAME,
        pageName: 'Edit Payment - Delete Payment Page',
      }}
    >
      <div className="flex flex-column items-center">
        <KardWrapper
          withKard={!isMobile}
          className="flex flex-column payment-plan-kard-small mt5-ns mt6 mb0-ns w-100 h-100-ns vh-50 justify-between"
        >
          <div className="flex flex-column">
            <ResponsiveTypography
              type="HEADER"
              desktopLevel="H3"
              mobileLevel="H2"
            >
              Are you sure you want to delete this payment?
            </ResponsiveTypography>
            <div className="mt4">
              <Notification variant="inline" level="warning">
                <span>
                  If you delete this payment, you may have to adjust other
                  payments to meet your settlement balance.
                </span>
              </Notification>
            </div>
          </div>
          <div className="flex flex-row-ns flex-column mt4">
            <div className="mr2-ns">
              <Button onPress={onDelete} text="Delete This Payment" />
            </div>
            <Button
              onPress={() => {
                navigate('../');
              }}
              variant="text"
              text="Cancel"
            />
          </div>
        </KardWrapper>
      </div>
    </PageWrapper>
  );
};

export default DeleteOfferTransaction;
