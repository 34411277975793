import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { B, Button, H4, Icon, Link, P3, Spacer } from '@missionlane/compass-ui';
import { AccountBreadcrumbs } from '../../Account/AccountBreadcrumbs';
import { useAccountPageTitle } from '../../Account/useAccountPageTitle';
import PageWrapper from '../../Page/PageWrapper';
import { ContentCard } from '../../General/ContentCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { faLeaf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ControlledCheckbox from '../../General/Checkbox/ControlledCheckbox';
import { useEffect, useState } from 'react';
import PaperStatementsModal from './PaperStatementsModal';
import { TrackService } from '@core/services';
import useStatementPreference from '@core/utils/hooks/useStatementPreference';
import { ErrorBoundary } from '@core/services/Error/Error';

export const StatementsPreferences = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, error, isPaperlessStatement, cardNameWithLast4 } =
    useStatementPreference();

  useEffect(() => {
    setIsChecked(isPaperlessStatement);
  }, [isPaperlessStatement]);

  if (loading) return <LoadingSpinner />;

  if (error) throw error;

  const handleContinuePress = () => {
    TrackService.click('Statement Preference (step 2): Continue');
    if (!isChecked) {
      setIsModalOpen(true);
    } else {
      navigate('./confirm');
    }
  };
  const handleBackOrCancelPress = () => {
    TrackService.click('Statement Preference (step 2): Cancel');
    if (Boolean(location.state?.fromStatements)) {
      navigate('..');
    } else {
      navigate('../../summary', { replace: true });
    }
  };
  const handleModalCancel = () => {
    setIsChecked(true);
    setIsModalOpen(false);
  };
  const handleModalConfirm = () => {
    setIsModalOpen(false);
    navigate('./confirm');
  };

  return (
    <>
      <div className="flex items-center">
        <Link onPress={handleBackOrCancelPress}>
          <Icon name="back" color="blue" />
        </Link>
        <Spacer size="m" />
        <H4 color="ink">Statements Preferences</H4>
      </div>
      <div className="mt3">
        <P3>
          If you enroll in <P3 color="green">paperless billing</P3>, we’ll email
          you when each statement is ready online.{' '}
        </P3>
      </div>
      <div className="mt3">
        <P3 color="ink">
          <B>
            Enroll in{' '}
            <P3 color="green">
              <B>paperless </B>
            </P3>
            <FontAwesomeIcon icon={faLeaf} size="sm" color="green" /> for:
          </B>
        </P3>
      </div>
      <div className="mt2 ba br2 b--ink-10 bw1 pa3">
        <ControlledCheckbox
          data-testid="checkBox-paperless"
          onChange={() => {
            TrackService.click(
              'Statement Preference (step 2): Enroll/unenroll checkbox',
            );
            setIsChecked(!isChecked);
          }}
          name="checkBox-paperless"
          id="checkBox-paperless"
          isChecked={isChecked}
          label={cardNameWithLast4}
        />
      </div>
      <div className="flex mt5 flex-column-reverse flex-row-ns justify-end-ns">
        <Button
          text="Cancel"
          variant="text"
          onPress={handleBackOrCancelPress}
        />
        <Spacer size="l" />
        <Button
          disabled={isPaperlessStatement === isChecked}
          text="Continue"
          onPress={handleContinuePress}
        />
      </div>
      <PaperStatementsModal
        isOpen={isModalOpen}
        onCancel={handleModalCancel}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleModalConfirm}
      />
    </>
  );
};

const StatementsPreferencesPage = () => {
  const pageTitle = useAccountPageTitle('Statements');

  return (
    <PageWrapper
      greyBackground
      pageTitle={pageTitle}
      trackingProperties={{
        pageName: 'Statement Preferences',
        featureName: 'Statements Preferences',
      }}
    >
      <AccountBreadcrumbs
        items={[
          { text: 'Statements', link: '../' },
          { text: 'Statements Preferences' },
        ]}
      />
      <ErrorBoundary>
        <ContentCard>
          <StatementsPreferences />
        </ContentCard>
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default StatementsPreferencesPage;
