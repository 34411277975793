import Modal from '@core/components/General/Modal/Modal';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { B, Button } from '@missionlane/compass-ui';

interface ExitSetupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onExitSetup: () => void;
}

interface ParagraphProps {
  children: React.ReactNode;
}
const Paragraph = ({ children }: ParagraphProps) => (
  <div className="mb4">
    <ResponsiveTypography type="PARAGRAPH" desktopLevel="P3" mobileLevel="P1">
      {children}
    </ResponsiveTypography>
  </div>
);

const ExitSetupModal = ({
  isOpen,
  onClose,
  onExitSetup: onExit,
}: ExitSetupModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H4">
        Are you sure you'd like to exit?
      </ResponsiveTypography>
      <div className="mt4">
        <Paragraph>
          You can still set up a plan later if you exit now, but it's{' '}
          <B color="ink">a good idea to get started as soon as you can.</B>
        </Paragraph>
        <Paragraph>
          You'll be able to see our offer if you continue,{' '}
          <B color="ink">and you won't have to make a payment right away.</B>
        </Paragraph>

        <Paragraph>
          To learn more about your options, give us a call at 1-888-695-8536.
        </Paragraph>
        <div className="flex flex-row-ns flex-column">
          <Button
            text="Yes, I'd like to exit"
            variant="critical"
            onPress={onExit}
          />
          <Button text="Go Back" variant="text" onPress={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default ExitSetupModal;
