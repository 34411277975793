import LoadingSpinner from '@core/components/General/LoadingSpinner';
import PageWrapper from '@core/components/Page/PageWrapper';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import { B, Notification } from '@missionlane/compass-ui';

import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import {
  PaymentPlanContextData,
  usePaymentPlanContext,
} from '../contexts/PaymentPlanContext';
import KardWrapper from '@core/components/General/Wrappers/KardWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import OfferTransactions from '../OfferTransactions';
import { useNavigate } from 'react-router-dom';
import { isPaymentPlanTransaction } from '../utils/isPaymentPlanTransaction';
import { Title } from '@core/components/TitleBar/TitleBar';
import { SettlementOfferState } from '@core/graphql/globalTypes';
import dayjs from 'dayjs';
import { useState } from 'react';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import { TrackService } from '@core/services';

const ManagePaymentPlan = () => {
  const {
    paymentPlan,
    payments,
    loading,
    setPaymentPlan,
    showReturnedPaymentExperience,
  } = usePaymentPlanContext();
  const { isMobile } = useUserDevice();
  const pageTitle: Title = { primaryText: 'Payment Schedule' };
  const navigate = useNavigate();

  const transactions = paymentPlan?.transactions || [];

  if (loading) {
    return (
      <PageWrapper pageTitle={pageTitle} greyBackground>
        <LoadingSpinner />
      </PageWrapper>
    );
  }

  if (!transactions?.length && !payments?.length) {
    return <GenericFallbackFullPage />;
  }

  return (
    <PageWrapper
      pageTitle={pageTitle}
      greyBackground={!isMobile}
      banners={[
        () => (
          <ReturnedPaymentBanner
            hasReturnedPayment={showReturnedPaymentExperience}
            paymentPlan={paymentPlan}
          />
        ),
      ]}
      trackingProperties={{
        pageName: 'Edit Payment - View Payment Schedule',
        featureName: SETTLEMENTS_FEATURE_NAME,
      }}
    >
      <AccountBreadcrumbs items={[{ text: 'Payment Schedule' }]} />
      <div className="flex justify-center center">
        <KardWrapper withKard={!isMobile} className="payment-plan-kard w-100">
          <OfferTransactions
            transactions={transactions}
            payments={payments}
            onEdit={(transaction) => {
              if (isPaymentPlanTransaction(transaction)) {
                TrackService.trackClick('Edit Payment - Payment Edited', {
                  feature: SETTLEMENTS_FEATURE_NAME,
                });

                setPaymentPlan({
                  transactionToEdit: transaction,
                });

                navigate('./edit-transaction');
              }
            }}
            onAdd={
              paymentPlan?.offerState === SettlementOfferState.PendingCloseOut ?
                undefined
              : () => {
                  navigate('./edit-transaction');
                }
            }
          />
          {/* Don't prompt customer to edit unless its actually actionable */}
          {/* Transactions are editable, payments aren't */}
          {Boolean(transactions.length) && (
            <div className="mt3">
              <Notification variant="inline" level="info">
                <ResponsiveTypography
                  type="PARAGRAPH"
                  mobileLevel="P1"
                  desktopLevel="P2"
                  color="blue"
                >
                  <B>Ready to pay in full?</B> You can edit any upcoming payment
                  to repay the total amount you owe.
                </ResponsiveTypography>
              </Notification>
            </div>
          )}
        </KardWrapper>
      </div>
    </PageWrapper>
  );
};

interface ReturnedPaymentBannerProps {
  hasReturnedPayment: boolean;
  paymentPlan: Partial<PaymentPlanContextData> | undefined;
}

const ReturnedPaymentBanner = ({
  hasReturnedPayment,
  paymentPlan,
}: ReturnedPaymentBannerProps) => {
  const [showBanner, setShowBanner] = useState(true);
  if (!hasReturnedPayment || !paymentPlan) {
    return null;
  }

  return (
    <Notification
      show={showBanner}
      variant="banner"
      level="warning"
      onDismiss={() => setShowBanner(false)}
    >
      <B>Since your payment was returned,</B> you'll need to add or edit a
      payment in order to meet your total amount due{' '}
      <B>by {dayjs(paymentPlan.expiration).format('MMM DD')}.</B>
    </Notification>
  );
};

export default ManagePaymentPlan;
