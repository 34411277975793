import { useFlags } from 'launchdarkly-react-client-sdk';
import Breadcrumbs, { BreadcrumbsProps } from './Breadcrumbs';

/**
 * Temporary wrapper for Breadcrumbs in Multicard experience. Once
 * fully transitioned, replace all instances with Breadcrumbs component.
 */
export const Temp_MulticardBreadcrumbs = (props: BreadcrumbsProps) => {
  const { showMulticardExperience } = useFlags();

  if (!showMulticardExperience) return null;

  return <Breadcrumbs {...props} />;
};
