import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react';
import { MakePaymentStepData } from './types';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { gql } from '@apollo/client';
import {
  MakePaymentContextQuery,
  MakePaymentContextQueryVariables,
} from '@core/graphql/globalTypes';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

export const MAKE_PAYMENT_CONTEXT_QUERY = gql`
  query MakePaymentContext($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isDebitEligible
        isChargedOff
      }
    }
  }
`;

export interface PaymentValuesInterface extends MakePaymentStepData {
  cardName: string;
  last4: string;
  isFormFilled: boolean;
}
interface MakePaymentContextInterface {
  paymentValues: Partial<PaymentValuesInterface>;
  updatePaymentValues: (paymentValues: Partial<PaymentValuesInterface>) => void;
  isDebitEligible: boolean;
  isChargedOff: boolean;
}

export const MakePaymentContext = createContext<MakePaymentContextInterface>({
  paymentValues: {
    amount: 0,
    amountHelperText: '',
    amountId: null,
    cardName: '',
    customPaymentDate: null,
    last4: '',
    fundingAccount: null,
    paymentDate: null,
    paymentDateId: null,
    reference: null,
    isFormFilled: false,
  },
  updatePaymentValues: () => {},
  isDebitEligible: false,
  isChargedOff: false,
});

const MakePaymentProvider = ({ children }: PropsWithChildren) => {
  const { data, loading } = useAccountIdQuery<
    MakePaymentContextQuery,
    MakePaymentContextQueryVariables
  >(MAKE_PAYMENT_CONTEXT_QUERY);

  const [paymentValues, setPaymentValues] = useState<
    Partial<PaymentValuesInterface>
  >({
    amount: 0,
    amountHelperText: '',
    amountId: null,
    cardName: '',
    customPaymentDate: null,
    last4: '',
    fundingAccount: null,
    paymentDate: null,
    paymentDateId: null,
    reference: null,
    isFormFilled: false,
  });

  const updatePaymentValues = (newValues: Partial<PaymentValuesInterface>) => {
    setPaymentValues({ ...paymentValues, ...newValues });
  };

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  const { isDebitEligible, isChargedOff } = data?.account?.statuses || {};

  return (
    <MakePaymentContext.Provider
      value={{
        paymentValues,
        updatePaymentValues,
        isDebitEligible: !!isDebitEligible,
        isChargedOff: !!isChargedOff,
      }}
    >
      {children}
    </MakePaymentContext.Provider>
  );
};

export const useMakePayment = () => useContext(MakePaymentContext);

export default MakePaymentProvider;
