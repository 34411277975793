import { B, Link, Notification } from '@missionlane/compass-ui';
import { FC } from 'react';

interface Props {
  setHasPendingInfo: (hasPendingInfo: boolean) => void;
  hasPendingInfo: boolean;
}

export const PendingInfoNotification: FC<Props> = ({
  setHasPendingInfo,
  hasPendingInfo,
}) => {
  const onDismiss = () => setHasPendingInfo(false);

  return (
    <Notification
      variant="banner"
      level="info"
      onDismiss={onDismiss}
      show={hasPendingInfo}
    >
      <B>Some of your info is still pending. Check back tomorrow. </B>
      <Link onPress={onDismiss}>Dismiss.</Link>
    </Notification>
  );
};
