import { useState } from 'react';

const createIntersectionObserver = (
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit,
) => {
  const defaultOptions = {
    rootMargin: '0px',
    threshold: 1,
  };
  const observerOptions = { ...defaultOptions, ...options };
  return new IntersectionObserver(callback, observerOptions);
};

/**
 * Used to determine when an element comes into view of the viewport.
 * @param onVisible - A callback function that will call everytime the target element transistions
 * 100% back into the viewport. If target is unset `onVisible` will return `undefined`.
 * @returns An object containing `startObservingElement` function and `isVisible` status.
 */
export function useVisibilityObserver(onVisible: Function) {
  /**
   * Whether or not the target element is currently visible inside the viewport.
   * `undefined` - no element has been attached to the observer yet
   * `true` - target element is visible
   * `false` - target element is not visible
   */
  const [isVisible, setIsVisible] = useState<boolean | undefined>();
  const observer = createIntersectionObserver((entry) => {
    // intersectionRatio is the amount of pixels that are showing during the intersection transition
    // 1.0 means 100% of the target is showing, thats when we fire the callback
    // We only want to call the nn
    if (entry[0].intersectionRatio === 1) {
      setIsVisible(true);
      onVisible();
    } else {
      setIsVisible(false);
    }
  });

  /**
   * Used to set the target element when it becomes available
   * @param observedElement - A reference to the element you would like to observe.
   */
  const startObservingElement = (observedElement: Element) => {
    observer.disconnect();
    setIsVisible(false);
    observer.observe(observedElement);
  };

  return { startObservingElement, isVisible };
}
