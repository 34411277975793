import { H5, P3, P4 } from '@missionlane/compass-ui';

import { SupportLink } from '../../General/SupportLink';
import { cardSupportPhone } from '@core/utils/contact';
import { TrackService } from '@core/services';

interface StatementsPreferencesConfirmEmailProps {
  email?: string | null;
}

export const StatementsPreferencesConfirmEmail = ({
  email,
}: StatementsPreferencesConfirmEmailProps) => {
  return (
    <>
      <H5 color="ink">When statements are ready, we’ll email:</H5>
      <div className="mt3">
        <P3>{email}</P3>
      </div>
      <div className="bg-haze-lightest mt3 mb4 pt3 ph3 pb3 br2">
        <P4 color="greyLight">
          Wrong email?{' '}
          <a
            onClick={() =>
              TrackService.click('Statement Preference (step 3): Call us')
            }
            href={`tel:+1-${cardSupportPhone}`}
            className={'blue hover-indigo underline'}
          >
            Call
          </a>{' '}
          or{' '}
          <span
            className="underline"
            onClick={() =>
              TrackService.click('Statement Preference (step 3): Message us')
            }
          >
            <SupportLink>send us a message</SupportLink>
          </span>
        </P4>
      </div>
    </>
  );
};

export default StatementsPreferencesConfirmEmail;
