import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GET_CUSTOMER_ID_QUERY } from '@core/queries/getCustomerIdQuery';
import { useEffect, useState } from 'react';
import { formatUSD } from '@core/utils/formatters';
import { List, ListItem } from '@missionlane/compass-ui';
import AddPlaidAccount from '../AddPlaidAccount/AddPlaidAccount';
import {
  FundingAccountFieldsFragment,
  GetCustomerIdQuery,
} from '@core/graphql/globalTypes';
import { useNavigate } from 'react-router-dom';
import { BankAccountFlow } from '../AddBankAccount/AddAccount';
import { TrackService } from '@core/services';

interface Props {
  className?: string;
  onChange: (fundingAccount: FundingAccountFieldsFragment) => void;
  selectedId: number | null | undefined;
  currentFlow?: BankAccountFlow;
  fundingAccounts?: FundingAccountFieldsFragment[];
}

const AccountPicker = ({
  className,
  onChange,
  fundingAccounts = [],
  selectedId,
  currentFlow,
}: Props) => {
  const navigate = useNavigate();
  const { enablePlaidFlow, enablePlaidSpecificUsers } = useFlags();
  const [displayPlaidFlow, setDisplayPlaidFlow] = useState(false);

  const getBankAccountNavLink = () => {
    switch (currentFlow) {
      case BankAccountFlow.MODIFY_AUTOPAY:
      case BankAccountFlow.SETUP_AUTOPAY:
      case BankAccountFlow.COLLECTIONS_OFFER:
      case BankAccountFlow.EDIT_COLLECTIONS_OFFER:
        return '../../add-bank-account';
      default:
        return '../../../add-bank-account';
    }
  };

  //Temporal solution in order to allow specific users to test the plaid flow on production
  //It will be removed and updated on the future.
  const [getCustomerId] = useLazyQuery<GetCustomerIdQuery>(
    GET_CUSTOMER_ID_QUERY,
    {
      onCompleted: (customerIdData) => {
        const usersIds = [
          '2050562086',
          '10000000007',
          '1882497671',
          '10000600772',
          '1387635443',
          '10000385431',
        ];

        const { customer } = customerIdData || {};
        if (customer?.id) {
          setDisplayPlaidFlow(usersIds.indexOf(customer.id) > -1);
        }
      },
    },
  );

  function handleChange(id: string) {
    switch (id) {
      case 'plaidAddAccount': {
        TrackService.trackClick('Change Funding - Account Added via Plaid', {
          feature: 'Funding Account Picker',
        });
        break; // this functionality is built into <AddPlaidAccount/> Component
      }
      case 'addAccount': {
        TrackService.trackClick('Change Funding - Account Added via Manual', {
          feature: 'Funding Account Picker',
        });
        navigateToAddAccount();
        break;
      }
      default: {
        const fa = fundingAccounts.filter(
          (fundingAccount) => fundingAccount.id.toString() === id,
        )[0];

        if (!!fa.id) {
          TrackService.trackClick(
            'Change Funding - Existing Account Selected',
            {
              feature: 'Funding Account Picker',
            },
          );
        }

        onChange(fa);
      }
    }
  }

  function navigateToAddAccount() {
    navigate(getBankAccountNavLink(), {
      state: { currentFlow: currentFlow },
    });
  }

  /* funding accounts with default account at beginning of array */
  const sortedFundingAccounts = [...fundingAccounts].sort((fundingAccount) =>
    fundingAccount.isDefault ? -1 : 1,
  );

  useEffect(() => {
    if (!enablePlaidFlow && enablePlaidSpecificUsers) {
      getCustomerId();
    } else {
      setDisplayPlaidFlow(enablePlaidFlow);
    }
  }, [enablePlaidFlow, enablePlaidSpecificUsers]);

  return (
    <div id="account-picker" className={className}>
      <List
        variant="selectable"
        onChange={(id) => handleChange(id)}
        selected={selectedId ? selectedId.toString() : null}
      >
        {sortedFundingAccounts.map((fundingAccount) => {
          const balance = formatUSD(
            fundingAccount.balances?.[0]?.balance?.toFixed(2),
          );
          return (
            <ListItem
              key={fundingAccount.id}
              id={fundingAccount.id.toString()}
              label={`${fundingAccount.bankName}..${fundingAccount.numberLast4}`}
              description={`Checking ${balance ? balance : ''}`}
              iconProps={{ icon: 'bank', iconColor: 'green' }}
            />
          );
        })}
        {displayPlaidFlow && (
          <ListItem id="plaidAddAccount">
            <AddPlaidAccount />
          </ListItem>
        )}
        <ListItem
          id="addAccount"
          label="Add a Bank Account"
          description="Connect account manually with a routing and account number"
          iconProps={{
            icon: 'plus',
            iconColor: 'blue',
            rightIcon: 'forward',
            rightIconColor: 'blue',
          }}
        />
      </List>
    </div>
  );
};

export default AccountPicker;
