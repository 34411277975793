import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import './PaymentStreak.css';

function PaymentStreak() {
  const { currentPaymentStreak } = useClipOffer();

  return (
    <div className="flex items-center payment-streak-container">
      <div className="flex ba bw2 br-100 flex-shrink-0 items-center justify-center payment-streak-counter-outer-container">
        <div className="flex ba bw2 br-100 flex-shrink-0 items-center justify-center payment-streak-counter-inner-container">
          <h2>{currentPaymentStreak}</h2>
        </div>
      </div>
      <div className="flex flex-column">
        <h4>Payments Streak</h4>
        <p className="mt1 mb0">
          You've gone this many statements in a row without missing a payment.
        </p>
      </div>
    </div>
  );
}

export default PaymentStreak;
