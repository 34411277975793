import { gql } from '@apollo/client';

export const GET_CHANGE_PHONE_ELIGIBILITY_QUERY = gql`
  query getChangePhoneEligibility {
    creditCards {
      id
    }
    customer {
      contactInfo {
        fraudRulesEligibility {
          eligible
        }
      }
    }
  }
`;
