import { createContext, useContext, ReactNode, useState } from 'react';

import Modal, { ModalProps } from '@core/components/General/Modal/Modal';

export type InteractableModalProps = Partial<Omit<ModalProps, 'isOpen'>>;

interface ModalContextValue {
  openModal: (props: InteractableModalProps) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextValue | null>(null);

/**
 * @description
 * ModalProvider is a context provider that allows you to open and close modals
 * from anywhere in the app. It also handles rendering the modal itself.
 */
export function ModalProvider({ children }: { children: ReactNode }) {
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  /**
   * @description Closes the modal by setting modalProps to null
   */
  const closeModal = () => setModalProps(null);

  /**
   * @description
   * openModal is a function that takes in a ModalProps object and sets the
   * modalProps state to that object. It also sets the onClose function to
   * close the modal and call the onClose function passed in the props.
   */
  const openModal = (props: InteractableModalProps) => {
    return setModalProps({
      ...props,
      isOpen: true,
      onClose: () => {
        props.onClose?.();
        closeModal();
      },
    });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalProps && <Modal {...modalProps} />}
    </ModalContext.Provider>
  );
}

/**
 * @description
 * useModalProvider is a hook that allows you to open and close modals from
 * anywhere in the app. ModalProvider must be a parent of the component using
 * this hook. If it is not, an error will be thrown.
 */
export function useModalProvider() {
  const context = useContext(ModalContext);

  if (context === null) {
    throw new Error('useModalProvider must be used within a ModalProvider');
  }

  return context;
}
