import ChangePhoneForm, {
  ChangePhoneFormValues,
} from './ChangePhoneForm/ChangePhoneForm';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useRiskAuth } from '../../Auth/RiskAuthProvider';
import { H4 } from '@missionlane/compass-ui';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import {
  CvvVerification,
  UpdatePhone,
  UpdatePhoneMutation,
  UpdatePhoneMutationVariables,
} from '@core/graphql/globalTypes';

interface ChangePhoneProps {
  onSuccess: (
    updatedPhoneNumber: UpdatePhone.UpdatePhone['phoneNumber'],
  ) => void;
  tokenizedCvv: CvvVerification.CvvVerification['tokenizedCvv'];
}

const REQUIRED_YUP_MESSAGE = 'This field cannot be blank.';
export const UPDATE_FORMATTED_PHONE_NUMBER_MAX_LENGTH = 14;

export const schema = yup.object({
  updatedPhone: yup
    .string()
    .required(REQUIRED_YUP_MESSAGE)
    .min(UPDATE_FORMATTED_PHONE_NUMBER_MAX_LENGTH, ``),
});

const updatePhoneMutation = gql`
  mutation UpdatePhone(
    $tokenizedCvv: String!
    $updatedPhoneNumber: String!
    $tmxSessionId: String!
  ) {
    updatePhone(
      tokenizedCvv: $tokenizedCvv
      updatedPhoneNumber: $updatedPhoneNumber
      tmxSessionID: $tmxSessionId
    ) {
      phoneNumber
    }
  }
`;

const ChangePhone = ({ onSuccess, tokenizedCvv }: ChangePhoneProps) => {
  useEffect(() => {
    TrackService.page('Change Phone Enter Phone');
  }, []);

  const methods = useForm<ChangePhoneFormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { handleSubmit, formState, getValues, setValue, resetField } = methods;
  const navigate = useNavigate();
  const [updatePhone, { error }] = useMutation<
    UpdatePhoneMutation,
    UpdatePhoneMutationVariables
  >(updatePhoneMutation, {
    onCompleted: ({ updatePhone: onCompletedUpdatePhone }) => {
      onSuccess(onCompletedUpdatePhone.phoneNumber);
    },
    onError: () => {
      resetField('updatedPhone', {
        keepDirty: false,
        defaultValue: getValues().updatedPhone,
      });
    },
  });
  const { threatMetrixSessionId } = useRiskAuth();

  if (!threatMetrixSessionId) {
    throw new Error('ChangePhone failed to get threatMetrixSessionId');
  }
  if (!tokenizedCvv) {
    throw new Error('ChangePhone failed to get tokenizedCvv');
  }

  const onSubmit = handleSubmit(async () => {
    TrackService.click('Change Phone Enter Phone: Confirm');
    const { updatedPhone } = getValues();
    const cleanUpdatedPhone = updatedPhone.replace(/[^0-9.]/g, '');
    try {
      await updatePhone({
        variables: {
          updatedPhoneNumber: cleanUpdatedPhone,
          tokenizedCvv,
          tmxSessionId: threatMetrixSessionId,
        },
      });
    } catch (e) {
      // shows error message in the ui
    }
  });

  const onCancel = () => {
    TrackService.click('Change Phone Enter Phone: Cancel');
    navigate(-1);
  };

  return (
    <div className="flex flex-auto justify-center">
      <div className="flex flex-column mb5 pa3 pa5-l bg-white w-60-l w-100">
        <div className="mb3">
          <H4 testID="ChangePhoneHeader">Update number</H4>
        </div>
        <FormProvider {...methods}>
          <ChangePhoneForm
            onSubmit={onSubmit}
            formState={formState}
            error={error}
            onCancel={onCancel}
            setValue={setValue}
          />
        </FormProvider>
      </div>
    </div>
  );
};

export default ChangePhone;
