import { MLFlags } from 'flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutopayTileV2, {
  TRY_AUTOPAY_TILE_EVENT,
} from './OpportunityTiles/AutopayTile';
import Carousel, { CarouselTile } from '@core/components/Carousel/Carousel';
import Kard from '@core/components/General/Kard/Kard';
import { useOpporunityTileManager } from './useOpportunityTileManager';
import { useEffect, useState } from 'react';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import AddPaymentMethodTile, {
  ADD_PAYMENT_METHOD_TILE_EVENT,
} from './OpportunityTiles/AddPaymentMethodTile';
import { PROACTIVE_MULTICARD_OFFER_EVENT } from '@clip/Multicard/ProactiveMulticardOffer';
import ReactiveMulticardTile, {
  REACTIVE_MULTICARD_TILE_EVENT,
} from '@clip/Multicard/ReactiveMulticardTile';
import ProactiveMulticardTile from '@clip/Multicard/ProactiveMulticardTile';
import CreditProtectionTile, {
  CREDIT_PROTECTION_TILE_EVENT,
} from '@core/components/CreditProtection/CreditProtectionTile';
import OnboardingOpportunityTile, {
  ONBOARDING_TILE_EVENT,
} from '@core/components/Onboarding/OnboardingOpportunityTile';

export const OPPORTUNITY_TILE_CAROUSEL_TEST_ID = 'opportunity-tile-carousel';

const OpportunityTileCarousel = () => {
  const { tiles, dismissTile, loading } = useOpporunityTileManager();
  const { showOpportunityTileCarousel } = useFlags<MLFlags>();
  const { isMobile } = useUserDevice();
  const [carouselTiles, setCarouselTiles] = useState<CarouselTile[]>([]);

  useEffect(() => {
    setCarouselTiles(
      tiles.map((tile) => {
        switch (tile) {
          case ONBOARDING_TILE_EVENT:
            return {
              id: ONBOARDING_TILE_EVENT,
              component: OnboardingOpportunityTile,
            };
          case PROACTIVE_MULTICARD_OFFER_EVENT:
            return {
              id: PROACTIVE_MULTICARD_OFFER_EVENT,
              component: ProactiveMulticardTile,
            };
          case REACTIVE_MULTICARD_TILE_EVENT:
            return {
              id: REACTIVE_MULTICARD_TILE_EVENT,
              component: ReactiveMulticardTile,
            };
          case TRY_AUTOPAY_TILE_EVENT:
            return { id: TRY_AUTOPAY_TILE_EVENT, component: AutopayTileV2 };
          case ADD_PAYMENT_METHOD_TILE_EVENT:
            return {
              id: ADD_PAYMENT_METHOD_TILE_EVENT,
              component: AddPaymentMethodTile,
            };
          case CREDIT_PROTECTION_TILE_EVENT:
            return {
              id: CREDIT_PROTECTION_TILE_EVENT,
              component: CreditProtectionTile,
            };
        }
      }),
    );
  }, [tiles]);

  if (!tiles?.length || !showOpportunityTileCarousel || loading) return null;

  const carouselContent = (
    <div data-testid={OPPORTUNITY_TILE_CAROUSEL_TEST_ID}>
      <Carousel data={carouselTiles} onDismiss={dismissTile} />
    </div>
  );

  if (isMobile) {
    return <div className="mb4">{carouselContent}</div>;
  } else {
    return <Kard>{carouselContent}</Kard>;
  }
};

export default OpportunityTileCarousel;
