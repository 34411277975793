import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import ChangeAddressContainer from './ChangeAddressContainer/ChangeAddressContainer';
import PageWrapper from '@core/components/Page/PageWrapper';
import { Breadcrumb } from '@core/components/General/Breadcrumbs/Breadcrumb';
import { Temp_MulticardBreadcrumbs } from '@core/components/General/Breadcrumbs/temp_MulticardBreadcrumbs';
import { HomeBreadcrumb } from '@core/components/General/Breadcrumbs/common';

const breadcrumbs: Breadcrumb[] = [
  HomeBreadcrumb,
  {
    text: 'My profile',
    link: '../my-profile',
  },
  {
    text: 'Edit address',
  },
];

export const ChangeAddressFeature = () => {
  const navigate = useNavigate();
  const { showChangeAddress } = useFlags();

  if (!showChangeAddress) {
    navigate('/', { replace: true });
    return null;
  }

  return (
    <PageWrapper greyBackground pageTitle={{ primaryText: 'Edit address' }}>
      <Temp_MulticardBreadcrumbs items={breadcrumbs} />
      <ChangeAddressContainer />
    </PageWrapper>
  );
};

export default ChangeAddressFeature;
