import BackLink from './BackLink';
import OfferTransactions from '../OfferTransactions';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import KardWrapper from '@core/components/General/Wrappers/KardWrapper';

const ReviewOfferTransactions = () => {
  const { isMobile } = useUserDevice();
  const { activeOffer, loading } = useSettlementOffers();

  if (loading) return <LoadingSpinnerPage />;
  if (
    !activeOffer ||
    !activeOffer.data.paymentPlan?.transactions ||
    !activeOffer.data.paymentPlan.transactions.length
  )
    return <GenericFallbackFullPage />;

  return (
    <PageWrapper greyBackground={!isMobile}>
      <BackLink />
      <div className="flex flex-column items-center mb6 mt4 mt5-ns justify-between">
        <KardWrapper
          className="w-100 payment-plan-kard-small"
          withKard={!isMobile}
        >
          <OfferTransactions
            transactions={activeOffer?.data.paymentPlan.transactions}
          />
        </KardWrapper>
      </div>
    </PageWrapper>
  );
};

export default ReviewOfferTransactions;
