import EditLink from '../../../EditLink/EditLink';
import { useQuery } from '@apollo/client';
import { GET_CHANGE_PHONE_ELIGIBILITY_QUERY } from '@core/queries/getChangePhoneEligibilityQuery';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { TrackService } from '@core/services';
import { GetChangePhoneEligibilityQuery } from '@core/graphql/globalTypes';

const ChangePhoneEditButton = () => {
  const { data, error, loading } = useQuery<GetChangePhoneEligibilityQuery>(
    GET_CHANGE_PHONE_ELIGIBILITY_QUERY,
  );
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  if (loading || error || !data) return null;
  const { creditCards, customer } = data;
  if (
    creditCards.length > 1 ||
    !customer?.contactInfo.fraudRulesEligibility.eligible
  ) {
    return null;
  }
  return (
    <EditLink
      testID="ChangePhoneLink"
      to={'/change-phone'}
      onClick={() => {
        TrackService.click('My Profile: Edit Phone', customerAndAccountIds);
      }}
    />
  );
};

export default ChangePhoneEditButton;
