import './styleOverrides.css';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ApolloQueryResult, DocumentNode } from '@apollo/client';
import { loadVGSCollect } from '@vgs/collect-js';
import { SecureFormField } from './SecureFormField';
import { baseStyles } from './baseStyles';
import type {
  VGSCollectForm,
  FormValues,
  VGSCollect,
  FormConfig,
  VGSCollectField,
  ValidationErrorOverrides,
  VGSEventData,
  FieldValue,
} from './types';
import { isCustomizeableFormatField, isFormValid } from './helpers';
import { MLError } from '@core/services';

interface SubmitArgs<TVariables> {
  mutation: DocumentNode;
  variables: (formValues: FormValues) => {
    [Property in keyof TVariables]: any;
  };
  onSuccess: (status: string, response: ApolloQueryResult<any>) => void;
  onError: (errors: Record<string, FieldValue>) => void;
}

export interface SecureForm {
  isValid: boolean;
  isReady: boolean;
  focusOn: (fieldId: string) => void;
  reset?: () => void;
  submit: <TVariables>(args: SubmitArgs<TVariables>) => void;
}

export interface SecureFormProps {
  formConfig: FormConfig;
  cName?: string;
  vaultId?: string;
  onBlur?: (event: VGSEventData) => void;
  onFocus?: (event: VGSEventData) => void;
  onUpdate?: (event: VGSEventData) => void;
  /** This is only needed temporarily while we wait for VGS to allow us to pass custom error messages via the config */
  validationErrorMessageOverrides?: ValidationErrorOverrides;
  trackName?: string;
}

export const SecureForm = forwardRef<SecureForm, SecureFormProps>(
  (
    {
      formConfig,
      cName = process.env.REACT_APP_VGS_ACCOUNT_MANAGEMENT_CNAME,
      vaultId = process.env.REACT_APP_VGS_VAULT_ID,
      onBlur,
      onFocus,
      onUpdate,
      validationErrorMessageOverrides,
      trackName,
    },
    ref,
  ) => {
    const [collectForm, setCollectForm] = useState<VGSCollectForm>();
    const [formValues, setFormValues] = useState<FormValues>();
    const [isReady, setIsReady] = useState(false);
    const fields = useRef<VGSCollectField[]>([]);

    const fieldIds = useMemo(() => Object.keys(formConfig), []);

    useImperativeHandle(ref, () => ({
      focusOn: (fieldId: string) => {
        fields.current.find((field) => field.name === fieldId)?.focus();
      },
      isValid: isFormValid(formValues),
      isReady,
      submit,
      reset: () => {
        collectForm?.reset();
      },
    }));

    /**
     * Initialize a new VGS Collect instance using environment
     * settings and set the result in state.
     */
    const initializeForm = useCallback(async () => {
      if (
        !vaultId ||
        !process.env.REACT_APP_VGS_ENVIRONMENT ||
        !process.env.REACT_APP_VGS_COLLECT_VERSION
      ) {
        throw new Error('VGS Env Vars not set!');
      }

      try {
        const collect = (await loadVGSCollect({
          vaultId,
          environment: process.env.REACT_APP_VGS_ENVIRONMENT,
          version: process.env.REACT_APP_VGS_COLLECT_VERSION,
        })) as VGSCollect;
        const form = collect.init(setFormValues);
        cName && form.useCname(cName);
        setCollectForm(form);
      } catch (error) {
        if (error instanceof Error)
          MLError.report({
            name: error.name,
            error,
          });

        setCollectForm(undefined);
      }
    }, []);

    const initializeFields = useCallback(async () => {
      if (collectForm) {
        fieldIds.forEach((fieldId) => {
          const fieldOpts = formConfig[fieldId];
          if (fieldOpts) {
            const { replacePattern, mask, css, ...fieldConfig } = fieldOpts;
            const field = collectForm.field(`#${fieldId}`, {
              name: fieldId,
              css: {
                ...baseStyles,
                ...css,
              },
              ...fieldConfig,
            });
            if (replacePattern) {
              if (isCustomizeableFormatField(field, fieldConfig.type)) {
                field.replacePattern(...replacePattern);
              } else {
                throw new Error(
                  `${fieldId} field is using a replacePattern, which is not available for its field type: ${fieldConfig.type}`,
                );
              }
            }
            if (mask) {
              if (isCustomizeableFormatField(field, fieldConfig.type)) {
                field.mask(...mask);
              } else {
                throw new Error(
                  `${fieldId} field is using a mask, which is not available for its field type: ${fieldConfig.type}`,
                );
              }
            }
            field.on('focus', (event) => onFocus?.(event));
            field.on('blur', (event) => onBlur?.(event));
            field.on('update', (event) => onUpdate?.(event));
            fields.current = [...fields.current, field];
          }
        });
        const fieldPromises = fields.current.map((field) => field.promise);
        await Promise.all(fieldPromises);
        setIsReady(true);
      }
    }, [collectForm]);

    useEffect(() => {
      initializeForm();
    }, []);

    useEffect(() => {
      initializeFields();
    }, [initializeFields]);

    const submit: SecureForm['submit'] = ({
      mutation,
      variables,
      onSuccess,
      onError,
    }) => {
      collectForm?.submit(
        '/credit/ui/graphql',
        {
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'x-card-channel': 'web',
            role: 'customer',
          },
          data: (values: FormValues) => {
            return {
              query: mutation.loc?.source.body,
              variables: variables(values),
            };
          },
        },
        onSuccess,
        onError,
      );
    };

    const fieldComponents = useMemo(
      () =>
        fieldIds.map((fieldId) => {
          const fieldValue = formValues?.[fieldId];
          const fieldRef = fields.current.find((item) => item.name === fieldId);
          return (
            <SecureFormField
              key={fieldId}
              fieldId={fieldId}
              fieldValue={fieldValue}
              fieldRef={fieldRef}
              initialFieldConfig={formConfig[fieldId]}
              validationErrorMessageOverrides={validationErrorMessageOverrides}
              trackName={trackName}
            />
          );
        }),
      [formValues],
    );

    return <form>{fieldComponents}</form>;
  },
);

SecureForm.displayName = 'SecureForm';
