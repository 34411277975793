import { ActivityType } from '@core/graphql/globalTypes';
import { Colors, P3 } from '@missionlane/compass-ui';
import cx from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';

interface TransactionFilter {
  value: ActivityType | null;
  label: string;
}

export const transactionFilters: TransactionFilter[] = [
  { value: null, label: 'All' },
  {
    label: 'Payments',
    value: ActivityType.Payment,
  },
  {
    label: 'Purchases',
    value: ActivityType.Purchase,
  },
];

interface TxFiltersProps {
  selectedFilter: ActivityType | null;
  onSelectFilter: (value: ActivityType | null) => void;
}

export const TransactionFilters = ({
  selectedFilter,
  onSelectFilter,
}: TxFiltersProps) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        aria-label="Filter transactions by type"
        className="transaction-filter-menu__trigger"
      >
        <div style={{ position: 'relative' }}>
          <FontAwesomeIcon
            icon={faFilter}
            color={Colors.blue}
            style={styles.filterBtn}
          />
          {selectedFilter && <FilterIndicator />}
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="transaction-filter-menu__content"
          align="end"
        >
          {transactionFilters.map((filter) => (
            <DropdownMenu.Item
              key={filter.label}
              className={cx(
                'transaction-filter-menu__content--item',
                selectedFilter === filter.value ? 'selected' : '',
              )}
              onSelect={() => onSelectFilter(filter.value)}
            >
              <P3 color="ink">{filter.label}</P3>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const FilterIndicator = () => <span style={styles.filterIndicator} />;

export const txFilterBtnStyles: React.CSSProperties = {
  borderRadius: '50%',
  backgroundColor: Colors.blueWashed,
};

const styles: { [key: string]: React.CSSProperties } = {
  filterBtn: {
    ...txFilterBtnStyles,
    padding: '12px',
  },
  filterIndicator: {
    position: 'absolute',
    top: '3px',
    right: '-1px',
    backgroundColor: Colors.blue,
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  },
};
