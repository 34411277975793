import { AnalyticsBrowser, EventProperties } from '@segment/analytics-next';
import * as FullStory from '@fullstory/browser';
import { trackPage as trackPageCio } from '../CustomerIO/CustomerIO';
import * as MLError from '../Error/Error';

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY || '',
});

export interface TrackServiceNotifiableError {
  name: string;
  message: string;
  code?: string;
  service?: string;
  error?: string;
}

export interface TrackServiceTestProperties {
  testName: string | undefined;
  variant: {
    name: string;
    value: number;
  };
}

export interface TrackServiceEventProperties extends EventProperties {
  feature?: string;
  user?: {
    /* can be undefined due to data failures, but should always be defined when possible */
    ecsCustomerId: string | undefined;
    ecsAccountId: string | undefined;
  };
  error?: TrackServiceNotifiableError;
  testProperties?: TrackServiceTestProperties;
}

interface TrackingProperties
  extends Omit<TrackServiceEventProperties, 'error'>,
    Partial<TrackServiceNotifiableError> {
  client: {
    source: 'Card';
    platform: 'Web';
  };
  error?: string;
  testProperties?: TrackServiceTestProperties;
}

/*
Note: Client-side integration which exposes the "Write Key" in the browser.
REACT_APP_SEGMENT_WRITE_KEY can be found in Github but .env files. Here are the reasons this is ok-
1. This key only allows a user to write to segment's servers and not read any data.
2. The data itself only contains events such as "clicking" and "views" which is not high risk
https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
 */

class TrackingServiceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TrackingServiceError';
  }
}

const reportTrackServiceError = (messagePrefix: string, error: unknown) => {
  const trackingServiceError = new TrackingServiceError(
    `${messagePrefix}: ${error instanceof Error ? error.message : error}`,
  );

  MLError.report({
    name: trackingServiceError.name,
    error: trackingServiceError,
  });
};

const identify = (userId: string, email?: string | null) => {
  try {
    analytics.identify(userId, { email });
  } catch (error: unknown) {
    reportTrackServiceError(
      'TrackService.identify could not call analytics.identify',
      error,
    );
  }
};

/** @deprecated use trackPage method instead */
const page = (name: string, properties?: EventProperties) => {
  try {
    analytics.page('Page Viewed', name, properties);
    FullStory.event('Page Viewed', { ...properties, name });
    trackPageCio();
  } catch (error: unknown) {
    reportTrackServiceError(
      'TrackService.page could not call analytics.page',
      error,
    );
  }
};

const modal = (name: string, properties?: EventProperties) => {
  try {
    analytics.track('Modal Viewed', { ...properties, name });
    FullStory.event('Modal Viewed', { ...properties, name });
  } catch (error: unknown) {
    reportTrackServiceError(
      'TrackService.modal could not call analytics.track',
      error,
    );
  }
};

/** @deprecated use trackClick method instead */
const click = (name: string, properties?: EventProperties) => {
  try {
    analytics.track('Click', { ...properties, name });
    FullStory.event('Click', { ...properties, name });
  } catch (error: unknown) {
    reportTrackServiceError(
      'TrackService.click could not call analytics.track',
      error,
    );
  }
};

/** @deprecated use trackEvent method instead */
const track = ({
  event,
  properties,
}: {
  event: string;
  properties?: EventProperties;
}) => {
  try {
    analytics.track(event, properties);
    FullStory.event(event, properties || {});
  } catch (error: unknown) {
    reportTrackServiceError(
      'TrackService.track could not call analytics.track',
      error,
    );
  }
};

const trackEvent = (
  eventName: string,
  { feature, user, error, testProperties }: TrackServiceEventProperties,
) => {
  const trackingProperties = {
    feature,
    client: {
      source: 'Card',
      platform: 'Web',
    },
    user,
    testProperties,
    ...error,
  } satisfies TrackingProperties;

  try {
    analytics.track(eventName, trackingProperties);
    FullStory.event(eventName, trackingProperties);
  } catch (error: unknown) {
    reportTrackServiceError('TrackService.trackEvent failed', error);
  }
};

const trackClick = (
  name: string,
  { feature, user, error, testProperties }: TrackServiceEventProperties,
) => {
  const trackingProperties = {
    name,
    feature,
    client: {
      source: 'Card',
      platform: 'Web',
    },
    user,
    testProperties,
    ...error,
  } satisfies TrackingProperties;

  try {
    analytics.track('Click', trackingProperties);
    FullStory.event('Click', trackingProperties);
  } catch (error: unknown) {
    reportTrackServiceError('TrackService.trackClick failed', error);
  }
};

const trackPage = (
  pageName: string,
  { feature, user, error }: TrackServiceEventProperties,
) => {
  const trackingProperties = {
    feature,
    client: {
      source: 'Card',
      platform: 'Web',
    },
    user,
    ...error,
  } satisfies TrackingProperties;

  try {
    // https://developer.fullstory.com/browser/v1/set-page-properties/
    FullStory.setVars('page', { pageName });
    analytics.page('Page Viewed', pageName, trackingProperties);
    FullStory.event('Page Viewed', { name: pageName, ...trackingProperties });
    trackPageCio();
  } catch (error: unknown) {
    reportTrackServiceError('TrackService.trackPage failed', error);
  }
};

const message = (name: string, properties?: Record<string, unknown>) => {
  try {
    analytics.track('Message Viewed', { ...properties, name });
    FullStory.event('Message Viewed', { ...properties, name });
  } catch (error: unknown) {
    reportTrackServiceError(
      'TrackService.message could not call analytics.track',
      error,
    );
  }
};

const trackError = (
  name: string,
  { feature, user, error }: TrackServiceEventProperties,
) => {
  const trackingProperties = {
    name,
    feature,
    client: {
      source: 'Card',
      platform: 'Web',
    },
    user,
    ...error,
  } satisfies TrackingProperties;

  try {
    analytics.track('Error Viewed', trackingProperties);
    FullStory.event('Error Viewed', trackingProperties);
  } catch (error: unknown) {
    reportTrackServiceError(
      'TrackService.error could not call analytics.track',
      error,
    );
  }
};

export default {
  identify,
  page,
  trackPage,
  modal,
  click,
  track,
  trackEvent,
  trackClick,
  message,
  trackError,
};
