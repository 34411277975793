import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldProps } from '@missionlane/compass-ui/lib/typescript/components/Input/TextField/TextField';
import { TextField } from '@missionlane/compass-ui';
import { isCurrencyValue, formatCurrency } from '../../utils';

export const MoneyInput = ({ name, ...textFieldProps }: TextFieldProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { onChange, ...fieldProps } = field;

        return (
          <TextField
            {...fieldProps}
            {...textFieldProps}
            icon="dollarSign"
            onChange={(e) => {
              // The types indicate that target is a number, but it's not...
              // @ts-ignore
              const v: string = e.target.value;

              if (isCurrencyValue(v)) {
                onChange(formatCurrency(v));
              }
            }}
          />
        );
      }}
    />
  );
};
