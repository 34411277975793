import { gql } from '@apollo/client';
import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import { FUNDING_ACCOUNT_FIELDS } from '@payments/graphql/fundingAccountQueries';
import PAYMENT_FIELDS from '@payments/graphql/PaymentFieldsFragment';

export const GET_AUTOPAY = gql`
  query GetAutopay($accountId: String!) {
    account(accountId: $accountId) {
      id
      autopay {
        ...AutopayFields
      }
    }
  }
  ${AUTOPAY_FIELDS}
`;

export const GET_ACTIVE_CARD_STATUS = gql`
  query GetActiveCardStatus($accountId: String!) {
    account(accountId: $accountId) {
      id
      cardDetails {
        last4
        issuanceState
      }
    }
  }
`;

/**
 * All data necessary to determine if and how a user
 * can add or update an Autopay
 */
export const ADD_UPDATE_AUTOPAY_QUERY = gql`
  query AddUpdateAutopay($accountId: String!) {
    accountInformation(accountId: $accountId) {
      destinationAccount
    }
    account(accountId: $accountId) {
      id
      autopay {
        ...AutopayFields
      }
      autopayStartDateRange
      paymentInfo {
        minimumDue
        nextStatementCloseDate
        printDueDate
      }
      upcomingPayments {
        ...PaymentFields
      }
      cardDetails {
        last4
      }
    }
    fundingAccounts {
      ...FundingAccountFields
    }
  }
  ${PAYMENT_FIELDS}
  ${AUTOPAY_FIELDS}
  ${FUNDING_ACCOUNT_FIELDS}
`;

export const GET_CUSTOMER_NAME_QUERY = gql`
  query GetCustomerName {
    customer {
      id
      contactInfo {
        firstName
        lastName
      }
    }
  }
`;
