import { Button } from '@missionlane/compass-ui';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';

interface PaymentsSuccessButtonsProps {
  isDebitCardPayment?: boolean;
}

export const PaymentsSuccessButtons = ({
  isDebitCardPayment,
}: PaymentsSuccessButtonsProps) => {
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const { get } = useLocalStorage();
  const autopayPromptDismissedCount = Number(
    get('dismissedAutopayPromptCount'),
  );

  const { loading, hasAutopay, canEnrollInAutopay } = usePaymentStatus();
  const onDone = () => {
    if (
      !canEnrollInAutopay || // or if they can't enroll in autopay
      hasAutopay || // or if they already have autopay set up
      autopayPromptDismissedCount >= 1 // or they've dismissed it once already
    ) {
      // skip the autopay prompt screen and navigate home
      navigate(summaryPath);
    } else {
      isDebitCardPayment ?
        navigate('../set-up-autopay')
      : navigate('../../set-up-autopay');
    }
  };

  return (
    <div className="flex mt5">
      <Button loading={loading} onPress={onDone} text="Done" />
    </div>
  );
};
