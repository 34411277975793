import { MLError } from '@core/services';

export class RiskAuthServiceError extends Error {
  constructor(message: string, e?: Error) {
    super(message);
    this.name = 'RiskAuthServiceError';
    if (e) {
      this.message += `:${e.message}`;
      this.stack = e.stack;
    }
  }
}

export const handleError = (message: string): void => {
  const error = new RiskAuthServiceError(message);
  MLError.report({
    name: error.name,
    error,
  });
};
