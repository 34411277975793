import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';

const getStreakBody = (streakCount: number, isInGracePeriod: boolean) => {
  if (isInGracePeriod) {
    return 'You can still make a payment to stay on track. This could help you to avoid incurring fees or interest.';
  }

  switch (streakCount) {
    case 0:
      return 'Make on time payments and use your card every month to extend your Payments Streak and demonstrate responsible credit usage.';
    case 1:
      return 'Making on time payments demonstrates responsible credit usage and increases your chances for future credit limit increase opportunities.';
    default:
      return "You're continuing to demonstrate responsible credit usage, which could help improve your credit.";
  }
};

function Body() {
  const { currentPaymentStreak, isInGracePeriod } = useClipOffer();

  const title = getStreakBody(currentPaymentStreak || 0, !!isInGracePeriod);

  return <p className="mb0">{title}</p>;
}

export default Body;
