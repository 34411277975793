import { FundingAccounts, PaymentType } from '@core/graphql/globalTypes';

export enum PaymentDateId {
  immediately = 'immediately',
  earliest = 'earliest',
  scheduledDate = 'scheduledDate',
}

export interface PaymentValue<T = number> {
  value: T;
  display: string;
}
/** UI-only payment types used in addition to the PaymentType enum generated from Apollo */
export enum UIPaymentType {
  /** Payment type for rendering past due option in UI. If CH pays off their past due balance the object will be PaymentType.OneTimeFixed */
  PastDueBalance = 'PAST_DUE_BALANCE',
}

export type MakePaymentPaymentType =
  | `${PaymentType}`
  | `${UIPaymentType}`
  | null;
export type MakePaymentPaymentDateId = `${PaymentDateId}` | null;

export interface MakePaymentStepData {
  amountHelperText: string;
  cardName?: string | null;
  last4?: string | null;
  amount: number;
  amountId: MakePaymentPaymentType;
  customPaymentDate: string | null;
  fundingAccount: FundingAccounts.FundingAccounts | null;
  paymentDateId: MakePaymentPaymentDateId;
  paymentDate: string | null;
  reference?: string | null;
}

export interface MakePaymentLocationState {
  data: MakePaymentStepData;
  nextStep: (data: MakePaymentStepData) => void;
  previousStep: (data: MakePaymentStepData) => void;
}

export interface MakePaymentLinkState {
  text: string;
  referrer: string;
  prevState: MakePaymentLocationState;
}
