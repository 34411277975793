import { Button, P3 } from '@missionlane/compass-ui';
import underConstruction from '../../assets/underConstruction.svg';
import { SupportLink } from '@core/components/General/SupportLink';

interface GenericFallbackModalContentProps {
  onClose: () => void;
}

const GenericFallbackModalContent = ({
  onClose,
}: GenericFallbackModalContentProps) => (
  <div>
    <img src={underConstruction} alt="error" className="db center mb4" />
    <P3>
      We’re having trouble showing you this information. Please try again in a
      bit or <SupportLink>contact us</SupportLink> if you need help now.
    </P3>
    <div className="flex-ns justify-end mt4">
      <Button text="Close" onPress={onClose} />
    </div>
  </div>
);

export default GenericFallbackModalContent;
