import { QueryResult } from '@apollo/client';
import { CSSProperties } from 'react';

/**
 * VGS Collect field types. Determine available validations
 * and formatting when applied to form configuration.
 * NOTE: These are defined by VGS and their values should
 * not change unless changed in VGS documentation.
 */
export enum FieldType {
  CardExpirationDate = 'card-expiration-date',
  CardNumber = 'card-number',
  CardSecurityCode = 'card-security-code',
  Number = 'number',
  Password = 'password',
  PostalCode = 'zip-code',
  Text = 'text',
  TextArea = 'textarea',
}

export enum InputMode {
  Decimal = 'decimal',
  Email = 'email',
  None = 'none',
  Numeric = 'numeric',
  Search = 'search',
  Tel = 'tel',
  Text = 'text', // default
  Url = 'url',
}

export type FormValues = Record<string, FieldValue>;

/**
 * VGS Collect validation types, used in conjunction with
 * FieldType.
 * NOTE: These are defined by VGS and their values should
 * not change unless changed in VGS documentation.
 */
export enum Validation {
  CardExpirationDate = 'validCardExpirationDate',
  CardNumber = 'validCardNumber',
  CardSecurityCode = 'validCardSecurityCode',
  Compare = 'compareValue',
  PostalCode = 'postal_code/us',
  Required = 'required',
  Regex = 'regex',
  Unknown = 'unknown',
}

type RegexValidator = string;

interface MatchValidator {
  type: Validation.Compare;
  params: {
    field: string;
    function: 'match';
  };
}

export interface ValidationError {
  code: Validation;
  message: string;
}

export type ValidationErrorOverrides = Partial<Record<Validation, string>>;

export interface FieldConfig {
  ariaLabel?: string;
  autoFocus?: boolean;
  css?: CSSProperties;
  hideValue?: boolean;
  icons?: {
    cardPlaceholder?: string;
    cvvFront?: string;
    cvvBack?: string;
  };
  inputMode?: string;
  label?: string;
  mask?: [mask: string, maskChar: string, formatChar?: Record<string, string>];
  maxLength?: number;
  name: string;
  placeholder?: string;
  replacePattern?: [regExpString: string, newSubStr?: string];
  serializers?: unknown[];
  showCardIcon?: boolean;
  type: FieldType;
  validations?: (Validation | RegexValidator | MatchValidator)[];
  yearLength?: string;
}

export type VGSFocusEventData = {
  type: string;
  timeStamp: number;
  isTrusted: boolean;
};

export type VGSKeyboardEventData = {
  type: string;
  timeStamp: number;
  isTrusted: boolean;
  key: string | null;
  keyCode: number | null;
  which: number | null;
  metaKey: boolean;
  ctrlKey: boolean;
  keyIndex: number;
  valueHidden: boolean;
};
export type VGSEventData = VGSFocusEventData | VGSKeyboardEventData;

type VGSFocusEvents = 'focus' | 'blur';
type VGSKeyboardEvents = 'keyup' | 'keydown' | 'update';
export type VGSEvents = VGSFocusEvents | VGSKeyboardEvents;

export interface VGSCollectField extends FieldValue {
  focus: () => void;
  on: (eventName: VGSEvents, callback: (event: VGSEventData) => void) => void;
  promise: Promise<void>;
  update: (config: Partial<FieldConfig>) => void;
}

export interface VGSCollectCustomizeableFormatField extends VGSCollectField {
  mask: (
    mask: string,
    maskChar: string,
    formatChar?: Record<string, string>,
  ) => void;
  replacePattern: (regExpString: string, newSubStr?: string) => void;
}

interface VGSSubmitOptions {
  data:
    | Record<string, unknown>
    | ((formValues: FormValues) => Record<string, unknown>);
  method: 'post';
  serialization: 'json';
  headers: Record<string, string>;
  withCredentials: boolean;
}

export interface VGSCollectForm {
  useCname: (cname?: string) => void;
  field: (
    fieldId: string,
    config: FieldConfig,
  ) => VGSCollectField | VGSCollectCustomizeableFormatField;
  reset: () => void;
  submit: (
    path: string,
    options: Partial<VGSSubmitOptions>,
    successCallback: (status: string, data: QueryResult<unknown>) => void,
    errorCallback: (errors: Record<string, FieldValue>) => void,
  ) => void;
}

export interface VGSCollect {
  init: (callback: (state: FormValues) => void) => VGSCollectForm;
}

export interface FieldValue {
  errorMessages: string[];
  isDirty: boolean;
  isEmpty: boolean;
  isFocused: boolean;
  isTouched: boolean;
  isValid: boolean;
  name: string;
}

export type FormConfig = Record<string, Omit<FieldConfig, 'name'>>;
