import { Breadcrumb } from './Breadcrumb';

export interface BreadcrumbsProps {
  items: Breadcrumb[];
  testID?: string;
}

const Breadcrumbs = ({ items, testID }: BreadcrumbsProps) => (
  <div className="flex items-center flex-wrap pb3" data-testid={testID}>
    {items.map((item, index) => {
      const isLastCrumb = index === items.length - 1;

      return <Breadcrumb key={index} crumb={item} isLastCrumb={isLastCrumb} />;
    })}
  </div>
);

export default Breadcrumbs;
