import { useEffect, useState } from 'react';
import { B, Button, H5 } from '@missionlane/compass-ui';
import { parseValidationError } from './helpers';
import {
  FieldValue,
  FormConfig,
  ValidationErrorOverrides,
  VGSCollectField,
} from './types';
import { TrackService } from '@core/services';

interface SecureFormFieldProps {
  initialFieldConfig: FormConfig[string];
  fieldId: string;
  fieldRef?: VGSCollectField;
  fieldValue?: FieldValue;
  validationErrorMessageOverrides?: ValidationErrorOverrides;
  trackName?: string;
}

export const SecureFormField = ({
  initialFieldConfig,
  fieldId,
  fieldRef,
  fieldValue,
  validationErrorMessageOverrides,
  trackName,
}: SecureFormFieldProps) => {
  const [hideValue, setHideValue] = useState(initialFieldConfig.hideValue);

  const toggleHideValue = () => {
    if (trackName) {
      const eventName = trackName + (hideValue ? 'Show' : 'Hide');

      TrackService.track({
        event: 'Button Tapped',
        properties: {
          name: { eventName },
        },
      });
    }
    setHideValue((current) => !current);
  };

  useEffect(() => {
    fieldRef?.update({
      hideValue,
    });
  }, [hideValue]);

  return (
    <div className="mb5">
      <div className="mb2">
        <H5>{initialFieldConfig.label}</H5>
      </div>
      <div className="flex items-center relative w-100">
        <div
          id={fieldId}
          data-testid={`${fieldId}SecureInputRoot`}
          className="SecureFormField"
        />
        <div className="absolute right-1">
          <Button
            text={hideValue ? 'Show' : 'Hide'}
            variant="text"
            onPress={toggleHideValue}
          />
        </div>
      </div>
      {fieldValue?.isTouched &&
        !fieldValue?.isValid &&
        fieldValue?.errorMessages.length > 0 && (
          <B color="red">
            {parseValidationError(
              fieldValue?.errorMessages[0],
              validationErrorMessageOverrides,
            )}
          </B>
        )}
    </div>
  );
};
