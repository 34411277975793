import { gql } from '@apollo/client';

export const CREDIT_PROTECTION_QUERY = gql`
  query CreditProtection($accountId: String!) {
    account(accountId: $accountId) {
      creditProtectionInfo {
        status
        isEligible
        isEligibleForMarketing
      }
    }
  }
`;
