import { B, P4 } from '@missionlane/compass-ui';
import './CreditProtectionFAQ.css';

import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

export const CREDIT_PROTECTION_FAQ_3_TITLE =
  'How much does Mission Lane Credit Protection cost?';

const CreditProtectionFAQ_3 = () => {
  return (
    <div className="mt4">
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        Each month you will be charged a fee of 1.49% of your balance owed on
        your credit card at the end of a billing cycle. This amount is shown as
        “New Balance” on your monthly statement.
        <br />
        <br />
        See below for examples.
      </ResponsiveTypography>
      <div className="mb2">
        <table className="faq-table">
          <thead>
            <tr>
              <th className="faq-table-cell-left w-50">
                <P4>
                  <B>Monthly New Balance</B>
                </P4>
              </th>
              <th className="faq-table-cell-right w-50">
                <P4>
                  <B>Credit Protection Fee</B>
                </P4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="faq-table-cell-left">
                <P4>$100</P4>
              </td>
              <td className="faq-table-cell-right">
                <P4>$1.49</P4>
              </td>
            </tr>
            <tr>
              <td className="faq-table-cell-left">
                <P4>$250</P4>
              </td>
              <td className="faq-table-cell-right">
                <P4>$3.72</P4>
              </td>
            </tr>
            <tr>
              <td className="faq-table-cell-left">
                <P4>$400</P4>
              </td>
              <td className="faq-table-cell-right">
                <P4>$5.96</P4>
              </td>
            </tr>
            <tr>
              <td className="faq-table-cell-left">
                <P4>$500</P4>
              </td>
              <td className="faq-table-cell-right">
                <P4>$7.45</P4>
              </td>
            </tr>
            <tr>
              <td className="faq-table-cell-bottom-left">
                <P4>$750</P4>
              </td>
              <td className="faq-table-cell-bottom-right">
                <P4>$11.17</P4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CreditProtectionFAQ_3;
