import dayjs from 'dayjs';

export const sortDates = (txDate1?: string, txDate2?: string) => {
  const date1 = dayjs(txDate1);
  const date2 = dayjs(txDate2);

  if (date1.isBefore(date2)) {
    return -1;
  }
  if (date1.isAfter(date2)) {
    return 1;
  }
  return 0;
};
