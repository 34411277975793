import { H4 } from '@missionlane/compass-ui';
import AddressStackedItem from '../AccountDetailsPage/AccountDetailsCardholderInfo/AddressStackedItem/AddressStackedItem';
import { StackedItem } from '../AccountDetailsPage/StackedItem/StackedItem';
import HorizontalLine from './HorizontalLine';
import PhoneStackedItem from '../AccountDetailsPage/AccountDetailsCardholderInfo/PhoneStackedItem/PhoneStackedItem';
import { GetMyProfileInfo } from '@core/graphql/globalTypes';

export interface CardholderInformationProps {
  email: GetMyProfileInfo.ContactInfo['email'] | undefined;
  phoneNumber: GetMyProfileInfo.ContactInfo['phoneNumber'] | undefined;
  address: GetMyProfileInfo.ContactInfo['address'] | undefined;
}

const CardholderInformation = ({
  email,
  phoneNumber,
  address,
}: CardholderInformationProps) => (
  <div className="p3">
    <H4>Cardholder information</H4>
    <StackedItem title="Email">{email}</StackedItem>
    <PhoneStackedItem phoneNumber={phoneNumber} />
    <AddressStackedItem address={address} />
    <HorizontalLine />
  </div>
);

export default CardholderInformation;
