import { useState } from 'react';
import cx from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Colors } from '@missionlane/compass-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useQuery } from '@apollo/client';
import { CustomerIO, TrackService } from '@core/services';
import { useOktaAuth } from '@okta/okta-react';
import { faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';

import { MenuAccountQuery, MenuCustomerQuery } from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

import './Menu.css';
import { MenuItem } from './MenuItem';
import { MobileMenuTrigger, DesktopMenuTrigger } from './MenuTrigger';
import { PersonalSection } from './PersonalSection';
import { AccountSection } from './AccountSection';
import { SupportSection } from './SupportSection';
import { DROPDOWN_MENU_Z_INDEX } from '@core/utils/zIndexes';

export const createMenuClassName = (element: string, modifier?: string) => {
  const modifierClass = modifier ? `--${modifier}` : '';
  return `header-menu__${element}` + modifierClass;
};

export const MENU_ACCOUNT_QUERY = gql`
  query MenuAccount($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isChargedOff
      }
      cardDetails {
        last4
        name
      }
    }
  }
`;

export const MENU_CUSTOMER_QUERY = gql`
  query MenuCustomer {
    customer {
      id
      contactInfo {
        firstName
      }
    }
  }
`;

const Menu = () => {
  const { isMobile } = useUserDevice();
  const { oktaAuth } = useOktaAuth();
  const [isOpen, setIsOpen] = useState(false);
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  const { data: accountData } = useAccountIdQuery<MenuAccountQuery>(
    MENU_ACCOUNT_QUERY,
    {
      errorPolicy: 'all',
    },
  );

  const { data: customerData, loading } = useQuery<MenuCustomerQuery>(
    MENU_CUSTOMER_QUERY,
    {
      errorPolicy: 'all',
    },
  );

  if (loading) {
    return null;
  }

  const label = customerData?.customer?.contactInfo.firstName || '';
  const isChargedOff = accountData?.account?.statuses.isChargedOff;
  const cardDetails = accountData?.account?.cardDetails;
  const { accountId } = customerAndAccountIds;

  return (
    <DropdownMenu.Root
      onOpenChange={(isMenuOpen) => {
        setIsOpen(isMenuOpen);
        if (isMenuOpen) {
          TrackService.click(
            'Header: Profile drop down',
            customerAndAccountIds,
          );
        }
      }}
      open={isOpen}
    >
      <DropdownMenu.Trigger
        className={cx(
          createMenuClassName('trigger'),
          'mr1 pointer',
          !isMobile && 'mt3',
        )}
        asChild
      >
        {isMobile ?
          <MobileMenuTrigger />
        : <DesktopMenuTrigger label={label} />}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          data-testid="NavMenu"
          style={{ position: 'relative', zIndex: DROPDOWN_MENU_Z_INDEX }}
          className={cx(
            createMenuClassName('content'),
            'bg-white shadow-3 pv2',
            isMobile && 'mt5',
          )}
          align="end"
        >
          {!isMobile && (
            <DropdownMenu.Arrow
              className="mb2"
              fill={Colors.white}
              width="15"
              height="7.5"
            />
          )}

          <PersonalSection />
          {accountId && <AccountSection cardDetails={cardDetails} />}
          <SupportSection isChargedOff={isChargedOff} />

          {/* Sign out */}
          <DropdownMenu.Separator className="header-menu__separator" />
          <MenuItem
            trackingName="Profile drop down: Sign out"
            // for some reason, passing oktaAuth.signout directly does not work, so passing it as an anonymous function instead
            onSelect={() => {
              oktaAuth.signOut();
              CustomerIO.reset();
            }}
          >
            {/* TODO: Add this icon to compass-ui */}
            <FontAwesomeIcon
              className="mr3"
              icon={faRightFromBracket}
              color={Colors.blue}
            />
            Sign out
          </MenuItem>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default Menu;
