import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import dayjs from 'dayjs';
import { Button } from '@missionlane/compass-ui';
import { TrackService } from '@core/services';
import { useNavigate } from 'react-router-dom';

const ClipOfferFooter = ({ children }: { children: React.ReactNode }) => (
  <p className="mb3 mt0">{children}</p>
);

const InProgressFooter = () => {
  const { evaluationDate, isInGracePeriod, uiState } = useClipOffer();
  const navigate = useNavigate();

  if (isInGracePeriod) {
    return (
      <>
        <ClipOfferFooter>
          It may be a few days past your due date, but you can still make a
          payment to stay on track and increase your chances of a credit limit
          increase in{' '}
          <span className="green">{dayjs(evaluationDate).format('MMMM')}</span>.
        </ClipOfferFooter>
        <Button
          onPress={() => {
            TrackService.click(
              'Clip Tracker Relaunch: Is in grace period: Click make a payment',
              {
                uiState,
              },
            );
            navigate('../payments/pay');
          }}
          text="Make a Payment"
        />
      </>
    );
  }

  return (
    <ClipOfferFooter>
      <b>Use your card</b> and <b>make on time payments</b> for a chance at a
      credit limit increase in {dayjs(evaluationDate).format('MMMM')}!{' '}
    </ClipOfferFooter>
  );
};

const EvaluationPendingFooter = () => {
  const { evaluationDate } = useClipOffer();

  return (
    <ClipOfferFooter>
      Thanks to your responsible credit usage, you'll be considered for a credit
      limit increase soon. We'll email you or notify you in the app around{' '}
      {dayjs(evaluationDate).format('MMMM DD')}.
    </ClipOfferFooter>
  );
};

const ApprovedFooter = () => (
  <ClipOfferFooter>
    Because you made on time payments and kept your account in good standing,
    you just got a higher credit limit! Awesome work.
  </ClipOfferFooter>
);

const OverlimitFooter = () => {
  const { currentCreditLimit, evaluationDate } = useClipOffer();

  return (
    <ClipOfferFooter>
      <b>Bring your account under your ${currentCreditLimit} credit limit</b>{' '}
      and make another on time payment, and you will be reconsidered for a
      credit limit increase on <b> {dayjs(evaluationDate).format('MMMM DD')}</b>
      .
    </ClipOfferFooter>
  );
};

const IneligibleFooter = () => (
  <ClipOfferFooter>
    You've paid on time, but your account was either not in good standing or did
    not meet our credit requirements during our evaluation. Please scroll down
    to the good standing criteria for more information.
  </ClipOfferFooter>
);

const FOOTER_CONTENT: Record<
  ClipOfferStatusCodes,
  (() => JSX.Element | null) | null
> = {
  [ClipOfferStatusCodes.IN_PROGRESS]: InProgressFooter,
  [ClipOfferStatusCodes.APPROVED]: ApprovedFooter,
  [ClipOfferStatusCodes.EVALUATION_PENDING]: EvaluationPendingFooter,
  [ClipOfferStatusCodes.INELIGIBLE]: IneligibleFooter,
  [ClipOfferStatusCodes.MISSED_PAYMENT]: null,
  [ClipOfferStatusCodes.OVERLIMIT]: OverlimitFooter,
  [ClipOfferStatusCodes.PENDING_PAYMENT]: InProgressFooter,
} as const;

const Footer = () => {
  const { clipStatus } = useClipOffer();

  const Content =
    clipStatus && FOOTER_CONTENT[clipStatus as ClipOfferStatusCodes];

  if (!Content) {
    return null;
  }

  return <Content />;
};

export default Footer;
