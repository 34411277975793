import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import ClipTracker from '@clip/ClipProgress/components/ClipTracker/ClipTracker';
import PaymentPending from '@clip/ClipProgress/components/PaymentPending/PaymentPending';
import NeverActiveWarning from '@clip/ClipProgress/components/NeverActiveWarning/NeverActiveWarning';
import dayjs from 'dayjs';

const InProgressBody = () => {
  return (
    <>
      <ClipTracker />
      <PaymentPending>
        Your recent payment is pending until tomorrow. The tracker will update
        once it clears.
      </PaymentPending>
      <NeverActiveWarning />
    </>
  );
};

const EvaluationPendingBody = () => {
  const { currentStatementNumber } = useClipOffer();

  // overlimit + evaluation pending
  if (
    typeof currentStatementNumber === 'number' &&
    currentStatementNumber >= 8
  ) {
    return null;
  }

  return <ClipTracker />;
};

const MissedPaymentBody = () => {
  const { evaluationDate } = useClipOffer();
  return (
    <p className="mt0">
      You've missed a payment, so we can't offer you an increase in{' '}
      {dayjs(evaluationDate).format('MMMM')}. Pay on time to be considered for
      more opportunities to raise your credit limit.
    </p>
  );
};

const BODY_CONTENT: Record<
  ClipOfferStatusCodes,
  (() => JSX.Element | null) | null
> = {
  [ClipOfferStatusCodes.IN_PROGRESS]: InProgressBody,
  [ClipOfferStatusCodes.APPROVED]: null,
  [ClipOfferStatusCodes.EVALUATION_PENDING]: EvaluationPendingBody,
  [ClipOfferStatusCodes.INELIGIBLE]: null,
  [ClipOfferStatusCodes.MISSED_PAYMENT]: MissedPaymentBody,
  [ClipOfferStatusCodes.OVERLIMIT]: null,
  [ClipOfferStatusCodes.PENDING_PAYMENT]: InProgressBody,
} as const;

const Body = () => {
  const { clipStatus } = useClipOffer();

  const Content =
    clipStatus && BODY_CONTENT[clipStatus as ClipOfferStatusCodes];

  if (!Content) {
    return null;
  }

  return <Content />;
};

export default Body;
