import { H4, Notification } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TrackService } from '../../services';
import EditLink from '../AccountDetailsPage/EditLink/EditLink';
import { StackedItem } from '../AccountDetailsPage/StackedItem/StackedItem';
import currency from 'currency.js';
import isNil from '../../utils/isNil';
import { GetMyProfileInfo } from '@core/graphql/globalTypes';

export interface FinancialInformationProps {
  financialInfo: GetMyProfileInfo.Customer['financialInfo'] | null;
}

const FinancialInformation = ({ financialInfo }: FinancialInformationProps) => {
  const { showUpdateIncome } = useFlags();

  if (!financialInfo) return <FinancialInformationError />;

  const { eligibleForUpdate, annualIncome, monthlyLiving } = financialInfo;

  const handleOnClick = () => {
    TrackService.click('My profile: Edit financial information');
  };

  return (
    <div className="p3">
      <div className="flex justify-between items-center">
        <H4>Financial information</H4>
        {eligibleForUpdate && showUpdateIncome && (
          <EditLink to="/update-income" onClick={handleOnClick} />
        )}
      </div>
      <StackedItem title="Annual income">
        {!isNil(annualIncome) && `${currency(annualIncome).format()} per year`}
      </StackedItem>
      <StackedItem title="Monthly rent or mortgage payment">
        {!isNil(monthlyLiving) &&
          `${currency(monthlyLiving).format()} per month`}
      </StackedItem>
    </div>
  );
};

const FinancialInformationError = () => (
  <div>
    <div className="mb5">
      <H4>Financial information</H4>
    </div>
    <Notification variant="inline" level="warning">
      Sorry, we're having trouble loading your financial information right now.
      Please try again later.
    </Notification>
  </div>
);

export default FinancialInformation;
