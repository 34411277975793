import React, { CSSProperties } from 'react';
import { P3 } from '@missionlane/compass-ui';

interface Address {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface AddressDisplayProps {
  address: Address;
  name?: string;
  testID?: string;
}

export const AddressDisplay: React.FC<AddressDisplayProps> = ({
  address,
  name,
}) => {
  const addressLine =
    address.street2 ? `${address.street1} ${address.street2}` : address.street1;
  return (
    <div data-testid="AddressDisplay" style={containerStyle}>
      <div style={verticalLineStyle} />
      <div style={verticalLineTextStyle}>
        {name && <P3>{name}</P3>}
        <div>
          <P3>{addressLine}</P3>
        </div>
        <div>
          <P3>
            {address.city}, {address.state}
          </P3>
        </div>
        <div>
          <P3>{address.postalCode}</P3>
        </div>
      </div>
    </div>
  );
};

const containerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
};

const verticalLineStyle: CSSProperties = {
  width: 2,
  backgroundColor: '#535353',
};

const verticalLineTextStyle: CSSProperties = {
  marginLeft: 16,
};

export default AddressDisplay;
