import { Icon, Spacer } from '@missionlane/compass-ui';
import TrackingLink, { TrackingLinkProps } from './TrackingLink';

const NextLink = ({ children, ...trackingLinkProps }: TrackingLinkProps) => (
  <TrackingLink {...trackingLinkProps}>
    <div className="inline-flex items-center">
      {children}
      <Spacer size="xs" />
      <Icon name="forward" color="blue" />
    </div>
  </TrackingLink>
);

export default NextLink;
