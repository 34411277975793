import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { VerifyEnterCodeForm } from './VerifyEnterCodeForm';
import { OtpFactor } from '@core/services/RiskAuthService';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import { TrackService } from '@core/services';

export type FormValues = {
  otp: string;
};

yup.setLocale({
  mixed: {
    required: 'This field cannot be blank.',
  },
});

export const schema = yup.object({
  otp: yup.string().required(),
});

interface VerifyEnterCodeProps {
  onSuccess: () => void;
  selectedOtpFactor: OtpFactor | undefined;
}

export const VerifyEnterCode = ({
  onSuccess,
  selectedOtpFactor,
}: VerifyEnterCodeProps) => {
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
  });
  const { handleSubmit, formState, getValues } = methods;
  const { otpEnter, otpRequest } = useRiskAuth();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [resendCodeSuccessMessage, setResendCodeSuccessMessage] =
    useState<string>();

  useEffect(() => {
    TrackService.page('Verify Enter Code');
  }, []);
  const clearMessages = () => {
    setErrorMessage(undefined);
    setResendCodeSuccessMessage(undefined);
  };

  const handleResponseError = (trackName: string, newErrorMessage: string) => {
    TrackService.track({ event: trackName });
    setErrorMessage(newErrorMessage);
  };

  const verifyEnterCodeHandleSubmit = handleSubmit(async () => {
    clearMessages();
    TrackService.track({ event: 'Verify Enter Code Submitted' });
    const { otp } = getValues();
    if (!selectedOtpFactor) {
      throw new Error('selectedOtpFactor is undefined');
    }
    const response = await otpEnter(
      selectedOtpFactor.id,
      otp,
      'UPDATE_ADDRESS',
    );
    if (response.error) {
      handleResponseError('Verify Enter Code Failed', response.error);
    } else {
      TrackService.track({ event: 'Verify Enter Code Successful' });
      onSuccess();
    }
  });

  const onResendCode = async () => {
    clearMessages();
    TrackService.track({ event: 'Verify Resend Code Submitted' });
    if (!selectedOtpFactor) {
      throw new Error('selectedOtpFactor is undefined');
    }
    const response = await otpRequest(selectedOtpFactor, 'UPDATE_ADDRESS');
    if (response.error) {
      handleResponseError('Verify Resend Code Failed', response.error);
      return;
    }
    TrackService.track({ event: 'Verify Resend Code Success' });
    setResendCodeSuccessMessage('A new code was sent to your device.');
  };

  return (
    <FormProvider {...methods}>
      <VerifyEnterCodeForm
        onSubmit={verifyEnterCodeHandleSubmit}
        formState={formState}
        resendCode={onResendCode}
        errorMessage={errorMessage}
        resendCodeSuccessMessage={resendCodeSuccessMessage}
        selectedOtpFactor={selectedOtpFactor}
      />
    </FormProvider>
  );
};
