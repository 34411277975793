import { FieldId } from './types';
import type { FieldValue } from '@core/components/SecureForm';

type Props = {
  fieldId: FieldId;
  fieldValue?: FieldValue;
};

/** Convert a fieldId to an HTML ID */
const fieldIdToHtmlId = (fieldId: FieldId): string => fieldId.replace('#', '');

/**
 * Convert a generic VGS Collect field error message into
 * a Mission Lane specific version
 */
const getMLErrorMsg = (vgsErrMsg?: string) => {
  switch (vgsErrMsg) {
    case 'is required':
      return 'Required';
    case 'is not a valid card number':
      return 'Not a valid card number';
    case 'is not a valid security code':
      return 'Not a valid security code';
    case 'is not a valid expiration date':
      return 'Not a valid expiration date';
    case 'is not a valid US zip/postal code':
      return 'Not a valid zip code';
    case undefined:
      return '';
    default:
      return vgsErrMsg;
  }
};

export const BankCardField: React.FC<Props> = ({ fieldId, fieldValue }) => (
  <div id={fieldIdToHtmlId(fieldId)} className="BankCardField">
    {fieldValue?.isTouched &&
      !fieldValue?.isValid &&
      fieldValue?.errorMessages.length > 0 && (
        <span className="Error">
          {getMLErrorMsg(fieldValue?.errorMessages[0])}
        </span>
      )}
  </div>
);
