import { UpcomingPayments } from '@payments/components/UpcomingPayments';
import {
  MakePayment,
  UpcomingPayments as UpcomingPaymentsNamespace,
} from '@core/graphql/globalTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cx from 'classnames';
import './PaymentFormLayout.css';
import { ApolloError } from '@apollo/client';

interface PaymentFormLayoutProps {
  statuses: MakePayment.Statuses | undefined;
  upcomingPayments: UpcomingPaymentsNamespace.UpcomingPayments[] | undefined;
  autopay: MakePayment.Autopay | undefined;
  children: JSX.Element;
  error?: ApolloError;
  loading?: boolean;
}

export const PaymentFormLayout = ({
  statuses,
  upcomingPayments,
  autopay,
  children,
}: PaymentFormLayoutProps) => {
  const { showPastDueExperience } = useFlags();
  return (
    <div
      className={cx('make-payment-page-body', {
        'bg-haze-lightest': showPastDueExperience,
      })}
    >
      <div className="make-payment-form">{children}</div>
      <div className="dn flex-l upcoming-payments-container">
        <UpcomingPayments
          autopay={autopay}
          statuses={statuses}
          upcomingPayments={upcomingPayments}
        />
      </div>
    </div>
  );
};
