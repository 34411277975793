import { B, P3 } from '@missionlane/compass-ui';
import { ReactNode } from 'react';

interface StackedItemProps {
  title: string;
  children: ReactNode;
}

export const StackedItem = ({ title, children }: StackedItemProps) => (
  // prefer not to use flex for this item, but currently compass-ui exports P tags
  // as inline elements. Once that's fixed, we can remove these classnames
  <div className="flex flex-column">
    <P3 style={{ marginBottom: 0 }} color="ink">
      <B>{title}</B>
    </P3>
    <P3>{children}</P3>
  </div>
);
