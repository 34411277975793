import { useEffect } from 'react';

import { RewardType, SpendBonusStatus } from '@core/graphql/globalTypes';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import Kard from '@core/components/General/Kard/Kard';

import { DismissOfferButton } from '../DismissOfferButton';
import track, { EventNames } from '../track';
import { TEST_ID } from './consts';
import '../../assets/SpendBonus.css';

export const SpendBonusSuccess = () => {
  const spendBonusResult = useSpendBonus();

  useEffect(() => {
    track({
      eventName: EventNames.OfferViewed,
      status: SpendBonusStatus.Completed,
      rewardType: RewardType.Clip,
    });
  }, []);

  if (spendBonusResult.isEnabled === false) {
    return null;
  }

  const { creditLimit } = spendBonusResult;

  return (
    <Kard
      header={{
        textPrimary: 'You boosted your credit limit to',
        textSecondary: `${creditLimit}!`,
        className: 'mb2 mr5',
        level: 'H4',
      }}
      testID={TEST_ID.SPEND_BONUS__SUCCESS}
      className="mb3 w-100 relative"
    >
      <DismissOfferButton />
      <p
        className="spend-bonus__message"
        data-testid={TEST_ID.SPEND_BONUS__SUCCESS__MESSAGE__CLIP}
      >
        By using your Mission Lane Visa® responsibly and making on-time
        payments, you earned a higher credit limit. We’ll evaluate you for more
        offers and increases on a regular basis.
      </p>
    </Kard>
  );
};
