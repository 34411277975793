import AddressForm, {
  UpdateAddressFormValues,
} from './AddressForm/AddressForm';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { STATEMENTS_PREFERENCES_CONFIRM_QUERY } from '../Statements/StatementsPreferences/StatementsPreferencesConfirmPage';
import { ADDRESS_QUERY } from '../General/CustomerAddress';
import { GET_MY_PROFILE_INFO_QUERY } from '../MyProfile/MyProfilePage';
import { getCustomerInfo } from '@core/queries/getCustomerInfoQuery';
import { ACCOUNT_DETAILS_PAGE_QUERY } from '../AccountDetailsPage/AccountDetailsPage';

export const schema = yup.object({
  street1: yup
    .string()
    .required()
    .max(40, 'The max length for this field is 40 characters.'),
  city: yup.string().required(),
  state: yup.string().required(),
  postalCode: yup.string().required().max(5, 'Zip code must be 5 digits.'),
});

const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress(
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $postalCode: String!
  ) {
    updateAddress(
      street1: $street1
      street2: $street2
      city: $city
      state: $state
      postalCode: $postalCode
    ) {
      street1
      street2
      city
      state
      postalCode
    }
  }
`;
interface ChangeAddressPageProps {
  onSuccess: () => void;
}

const ChangeAddressPage = ({ onSuccess }: ChangeAddressPageProps) => {
  const methods = useForm<UpdateAddressFormValues>({
    resolver: yupResolver(schema),
    mode: 'all', // onChange and onBlur
  });
  const { handleSubmit, formState, getValues, setValue } = methods;
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.page('Change Address');
  }, []);

  const [updateAddress, { error }] = useMutation(UPDATE_ADDRESS_MUTATION, {
    // Call onSuccess when mutation successful
    onCompleted: () => {
      TrackService.track({ event: 'Update Address Successful' });
      onSuccess();
    },
    onError: (e) => {
      TrackService.message('Change Mailing Address Error Message: Rejection', {
        error: e,
      });
    },
    refetchQueries: [
      ACCOUNT_DETAILS_PAGE_QUERY,
      ADDRESS_QUERY,
      getCustomerInfo,
      GET_MY_PROFILE_INFO_QUERY,
      STATEMENTS_PREFERENCES_CONFIRM_QUERY,
    ],
  });

  const onSubmit = handleSubmit(async () => {
    try {
      TrackService.track({ event: 'Update Address Submitted' });
      const { street1, street2, city, state, postalCode } = getValues();
      await updateAddress({
        variables: { street1, street2, city, state, postalCode },
      });
    } catch (e) {
      // error handled in updateAddress mutation onError
    }
  });

  const handleCancel = () => {
    TrackService.click('Change Address Cancel');
    navigate('/', { replace: true });
  };

  return (
    <>
      <h3>Change Mailing Address</h3>
      <div className="mt4">
        <FormProvider {...methods}>
          <AddressForm
            onCancel={handleCancel}
            onSubmit={onSubmit}
            error={error}
            formState={formState}
            setValue={setValue}
            getValues={getValues}
          />
        </FormProvider>
      </div>
    </>
  );
};

export default ChangeAddressPage;
