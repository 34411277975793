import dayjs from 'dayjs';
import { getOrdinalFromDayOfMonth } from '@core/utils/getOrdinalDate';
import { PaymentType, AutopayFields } from '@core/graphql/globalTypes';
import AutopayListItem from './AutopayListItem';
import { getAmountDisplay } from './utils/helpers';

interface Props {
  amount: number;
  paymentType: PaymentType;
  dayOfMonth: number;
  fundingAccount: Pick<
    AutopayFields.FundingAccount,
    'bankName' | 'numberLast4'
  >;
  paymentDateLabel?: string;
  firstPaymentDate?: string | null;
  nextPaymentDate?: string | null;
  active?: boolean | null;
}

const AutoPayPaymentDisplay = ({
  amount,
  paymentType,
  dayOfMonth,
  paymentDateLabel,
  fundingAccount,
  nextPaymentDate,
  active,
}: Props) => {
  return (
    <div className="pl3 bl bw1 b--light-ink">
      {active && (
        <AutopayListItem label="Status">
          <div className="fw7 green">Active</div>
        </AutopayListItem>
      )}
      <AutopayListItem label="Amount">
        {getAmountDisplay(paymentType, amount)}
      </AutopayListItem>
      <AutopayListItem label="Recurring Date">
        {`${getOrdinalFromDayOfMonth(dayOfMonth)} of every month`}
      </AutopayListItem>
      <AutopayListItem label="Pay      From">
        {`${fundingAccount?.bankName}...${fundingAccount?.numberLast4}`}
      </AutopayListItem>
      {nextPaymentDate && paymentDateLabel && (
        <AutopayListItem label={paymentDateLabel}>
          {`${getAmountDisplay(paymentType, amount)} on ${dayjs(
            nextPaymentDate,
          ).format('ddd, MMM D, YYYY')}`}
        </AutopayListItem>
      )}
    </div>
  );
};

export default AutoPayPaymentDisplay;
