import { CSSProperties } from 'react';

export const baseStyles: CSSProperties & {
  '&:focus': CSSProperties;
  '&.invalid.touched': CSSProperties;
} = {
  fontFamily: '"Suisse Intl", sans-serif',
  fontStyle: 'normal',
  fontWeight: 450,
  fontSize: '16px',
  lineHeight: '24px',
  padding: '1rem',
  width: '100%',
  display: 'block',
  borderWidth: '0.125rem',
  borderRadius: '0.25rem',
  borderStyle: 'solid',
  outline: '0',
  backgroundColor: '#fff',
  borderColor: 'rgba(13, 11, 35, .2)',
  overflow: 'visible',
  margin: '0',
  color: '#122c26',
  transition: 'border-color .15s linear',
  boxSizing: 'border-box',
  '&:focus': {
    borderColor: '#005cc4',
  },
  '&.invalid.touched': {
    borderColor: '#b50b13',
  },
};
