import { gql } from '@apollo/client';

export const GET_ACCOUNT_SUMMARY_QUERY = gql`
  query GetAccountCreditLimitQuery($accountId: String!) {
    account(accountId: $accountId) {
      id
      pricingDetails {
        creditLimit
      }
    }
  }
`;
