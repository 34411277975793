import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';

type GetStreakTitle = {
  currentPaymentStreak: number;
  userFirstName: string;
  isInGracePeriod: boolean;
};

const getStreakTitle = ({
  currentPaymentStreak,
  userFirstName,
  isInGracePeriod,
}: GetStreakTitle) => {
  if (isInGracePeriod) {
    return `${userFirstName}, make a payment now to stay on track!`;
  }

  switch (currentPaymentStreak) {
    case 0:
      return "Let's get a streak going!";
    case 1:
      return `${userFirstName}, you've started a streak!`;
    default:
      return `${userFirstName}, way to keep making on time payments!`;
  }
};

function Title() {
  const { currentPaymentStreak, userFirstName, isInGracePeriod } =
    useClipOffer();

  const title = getStreakTitle({
    currentPaymentStreak: currentPaymentStreak || 0,
    userFirstName: userFirstName || '',
    isInGracePeriod: !!isInGracePeriod,
  });

  return <h5>{title}</h5>;
}

export default Title;
