import { Icon, Link, Spacer } from '@missionlane/compass-ui';

interface Props {
  onClick: () => void;
  text?: string;
}

export const EditPaymentLink = ({
  onClick,
  text = 'Edit your payment',
}: Props) => (
  <div className="flex items-center">
    <Icon name="edit" color="blue" />
    <Spacer size="xs" />
    <Link onPress={onClick}>{text}</Link>
  </div>
);
