import { gql } from '@apollo/client';

export const CREATE_ASSET_REPORT_MUTATION = gql`
  mutation CreateCashFlowUnderwritingAssetReport(
    $accountId: String!
    $publicToken: String!
    $accessTokenId: String
  ) {
    createCashFlowUnderwritingAssetReport(
      accountId: $accountId
      publicToken: $publicToken
      accessTokenId: $accessTokenId
    )
  }
`;
