import OfferFundingDetails from '../PaymentPlanFlow/OfferFundingDetails';
import { Breadcrumb } from '@core/components/General/Breadcrumbs/Breadcrumb';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';
import { useNavigate } from 'react-router-dom';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { useEditOfferFundingAccountContext } from '../contexts/EditOfferFundingAccountContext';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

const EditOfferFundingAccount = () => {
  const { isMobile } = useUserDevice();
  const navigate = useNavigate();
  const { activeOffer } = useSettlementOffers();
  const { selectedFundingAccountId, setSelectedFundingAccountId } =
    useEditOfferFundingAccountContext();
  const breadcrumbs: Breadcrumb[] = [{ text: 'Edit Funding Account' }];

  if (!selectedFundingAccountId) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper
      greyBackground={!isMobile}
      pageTitle={{ primaryText: 'Edit Funding Account' }}
    >
      <AccountBreadcrumbs items={breadcrumbs} />
      <OfferFundingDetails
        isEditing
        nextStep={() => navigate('../../summary')}
        offer={activeOffer}
        initialSelectedFundingAccountId={selectedFundingAccountId}
        onChange={setSelectedFundingAccountId}
      />
    </PageWrapper>
  );
};

export default EditOfferFundingAccount;
