import * as React from 'react';
import * as Sentry from '@sentry/react';
import {
  Routes as ReactRoutes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { CustomerRoutesQuery } from '@core/graphql/globalTypes';
import { serializeError } from 'serialize-error';
import { CaptureExceptionArgs, NotifiableError } from './types';
import SentryFullStory from '@sentry/fullstory';
import * as FullStory from '@fullstory/browser';

// const gqlEndpoint = process.env.REACT_APP_APOLLO_URL ?? ""

export const init = () => {
  Sentry.init({
    dsn: 'https://be6e392a4856e78a6afa2c2908b08ad5@o543490.ingest.us.sentry.io/4507017296871424',
    environment:
      window.location.host === 'dashboard.missionlane-staging.com' ?
        'staging'
      : process.env.NODE_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: true, // TODO: turning this on during the demo to prevent PII from being displayed
        blockAllMedia: true, // TODO: turning this on during the demo to prevent PII from being displayed
      }),
      new SentryFullStory('mission-lane', {
        client: FullStory,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", gqlEndpoint], // TODO: CORS Errors from GQL?
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Configure tags
  Sentry.setTags({
    'commit-id': process.env.REACT_APP_GIT_COMMIT ?? 'development',
  });
};

export const setUser = (
  customer: NonNullable<CustomerRoutesQuery['customer']>,
) => {
  const email = customer.contactInfo.email || 'Customer email is not defined';
  Sentry.setUser({ id: customer.id, email });
};

/**
 *
 * @param error - logged into MLError message
 * @param name - identification by MLError
 * @param prefix - string in front in name that allows MLError to identify more specific errors
 * @returns NotifiableError - object wih name and a message containing a json stringified serialized error
 */
export const captureException = ({
  error,
  name,
  prefix,
}: CaptureExceptionArgs): NotifiableError => {
  const formattedMessage = JSON.stringify(serializeError(error), null, 2);
  const prefixedName = `${prefix ? `${prefix} - ` : ''}${name}`;
  const notifiableError = {
    name: prefixedName,
    message: formattedMessage,
  };

  Sentry.captureException(notifiableError);
  return notifiableError;
};

export const addBreadcrumb = Sentry.addBreadcrumb;

export const ErrorBoundary = Sentry.ErrorBoundary;

export const Routes = Sentry.withSentryReactRouterV6Routing(ReactRoutes);

export type { Metadata } from './types';

export const setTags = Sentry.setTags;
