import gql from 'graphql-tag';

export const GET_CASH_FLOW_UNDERWRITING_PROGRESS = gql`
  query GetCashFlowUnderwritingProgress($accountId: String!) {
    cashFlowUnderwritingProgress(accountId: $accountId) {
      eligible
      evaluationStatus
      offerExpirationDate
      lineIncreaseAmount
      nextEvaluationDate
    }
  }
`;
