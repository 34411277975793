import { CONTACT_US_LINK } from '@core/utils/constants';

const SectionFallbackUI = () => (
  <div className="pa2">
    We&rsquo;re having trouble showing you this info. Please try again in a bit
    or{' '}
    <a
      href={CONTACT_US_LINK}
      className="nowrap blueMidSecondary no-underline pointer"
    >
      contact us
    </a>{' '}
    if you need help now.
  </div>
);

export default SectionFallbackUI;
