import { MenuItem } from './MenuItem';
import { MenuLabel } from './MenuLabel';
import { cardSupportPhone, recoveriesPhone } from '@core/utils/contact';

interface Props {
  isChargedOff?: boolean;
}

export const SupportSection = ({ isChargedOff }: Props) => {
  const supportPhoneNumber = isChargedOff ? recoveriesPhone : cardSupportPhone;

  return (
    <>
      <MenuLabel label="Support" />
      <MenuItem
        trackingName="Profile drop down: Help Center FAQ"
        onSelect={() =>
          window.open(process.env.REACT_APP_ZENDESK_URL, '_blank')
        }
        icon="help"
      >
        Help center
      </MenuItem>
      <MenuItem
        trackingName="Profile drop down: View Messages"
        onSelect={() =>
          window.open(
            `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/requests`,
            '_blank',
          )
        }
        icon="message"
      >
        Secure message center
      </MenuItem>
      <MenuItem
        trackingName="Profile drop down: Call Support"
        onSelect={() => window.open(`tel:+1-${supportPhoneNumber}`)}
        icon="phone"
      >
        Call support: {supportPhoneNumber}
      </MenuItem>
    </>
  );
};
