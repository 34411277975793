import { navLinks } from './footerLinks';
import TrackingLink, { TrackingLinkProps } from '../TrackingLink';

interface FooterNavProps {
  trackingProperties: TrackingLinkProps['trackingProperties'];
}

export const FooterNav = ({ trackingProperties }: FooterNavProps) => {
  return (
    <nav className="flex flex-wrap mb4">
      {navLinks.map(({ id, href, label }) => (
        <div key={id} className="pr4">
          <TrackingLink
            to={href}
            target="_blank"
            trackingName={`Footer: ${label} link`}
            trackingProperties={trackingProperties}
          >
            <span className="white hover-light-blue f7 fw7">{label}</span>
          </TrackingLink>
        </div>
      ))}
    </nav>
  );
};
