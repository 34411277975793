import { ReactNode } from 'react';
import { CONTACT_US_LINK } from '../../utils/constants';

export const SupportLink = ({ children }: { children: ReactNode }) => (
  <a
    className="link blue hover-indigo"
    href={CONTACT_US_LINK}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
);
