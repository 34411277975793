import { BubbleIcon, Icon, P3 } from '@missionlane/compass-ui';
import './ManageFundingAccounts.css';

const AddAccountListItem = ({
  onClick,
  label,
  description,
}: {
  onClick: () => void;
  label: string;
  description: string;
}) => (
  <a
    className="flex justify-between ba b--grey-lightest br3 pa3 flex-grow-1 items-center pointer gap-16"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    <BubbleIcon name="plus" iconColor="blue" bubbleColor="blueWashed" />
    <div className="flex flex-grow-1 flex-column">
      <P3 color="ink" style={{ fontWeight: '600', marginVertical: 0 }}>
        {label}
      </P3>
      <P3 style={{ marginVertical: 0 }}>{description}</P3>
    </div>
    <Icon name="forward" color="blue" />
  </a>
);

export default AddAccountListItem;
