import { Link } from '@missionlane/compass-ui';
import {
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  CLOSE_BUTTON_WIDTH,
} from '../AccountSummaryPage/Dashboard/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import OnboardingSVG from '@core/assets/onboarding.svg';
import type { CarouselTileProps } from '../Carousel/Carousel';

const TITLE = 'Welcome to Mission Lane!';
const BODY = 'We’re glad you’re here. Let’s get started!';
const IMAGE_WIDTH = 127;
const IMAGE_RIGHT = 6;
const IMAGE_BOTTOM = 8;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;
export const ONBOARDING_TILE_EVENT = 'Opportunity Tile: Onboarding';

const OnboardingOpportunityTile = ({ onDismiss }: CarouselTileProps) => {
  return (
    <OpportunityTileContainer backgroundColor="greenWashed">
      <OpportunityTileDiagonalBackground
        background="green"
        imgAlt="credit protection shield"
        diagonalHeight={441}
        diagonalWidth={342}
        diagonalRight={-190}
        imgSource={OnboardingSVG}
        imgWidth={IMAGE_WIDTH}
        imageRight={IMAGE_RIGHT}
        imageBottom={IMAGE_BOTTOM}
      />
      <OpportunityTileCTA
        trackingName={ONBOARDING_TILE_EVENT}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title={TITLE}
        text={BODY}
        // leaving null for now since we don't have that route needed for this cta created at the moment
        onButtonClick={() => null}
        buttonLabel="Get Started"
      />
      <Link
        onPress={() => onDismiss(ONBOARDING_TILE_EVENT)}
        style={styles.link}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default OnboardingOpportunityTile;

const styles = {
  link: {
    zIndex: 2,
  },
};
