import ForgotPasswordContainer from './ForgotPasswordContainer';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthnTransaction } from '@okta/okta-auth-js/types/lib/authn';
import { useEffect, useState } from 'react';
import { MLError, TrackService } from '@core/services';
import { isOktaError, OktaResponseError } from '../types';
import { OktaErrorMessage } from '../OktaErrorMessage';
import PasswordValidator, {
  validatePasswordComplexity,
} from './PasswordValidator';
import { HookFormTextField } from '@core/components/Form';
import { ContactUsMessage } from '../Login/ContactUsMessage';
import LoginActionButtons from '../Login/LoginActionButtons';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please enter your new password.')
    .test(
      'meets-complexity',
      'Please make sure your new password meets the complexity requirements listed below.',
      (value) => (value ? validatePasswordComplexity(value).isValid : false),
    ),
  repeatPassword: Yup.string()
    .required('Please enter your new password again.')
    .oneOf(
      [Yup.ref('newPassword')],
      'Please correctly enter your new password again.',
    ),
});
interface FormValues {
  newPassword: string;
  repeatPassword: string;
}

const defaultValues: FormValues = {
  newPassword: '',
  repeatPassword: '',
};

interface Props {
  authTransaction: AuthnTransaction;
  onSuccess: (transaction: AuthnTransaction) => void;
  onCancel: () => void;
}

const ResetPassword = ({ authTransaction, onSuccess, onCancel }: Props) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues,
  });
  const {
    handleSubmit: hookFormSubmit,
    watch: subscribeToValue,
    getValues, // NOTE: The values do not get updated on rerenders, use watch for that
    formState: { errors, isValid, isSubmitting, touchedFields },
  } = form;
  const fieldNames = Object.keys(getValues());

  const [authError, setAuthError] = useState<OktaResponseError>();
  const [maskPassword, setMaskPassword] = useState(true);
  const [maskRepeatPassword, setMaskRepeatPassword] = useState(true);

  useEffect(() => {
    TrackService.page('Reset Password');
  }, []);

  const handleSubmit = hookFormSubmit(async ({ newPassword }) => {
    setAuthError(undefined);
    try {
      const transaction = await resetPassword(authTransaction, newPassword);
      onSuccess(transaction);
    } catch (e) {
      if (isOktaError(e)) {
        setAuthError(e);
      } else {
        throw e;
      }
    }
  });

  return (
    <ForgotPasswordContainer header="Reset Password" signInButtonHeader={false}>
      <FormProvider {...form}>
        <HookFormTextField
          autoFocus
          name="newPassword"
          label="New Password"
          secureTextEntry={maskPassword}
          action={{
            label: maskPassword ? 'Show' : 'Hide',
            onPress: () => {
              setMaskPassword(!maskPassword);
            },
          }}
          error={errors.newPassword?.message}
        />
        <PasswordValidator password={subscribeToValue('newPassword')} />

        <div className="mb3">
          <HookFormTextField
            name="repeatPassword"
            label="Repeat Password"
            secureTextEntry={maskRepeatPassword}
            action={{
              label: maskRepeatPassword ? 'Show' : 'Hide',
              onPress: () => {
                setMaskRepeatPassword(!maskRepeatPassword);
              },
            }}
            error={errors.repeatPassword?.message}
          />
        </div>

        {authError && <OktaErrorMessage error={authError} onReset={onCancel} />}

        <LoginActionButtons
          submitButton={{
            onPress: handleSubmit,
            loading: isSubmitting,
            text: 'Reset Password',
            disabled:
              !isValid &&
              Object.values(touchedFields).length === fieldNames.length,
          }}
          onCancel={onCancel}
        />
        <ContactUsMessage />
      </FormProvider>
    </ForgotPasswordContainer>
  );
};

const resetPassword = async (
  authTransaction: AuthnTransaction,
  newPassword: string,
): Promise<AuthnTransaction> => {
  try {
    if (!authTransaction.resetPassword) {
      throw new Error('resetPassword function is undefined');
    }
    return await authTransaction.resetPassword({ newPassword });
  } catch (error) {
    if (error instanceof Error)
      MLError.report({
        name: error.name,
        error,
      });

    throw error;
  }
};

export default ResetPassword;
