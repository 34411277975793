import { Button, H4, P2 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import PersonOnCalendar from '@core/assets/personOnCalendar.svg';

import { useEffect } from 'react';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';

import { ContentCard } from '@core/components/General/ContentCard';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { AccountBreadcrumbs } from '@core/components/Account/AccountBreadcrumbs';

const AddAccountSuccessAutopayPrompt = () => {
  const navigate = useNavigate();
  const accountSummaryPath = useAccountSummaryPath();
  const { isMobile } = useUserDevice();
  const { trackClick, trackEvent } = useTracking();

  const onGoHomeClick = () => {
    trackClick({
      name: 'Not now',
      feature: 'Add Account Success Autopay Prompt',
    });
    navigate(accountSummaryPath, { replace: true });
  };

  const onSetupClick = () => {
    trackClick({
      name: 'Set up',
      feature: 'Add Account Success Autopay Prompt',
    });
    navigate('../../../payments/autopay', { replace: true });
  };

  useEffect(() => {
    trackEvent({
      eventName: `Viewed Add Account Success Autopay Prompt`,
    });
  }, []);

  return (
    <PageWrapper greyBackground={!isMobile}>
      <AccountBreadcrumbs items={[{ text: 'Add a Checking Account' }]} />
      <ContentCard className="pv7 flex flex-column items-center justify-center">
        <img src={PersonOnCalendar} alt="person on calendar" className="mb4" />

        <H4 style={{ textAlign: 'center' }}>
          Set up recurring payments with autopay?
        </H4>
        <P2 style={{ textAlign: 'center' }}>
          An autopay plan can simply act as a backup if you need it. You can
          still make additional payments or edit your autopay plan.
        </P2>
        <div className="mt3">
          <Button text="Set up" onPress={onSetupClick} />
          <Button text="Not now" onPress={onGoHomeClick} variant="text" />
        </div>
      </ContentCard>
    </PageWrapper>
  );
};

export default AddAccountSuccessAutopayPrompt;
