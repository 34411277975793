import ChangePhoneLayout from './ChangePhoneLayout';
import { useState } from 'react';
import Cvv from './Cvv';
import ChangePhoneSuccess from './ChangePhoneSuccess';
import ChangePhone from './ChangePhone/ChangePhone';
import { GET_CHANGE_PHONE_ELIGIBILITY_QUERY } from '@core/queries/getChangePhoneEligibilityQuery';
import { LoadingPage } from '../General/Loading';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@apollo/client';
import {
  CvvVerification,
  GetChangePhoneEligibilityQuery,
  UpdatePhone,
} from '@core/graphql/globalTypes';

const ChangePhonePage = () => {
  const { loading, data, error } = useQuery<GetChangePhoneEligibilityQuery>(
    GET_CHANGE_PHONE_ELIGIBILITY_QUERY,
  );

  const navigate = useNavigate();
  const [page, setPage] = useState<
    undefined | 'CVV' | 'CHANGE_PHONE' | 'CHANGE_PHONE_SUCCESS'
  >('CVV');

  const [tokenizedCvv, setTokenizedCvv] =
    useState<CvvVerification.CvvVerification['tokenizedCvv']>('');

  const [updatedPhoneNumber, setUpdatedPhoneNumber] =
    useState<UpdatePhone.UpdatePhone['phoneNumber']>('');

  if (loading) return <LoadingPage />;
  const creditCards = data?.creditCards || [];
  const hasMoreThanOneCard = data && creditCards.length > 1;

  if (
    error ||
    hasMoreThanOneCard ||
    !data?.customer?.contactInfo.fraudRulesEligibility.eligible
  ) {
    navigate('/', { replace: true });
    return null;
  }

  const renderPage = () => {
    switch (page) {
      case 'CHANGE_PHONE':
        return (
          <ChangePhone
            tokenizedCvv={tokenizedCvv}
            onSuccess={(phoneNumber) => {
              setUpdatedPhoneNumber(phoneNumber);
              setPage('CHANGE_PHONE_SUCCESS');
            }}
          />
        );
      case 'CHANGE_PHONE_SUCCESS':
        return <ChangePhoneSuccess updatedPhoneNumber={updatedPhoneNumber} />;
      case 'CVV':
      default:
        return (
          <Cvv
            accountId={creditCards[0].id}
            onSuccess={(validTokenizedCvv) => {
              setTokenizedCvv(validTokenizedCvv);
              setPage('CHANGE_PHONE');
            }}
          />
        );
    }
  };

  return (
    <ChangePhoneLayout>
      <div>{renderPage()}</div>
    </ChangePhoneLayout>
  );
};

const ChangePhonePageWithFeatureFlag = () => {
  const { showChangePhone } = useFlags();
  const navigate = useNavigate();

  if (!showChangePhone) {
    navigate('/', { replace: true });
    return null;
  }

  return <ChangePhonePage />;
};

export default ChangePhonePageWithFeatureFlag;
