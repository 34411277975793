import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import ChargedOffBanner from './ChargedOffBanner';
import { useFlags } from 'launchdarkly-react-client-sdk';
import HasPastDueBalancePaymentBanner from '../HasPastDueBalancePaymentBanner/HasPastDueBalancePaymentBanner';

const AuthenticatedBanners = () => {
  const { isAuthenticated } = useRiskAuth();
  const { showHcrExperience, enableAccountSummaryCardArt } = useFlags();

  if (!isAuthenticated) return null;

  return (
    <div>
      {showHcrExperience && <ChargedOffBanner />}
      {enableAccountSummaryCardArt && <HasPastDueBalancePaymentBanner />}
    </div>
  );
};

export default AuthenticatedBanners;
