import plaidStart from '@core/assets/plaidStart.svg';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import Modal from '@core/components/General/Modal/Modal';

export interface PlaidModalProps {
  isOpen: boolean;
  loading: boolean;
  setShowPlaidModal: (showPlaidModal: boolean) => void;
  launchPlaid: () => void;
}

const PlaidModal = ({
  isOpen,
  loading,
  setShowPlaidModal,
  launchPlaid,
}: PlaidModalProps) => {
  function closeModal() {
    setShowPlaidModal(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      headerText="Easily link your bank with Plaid."
      onClose={closeModal}
      primaryButton={{ text: 'Take me to Plaid', onPress: launchPlaid }}
    >
      <div className="mw6" data-testid={'modal-client-id'}>
        {!loading ?
          <>
            <div className="flex justify-center">
              <img className="w-60" src={plaidStart} alt="Plaid Icon" />
            </div>
            <p className="mb3">
              We use Plaid to securely connect your bank account to your Mission
              Lane account.
            </p>
            <p>
              By linking your bank account through Plaid, you allow us to
              receive information from Plaid and send you other offerings to
              support your financial journey.
            </p>
          </>
        : <LoadingSpinner />}
      </div>
    </Modal>
  );
};

export default PlaidModal;
