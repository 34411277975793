import Modal from '@core/components/General/Modal/Modal';
import creditProtectionCardAndShield from '@core/assets/CreditProtectionCardAndShield.svg';
import { useEffect, useState } from 'react';
import { P3 } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MLFlags } from 'flags';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useNavigate } from 'react-router-dom';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { CREDIT_PROTECTION_QUERY } from '@core/components/CreditProtection/creditProtectionQuery';
import { CreditProtectionQuery } from '@core/graphql/globalTypes';

export const CREDIT_PROTECTION_MODAL_ID = 'credit-protection-modal';

const CreditProtectionModal = () => {
  const { creditProtectionMarketing } = useFlags<MLFlags>();
  const { trackClick, trackEvent } = useTracking();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(
    !Boolean(localStorage.getItem(CREDIT_PROTECTION_MODAL_ID)),
  );

  const { data } = useAccountIdQuery<CreditProtectionQuery>(
    CREDIT_PROTECTION_QUERY,
    {
      skip: creditProtectionMarketing === 'DISABLED',
    },
  );

  const showCreditProtectionModal =
    data?.account?.creditProtectionInfo?.isEligibleForMarketing &&
    (creditProtectionMarketing === 'MODAL_TILE_A' ||
      creditProtectionMarketing === 'MODAL_TILE_B');

  const onClose = () => {
    trackClick({
      name: 'Close',
      feature: CREDIT_PROTECTION_MODAL_ID,
    });
    setIsOpen(false);
    localStorage.setItem(CREDIT_PROTECTION_MODAL_ID, dayjs().toString());
  };

  const onLearnMore = () => {
    trackClick({
      name: 'Learn More',
      feature: CREDIT_PROTECTION_MODAL_ID,
    });
    navigate('../credit-protection');
  };

  useEffect(() => {
    if (showCreditProtectionModal && isOpen) {
      trackEvent({
        eventName: 'Modal Viewed',
        feature: CREDIT_PROTECTION_MODAL_ID,
      });
    }
  }, []);

  if (!showCreditProtectionModal) {
    return null;
  }

  return (
    <Modal
      headerText="Introducing Mission Lane Credit Protection"
      isOpen={isOpen}
      onClose={onClose}
      secondaryButton={{
        onPress: onClose,
        text: 'Close',
      }}
      primaryButton={{
        onPress: onLearnMore,
        text: 'Learn More',
      }}
      maxWidth="640px"
    >
      <div>
        <div
          className="flex items-center justify-center br2 mb4 mt4"
          style={{ height: '184px' }}
        >
          <img className="h-75" src={creditProtectionCardAndShield} />
        </div>
        <P3>
          Enrolling in Mission Lane Credit Protection can help with your card
          account payments if you experience certain financial hardships,
          including job loss, auto repairs, hospitalization, and medical bills.
        </P3>
      </div>
    </Modal>
  );
};

export default CreditProtectionModal;
