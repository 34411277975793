import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { H3 } from '@missionlane/compass-ui';
import cx from 'classnames';

export type Title = {
  primaryText: string;
  secondaryText?: string;
};

interface TitleBarProps {
  title: Title;
}

const TitleBar = ({ title }: TitleBarProps) => {
  const { primaryText, secondaryText } = title;
  const { isMobile } = useUserDevice();
  // Compass does not currently support a header component with this font size
  // but it will in v0.5.8 with context-agnostic typography
  const styleOverrides =
    isMobile ? { fontSize: 18, lineHeight: 22.5 } : undefined;

  return (
    // The title bar changes too often based on time of day so we
    // use data-chromatic to ignore it in our e2e UI tests
    <div
      className={cx('flex flex-wrap pt3 pt4-l', { nr4: isMobile })}
      data-chromatic="ignore"
    >
      <H3 color="greenWashed" style={styleOverrides}>
        {primaryText}&nbsp;
      </H3>
      <H3 color="white" style={styleOverrides}>
        {secondaryText}
      </H3>
    </div>
  );
};

export default TitleBar;
