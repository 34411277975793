import PageWrapper from '../Page/PageWrapper';
import { ReactNode } from 'react';

import Breadcrumbs from '@core/components/General/Breadcrumbs/Breadcrumbs';
import { HomeBreadcrumb } from '@core/components/General/Breadcrumbs/common';

const breadcrumbs = [
  HomeBreadcrumb,
  {
    text: 'My profile',
    link: '../my-profile',
  },
  {
    text: 'Update number',
  },
];

interface ChangePhoneLayoutProps {
  children: ReactNode;
}

const ChangePhoneLayout = ({ children }: ChangePhoneLayoutProps) => (
  <PageWrapper pageTitle={{ primaryText: 'Update number' }} greyBackground>
    <Breadcrumbs items={breadcrumbs} />
    {children}
  </PageWrapper>
);

export default ChangePhoneLayout;
