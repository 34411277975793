import { cardSupportPhone, recoveriesPhone } from '@core/utils/contact';
import PhoneLink, { PhoneLinkProps } from './PhoneLink';
interface Props extends Omit<PhoneLinkProps, 'phoneNumber'> {
  /** if true, link calls recoveries, ie for charged off customers */
  shouldCallRecoveries?: boolean;
  /** name of the feature that uses the support phone link, for Segment tracking */
  feature?: string;
}

const SupportPhoneLink = ({
  shouldCallRecoveries,
  feature,
  tracking = { name: 'Call Support' },
  ...phoneLinkProps
}: Props) => {
  if (feature) {
    tracking.properties = { ...tracking.properties, feature };
  }

  return (
    <PhoneLink
      phoneNumber={shouldCallRecoveries ? recoveriesPhone : cardSupportPhone}
      tracking={tracking}
      {...phoneLinkProps}
    />
  );
};

export default SupportPhoneLink;
