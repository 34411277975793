import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingSpinnerPage } from '../General/LoadingSpinner';
import GenericFallbackFull from '../GenericFallbacks/GenericFallbackFull';

const CreditProtectionRedirect = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const accountId = queryParams.get('accountId');

  useEffect(() => {
    if (accountId) {
      const redirectLink = `${process.env.REACT_APP_CREDIT_PROTECTION_URL}?RelayState=${accountId}`;
      window.location.href = redirectLink;
    }
  }, []);
  return accountId ? <LoadingSpinnerPage /> : <GenericFallbackFull />;
};

export default CreditProtectionRedirect;
