import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { centsToDollars } from '@core/utils/centsToDollars';
import { B, Button, Fonts, H4, P2 } from '@missionlane/compass-ui';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { useNavigate } from 'react-router-dom';
import DashboardFooter from './DashboardFooter';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';

const FulfilledSIFDashboard = () => {
  const navigate = useNavigate();
  const { isMobile } = useUserDevice();
  const { isDebitEligible } = usePaymentStatus();
  const { activeOffer } = useSettlementOffers();

  useEffect(() => {
    TrackService.trackPage('Charged Off Dashboard - SIF Offer Complete', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  if (activeOffer === undefined) {
    return <LoadingSpinner />;
  }

  if (activeOffer === null) {
    throw new Error('No offer data to display');
  }

  const {
    originalOutstandingBalance,
    originalSettlementBalance,
    remainingBalance,
  } = activeOffer.data;

  const settledBalance = centsToDollars(
    originalSettlementBalance - remainingBalance,
  );
  const forgivenBalance = centsToDollars(
    originalOutstandingBalance - originalSettlementBalance + remainingBalance,
  );

  const balanceOverviewKardHeader: KardHeader = {
    level: 'H4',
    textPrimary: 'Balance Overview',
    className: 'mb2',
  };

  const makeAnotherPaymentKardHeader: KardHeader = {
    level: isMobile ? 'H2' : 'H3',
    textPrimary: 'You’ve paid off your',
    textSecondary: 'settlement balance!',
    className: 'mb3',
  };
  return (
    <>
      <div className="flex flex-column flex-column-reverse flex-row-ns">
        <div className="w-50-ns mt2 mt0-ns mr4-ns">
          <Kard header={balanceOverviewKardHeader}>
            <div className="flex justify-between items-end w-100">
              <div className="flex flex-column">
                <ResponsiveTypography
                  type="HEADER"
                  mobileLevel="H1"
                  desktopLevel="H2"
                >
                  {settledBalance}
                </ResponsiveTypography>
                <P2 style={{ margin: 0 }} color="inkLight">
                  Settled balance
                </P2>
              </div>
              <div className="flex flex-column items-end tr">
                <H4 style={{ fontFamily: Fonts.semiBold }} color="inkLight">
                  {forgivenBalance}
                </H4>
                <P2 style={{ margin: 0 }} color="inkLight">
                  Forgiven balance
                </P2>
              </div>
            </div>
          </Kard>
        </div>
        <div className="w-50-ns">
          <Kard header={makeAnotherPaymentKardHeader}>
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              className="mb3"
            >
              Way to go! You’ve paid off your full settlement balance.
            </ResponsiveTypography>
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              className="mb5"
            >
              <B color="ink">You aren’t required to repay</B> the forgiven
              balance, but you can still{' '}
              <B color="ink">reach paid in full status</B> if you do.
            </ResponsiveTypography>
            <div className="flex-ns">
              <Button
                onPress={() => {
                  !!isDebitEligible ?
                    navigate('../payments/pay')
                  : navigate('../payments/pay/make-ach-payment');
                }}
                text="Make Another Payment"
              />
            </div>
          </Kard>
        </div>
      </div>
      <DashboardFooter
        CallToAction={
          <div style={{ marginLeft: '-8px', marginBottom: '24px' }}>
            <Button
              size="small"
              text="Access Account Details"
              variant="text"
              onPress={() => navigate('../details')}
              icon={{ name: 'forward' }}
            />
          </div>
        }
        Disclaimer={
          <ResponsiveTypography
            className="flex"
            type="PARAGRAPH"
            mobileLevel="P3"
            desktopLevel="P4"
          >
            This is an attempt to collect a debt and any information obtained
            will be used for that purpose.
          </ResponsiveTypography>
        }
      />
    </>
  );
};

export default FulfilledSIFDashboard;
