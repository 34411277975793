import { gql } from '@apollo/client';

export const CREATE_AUTOPAY = gql`
  mutation CreateAutopay(
    $accountId: String!
    $source: Source!
    $authorizationText: String!
    $fundingAccountId: Long!
    $paymentType: PaymentType!
    $accountType: AccountType!
    $amount: Int!
    $dayOfMonth: Int!
  ) {
    createAutopay(
      accountId: $accountId
      source: $source
      authorizationText: $authorizationText
      fundingAccountId: $fundingAccountId
      paymentType: $paymentType
      accountType: $accountType
      amount: $amount
      dayOfMonth: $dayOfMonth
    ) {
      amount
      confirmationId
      dayOfMonth
      eftaUrl
      fundingAccount {
        id
        numberLast4
        bankName
      }
      id
      nextPaymentDate
      paymentType
      scheduleStatus
    }
  }
`;

export const UDPATE_AUTOPAY = gql`
  mutation UpdateAutopay(
    $accountId: String!
    $source: Source!
    $id: String!
    $authorizationText: String!
    $fundingAccountId: Long!
    $paymentType: PaymentType!
    $amount: Int!
    $dayOfMonth: Int!
  ) {
    updateAutopay(
      accountId: $accountId
      source: $source
      id: $id
      authorizationText: $authorizationText
      fundingAccountId: $fundingAccountId
      paymentType: $paymentType
      amount: $amount
      dayOfMonth: $dayOfMonth
    ) {
      amount
      confirmationId
      dayOfMonth
      eftaUrl
      fundingAccount {
        id
        numberLast4
        bankName
      }
      id
      nextPaymentDate
      paymentType
      scheduleStatus
    }
  }
`;
