import { List, ListItem, P3 } from '@missionlane/compass-ui';
import {
  ScheduledPaymentListItemProps,
  ScheduledPaymentListItem,
} from './ScheduledPaymentListItem';

interface ScheduledPaymentsListProps {
  scheduledPayments: ScheduledPaymentListItemProps[];
}

const ScheduledPaymentsList = ({
  scheduledPayments,
}: ScheduledPaymentsListProps) => {
  return (
    <>
      <List variant="transaction">
        {scheduledPayments.map((payment, key) => (
          <ListItem key={key}>
            <ScheduledPaymentListItem {...payment} />
          </ListItem>
        ))}
      </List>
      <div className="mt4">
        <P3>
          Payments can be canceled up until 11:59 p.m. ET one day before payment
          date.
        </P3>
      </div>
    </>
  );
};

export default ScheduledPaymentsList;
