import { P3 } from '@missionlane/compass-ui';

import Kard from '@core/components/General/Kard/Kard';
import NextLink from '@core/components/General/NextLink';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';

const FinancialLiteracyKardHeadline = () => (
  <div className="bg-washed-purple h4 overflow-hidden">
    <div
      className="ba b--light-purple ba bw5 br-100 w5 h5 center flex items-center justify-center"
      style={{ transform: 'translate(0, -25%)' }}
    >
      <i className="fas fa-graduation-cap f-headline lh-title purple" />
    </div>
  </div>
);

const FinancialLiteracyKard = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  return (
    <Kard
      headline={<FinancialLiteracyKardHeadline />}
      header={{
        textPrimary: 'Boost your financial literacy',
        className: 'mb3',
        level: 'H4',
      }}
    >
      <P3>
        Watch 2–4 minute videos, designed to help you improve your financial
        health.
      </P3>
      <div className="pt4 pt5-ns">
        <NextLink
          to={`${process.env.REACT_APP_MONO_URL}/education`}
          trackingName="Account Summary: (Financial literacy) Start learning link"
          trackingProperties={customerAndAccountIds}
        >
          Start learning
        </NextLink>
      </div>
    </Kard>
  );
};

export default FinancialLiteracyKard;
